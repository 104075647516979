import { IsString, IsUUID } from "class-validator";

export enum ESkillCategory {
  METHODICAL_ID = 1,
  FUNCTIONAL_ID = 2,
  GENERAL_ID = 3,
  LEADERSHIP_ID = 4
}

export default class SkillCategory {
  @IsUUID("4")
  public readonly id?: number | null = null;

  @IsString()
  public readonly value!: string;
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import testings_routes from "@/api/routes/testings";

import { EPermission } from "@/enums/permissions";
import { ETestingStatus } from "@/models/testing/testing";
import TestingFolder, {
  ITestingData,
  ITestingFolder
} from "@/models/testing/folder";
import { IChangedTesting, IChangingTesting } from "./TestingChange.vue";
import { IChangeArchiveTesting } from "./ChangeArchiveTesting.vue";

@Component({
  name: "CTestingsTree"
})
export default class CTestingsTree extends Vue {
  @Prop({ required: false, default: null }) public parent_id!: string | null;

  public ETestingStatus = ETestingStatus;

  public folders: TestingFolder[] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.createdTestingFolder,
        (createdTestingFolder: TestingFolder) => {
          if (this.parent_id === createdTestingFolder.parent_folder_id) {
            this.folders.unshift(createdTestingFolder);

            this.$emit("childrenChanged", {
              parent_id: this.parent_id,
              children_exist: true
            });
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.updatedTestingFolder,
        (updatedTestingFolder: TestingFolder) => {
          if (this.parent_id === updatedTestingFolder.parent_folder_id) {
            const folder = this.folders.find(
              f => f.id === updatedTestingFolder.id
            );

            if (folder) {
              this.$set(folder, "name", updatedTestingFolder.name);
            }
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.deletedTestingFolder,
        (deletedTestingFolder: ITestingFolder) => {
          if (this.parent_id === deletedTestingFolder.parent_folder_id) {
            const folder_idx = this.folders.findIndex(
              f => f.id === deletedTestingFolder.id
            );

            if (folder_idx !== -1) {
              this.$delete(this.folders, folder_idx);
            }

            if (!this.folders.length) {
              this.$emit("childrenChanged", {
                parent_id: this.parent_id,
                children_exist: false
              });
            }
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedTesting,
        (changedTesting: IChangedTesting) => {
          const folder_idx = this.folders.findIndex(
            f => f.id === changedTesting.parent_folder_id
          );

          if (folder_idx === -1) {
            return;
          }

          if (!Array.isArray(this.folders[folder_idx].testings)) {
            this.$set(this.folders[folder_idx], "testings", []);
          }

          const testing_idx = this.folders[folder_idx].testings!.findIndex(
            testing => testing.id === changedTesting.id
          );

          if (testing_idx !== -1) {
            const testing = this.folders[folder_idx].testings![testing_idx];

            testing.name = changedTesting.name;
          } else {
            this.folders[folder_idx].testings!.push({
              id: changedTesting.id,
              name: changedTesting.name,
              is_select: false,
              status: ETestingStatus.CREATED
            });
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.movedFolder,
        async (movedFolder: {
          id: string;
          parent_folder_id: string | null;
        }) => {
          if (this.parent_id === movedFolder.parent_folder_id) {
            await this.loadFolders();
          } else {
            const folderIdx = this.folders.findIndex(
              f => f.id === movedFolder.id
            );

            if (folderIdx !== -1) {
              this.$delete(this.folders, folderIdx);
            }
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.movedTestings,
        async (_movedTestings: number) => {
          if (this.parent_id == null) {
            await this.loadFolders();
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedArchiveTestings,
        (changedArchiveTestings: IChangeArchiveTesting) => {
          if (changedArchiveTestings.parent_folder_id === this.parent_id) {
            if (
              changedArchiveTestings.folder_idx != null &&
              changedArchiveTestings.testing_idx != null
            ) {
              const folder = this.folders[changedArchiveTestings.folder_idx];

              if (changedArchiveTestings.is_archive) {
                this.$delete(
                  folder.testings!,
                  changedArchiveTestings.testing_idx
                );
              }
            }
          }
        }
      )
    );

    await this.loadFolders();
  }

  public childrenChanged(data: { parent_id: string; children_exist: boolean }) {
    const folder = this.folders.find(f => f.id === data.parent_id);

    if (folder) {
      if (data.children_exist || !folder.testings?.length) {
        this.$set(folder, "children_exist", data.children_exist);
      }
    }
  }

  private async loadFolders() {
    this.$api
      .get(testings_routes.folders, {
        params: {
          folder_id: this.parent_id
        }
      })
      .then(({ data: res }: { data: TestingFolder[] }) => {
        this.folders = plainToClass(TestingFolder, res);

        if (this.$store.state.selectedTestingIdsSize) {
          this.folders.forEach(f => {
            if (f.testings?.length) {
              f.testings.forEach(t => {
                if (this.$store.state.selectedTestingIds.has(t.id)) {
                  t.is_select = true;
                }
              });
            }
          });
        }
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public toggleFolder(folder: TestingFolder) {
    this.$set(folder, "is_show", !folder.is_show);
  }

  public newFolder() {
    const edit_folder: ITestingFolder = {
      id: null,
      name: "",
      parent_folder_id: this.parent_id
    };

    this.$store.commit("creatingTestingFolder", edit_folder);
  }

  public editFolder(folder: TestingFolder) {
    const edit_folder: ITestingFolder = {
      id: folder.id,
      name: folder.name,
      parent_folder_id: this.parent_id
    };

    this.$store.commit("updatingTestingFolder", edit_folder);
  }

  public deleteFolder(folder: TestingFolder) {
    if (folder.testings?.length || folder.children_exist) {
      this.$notify({
        group: "notifications",
        type: "error",
        text:
          "Нельзя удалить папку с тестами. Переместите тесты в другое место.",
        speed: 500
      });

      return;
    }

    const edit_folder: ITestingFolder = {
      id: folder.id,
      name: folder.name,
      parent_folder_id: this.parent_id
    };

    this.$store.commit("deletingTestingFolder", edit_folder);
  }

  public createTesting() {
    const changing_testing: IChangingTesting = {
      id: null,
      parent_folder_id: this.parent_id
    };

    this.$store.commit("changingTesting", changing_testing);
  }

  public updateTesting(testing: ITestingData, folder_id: string) {
    const changing_testing: IChangingTesting = {
      id: testing.id,
      parent_folder_id: folder_id
    };

    this.$store.commit("changingTesting", changing_testing);
  }

  public async archiveTesting(
    f_index: number,
    t_index: number,
    testing: ITestingData
  ) {
    this.$store.commit("changingArchiveTestings", {
      id: testing.id,
      name: testing.name,
      parent_folder_id: this.parent_id,
      is_select: testing.is_select,
      is_archive: false,
      folder_idx: f_index,
      testing_idx: t_index
    });
  }

  public movingFolder(folder: TestingFolder) {
    this.$store.commit("movingFolderId", folder.id);
  }

  public toggleSelected(testing: ITestingData) {
    testing.is_select = !testing.is_select;

    this.$store.commit("toggleTestingId", testing.id);
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testings);
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";

import CPeriodDatePicker from "@/components/PeriodDatePicker.vue";

import { AssignmentUserWorkplace } from "@/models/akt/assignment_user_workplace";
import { plainToClass } from "class-transformer";

interface IAnswer {
  label: string;
  value: number;
}

@Component({
  name: "CTeamAktAssignmentNewAttempt",
  components: {
    CPeriodDatePicker
  }
})
export default class CTeamAktAssignmentNewAttempt extends Vue {
  @Prop({ required: true })
  public readonly workplace!: AssignmentUserWorkplace | null;

  public curr_value: AssignmentUserWorkplace | null = plainToClass(
    AssignmentUserWorkplace,
    this.workplace
  );

  public answers: IAnswer[] = [
    {
      label: "Все вопросы",
      value: 1
    },
    {
      label: "Только неверное отвеченные",
      value: 2
    }
  ];
  public selected_answer: IAnswer = { ...this.answers[0] };

  public async changeStartsAt(starts_at: string) {
    if (this.curr_value) {
      this.curr_value.attempt_starts_at = starts_at;
    }
  }

  public async changeEndsAt(ends_at: string) {
    if (this.curr_value) {
      this.curr_value.attempt_ends_at = ends_at;
    }
  }

  protected openModal() {
    this.$modal.show("assignment-new-attempt-modal");
  }

  protected closeModal() {
    this.$modal.hide("assignment-new-attempt-modal");
  }

  @Watch("workplace")
  protected changeValue() {
    this.curr_value = plainToClass(AssignmentUserWorkplace, this.workplace);

    if (this.curr_value) {
      this.openModal();
    } else {
      this.closeModal();
    }
  }

  public btnCancel() {
    this.curr_value = null;
    this.emitWorkplace();
  }

  public async btnDone() {
    if (this.curr_value) {
      return this.$api
        .put(akt_routes.put_assignment_user_workplace(this.curr_value.id), {
          attempt_starts_at: this.curr_value.attempt_starts_at,
          attempt_ends_at: this.curr_value.attempt_ends_at,
          attempt_all_subs: this.selected_answer.value === 1
        })
        .then(() => {
          this.emitWorkplace();

          this.$notify({
            group: "notifications",
            type: "success",
            text: "Изменения сохранены",
            speed: 500
          });
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  public emitWorkplace() {
    this.$emit("input", this.curr_value);
  }
}

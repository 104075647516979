
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Upload extends Vue {
  @Prop() private accept!: string[];
  @Prop() private outline!: boolean;
  @Prop() private minView!: boolean;
  @Prop() private newForm!: boolean;
  @Prop({ default: () => false }) private disabled!: boolean;
  @Prop({ default: () => false }) private custom!: boolean;
  @Prop({ default: () => "" }) private customClass!: string;
  @Prop({ default: () => false }) private sizeLimitation!: boolean;
  @Prop({ default: () => "" }) private diffFileExtension!: string;

  private file: File | null = null;
  private uploadProgress: number = 0;
  private uploadErrors: string | string[] | null = null;

  private randomNumber: number = Math.random();

  $refs!: {
    fileform: HTMLFormElement;
    file: HTMLFormElement;
  };

  private handleFile() {
    if (this.disabled) {
      return;
    }

    this.uploadProgress = 0;
    this.uploadErrors = null;

    this.file = this.$refs.file.files[0];
    // очистка значения, чтобы при повторном выборе того же файла произошло событие change
    this.$refs.file.value = "";

    this.uploadFile();
  }

  private defaultEvent(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }

  private dropFile(e: DragEvent) {
    if (this.disabled) {
      return;
    }

    this.uploadProgress = 0;
    this.uploadErrors = null;

    this.file = e.dataTransfer!.files[0];

    this.uploadFile();
  }

  private uploadFile() {
    if (!this.accept.includes(this.file!.type) && this.file!.type) {
      this.uploadErrors = "Тип файла недопустим: " + this.file!.type;
      this.file = null;
      return;
    }

    if (this.sizeLimitation && this.file!.size > 5242880) {
      this.uploadErrors = "Объем файла должен быть не больше 5 мегабайт";
      this.file = null;

      return;
    }

    const formData = new FormData();
    formData.append("file", this.file!);

    this.$api
      .upload(formData, {
        headers: {
          "Conent-Type": "multipart/form-data"
        },
        onUploadProgress: progressEvent => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      })
      .then(({ data: res }: { data: { url: string } }) => {
        this.$emit("input", res.url);
        this.$emit("inputFormat", this.file!.name.split(".").pop()!);
        this.$emit("fileChanged", this.file);
      })
      .catch(({ response: res }) => {
        this.uploadErrors = res.data.error;
      });
  }
}

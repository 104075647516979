import { IsUUID, IsOptional } from "class-validator";

export default class UnitUnit {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsUUID("4")
  public skill_id?: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.skill_id = data.skill_id;
    }
  }
}

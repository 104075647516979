
import { Component, Prop, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CPositionLevelWorkshop from "./Workshop.vue";

import StructureWorkshop from "@/models/structure/workshop";
import PositionLocalLevel from "@/models/position/level";
import PositionLocalLevelWorkplace from "@/models/position/level_workplace";

import positionRoutes from "@/api/routes/positions";
import structureRoutes from "@/api/routes/structures";

import { EPermission } from "@/enums/permissions";

@Component({
  name: "CPositionLevelFactoryUec",
  components: {
    Preloader,
    CPositionLevelWorkshop
  }
})
export default class CPositionLevelFactoryUec extends Vue {
  @Prop({ required: true }) protected factory_id!: string;

  protected preload: boolean = false;
  protected workshops: StructureWorkshop[] = [];
  protected position_levels: PositionLocalLevel[] = [];

  protected watchers: Function[] = [];

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.editedTreeLevel,
        (editedTreeLevel: PositionLocalLevelWorkplace) => {
          let check_self = false;

          if (
            editedTreeLevel.factory_id === this.factory_id &&
            !editedTreeLevel.infinity_id &&
            !editedTreeLevel.workshop_id &&
            !editedTreeLevel.workline_id &&
            !editedTreeLevel.equipment_id
          ) {
            check_self = true;
          }

          editedTreeLevel.levels.forEach(level => {
            const index = this.position_levels.findIndex(
              pl => pl.id === level.id
            );

            if (check_self) {
              if (index === -1) {
                this.position_levels.push({
                  id: level.id!,
                  level_id: level.level_id!,
                  workplace_id: editedTreeLevel.id,
                  structure_name: editedTreeLevel.structure_name,
                  level_name: level.level_name!
                });
              }
            } else {
              if (index !== -1) {
                this.$delete(this.position_levels, index);
              }
            }
          });

          editedTreeLevel.deletedLevels.forEach(level_id => {
            const index = this.position_levels.findIndex(
              pl => pl.id === level_id
            );

            if (index !== -1) {
              this.$delete(this.position_levels, index);
            }
          });
        }
      )
    );

    await Promise.all([this.loadLevels(), this.loadWorkshops()]);

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.positions);
  }

  protected async loadWorkshops() {
    return this.$api
      .get(structureRoutes.workshops, {
        params: { factory_id: this.factory_id }
      })
      .then(({ data: res }: { data: StructureWorkshop[] }) => {
        res.forEach(r => (r.is_show = false));

        this.workshops = res;
      });
  }

  protected async toggleWorkshop(workshop_index: number) {
    const workshop = this.workshops[workshop_index];

    workshop.is_show = !workshop.is_show;
  }

  protected async loadLevels() {
    return this.$api
      .get(positionRoutes.levels_tree, {
        params: {
          factory_id: this.factory_id
        }
      })
      .then(({ data: res }: { data: PositionLocalLevel[] }) => {
        this.position_levels = res;
      });
  }

  protected beforeUpdateLevel(level_index: number) {
    if (!this.canWrite()) {
      return;
    }

    const level = this.position_levels[level_index];

    this.$store.commit("editTreeLevel", level);
  }
}

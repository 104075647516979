import BlockBlock from "../models/block/block";

export function onDisplayZeroLevelSkillsChanged(
  blocks: BlockBlock[],
  displayZeroLevelSkills: boolean
): BlockBlock[] {
  blocks?.forEach(block => {
    block.skills?.forEach(
      skill =>
        (skill.display =
          displayZeroLevelSkills || (skill.level && skill.level > 0) || false)
    );
    block.skill_count =
      block.skills?.filter(skill => skill.display).length || 0;
  });
  return blocks;
}

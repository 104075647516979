
import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";
import { phases } from "@/consts";

import Upload from "@/components/Upload.vue";
import ImageView from "@/components/ImageView.vue";
import VideoView from "@/components/VideoView.vue";
import CAudioView from "@/components/audioView.vue";
import PdfView from "@/components/PdfView.vue";
import CFileDownload from "@/components/FileDownload.vue";

import RelationVersion from "@/models/relation/version";
import UnitVersion, { EUnitableType } from "@/models/unit/unit_version";
import EventVersion from "@/models/unit/unitable/event/version";
import UserUser from "@/models/user/user";

import unitRoutes from "@/api/routes/units";
import userRoutes from "@/api/routes/users";

@Component({
  components: {
    Upload,
    CFileDownload,
    ImageView,
    VideoView,
    CAudioView,
    PdfView
  }
})
export default class ConstructorProgramsMaterialsEvent extends Vue {
  @Prop({ required: true }) program_id!: string;
  @Prop({ required: true }) relation!: RelationVersion;

  @Prop({ required: false, default: false }) public disabled!: boolean;

  protected phases = phases.slice(1, 5);

  private acceptFiles: string[] = [
    "application/pdf",
    "image/jpeg",
    "image/bmp",
    "image/png",
    "video/mp4",
    "audio/mpeg",
    "audio/wav",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
    "application/vnd.ms-excel.sheet.macroenabled.12", // xlsm MacOS|Linux
    "application/vnd.ms-excel.sheet.macroEnabled.12", //xlsm Win
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
    "application/msword", // doc
    "application/vnd.ms-excel", // xlx
    "application/vnd.ms-powerpoint", // ppt
    "application/vnd.oasis.opendocument.text", // odt
    "application/vnd.oasis.opendocument.spreadsheet", // ods
    "application/vnd.oasis.opendocument.presentation", // odp
    "application/rtf" // rtf
  ];

  private unit_version: UnitVersion | null = null;

  private editErrors: string | null = null;

  private trainers: UserUser[] = [];

  private created() {
    this.loadUnitVersion();
    this.loadTrainers();
  }

  private loadUnitVersion() {
    if (this.relation.id) {
      this.$api
        .get(unitRoutes.version(this.relation.unit_version_id))
        .then(({ data: res }: { data: UnitVersion }) => {
          this.unit_version = plainToClass(UnitVersion, res);
        });
    } else {
      this.unit_version = new UnitVersion({
        relation_version: this.relation,
        unitable: new EventVersion(),
        unitable_type: EUnitableType["Event::Version"]
      });

      this.unit_version!.title = "Встреча";
    }
  }

  private changeFormatLeader(type: string) {
    (this.unit_version!.unitable as EventVersion).leader_file_type = type;
  }

  private changeFormatSubordinate(type: string) {
    (this.unit_version!.unitable as EventVersion).subordinate_file_type = type;
  }

  private loadTrainers() {
    this.$api.get(userRoutes.trainers).then(res => {
      this.trainers = res.data;
    });
  }

  private createEvent() {
    this.$api
      .post(unitRoutes.versions, {
        ...this.unit_version,
        ...{ relation: { program_id: this.program_id } }
      })
      .then(({ data: res }: { data: RelationVersion }) => {
        this.$emit("relationCreated", res);
        this.$emit("RelationAdd", true);
      })
      .catch(({ response: res }) => {
        this.editErrors = res.data.error;
      });
  }

  private updateEvent() {
    if (
      !window.confirm(
        "Вы уверены, что хотите сохранить новую редакцию материала?"
      )
    ) {
      return;
    }

    this.$api
      .put(unitRoutes.version(this.unit_version!.id), {
        ...this.unit_version,
        ...{
          relation_version: { id: this.relation.id, level: this.relation.level }
        }
      })
      .then(({ data: res }: { data: RelationVersion }) => {
        this.$emit("relationUpdated", res);
      })
      .catch(({ response: res }) => {
        this.editErrors = res.data.error;
      });
  }

  private deleteSubordinateFile() {
    (this.unit_version!.unitable as EventVersion).subordinate_url = null;
  }

  private deleteLeaderFile() {
    (this.unit_version!.unitable as EventVersion).leader_url = null;
  }
}


import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";
import CPrioritySkillFilters, {
  IPrioritySkillFiltersInput
} from "@/components/priority/skill_filters.vue";

import { CheckInteger } from "@/helpers/number";

import structureRoutes from "@/api/routes/structures";
import prioritiesRoutes from "@/api/routes/priorities";
import positionsRoutes from "@/api/routes/positions";
import skillsRoutes from "@/api/routes/skills";
import blocksRoutes from "@/api/routes/blocks";
import pillarsRoutes from "@/api/routes/pillars";
import signsRoutes from "@/api/routes/signs";

import StructureFactory from "@/models/structure/factory";
import { EPermission } from "@/enums/permissions";
import { compulsoryTabs, blocksTabs } from "@/models/tabs";
import { PriorityTypes } from "@/enums/priority_types";
import {
  IPriority,
  IPriorityPosition,
  IPriorityShort,
  IPrioritySkill,
  sort_fn
} from "@/models/priority/priority";
import SkillCategory from "@/models/skill/category";
import BlockBlock from "@/models/block/block";
import PillarPillar from "@/models/pillar/pillar";
import SignSign from "@/models/sign/sign";

import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    Preloader,
    Search,
    CStructure,
    CPrioritySkillFilters
  },
  data: () => {
    return {
      compulsoryTabs,
      blocksTabs,
      CheckInteger
    };
  }
})
export default class Priorities extends Vue {
  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected preload: boolean = false;
  protected preloadModal: boolean = false;
  protected saveLoader: boolean = false;

  protected watchers: Function[] = [];

  protected tabs: { id: string; value: string }[] = this.currentUser?.is_admin
    ? [
        { id: "common", value: "Общие" },
        { id: "local", value: "Подразделения" }
      ]
    : [{ id: "local", value: "Подразделения" }];
  protected currentTab = this.tabs[0];

  protected actions: { id: string; label: string }[] = [
    { id: "set_value", label: "Установить значение" },
    { id: "set_max", label: "Высший приоритет" }
  ];
  protected currentAction = this.actions[0];
  protected actionGeneralValue: number = 1000;
  protected actionEditValue: number = 1000;

  @Validations()
  protected validations = {
    actionGeneralValue: { required },
    actionEditValue: { required },
    generalPriority: {
      skills: {
        $each: {
          priority: { required }
        }
      }
    },
    editPriority: {
      skills: {
        $each: {
          priority: { required }
        }
      }
    }
  };

  protected generalPriority: IPriority | null = null;
  protected generalPrioritySkillChecked: string[] = [];

  protected editPriority: IPriority | null = null;
  protected editPriorityIndex: number | null = null;
  protected editPrioritySkillChecked: string[] = [];

  protected editPriorityPositionsList: IPriorityPosition[] = [];

  protected currentSkillId: string | null = null;

  protected deletePriorityIndex: number | null = null;
  protected priorities: IPriorityShort[] = [];
  protected current_page: number = 0;
  protected next_page: boolean = false;
  protected per_page: number = 100;
  protected infinite_preload: boolean = false;

  protected factories: StructureFactory[] = [];
  protected currentFactory: StructureFactory | null = null;

  protected categories: SkillCategory[] = [];
  protected blocks: BlockBlock[] = [];
  protected pillars: PillarPillar[] = [];
  protected signs: SignSign[] = [];

  /** Для применения фильтров */
  protected showGeneralSkills: boolean = true;
  protected showEditSkills: boolean = true;

  protected async created() {
    this.preload = true;

    await Promise.all([
      this.loadGeneralPriority(),
      this.loadPriorities(this.current_page),
      this.loadFactories(),
      this.loadCategories(),
      this.loadBlocks(),
      this.loadPillars(),
      this.loadSigns()
    ]);

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.preload = false;
  }

  protected changeTab(id: string) {
    const tab = this.tabs.find(c => c.id === id);

    if (tab) {
      this.currentTab = tab;
    }
  }

  protected selectGeneralPrioritySkill(index: number) {
    const skill = this.generalPriority!.skills![index];

    if (!skill.is_archived) {
      const checked = skill.checked;

      this.generalPriority!.skills![index].checked = !checked;
    }
  }

  protected selectEditPrioritySkill(index: number) {
    const skill = this.editPriority!.skills![index];

    if (!skill.is_archived) {
      const checked = this.editPriority!.skills![index].checked;

      this.editPriority!.skills![index].checked = !checked;
    }
  }

  protected changeGeneralPrioritySkillChecked(select: boolean = false) {
    if (this.generalPrioritySkillChecked.length && !select) {
      this.generalPrioritySkillChecked = [];

      this.generalPriority!.skills!.forEach(skill => {
        skill.checked = false;
      });
    } else {
      this.generalPrioritySkillChecked = [];

      this.generalPriority!.skills!.forEach(skill => {
        if (skill.show) {
          skill.checked = true;

          this.generalPrioritySkillChecked.push(skill.skill_id);
        }
      });
    }
  }

  protected changeEditPrioritySkillChecked(select: boolean = false) {
    if (this.editPrioritySkillChecked.length && !select) {
      this.editPrioritySkillChecked = [];

      this.editPriority!.skills!.forEach(skill => {
        skill.checked = false;
      });
    } else {
      this.editPrioritySkillChecked = [];

      this.editPriority!.skills!.forEach(skill => {
        if (skill.show) {
          skill.checked = true;

          this.editPrioritySkillChecked.push(skill.skill_id);
        }
      });
    }
  }

  protected async loadGeneralPriority() {
    return this.$api
      .get(prioritiesRoutes.general)
      .then(({ data: res }: { data: IPriority }) => {
        this.generalPriority = res;

        if (this.generalPriority.skills?.length) {
          this.generalPriority.skills.forEach(skill => {
            skill.show = true;
            skill.checked = false;
          });
        }
      });
  }

  protected async loadPosition(factory_id?: string) {
    if (!factory_id) {
      this.editPriorityPositionsList = [];

      return;
    }

    return this.$api
      .get(positionsRoutes.positions_factory(factory_id))
      .then(({ data: res }: { data: IPriorityPosition[] }) => {
        this.editPriorityPositionsList = res;
      });
  }

  protected async loadPriority(id: string, index: number) {
    return this.$api
      .get(prioritiesRoutes.show(id))
      .then(({ data: res }: { data: IPriority }) => {
        this.editPriority = res;
        this.editPriorityIndex = index;

        if (this.editPriority.skills?.length) {
          this.editPriority.skills.forEach(skill => {
            skill.show = true;
            skill.checked = false;
          });
        }

        this.currentFactory = this.factories.find(
          f => f.id === this.editPriority?.factory_id
        )!;
      })
      .then(async () => {
        await this.loadPosition(this.currentFactory!.id!);
      })
      .then(() => {
        this.openEditModal();
      });
  }

  protected async updateGlobalPriority() {
    if (this.$v.generalPriority.skills?.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    this.saveLoader = true;

    return this.$api
      .put(prioritiesRoutes.update(this.generalPriority!.id!), {
        priority: this.generalPriority
      })
      .then(() => {
        this.generalPriority!.skills = this.generalPriority!.skills?.sort(
          sort_fn()
        );

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async createPriority() {
    if (this.$v.editPriority.skills?.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    this.preloadModal = true;

    return this.$api
      .post(prioritiesRoutes.create, {
        priority: this.editPriority
      })
      .then(({ data: res }: { data: IPriorityShort }) => {
        this.priorities.push(res);

        this.closeEditModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Приоритет для подразделения создан",
          speed: 500
        });
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preloadModal = false;
      });
  }

  protected async updatePriority() {
    if (this.$v.editPriority.skills?.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    this.preloadModal = true;

    return this.$api
      .put(prioritiesRoutes.update(this.editPriority!.id!), {
        priority: this.editPriority
      })
      .then(({ data: res }: { data: IPriorityShort }) => {
        this.$set(this.priorities, this.editPriorityIndex!, res);

        this.closeEditModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preloadModal = false;
      });
  }

  protected async loadPriorities(page: number = 0) {
    this.infinite_preload = true;

    return this.$api
      .get(prioritiesRoutes.index, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(
        ({
          data: res
        }: {
          data: {
            current_page: number;
            next_page: boolean;
            priorities: IPriorityShort[];
          };
        }) => {
          this.current_page = res.current_page;
          this.next_page = res.next_page;

          this.priorities.push(...res.priorities);
        }
      )
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadPriorities(this.current_page + 1);
    }
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      });
  }

  protected openDeleteModal() {
    this.$modal.show("deletePriorityModal");
  }

  protected closeDeleteModal() {
    this.$modal.hide("deletePriorityModal");
    this.deletePriorityIndex = null;
  }

  protected openEditModal() {
    this.$modal.show("editPriorityModal");
  }

  protected closeEditModal() {
    this.$modal.hide("editPriorityModal");
    this.editPriority = null;
    this.editPriorityIndex = null;
    this.currentFactory = null;
    this.actionEditValue = 1000;
    this.editPrioritySkillChecked = [];
  }

  protected async deletePriorityConfirm(index: number) {
    this.deletePriorityIndex = index;

    this.openDeleteModal();
  }

  protected async deletePriority() {
    if (typeof this.deletePriorityIndex === "number") {
      return this.$api
        .destroy(
          prioritiesRoutes.destroy(
            this.priorities[this.deletePriorityIndex].id!
          )
        )
        .then(() => {
          this.$delete(this.priorities, this.deletePriorityIndex!);

          this.closeDeleteModal();

          this.$notify({
            group: "notifications",
            type: "success",
            text: "Приоритет для подразделения удалён",
            speed: 500
          });
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected async addPriority() {
    this.editPriority = {
      programs_count: 6,
      priority_type: PriorityTypes.SUBDIVISION,
      skills: []
    };

    this.openEditModal();
  }

  protected async changeFactory() {
    this.editPriority!.factory_id = this.currentFactory?.id;

    await this.loadPosition(this.editPriority!.factory_id!);

    await this.loadNewSkills();
  }

  protected async loadNewSkills() {
    return this.$api
      .get(prioritiesRoutes.new_skills, {
        params: {
          factory_id: this.editPriority!.factory_id,
          workshop_id: this.editPriority!.workshop_id,
          workline_id: this.editPriority!.workline_id,
          equipment_id: this.editPriority!.equipment_id,
          infinity_id: this.editPriority!.infinity_id,
          priority_id: this.editPriority!.id
        }
      })
      .then(({ data: res }: { data: IPrioritySkill[] }) => {
        this.editPriority!.skills = res;

        if (this.editPriority!.skills?.length) {
          this.editPriority!.skills.forEach(skill => {
            skill.show = true;
            skill.checked = false;
          });
        }
      });
  }

  protected async setStructure(structure: IStructureProp) {
    if (this.editPriority) {
      this.editPriority.factory_id = structure.factory_id || null;
      this.editPriority.workshop_id = structure.workshop_id || null;
      this.editPriority.workline_id = structure.workline_id || null;
      this.editPriority.equipment_id = structure.equipment_id || null;
      this.editPriority.infinity_id = structure.infinity_id || null;

      await this.loadNewSkills();
    }
  }

  protected setGeneralAction() {
    if (this.$v.actionGeneralValue.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    if (this.currentAction.id === "set_max") {
      this.generalPriority?.skills?.forEach(skill => {
        if (skill.checked) {
          skill.priority = 0;
        }
      });
    } else {
      this.generalPriority?.skills?.forEach(skill => {
        if (skill.checked) {
          skill.priority = this.actionGeneralValue;
        }
      });
    }
  }

  protected setEditAction() {
    if (this.$v.actionEditValue.$error) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Значение приоритета не может быть пустым",
        speed: 500
      });
      return;
    }

    if (this.currentAction.id === "set_max") {
      this.editPriority?.skills?.forEach(skill => {
        if (skill.checked) {
          skill.priority = 0;
        }
      });
    } else {
      this.editPriority?.skills?.forEach(skill => {
        if (skill.checked) {
          skill.priority = this.actionEditValue;
        }
      });
    }
  }

  protected async loadCategories() {
    this.$api
      .get(skillsRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.categories = res;
      });
  }

  protected async loadBlocks() {
    this.$api
      .get(blocksRoutes.blocks, { params: { paginate: false } })
      .then(({ data: res }: { data: { blocks: BlockBlock[] } }) => {
        this.blocks = res.blocks;
      });
  }

  protected async loadPillars() {
    this.$api
      .get(pillarsRoutes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillars = res;
      });
  }

  protected async loadSigns() {
    this.$api
      .get(signsRoutes.signs, {
        params: { paginate: false, include_skills: false }
      })
      .then(({ data: res }: { data: { signs: SignSign[] } }) => {
        this.signs = res.signs;
      });
  }

  protected changeGeneralFilter(value: IPrioritySkillFiltersInput) {
    if (this.generalPriority?.skills?.length) {
      this.showGeneralSkills = false;

      for (const skill of this.generalPriority.skills) {
        let show: boolean = true;

        if (value.category_id && skill.category_id !== value.category_id) {
          show = false;
        }

        if (value.pillar_id && skill.pillar_id !== value.pillar_id) {
          show = false;
        }

        if (value.block_id && !skill.block_ids![value.block_id]) {
          show = false;
        }

        if (value.sign_id && !skill.sign_ids![value.sign_id]) {
          show = false;
        }

        if (
          value.search &&
          value.search.length > 0 &&
          !skill.full_name.toLowerCase().includes(value.search.toLowerCase())
        ) {
          show = false;
        }

        skill.show = show;
      }

      this.showGeneralSkills = true;
    }
  }

  protected changeEditFilter(value: IPrioritySkillFiltersInput) {
    if (this.editPriority?.skills?.length) {
      this.showEditSkills = false;

      for (const skill of this.editPriority.skills) {
        let show: boolean = true;

        if (value.category_id && skill.category_id !== value.category_id) {
          show = false;
        }

        if (value.pillar_id && skill.pillar_id !== value.pillar_id) {
          show = false;
        }

        if (value.block_id && !skill.block_ids![value.block_id]) {
          show = false;
        }

        if (value.sign_id && !skill.sign_ids![value.sign_id]) {
          show = false;
        }

        if (
          value.search &&
          value.search.length > 0 &&
          !skill.full_name.toLowerCase().includes(value.search.toLowerCase())
        ) {
          show = false;
        }

        skill.show = show;
      }

      this.showEditSkills = true;
    }
  }

  protected resetSkillLevel() {
    return this.$api
      .destroy(prioritiesRoutes.set_zero_current_level_in_all_skills, {
        params: { skill_id: this.currentSkillId }
      })
      .then(() => {
        this.CloseResetModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Уровень навыков сброшен",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected resetSkillLevelStructure() {
    return this.$api
      .post(prioritiesRoutes.set_zero_current_level_in_all_skills_structure, {
        skill_id: this.currentSkillId,
        positions: this.editPriority?.positions,
        factory_id: this.editPriority?.factory_id,
        infinity_id: this.editPriority?.infinity_id
      })
      .then(() => {
        this.CloseResetModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Уровень навыков сброшен",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected ShowResetModal(skill_id: string) {
    this.currentSkillId = skill_id;

    this.$modal.show("resetSkillModal");
  }
  protected CloseResetModal() {
    this.$modal.hide("resetSkillModal");
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.priorities);
  }
}

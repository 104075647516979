import { IsUUID, IsString } from "class-validator";
import DocumentDocument from "./document";

import { EFormatFile } from "@/enums/fileTypes";

export default class DocumentVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public url: string = "";

  @IsString()
  public file_type: string = "";

  public document?: DocumentDocument;

  public isPdf() {
    return this.file_type === EFormatFile.pdf;
  }

  public isVideo() {
    return this.file_type === EFormatFile.mp4;
  }

  public isAudio() {
    return (
      this.file_type === EFormatFile.mpeg || this.file_type === EFormatFile.wav
    );
  }

  public isImage() {
    return (
      this.file_type === EFormatFile.jpeg ||
      this.file_type === EFormatFile.jpg ||
      this.file_type === EFormatFile.bmp ||
      this.file_type === EFormatFile.png
    );
  }

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.url = data.url;
    }
  }
}


import { Component, Vue } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";
import {
  IAktAssignment,
  IAktAssignmentStepType
} from "@/models/akt/assignment";
import { EPermission } from "@/enums/permissions";

import CAktAssignmentsEdit from "./assignments/Edit.vue";

@Component({
  name: "VAktAssignments",
  components: {
    CAktAssignmentsEdit
  }
})
export default class VAktAssignments extends Vue {
  public preload: boolean = false;
  public show_filter: boolean = false;

  public current_year: number = new Date().getFullYear();
  public selected_year: number = this.current_year;
  public available_years: number[] = [this.current_year];

  public available_steps: IAktAssignmentStepType[] = [];

  public assignments: IAktAssignment[] = [];

  public show_edit_modal: boolean = false;
  public edit_assignment_index: number | null = null;
  public edit_assignment_id: string | null = null;

  protected async created() {
    this.preload = true;

    await Promise.all([
      this.loadYears(),
      this.loadAssignments(),
      this.loadStepTypes()
    ]);

    this.preload = false;
  }

  public async changeYear() {
    if (!this.selected_year) {
      this.selected_year = this.current_year;
    }

    this.preload = true;

    await this.loadAssignments();

    this.preload = false;
  }

  protected async loadYears() {
    return this.$api
      .get(akt_routes.assignment_available_years)
      .then(({ data: res }: { data: number[] }) => {
        this.available_years = res;
      });
  }

  protected async loadStepTypes() {
    return this.$api
      .get(akt_routes.assignment_available_steps)
      .then(({ data: res }: { data: IAktAssignmentStepType[] }) => {
        this.available_steps = res;
      });
  }

  protected async loadAssignments(year: number = this.selected_year) {
    return this.$api
      .get(akt_routes.assignment_modules(`${year}`))
      .then(({ data: res }: { data: IAktAssignment[] }) => {
        this.assignments = res;
      });
  }

  public createAssignment() {
    this.edit_assignment_id = null;
    this.edit_assignment_index = null;
    this.show_edit_modal = true;
  }

  public editAssignment(assignment_id: string, index: number) {
    this.edit_assignment_id = assignment_id;
    this.edit_assignment_index = index;
    this.show_edit_modal = true;
  }

  public closeAssignment() {
    this.edit_assignment_id = null;
    this.edit_assignment_index = null;
    this.show_edit_modal = false;
  }

  public deleteAssignment() {
    if (this.edit_assignment_index != null) {
      this.$delete(this.assignments, this.edit_assignment_index);
    }

    this.closeAssignment();
  }

  public changeAssignment(data: IAktAssignment | null) {
    if (data) {
      if (this.edit_assignment_index != null) {
        this.$set(this.assignments, this.edit_assignment_index, data);
      } else {
        this.assignments.unshift(data);
      }
    }

    this.closeAssignment();
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.akt_assignments);
  }
}

import "reflect-metadata";
import { IsDate, IsString, IsUUID, IsOptional } from "class-validator";

export default class ApplicationInterval {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsUUID("4")
  public readonly student_id?: string;

  @IsDate()
  public start_education_date: Date = new Date();

  @IsDate()
  public end_education_date: Date = new Date();

  @IsOptional()
  @IsString()
  public full_name?: string;

  @IsOptional()
  @IsString()
  public readonly student_name?: string;

  @IsOptional()
  @IsString()
  public education_place?: string;

  public show_interval?: boolean;
}

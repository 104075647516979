
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import applicationRoutes from "@/api/routes/applications";

import ApplicationApplication from "@/models/application/application";

@Component
export default class CIntervalDate extends Vue {
  protected planningError: string = "";

  protected intervalApplications: ApplicationApplication[] = [];

  protected async created() {
    await this.loadIntervalApplication();
  }

  protected showApplication(index: number) {
    const application = this.intervalApplications[index];

    this.$set(application, "show", !application.show);
  }

  protected async loadIntervalApplication() {
    return this.$api
      .get(applicationRoutes.applications, {
        params: {
          paginate: false,
          only_intervals: true
        }
      })
      .then(
        ({
          data: res
        }: {
          data: { application: ApplicationApplication[] };
        }) => {
          this.intervalApplications = plainToClass(
            ApplicationApplication,
            res.application
          );
        }
      )
      .catch(({ response: res }) => {
        this.planningError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

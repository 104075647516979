
import { Component, Vue } from "vue-property-decorator";

import { EDeviceTypes } from "@/enums/deviceTypes";

import kpiRoutes from "@/api/routes/kpi";

import Preloader from "@/components/Preloader.vue";
import { EPermission } from "@/enums/permissions";

export interface IData {
  readonly next_page: boolean;
  readonly current_page: number;
  readonly kpis: IKpiData;
}

interface IKpiDataUpload {
  readonly full_name: string;
  readonly identification_number: string;
  readonly id: string;
  readonly objective: string;
  readonly action: string;
  readonly bonus: number;
  readonly weight: number;
}

export interface IKpiData {
  readonly [user: string]: {
    readonly user_name: string;
    readonly identification_number: string;
    readonly actions: {
      readonly [action: string]: {
        show_all?: boolean;
        readonly name: string;
        readonly criteria: {
          readonly [criterion: string]: {
            readonly name: string;
            readonly weight: number;
            readonly bonus: number;
          };
        };
      };
    };
  };
}

interface IErrorRow {
  readonly [name: string]: { readonly message: string };
}

interface IKpiResponseData {
  readonly error_rows: IErrorRow;
  readonly errors_count: number;
  readonly success_rows: IKpiDataUpload[];
  readonly success_count: number;
}
@Component({
  components: {
    Preloader
  },
  data: () => {
    return {
      EDeviceTypes
    };
  }
})
export default class CKpiAll extends Vue {
  protected preload: boolean = false;
  protected kpis: IKpiData | null = null;

  protected uploadErrors: string | string[] | null = null;
  protected error_rows: IErrorRow | null = null;
  protected success_rows: IKpiDataUpload[] = [];
  protected success_count: number = 0;
  protected errors_count: number = 0;

  protected acceptFiles: string[] = [".xls", ".xlsx", ".xlsm"];
  protected disabled: boolean = false;
  protected uploadProgress: number = 0;

  protected per_page: number = 50;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  $refs!: {
    fileform: HTMLFormElement;
    file: HTMLFormElement;
  };

  protected async created() {
    this.preload = true;

    await this.loadKpi();
  }

  protected async loadKpi(page: number = 0) {
    return this.$api
      .get(kpiRoutes.kpis, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.kpis = res.kpis;
        this.next_page = res.next_page;
        this.current_page = res.current_page;
      })
      .finally(() => {
        this.uploadProgress = 0;
        this.preload = false;
      });
  }

  protected async uploadKpi() {
    if (!this.canWrite()) {
      return;
    }

    this.uploadProgress = 0;
    this.clearResults();
    this.preload = true;

    const formData = new FormData();
    formData.append("file", this.$refs.file.files[0]);

    return this.$api
      .post(kpiRoutes.kpi_file, formData, {
        onUploadProgress: progressEvent => {
          this.disabled = true;
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      })
      .then(({ data: res }: { data: IKpiResponseData }) => {
        this.success_rows = res.success_rows;
        this.success_count = res.success_count;
        this.error_rows = res.error_rows;
        this.errors_count = res.errors_count;
      })
      .catch(({ response: res }) => {
        this.uploadErrors = res.data.error;
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        if (!this.uploadErrors) {
          this.showResult();
        }
        this.kpis = null;
        this.loadKpi();
        this.disabled = false;
      });
  }

  protected clearResults() {
    this.success_count = 0;
    this.errors_count = 0;
    this.error_rows = null;
    this.success_rows = [];
  }

  protected showResult() {
    this.$modal.show("results");
  }

  protected closeResults() {
    this.$modal.hide("results");
  }

  protected async infiniteHandler() {
    if (this.next_page) {
      await this.loadKpi(this.current_page + 1);
    }
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.kpi_all);
  }
}

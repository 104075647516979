import "reflect-metadata";

import { IsString, IsUUID } from "class-validator";

export default class TaskCommentFile {
  @IsUUID("4")
  public readonly id?: string;

  @IsString()
  public url!: string;
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row",attrs:{"id":"c-category-chart"}},[(_vm.category == _vm.ESkillCategory.GENERAL_ID)?[(_vm.pillar_avg)?[_c('div',{staticClass:"col-6 t-32"},[_c('CWindRoseChart',{attrs:{"labels":_vm.pillar_avg.labels,"datasets":[
            {
              label: 'Целевые',
              data: _vm.pillar_avg.target_array,
              fill: false,
              borderColor: ['#826AF9'],
              backgroundColor: ['#826AF9']
            },
            {
              label: 'Фактические',
              data: _vm.pillar_avg.current_array,
              fill: false,
              borderColor: ['#FFAB00'],
              backgroundColor: ['#FFAB00']
            }
          ],"title":'Колонны'},on:{"click":_vm.selectPillar}})],1),(_vm.current_pillar_avg)?[_c('div',{staticClass:"col-6 t-32"},[_c('CWindRoseChart',{attrs:{"labels":_vm.current_pillar_avg.labels,"datasets":[
              {
                label: 'Целевые',
                data: _vm.current_pillar_avg.target_array,
                fill: false,
                borderColor: ['#826AF9'],
                backgroundColor: ['#826AF9']
              },
              {
                label: 'Фактические',
                data: _vm.current_pillar_avg.current_array,
                fill: false,
                borderColor: ['#FFAB00'],
                backgroundColor: ['#FFAB00']
              }
            ],"title":_vm.pillar_label}})],1),(_vm.current_table_pillar_avg)?[_c('div',{staticClass:"col-12 t-32"},[_vm._m(0),_vm._l((_vm.current_table_pillar_avg),function(skill){return _c('div',{key:`user_name=${skill.user_name}_skill_name=${skill.skill_name}`,staticClass:"row alig-items-center t-8 font-12 it-10 ib-10"},[_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(skill.user_name))]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(skill.skill_name))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.target))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.current))])])})],2)]:_vm._e()]:_vm._e()]:_vm._e()]:_vm._e(),(_vm.category == _vm.ESkillCategory.METHODICAL_ID)?[(_vm.group_avg)?[_c('div',{staticClass:"col-6 t-32"},[_c('CWindRoseChart',{attrs:{"labels":_vm.group_avg.labels,"datasets":[
            {
              label: 'Целевые',
              data: _vm.group_avg.target_array,
              fill: false,
              borderColor: ['#826AF9'],
              backgroundColor: ['#826AF9']
            },
            {
              label: 'Фактические',
              data: _vm.group_avg.current_array,
              fill: false,
              borderColor: ['#FFAB00'],
              backgroundColor: ['#FFAB00']
            }
          ],"title":'Группы навыков'},on:{"click":_vm.selectGroup}})],1),(_vm.current_group_avg)?[_c('div',{staticClass:"col-6 t-32"},[_c('CWindRoseChart',{attrs:{"labels":_vm.current_group_avg.labels,"datasets":[
              {
                label: 'Целевые',
                data: _vm.current_group_avg.target_array,
                fill: false,
                borderColor: ['#826AF9'],
                backgroundColor: ['#826AF9']
              },
              {
                label: 'Фактические',
                data: _vm.current_group_avg.current_array,
                fill: false,
                borderColor: ['#FFAB00'],
                backgroundColor: ['#FFAB00']
              }
            ],"title":_vm.group_label}})],1),(_vm.current_table_group_avg)?[_c('div',{staticClass:"col-12 t-40 b-32"},[_vm._m(1),_vm._l((_vm.current_table_group_avg),function(skill){return _c('div',{key:`user_name=${skill.user_name}_skill_name=${skill.skill_name}`,staticClass:"row alig-items-center t-8 font-12 it-10 ib-10"},[_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(skill.user_name))]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(skill.skill_name))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.target))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.current))])])})],2)]:_vm._e()]:_vm._e()]:_vm._e()]:_vm._e(),(_vm.category == _vm.ESkillCategory.FUNCTIONAL_ID)?[(_vm.workplace_avg)?[_c('div',{staticClass:"col-6 t-32"},[_c('CWindRoseChart',{attrs:{"labels":_vm.workplace_avg.labels,"datasets":[
            {
              label: 'Целевые',
              data: _vm.workplace_avg.target_array,
              fill: false,
              borderColor: ['#826AF9'],
              backgroundColor: ['#826AF9']
            },
            {
              label: 'Фактические',
              data: _vm.workplace_avg.current_array,
              fill: false,
              borderColor: ['#FFAB00'],
              backgroundColor: ['#FFAB00']
            }
          ],"title":'Рабочие места',"showSlot":true},on:{"click":_vm.selectWorkplace}},[(_vm.workplaces.length > 0)?_c('div',{staticClass:"t-26 b-16 container-fluid"},[_c('div',{staticClass:"select",class:{ active: _vm.currentWorkplace }},[_c('v-select',{staticClass:"ma-2",attrs:{"options":_vm.workplaces,"label":"structure_name","value":"id","appendToBody":true},on:{"input":_vm.loadSkillWorkplaceAvg},scopedSlots:_vm._u([{key:"selected-option",fn:function({ structure_name }){return [_c('div',{staticStyle:{"display":"flex","flex-wrap":"nowrap"}},[_c('span',[_vm._v(_vm._s(structure_name))]),(_vm.currentWorkplace.structure_phase != null)?_c('span',{staticClass:"bl-16 font-gray"},[_vm._v("Фаза "+_vm._s(_vm.currentWorkplace.structure_phase))]):_vm._e()])]}}],null,false,2471818954),model:{value:(_vm.currentWorkplace),callback:function ($$v) {_vm.currentWorkplace=$$v},expression:"currentWorkplace"}}),_c('label',[_vm._v("Рабочее место")])],1)]):_vm._e()])],1),(_vm.current_workplace_avg)?[_c('div',{staticClass:"col-6 t-32"},[_c('CWindRoseChart',{attrs:{"labels":_vm.current_workplace_avg.labels,"datasets":[
              {
                label: 'Целевые',
                data: _vm.current_workplace_avg.target_array,
                fill: false,
                borderColor: ['#826AF9'],
                backgroundColor: ['#826AF9']
              },
              {
                label: 'Фактические',
                data: _vm.current_workplace_avg.current_array,
                fill: false,
                borderColor: ['#FFAB00'],
                backgroundColor: ['#FFAB00']
              }
            ],"title":_vm.workplace_label}})],1),(_vm.current_table_workplace_avg)?[_c('div',{staticClass:"col-12 t-40 b-32"},[_vm._m(2),_vm._l((_vm.current_table_workplace_avg),function(skill){return _c('div',{key:`user_name=${skill.user_name}_skill_name=${skill.skill_name}`,staticClass:"row alig-items-center t-8 font-12 it-10 ib-10"},[_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(skill.user_name))]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(skill.skill_name))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.target))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.current))])])})],2)]:_vm._e()]:_vm._e()]:_vm._e()]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row middle alig-items-center h-36 font-10 font-gray"},[_c('div',{staticClass:"col-3"},[_vm._v("Сотрудник")]),_c('div',{staticClass:"col"},[_vm._v("Навык")]),_c('div',{staticClass:"col-2"},[_vm._v("Цел. ур.")]),_c('div',{staticClass:"col-2"},[_vm._v("Факт. ур.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row middle alig-items-center h-36 font-10 font-gray"},[_c('div',{staticClass:"col-3"},[_vm._v("Сотрудник")]),_c('div',{staticClass:"col"},[_vm._v("Навык")]),_c('div',{staticClass:"col-2"},[_vm._v("Цел. ур.")]),_c('div',{staticClass:"col-2"},[_vm._v("Факт. ур.")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row middle alig-items-center h-36 font-10 font-gray"},[_c('div',{staticClass:"col-3"},[_vm._v("Сотрудник")]),_c('div',{staticClass:"col"},[_vm._v("Навык")]),_c('div',{staticClass:"col-2"},[_vm._v("Цел. ур.")]),_c('div',{staticClass:"col-2"},[_vm._v("Факт. ур.")])])
}]

export { render, staticRenderFns }
import {
  IsBoolean,
  IsDate,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";
import { Type } from "class-transformer";

import UserDocument from "@/models/user/document";
import ApplicationApplication from "@/models/application/application";

export default class ApplicationPlan {
  @IsUUID("4")
  public skill_id!: string;

  @IsOptional()
  @IsNumber()
  public educations_count?: number;

  @IsOptional()
  @IsDate()
  public end_date?: Date;

  @IsUUID("4")
  @IsString()
  public plan_choices_id?: string;

  @IsOptional()
  @IsBoolean()
  public is_planned?: boolean;

  @Type(() => UserDocument)
  public educations: UserDocument[] = [];

  @Type(() => ApplicationApplication)
  public applications: ApplicationApplication[] = [];
}


import { Component, Vue } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";

import { EPermission } from "@/enums/permissions";

export interface IChangeArchiveTesting {
  id: string;
  name: string;
  is_select: boolean;
  is_archive: boolean;
  parent_folder_id?: string;
  folder_idx?: number;
  testing_idx?: number;
}

@Component({
  name: "CTestingsChangeArchiveTesting"
})
export default class CTestingsChangeArchiveTesting extends Vue {
  private watchers: Function[] = [];

  public testing: IChangeArchiveTesting | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.changingArchiveTestings,
        (changingArchiveTestings: IChangeArchiveTesting | null) => {
          this.testing = changingArchiveTestings;

          if (this.testing) {
            this.showModal();
          }
        }
      )
    );
  }

  public async archiveTesting() {
    if (!this.testing) {
      return;
    }

    return this.$api
      .post(testings_routes.testing_archived(this.testing.id), {})
      .then(({ data: res }: { data: { is_archive: boolean } }) => {
        if (this.testing!.is_select) {
          this.testing!.is_select = !this.testing!.is_select;

          this.$store.commit("toggleTestingId", this.testing!.id);
        }

        this.$store.commit("changedArchiveTestings", {
          ...this.testing,
          is_archive: res.is_archive
        });

        this.hideModal();

        let success_msg = "Тестирование отправлено в архив";

        if (!res.is_archive) {
          success_msg = "Тестирование восстановлено из архива";
        }

        this.$notify({
          group: "notifications",
          type: "success",
          text: success_msg,
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("change_archive_testing_modal");
  }

  public hideModal() {
    this.$modal.hide("change_archive_testing_modal");

    this.$store.commit("changingArchiveTestings", null);
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testings);
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

import DateTimePicker from "@/components/DateTimePicker.vue";

import TestingTestingVersion, {
  repeatType
} from "@/models/testing/testing_version";

interface IUnlimitedOption {
  readonly label: string;
  readonly value?: boolean;
}

@Component({
  name: "CTestingsApprovalSettings",
  components: {
    DateTimePicker
  }
})
export default class CTestingsApprovalSettings extends Vue {
  @Prop({ required: true }) public testing!: TestingTestingVersion;

  public disabled = true;
  public repeatType = repeatType;
  public ru = ru;

  public completion_interval: { mm?: string; ss?: string } = {
    mm: "02",
    ss: "00"
  };

  public unlimited: IUnlimitedOption[] = [
    { label: "Да", value: true },
    { label: "Нет", value: false }
  ];

  protected created() {
    if (this.testing.completion_interval) {
      const minutes = Math.floor(this.testing.completion_interval / 60);
      const seconds = this.testing.completion_interval % 60;

      this.completion_interval["mm"] =
        minutes > 9 ? `${minutes}` : `0${minutes}`;
      this.completion_interval["ss"] =
        seconds > 9 ? `${seconds}` : `0${seconds}`;
    }
  }
}

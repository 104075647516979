import { Type } from "class-transformer";

import AktAnswer from "./answer";
import AktSurveyVersion from "./survey_version";
import UserUser from "../user/user";
import AktAnswerResult from "./answer_result";

export default class AktAnswerVersion {
  public readonly id!: string;

  public title?: string;
  public version: number;
  public position?: number;

  public answer_id?: string;
  @Type(() => AktAnswer)
  public answer?: AktAnswer;

  public survey_version_id?: string;
  public survey_version?: AktSurveyVersion;

  public creator_id?: string;
  @Type(() => UserUser)
  public creator?: UserUser;

  public editor_id?: string;
  @Type(() => UserUser)
  public editor?: UserUser;

  @Type(() => AktAnswerResult)
  public results?: AktAnswerResult[];

  constructor(data: any) {
    this.version = 1;
    this.answer = new AktAnswer();

    if (data) {
      this.title = data.title || this.title;
      this.position = data.position || this.position;
      this.version = data.version || this.version;
    }
  }

  public isCorrect() {
    return this.answer?.is_correct;
  }
}

import "reflect-metadata";
import { IsDate, IsString, IsUUID, IsOptional } from "class-validator";
import { Type } from "class-transformer";
import TestingCommentFile from "@/models/testing/comment_file";

export default class TestingComment {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public application_id!: string;

  @IsString()
  public text!: string;

  @IsOptional()
  @IsString()
  public sender_name?: string;

  @IsOptional()
  @IsDate()
  public created_at?: Date;

  @Type(() => TestingCommentFile)
  public comment_files?: TestingCommentFile[];
}

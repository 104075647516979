
import { Component, Vue } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";

import { EPermission } from "@/enums/permissions";

import CTestingsMovingFolderTree, { IFolder } from "./MovingFolderTree.vue";

@Component({
  name: "CTestingsMovingTesting",
  components: {
    CTestingsMovingFolderTree
  }
})
export default class CTestingsMovingTesting extends Vue {
  private watchers: Function[] = [];

  public show_folder_list: boolean = false;

  public target_folder: IFolder | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.movingTestings,
        (movingTestings: boolean | null) => {
          this.target_folder = null;

          if (movingTestings) {
            this.showModal();
          }
        }
      )
    );
  }

  public showModal() {
    this.$modal.show("moving_testing_modal");
  }

  public hideModal() {
    this.$modal.hide("moving_testing_modal");

    this.$store.commit("movingTestings", null);
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testings);
  }

  public changeTargetFolder(folder: IFolder) {
    this.show_folder_list = false;

    this.target_folder = folder;
  }

  public async moveTestings() {
    if (!this.target_folder?.id) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Выберите папку",
        speed: 500
      });

      return;
    }

    this.$api
      .put(testings_routes.migrate_testings, {
        testing_ids: Array.from(this.$store.state.selectedTestingIds),
        folder_id: this.target_folder.id
      })
      .then(() => {
        this.$store.commit("clearSelectedTestingIds");
        this.$store.commit("movedTestings", Math.random());

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

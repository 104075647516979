
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Search from "@/components/Search.vue";

import PillarPillar from "@/models/pillar/pillar";
import SkillCategory from "@/models/skill/category";
import SignSign from "@/models/sign/sign";
import BlockBlock from "@/models/block/block";

export interface IPrioritySkillFiltersInput {
  category_id?: number;
  block_id?: string;
  pillar_id?: string;
  sign_id?: string;
  search?: string;
}

export interface IFilterValue {
  readonly id: string | null;
  readonly full_name: string;
}

@Component({
  components: {
    Search
  }
})
export default class CPrioritySkillFilters extends Vue {
  private defaultValueCategory: SkillCategory = { id: null, value: "Все" };
  private defaultValueFilter: IFilterValue = { id: null, full_name: "Все" };

  @Prop({ required: true }) categories!: SkillCategory[];
  protected categoriesFilter: SkillCategory[] = [{ id: null, value: "Все" }];
  protected currentCategory: SkillCategory | null = this.categoriesFilter[0];

  @Watch("categories")
  protected setCategoriesFilter(categories: SkillCategory[]) {
    this.categoriesFilter = [this.defaultValueCategory, ...categories];
  }

  @Prop({ required: true }) blocks!: BlockBlock[];
  protected blocksFilter: IFilterValue[] = [{ id: null, full_name: "Все" }];
  protected currentBlock: IFilterValue | null = this.blocksFilter[0];

  @Watch("blocks")
  protected setBlocksFilter(blocks: BlockBlock[]) {
    this.blocksFilter = [
      this.defaultValueFilter,
      ...(blocks as IFilterValue[])
    ];
  }

  @Prop({ required: true }) pillars!: PillarPillar[];
  protected pillarsFilter: IFilterValue[] = [{ id: null, full_name: "Все" }];
  protected currentPillar: IFilterValue | null = this.pillarsFilter[0];

  @Watch("pillars")
  protected setPillarsFilter(pillars: PillarPillar[]) {
    this.pillarsFilter = [
      this.defaultValueFilter,
      ...(pillars as IFilterValue[])
    ];
  }

  @Prop({ required: true }) signs!: SignSign[];
  protected signsFilter: IFilterValue[] = [{ id: null, full_name: "Все" }];
  protected currentSign: IFilterValue | null = this.signsFilter[0];

  @Watch("signs")
  protected setSignsFilter(signs: SignSign[]) {
    this.signsFilter = [this.defaultValueFilter, ...(signs as IFilterValue[])];
  }

  @Prop({ default: false }) topSelectPosition!: boolean;

  protected search: string = this.$store.state.search;

  protected showFilter: boolean = false;

  protected watchers: Function[] = [];

  protected created() {
    this.setCategoriesFilter(this.categories);
    this.setBlocksFilter(this.blocks);
    this.setPillarsFilter(this.pillars);
    this.setSignsFilter(this.signs);

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.changeFilter();
        }
      )
    );
  }

  protected async changeFilter() {
    this.$emit("input", {
      category_id: this.currentCategory?.id,
      block_id: this.currentBlock?.id,
      pillar_id: this.currentPillar?.id,
      sign_id: this.currentSign?.id,
      search: this.search
    });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-user-catalog"}},[_c('div',{staticClass:"row align-items-center middle d-xs-none flex-none"},[_vm._m(0),(_vm.currentProgress && _vm.programs.length > 0)?_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"tag font-normal",class:_vm.currentProgress.clazz},[_vm._v(" "+_vm._s(_vm.currentProgress.name)+" ")])]):_vm._e()]),(_vm.currentProgress && _vm.programs.length > 0)?_c('div',{staticClass:"d-none d-xs-block it-8",attrs:{"id":"mobile_current_progress"}},[_c('span',{staticClass:"tag font-normal max-width-100-procents-i",class:_vm.currentProgress.clazz},[_vm._v(" "+_vm._s(_vm.currentProgress.name)+" ")])]):_vm._e(),_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.infiniteHandler),expression:"infiniteHandler"}],staticClass:"pillar_list t-0-i",attrs:{"infinite-scroll-distance":"10","infinite-scroll-throttle-delay":"200"}},[(_vm.programs.length > 0)?_vm._l((_vm.programs),function(program){return _c('router-link',{key:program.id,staticClass:"row align-items-center min-height-48 b-8",class:{ skill_item: true },attrs:{"tag":"div","to":{
          name: 'subscription_program',
          params: { id: program.id, student: true }
        }}},[_c('div',{staticClass:"col-auto"},[_c('v-progress-circular',{attrs:{"size":"30","width":"2","value":program.program_status === _vm.ESubscriptionStatus.FAILED
                ? 100
                : program.program_percent,"rotate":"-90","color":program.program_status ===
                _vm.ESubscriptionStatus.AWAITING_COMPLETION ||
              program.program_status ===
                _vm.ESubscriptionStatus.AWAITING_CONFIRMATION
                ? '#FFAB00'
                : program.program_status === _vm.ESubscriptionStatus.FAILED
                ? '#FA4747'
                : '#1E64AA'}},[_c('div',{staticClass:"font-10 font-black"},[(
                  program.program_status ===
                    _vm.ESubscriptionStatus.AWAITING_COMPLETION ||
                    program.program_status ===
                      _vm.ESubscriptionStatus.AWAITING_CONFIRMATION
                )?_c('inline-svg',{staticClass:"w-20 h-20 font-yellow",attrs:{"src":"/img/icon-done-inherit.svg"}}):(program.program_percent === 100)?_c('div',[_c('img',{staticClass:"w-20 h-20",attrs:{"src":"/img/icon-done-blue.svg","alt":"done"}})]):(
                  program.program_status === _vm.ESubscriptionStatus.FAILED
                )?_c('div',[_c('img',{staticClass:"w-20 h-20",attrs:{"src":"/img/icon-close-red.svg","alt":"failed"}})]):(program.program_percent === 0)?_c('div',{staticClass:"font-lightgray"},[_vm._v(" 0% ")]):_c('div',[_vm._v(_vm._s(Math.round(program.program_percent))+"%")])],1)])],1),_c('div',{staticClass:"col ti-0"},[_c('div',{staticClass:"row middle align-items-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('div',{staticClass:"col-auto line-0 ti-4",style:({
                    color: program.color
                  })},[(
                      program.progress_status ===
                        _vm.ESubscriptionProgressStatus.NOT_STARTED
                    )?_c('inline-svg',{staticClass:"w-20 h-20 blue-icon",attrs:{"src":'/img/icon-article-inherit.svg'}}):_vm._e(),(
                      program.progress_status ===
                        _vm.ESubscriptionProgressStatus.MAKE_PROGRESS
                    )?_c('inline-svg',{staticClass:"w-20 h-20 yellow-icon",attrs:{"src":'/img/icon-article-inherit.svg'}}):_vm._e(),(
                      [
                        _vm.ESubscriptionProgressStatus.SLIGHTLY_BEHIND,
                        _vm.ESubscriptionProgressStatus.FAR_BEHIND
                      ].includes(program.progress_status)
                    )?_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-fire.svg'}}):_vm._e()],1),_c('div',{staticClass:"font-12 font-bold ir-8"},[_vm._v(" "+_vm._s(program.program_version_title)+" ")]),_c('div',{staticClass:"font-12 font-normal font-gray font-xs-bold font-xs-black"},[_vm._v(" "+_vm._s(_vm._f("toDate")(program.starts_at))+" - "+_vm._s(_vm._f("toDate")(program.deadline_at))+" ")])]),_c('div',{staticClass:"d-none it-xs-4 d-xs-block font-12 font-gray line-1"},[_vm._v(" "+_vm._s(program.pillar_name)+" ")])]),(program.pillar_name)?_c('div',{staticClass:"d-flex d-xs-none col-sm-3 align-items-center"},[_c('span',{staticClass:"mark",style:({
                  color: program.color,
                  background_color: `${program.color}29`
                })},[_vm._v(" "+_vm._s(program.pillar_short_name)+" ")]),_c('span',{staticClass:"font-12 font-gray bl-16"},[_vm._v(_vm._s(program.pillar_name))])]):_vm._e()]),_c('p',{staticClass:"font-gray tag bl-26"},[_vm._v(" "+_vm._s(_vm._f("normalizedSubscriptionStatus")(program.program_status))+" ")]),(!program.is_skill_assignment)?_c('p',{staticClass:"font-gray tag"},[_vm._v(" Программа по навыку обазательного обучения ")]):_vm._e()])])}):[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column"},[_c('div',{staticClass:"no-found-icon"},[_c('inline-svg',{staticClass:"w-40 h-40",attrs:{"src":'/img/icon-catalog.svg'}})],1),_c('p',{staticClass:"t-24 font-18 font-superbold align-center"},[_vm._v(" Программы отсутствуют ")]),_c('p',{staticClass:"align-center font-14 t-8 max-width-320"},[_vm._v(" Назначьте сотруднику программы обучения, чтобы следить за прогрессом обучения ")])])]],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-auto"},[_c('h1',[_vm._v("План обучения")])])
}]

export { render, staticRenderFns }

import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import testings_routes from "@/api/routes/testings";

import Preloader from "@/components/Preloader.vue";

import TestingTestingVersion from "@/models/testing/testing_version";
import TestingQuestionVersion from "@/models/testing/question_version";
import TestingComment from "@/models/testing/comment";

import CTestingsApprovalSettings from "./TestingApprovalSettings.vue";
import CTestingsApprovalQuestions from "./TestingApprovalQuestions.vue";
import CTestingsApprovalComments from "./TestingApprovalComments.vue";

@Component({
  name: "CTestingsApproval",
  components: {
    Preloader,
    CTestingsApprovalSettings,
    CTestingsApprovalQuestions,
    CTestingsApprovalComments
  }
})
export default class CTestingsApproval extends Vue {
  @Prop({ required: true }) public testing_id!: string;
  @Prop({ required: false, default: false }) public disabled!: boolean;

  private watchers: Function[] = [];

  public testing: TestingTestingVersion | null = null;
  public questions: TestingQuestionVersion[] = [];
  public comments: TestingComment[] = [];

  public last_comment_id: string | null = null;

  public testing_tabs: string[] = ["Настройки", "Вопросы", "Комментарии"];
  public current_testing_tab_idx: number = 0;

  public preload: boolean = false;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.preload = true;

    await Promise.all([
      this.loadTesting(),
      this.loadComments(),
      this.loadQuestions()
    ]).finally(() => {
      this.preload = false;
    });
  }

  private async loadTesting() {
    return this.$api
      .get(testings_routes.testing(this.testing_id))
      .then(({ data: res }: { data: TestingTestingVersion }) => {
        this.testing = plainToClass(TestingTestingVersion, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadComments() {
    return this.$api
      .get(testings_routes.comments, {
        params: {
          id: this.testing_id
        }
      })
      .then(({ data: res }: { data: TestingComment[] }) => {
        this.comments = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadQuestions() {
    return this.$api
      .get(testings_routes.show_question(this.testing_id))
      .then(({ data: res }: { data: TestingQuestionVersion[] }) => {
        this.questions = plainToClass(TestingQuestionVersion, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public commentAdded(comment_id: string) {
    this.last_comment_id = comment_id;
  }

  public closeApproval() {
    this.$emit("close", true);
  }

  public async rejectApproval() {
    if (!this.last_comment_id) {
      this.current_testing_tab_idx = 2;

      this.$notify({
        group: "notifications",
        type: "error",
        text: "Добавьте комментарий",
        speed: 500
      });

      return;
    }

    return this.$api
      .put(
        testings_routes.cancel(
          this.testing!.id,
          this.$store.state.currentUser.id
        ),
        {
          last_comment_id: this.last_comment_id
        }
      )
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: `Вы отклонили тест ${this.testing!.name}`,
          speed: 500
        });

        this.$emit("onVisited", false);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async resolveApproval() {
    return this.$api
      .put(
        testings_routes.confirm(
          this.testing!.id,
          this.$store.state.currentUser.id
        ),
        {
          last_comment_id: this.last_comment_id
        }
      )
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: `Вы согласовали тест ${this.testing!.name}`,
          speed: 500
        });

        this.$emit("onVisited", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

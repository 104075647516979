
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import ImageView from "@/components/ImageView.vue";

import SubscriptionUnit from "@/models/subscriptions/unit";
import TestQuestionVersion from "@/models/unit/unitable/test/question_version";
import TestVersion from "@/models/unit/unitable/test/version";
import TestAnswerVersion from "@/models/unit/unitable/test/answer_version";

@Component({
  components: {
    ImageView
  }
})
export default class SubscriptionUnitTestQuestion extends Vue {
  @Prop() private subscription_id!: string;
  @Prop() private question_version!: TestQuestionVersion;

  private singleChecked: string = "";
  private multipleChecked: string[] = [];

  @Watch("question_version")
  private watchQuestion() {
    this.singleChecked = "";
    this.multipleChecked = [];

    this.question_version.answer_versions.forEach(av => {
      av.is_selected = false;
    });
  }

  private nextQuestion() {
    if (this.question_version.question.type === "SINGLE") {
      this.$emit("nextQuestion", [this.singleChecked]);
    } else {
      this.$emit("nextQuestion", this.multipleChecked);
    }
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import CBaseStructure, { IBaseProp } from "@/components/structures/Base.vue";
import CUecStructure, { IUecProp } from "@/components/structures/Uec.vue";

export interface IStructureProp {
  factory_id?: string | null;
  workshop_id?: string | null;
  workline_id?: string | null;
  equipment_id?: string | null;
  infinity_id?: string | null;
  parent_infinity_ids?: string[] | null;
  workplace_name?: string;
}

@Component({
  name: "CStructure",
  components: {
    CBaseStructure,
    CUecStructure
  }
})
export default class CStructure extends Vue {
  @Prop({ required: false, default: false }) public factory_uec!: boolean;
  @Prop({ required: false, default: false }) public include_archived!: boolean;
  @Prop({ required: false, default: false }) public disabled!: boolean;
  @Prop({ required: false, default: false }) public clear!: boolean;
  @Prop({ required: false, default: true }) public appendToBody!: boolean;

  @Prop({ required: false, default: 0 }) public index!: number;

  @Prop({ required: true }) public factory_id!: string;
  @Prop({ required: true }) public structure!: IStructureProp;

  public changeBaseStructure(obj: IBaseProp) {
    this.$emit("changeStructure", {
      factory_id: this.factory_id,
      workshop_id: null,
      workline_id: null,
      equipment_id: null,
      infinity_id: obj.infinity_id,
      workplace_name: obj.infinity_names
    });
  }

  public changeUecStructure(obj: IUecProp) {
    let workplace_name = "";
    if (obj.workshop_name) {
      workplace_name = obj.workshop_name;
    }

    if (obj.workline_name) {
      workplace_name = workplace_name + ` > ${obj.workline_name}`;
    }

    if (obj.equipment_name) {
      workplace_name = workplace_name + ` > ${obj.equipment_name}`;
    }

    this.$emit("changeStructure", {
      factory_id: this.factory_id,
      workshop_id: obj.workshop_id,
      workline_id: obj.workline_id,
      equipment_id: obj.equipment_id,
      infinity_id: null,
      workplace_name: workplace_name
    });
  }
}


import { Vue, Component } from "vue-property-decorator";

import signRoutes from "@/api/routes/signs";
import structureRoutes from "@/api/routes/structures";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";

import SignUser, { ISignUserData } from "@/models/sign/user";
import StructureFactory from "@/models/structure/factory";

import { EPermission } from "@/enums/permissions";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataSignUsers extends IData {
  readonly signUsers: SignUser[];
}

@Component({
  components: {
    Preloader,
    Search
  }
})
export default class CUserSigns extends Vue {
  private preload: boolean = false;
  private showFilter: boolean = false;

  private users: ISignUserData[] = [];
  private signUsers: SignUser[] = [];

  private per_page: number = 100;
  private next_page: boolean = false;
  private current_page: number = 1;

  private currentSign: SignUser = new SignUser();
  private currentSignIndex?: number;

  private filters: Map<string, boolean> = new Map();

  private factoriesFilter: StructureFactory[] = [
    { full_name: "Все", skills: [] }
  ];
  private currentFactoriesFilter?: StructureFactory = this.factoriesFilter[0];

  private search: string = this.$store.state.search;

  private signError: string = "";

  private watchers: Function[] = [];

  private created() {
    this.preload = true;

    Promise.all([
      this.loadUsers(),
      this.loadSignUsers(),
      this.loadFactories()
    ]).then(() => {
      this.preload = false;
    });

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearUsers();
        }
      )
    );
  }

  private loadSignUsers(page: number = 0) {
    this.$api
      .get(signRoutes.sign_users, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataSignUsers }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.signUsers.push(...res.signUsers);
      });
  }

  private loadUsers() {
    this.$api
      .get(signRoutes.users, {
        params: {
          factory_id: this.currentFactoriesFilter?.id,
          search: this.search
        }
      })
      .then(({ data: res }: { data: ISignUserData[] }) => {
        this.users = res;
      });
  }

  private loadFactories() {
    this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factoriesFilter.push(...res);
      });
  }

  private clearUsers() {
    this.users = [];
    this.loadUsers();
  }

  private clearErrors() {
    this.signError = "";
  }

  private clearSigns() {
    this.signUsers = [];
    this.loadSignUsers();
  }

  private clearFilters() {
    this.currentFactoriesFilter = this.factoriesFilter[0];
    this.filters.clear();
    this.clearUsers();
  }

  private onCloseModal() {
    this.clearErrors();
    this.showFilter = false;
    this.clearFilters();
  }

  private changeFactory() {
    if (this.factoriesFilter[0] !== this.currentFactoriesFilter) {
      this.filters.set("factory", true);
    } else {
      this.filters.delete("factory");
    }

    this.clearUsers();
  }

  private showEditSign(index: number = -1) {
    if (!this.canWrite()) {
      return;
    }

    const sign = this.signUsers[index];
    this.currentSignIndex = index;

    if (sign) {
      this.currentSign = sign;
    } else {
      this.currentSign = new SignUser();
    }

    this.$modal.show("editSign");
  }

  private showSignUsers(index: number) {
    const sign = this.signUsers[index];
    this.$set(sign, "show_users", !sign.show_users);
  }

  private hideEditSign() {
    this.currentSign = new SignUser();
    this.$modal.hide("editSign");
  }

  private changeSearch() {
    this.clearUsers();
  }

  private createSign() {
    this.currentSign.full_name = this.currentSign.full_name?.trim();

    if (!this.currentSign.full_name?.length) {
      this.signError = "Заполните название";
      return;
    }

    this.$api
      .post(signRoutes.sign_users, this.currentSign)
      .then(() => {
        this.hideEditSign();
        this.clearSigns();
      })
      .catch(({ response: res }) => {
        this.signError = res.data.error;
      });
  }

  private updateSign() {
    this.currentSign.full_name = this.currentSign.full_name?.trim();

    if (!this.currentSign.full_name?.length) {
      this.signError = "Заполните имя";
      return;
    }

    this.$api
      .put(signRoutes.sign_user(this.currentSign.id), this.currentSign)
      .then(({ data: res }: { data: SignUser }) => {
        this.hideEditSign();
        this.$set(this.signUsers, this.currentSignIndex!, res);
      })
      .catch(({ response: res }) => {
        this.signError = res.data.error;
      });
  }

  private deleteSign(index: number) {
    if (window.confirm("Вы точно хотите удалить признак?")) {
      const sign = this.signUsers[index];
      this.$api
        .destroy(signRoutes.sign_user(sign.id))
        .then(() => {
          this.$delete(this.signUsers, index);
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
      this.$modal.hide("editSign");
    }
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadSignUsers(this.current_page + 1);
    }
  }

  private beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private canWrite() {
    return this.$api.canWrite(EPermission.user_signs);
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

import applicationRoutes from "@/api/routes/applications";

import ApplicationInterval from "@/models/application/interval";
import ApplicationApplication from "@/models/application/application";

@Component({
  data: () => {
    return {
      ru
    };
  }
})
export default class CCardIntervals extends Vue {
  @Prop({ required: true })
  protected currentApplication!: ApplicationApplication;

  protected cardIntervals: ApplicationInterval[] = [];

  protected async created() {
    await this.loadCardIntervals();
  }

  protected async loadCardIntervals() {
    return this.$api
      .get(applicationRoutes.intervals, {
        params: {
          application_id: this.currentApplication.id
        }
      })
      .then(({ data: res }: { data: ApplicationInterval[] }) => {
        this.cardIntervals = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

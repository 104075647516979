
import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import akt_routes from "@/api/routes/akt";
import skill_routes from "@/api/routes/skills";
import structure_routes from "@/api/routes/structures";

import Preloader from "@/components/Preloader.vue";
import PdfView from "@/components/PdfView.vue";
import ImageView from "@/components/ImageView.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";

import AktQuestionVersion, { aktTypes } from "@/models/akt/question_version";
import UserUser from "@/models/user/user";
import AktSurveyVersion from "@/models/akt/survey_version";
import AktTextVersion from "@/models/akt/text_version";
import SkillCategory from "@/models/skill/category";
import SkillLocal from "@/models/skill/local";
import StructureFactory from "@/models/structure/factory";
import { aktQuestionTypes } from "@/models/akt/question";

@Component({
  name: "CQuestionApproval",
  components: {
    Preloader,
    CStructure,
    PdfView,
    ImageView
  }
})
export default class CQuestionApproval extends Vue {
  @Prop({ required: true }) public question_version_id!: string;
  @Prop({ required: false, default: false }) public disabled!: boolean;

  public akt_question_types = aktQuestionTypes;
  public akt_types = aktTypes;
  public akt_types_first_two = aktTypes.slice(0, 2);

  public preload: boolean = false;

  public selected_users: UserUser[] = [];
  public question_version: AktQuestionVersion | null = null;
  public questionable: AktSurveyVersion | AktTextVersion | null = null;

  public skill_category: SkillCategory | null = null;
  public skill: SkillLocal | null = null;
  public factory: StructureFactory | null = null;

  public structure: IStructureProp = {};

  public tabs = ["Вопрос", "Параметры", "Комментарий"];
  public current_tab: number = 0;

  public closeApproval() {
    this.$emit("close", true);
  }

  public async rejectApproval() {
    if (!this.question_version?.comment?.length) {
      this.current_tab = 2;

      this.$notify({
        group: "notifications",
        type: "error",
        text: "Добавьте комментарий",
        speed: 500
      });

      return;
    }

    return this.$api
      .put(akt_routes.question_to_approve(this.question_version_id), {
        approve: false,
        comment: this.question_version!.comment
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: `Вы отклонили вопрос ${this.question_version!.title}`,
          speed: 500
        });

        this.$emit("onVisited", false);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async resolveApproval() {
    return this.$api
      .put(akt_routes.question_to_approve(this.question_version_id), {
        approve: true,
        comment: this.question_version!.comment
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: `Вы согласовали вопрос ${this.question_version!.title}`,
          speed: 500
        });

        this.$emit("onVisited", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showSummary() {
    this.$emit("onVisited", true);
  }

  protected async created() {
    this.preload = true;

    await this.loadQuestion();

    const promises: Promise<void>[] = [];

    if (this.question_version?.question?.factory_id) {
      promises.push(
        this.loadFactory(this.question_version.question.factory_id)
      );
    }

    if (this.question_version?.question?.skill_category_id) {
      promises.push(
        this.loadSkillCategory(this.question_version.question.skill_category_id)
      );
    }

    if (this.question_version?.question?.skill_id) {
      promises.push(this.loadSkill(this.question_version.question.skill_id));
    }

    if (promises.length) {
      await Promise.all(promises);
    }

    this.preload = false;
  }

  private async loadQuestion() {
    return this.$api
      .get(akt_routes.question(this.question_version_id))
      .then(async ({ data: res }: { data: AktQuestionVersion }) => {
        this.selected_users = res.responsibles!;
        this.question_version = plainToClass(AktQuestionVersion, res);
        this.questionable = this.question_version.questionable!;

        this.structure = {
          factory_id: res.question!.factory_id,
          workshop_id: res.question!.workshop_id,
          workline_id: res.question!.workline_id,
          equipment_id: res.question!.equipment_id,
          infinity_id: res.question!.infinity_id
        };
      });
  }

  private async loadSkillCategory(category_id: number) {
    return this.$api
      .get(skill_routes.category(category_id))
      .then(({ data: res }: { data: SkillCategory }) => {
        this.skill_category = res;
      });
  }

  private async loadSkill(skill_id: string) {
    return this.$api
      .get(skill_routes.skill(skill_id))
      .then(({ data: res }: { data: SkillLocal }) => {
        this.skill = res;
      });
  }

  private async loadFactory(factory_id: string) {
    return this.$api
      .get(structure_routes.factory(factory_id))
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factory = res;
      });
  }
}

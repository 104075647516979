import "reflect-metadata";
import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";
import { Type } from "class-transformer";

import { ESkillDocumentTypes } from "@/models/skill/local";
import ApplicationProtocol from "@/models/application/protocol";
import ApplicationCertificate from "@/models/application/certificate";
import ApplicationInterval from "@/models/application/interval";

export const educationTypes = [
  {
    label: "Внешнее",
    value: 1
  },
  {
    label: "Внутреннее",
    value: 2
  }
];

export enum EEducationTypes {
  EXTERNAL = 1,
  INSIDE = 2
}

export const applicationStatuses = [
  {
    label: "Отозвано",
    value: 1
  },
  {
    label: "На согласовании",
    value: 2
  },
  {
    label: "В работе",
    value: 3
  },
  {
    label: "Закрыта",
    value: 4
  },
  {
    label: "Отклонена",
    value: 5
  },
  {
    label: "В работе",
    value: 6
  },
  {
    label: "В работе",
    value: 7
  },
  {
    label: "Подтверждена",
    value: 8
  },
  {
    label: "На согласовании",
    value: 9
  }
];

export enum EApplicationStatuses {
  WITHDRAWN = 1,
  APPROVAL = 2,
  WORK = 3,
  CLOSE = 4,
  REJECTED = 5,
  PLANNED = 6,
  LOAD = 7,
  CONFIRMED = 8,
  EXECUTION = 9
}

export const applicationTypes = [
  {
    label: "Групповое",
    value: 1
  },
  {
    label: "Персональное",
    value: 2
  }
];

export enum EApplicationTypes {
  COLLECTIVE = 1,
  INDIVIDUAL = 2
}

export default class ApplicationApplication {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsEnum(EApplicationStatuses)
  public status?: EApplicationStatuses;

  @IsOptional()
  @IsEnum(EApplicationTypes)
  public application_type?: EApplicationTypes;

  @IsOptional()
  @IsEnum(EEducationTypes)
  public education_type?: EEducationTypes;

  @IsOptional()
  @IsEnum(ESkillDocumentTypes)
  public document_type?: ESkillDocumentTypes | null;

  @IsUUID("4")
  public workshop_id!: string;

  @IsUUID("4")
  public author_id!: string;

  @IsUUID("4")
  public responsible_id!: string;

  @IsUUID("4")
  public skill_id!: string;

  @IsOptional()
  @IsNumber()
  public readonly count_student?: number;

  @IsOptional()
  @IsNumber()
  public readonly seen_count?: number;

  @IsOptional()
  @IsString()
  public readonly author_name?: string;

  @IsOptional()
  @IsString()
  public readonly responsible_name?: string;

  @IsOptional()
  @IsString()
  public readonly skill_name?: string;

  @IsOptional()
  @IsNumber()
  public readonly skill_category?: string;

  @IsOptional()
  @IsString()
  public readonly workshop_name?: string;

  @IsOptional()
  @IsString()
  public readonly factory_name?: string;

  @IsOptional()
  @IsBoolean()
  public documentary_evidence: boolean = false;

  @IsOptional()
  @IsDate()
  public readonly created_at?: Date;

  @IsNumber()
  public deadline: number = 0;

  @Type(() => ApplicationProtocol)
  public protocols?: ApplicationProtocol[];

  @Type(() => ApplicationInterval)
  public intervals?: ApplicationInterval[];

  @Type(() => ApplicationCertificate)
  public certificates?: ApplicationCertificate[];

  public deadline_certificate = 1;

  public show?: boolean;

  public not_planned_count: number = 0;

  @IsOptional()
  @IsString()
  public certificate_id?: string;

  @IsOptional()
  @IsString()
  public protocol_id?: string;

  public isMultiInterval() {
    return (
      this.application_type !== EApplicationTypes.COLLECTIVE ||
      (this.application_type == EApplicationTypes.COLLECTIVE &&
        this.education_type === EEducationTypes.INSIDE)
    );
  }

  public isCollectiveExternal() {
    return (
      this.application_type === EApplicationTypes.COLLECTIVE &&
      this.education_type === EEducationTypes.EXTERNAL
    );
  }

  public isShowInterval() {
    return (
      this.status === EApplicationStatuses.PLANNED ||
      this.status === EApplicationStatuses.LOAD ||
      this.status === EApplicationStatuses.CLOSE
    );
  }

  public isApprovalExecution() {
    return (
      this.status === EApplicationStatuses.APPROVAL ||
      this.status === EApplicationStatuses.EXECUTION
    );
  }

  public isRejectedWithdrawn() {
    return (
      this.status === EApplicationStatuses.REJECTED ||
      this.status === EApplicationStatuses.WITHDRAWN
    );
  }

  public isGroupDocument() {
    return (
      this.document_type === ESkillDocumentTypes.GROUP || !this.document_type
    );
  }

  public get isInsideGroupApplication() {
    return (
      this.education_type === EEducationTypes.INSIDE &&
      this.application_type === EApplicationTypes.COLLECTIVE
    );
  }

  public get withdrawn() {
    return this.status === EApplicationStatuses.WITHDRAWN;
  }

  public get approval() {
    return (
      this.status === EApplicationStatuses.APPROVAL ||
      this.status === EApplicationStatuses.EXECUTION
    );
  }

  public get work() {
    return (
      this.status === EApplicationStatuses.WORK ||
      this.status === EApplicationStatuses.PLANNED ||
      this.status === EApplicationStatuses.LOAD
    );
  }

  public get close() {
    return this.status === EApplicationStatuses.CLOSE;
  }

  public get rejected() {
    return this.status === EApplicationStatuses.REJECTED;
  }
}

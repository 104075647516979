
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import structures_routes from "@/api/routes/structures";
import admission_routes from "@/api/routes/admission";

import { AdmissionRole } from "@/models/admission/role";
import StructureFactory from "@/models/structure/factory";

import CAdmissionRoleChangePermissions from "./RoleChangePermissions.vue";
import CAdmissionRoleChangePositions from "./RoleChangePositions.vue";
import CAdmissionRoleChangeUsers from "./RoleChangeUsers.vue";

export interface IChangingAdmissionRole {
  id: string | null;
  role_index: number | null;
}

export interface IChangedAdmissionRole {
  role: Omit<AdmissionRole, "permissions">;
  role_index: number | null;
}

interface IChangePositionsOrUsers {
  add: Set<string>;
  del: Set<string>;
}

@Component({
  name: "CAdmissionRoleChange",
  components: {
    CAdmissionRoleChangePermissions,
    CAdmissionRoleChangePositions,
    CAdmissionRoleChangeUsers
  }
})
export default class CAdmissionRoleChange extends Vue {
  private modal_opened: boolean = false;

  public tabs = ["Модель", "Должности", "Пользователи"];
  public current_tab: number = 0;

  public role_id: string | null = null;
  private role_index: number | null = null;

  public role: AdmissionRole | null = null;

  private add_positions_ids: Set<string> | null = null;
  private del_positions_ids: Set<string> | null = null;

  private add_users_ids: Set<string> | null = null;
  private del_users_ids: Set<string> | null = null;

  public factories: StructureFactory[] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await Promise.all([this.loadFactories()]);

    this.watchers.push(
      this.$store.watch(
        state => state.changingAdmissionRole,
        async (changingAdmissionRole: IChangingAdmissionRole | null) => {
          await this.initHandler(changingAdmissionRole);
        }
      )
    );

    if (this.$store.state.changingAdmissionRole && !this.modal_opened) {
      await this.initHandler(this.$store.state.changingAdmissionRole);
    }
  }

  private async initHandler(
    changing_admission_role: IChangingAdmissionRole | null
  ) {
    this.add_positions_ids = null;
    this.del_positions_ids = null;

    this.add_users_ids = null;
    this.del_users_ids = null;

    if (changing_admission_role != null) {
      this.current_tab = 0;
      this.role_id = changing_admission_role.id;
      this.role_index = changing_admission_role.role_index;

      await this.loadRole();

      this.showModal();
    } else {
      this.role_id = null;
      this.role_index = null;
      this.role = null;
    }
  }

  public showModal() {
    this.$modal.show("change_admission_role_modal");
    this.modal_opened = true;
  }

  public beforeHideModal(event: { cancel: () => void }) {
    if (this.modal_opened) {
      event.cancel();

      this.$modal.show("confirm_close_change_admission_role_modal");
    }
  }

  public hideModal() {
    this.$modal.hide("change_admission_role_modal");
  }

  public afterHideModal() {
    this.modal_opened = false;

    this.$store.commit("changingAdmissionRole", null);

    this.$modal.hide("confirm_close_change_admission_role_modal");
    this.$modal.hide("change_admission_role_modal");
  }

  private async loadRole() {
    if (!this.role_id) {
      this.role = new AdmissionRole();

      return;
    }

    return this.$api
      .get(admission_routes.role(this.role_id))
      .then(({ data: res }: { data: AdmissionRole }) => {
        this.role = plainToClass(AdmissionRole, res);
      });
  }

  private async loadFactories() {
    return this.$api
      .get(structures_routes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      });
  }

  public changePositions(data: IChangePositionsOrUsers) {
    this.add_positions_ids = data.add;
    this.del_positions_ids = data.del;
  }

  public changeUsers(data: IChangePositionsOrUsers) {
    this.add_users_ids = data.add;
    this.del_users_ids = data.del;
  }

  public async createRole() {
    return this.$api
      .post(admission_routes.roles, {
        role: this.role,
        add_positions_ids: Array.from(this.add_positions_ids || []),
        add_users_ids: Array.from(this.add_users_ids || [])
      })
      .then(({ data: res }: { data: Omit<AdmissionRole, "permissions"> }) => {
        this.$store.commit("changedAdmissionRole", {
          role: plainToClass(AdmissionRole, res),
          role_index: this.role_index
        });

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.current_tab = 0;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async updateRole() {
    if (!this.role_id) {
      return;
    }

    return this.$api
      .put(admission_routes.role(this.role_id), {
        role: this.role,
        add_positions_ids: Array.from(this.add_positions_ids || []),
        add_users_ids: Array.from(this.add_users_ids || []),
        del_positions_ids: Array.from(this.del_positions_ids || []),
        del_users_ids: Array.from(this.del_users_ids || [])
      })
      .then(({ data: res }: { data: Omit<AdmissionRole, "permissions"> }) => {
        this.$store.commit("changedAdmissionRole", {
          role: plainToClass(AdmissionRole, res),
          role_index: this.role_index
        });

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        this.afterHideModal();
      })
      .catch(({ response: res }) => {
        this.current_tab = 0;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import NotificationNotification from "@/models/notification/notification";

@Component({
  name: "CSummaryContent"
})
export default class CSummaryContent extends Vue {
  @Prop({ required: true }) public notification!: NotificationNotification;

  public closeSummary() {
    this.$emit("close", true);
  }

  public showSummary() {
    this.$emit("onVisited", true);
  }
}

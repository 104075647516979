
import { Component, Vue } from "vue-property-decorator";

import userRoutes from "@/api/routes/users";

import UserDelegate from "@/models/user/delegate";
import UserUser from "@/models/user/user";

@Component
export default class CDelegate extends Vue {
  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected delegate: UserDelegate = new UserDelegate();

  protected availableUsers: UserUser[] = [];
  protected selectedUser: UserUser = new UserUser();

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    await this.loadDelegate();
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async showDelegateModal() {
    await this.loadAvailableUsers();

    this.$modal.show("DelegateUserModal");
  }

  protected hideDelegateModal() {
    this.$modal.hide("DelegateUserModal");
  }

  protected showUnDelegateModal() {
    this.$modal.show("UnDelegateUserModal");
  }

  protected hideUnDelegateModal() {
    this.$modal.hide("UnDelegateUserModal");
  }

  protected loadAvailableUsers() {
    return this.$api
      .get(userRoutes.delegates)
      .then(({ data: res }: { data: UserUser[] }) => {
        this.availableUsers = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadDelegate() {
    return this.$api
      .get(userRoutes.delegate(this.currentUser?.id!))
      .then(({ data: res }: { data: UserDelegate }) => {
        this.delegate = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async delegateUser() {
    if (!this.selectedUser?.id) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Необходимо выбрать пользователя",
        speed: 500
      });

      return;
    }
    return this.$api
      .post(userRoutes.delegates, { delegate_id: this.selectedUser?.id! })
      .then(({ data: res }: { data: UserDelegate }) => {
        this.delegate = res;

        this.hideDelegateModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async removeDelegate() {
    return this.$api
      .destroy(userRoutes.delegate(this.delegate.id))
      .then(() => {
        this.delegate = new UserDelegate();
        this.hideUnDelegateModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import { ru } from "vuejs-datepicker/dist/locale";
import { IDateTime } from "@/models/dateTime";

@Component({
  name: "DateTimePicker"
})
export default class DateTimePicker extends Vue {
  public ru = ru;

  @Prop({ required: false, default: () => new Date() })
  public value!: Date | string;

  @Prop({ required: false, default: () => true })
  public showDate!: boolean;

  @Prop({ required: false, default: () => true })
  public showTime!: boolean;

  @Prop({ required: false, default: () => false })
  public disabled!: boolean;

  @Prop({ required: false, default: () => true })
  public withDisabledDate!: boolean;

  @Prop({ required: false, default: () => "" })
  public labelAfter!: string;

  public dateTime: IDateTime | null = null;
  private date_value: Date | null = null;

  public isChange: boolean = true;

  protected created() {
    if (typeof this.value === "string") {
      this.date_value = new Date(this.value);
    } else {
      this.date_value = this.value;
    }

    this.dateTime = {
      date: this.date_value,
      time: {
        HH: this.addZero(this.date_value.getHours()),
        mm: this.addZero(this.date_value.getMinutes())
      },
      disabledDates: {
        to: this.date_value
      }
    };
  }

  public setDate() {
    if (this.isChange) {
      this.isChange = false;
      return;
    }

    this.$emit("input", this.getDate());
  }

  public setTime() {
    if (this.isChange) {
      this.isChange = false;
      return;
    }

    this.$emit("input", this.getDate());
  }

  public addZero(num: number) {
    if (num < 10) {
      return `0${num}`;
    }

    return `${num}`;
  }

  public getDate() {
    const day = this.dateTime!.date!.getDate();
    const month = this.dateTime!.date!.getMonth();
    const year = this.dateTime!.date!.getFullYear();
    const hour = parseInt(this.dateTime!.time.HH, 10);
    const minutes = parseInt(this.dateTime!.time.mm, 10);

    return new Date(year, month, day, hour!, minutes!);
  }
}

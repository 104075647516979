
import { Component, Vue } from "vue-property-decorator";

import { ETestingStatus, testingStatus } from "@/models/testing/testing";

interface IStatusOption {
  label: string;
  value?: ETestingStatus;
}

interface IRepeatOption {
  label: string;
  value?: boolean;
}

export interface IFilters {
  status?: ETestingStatus;
  repeat?: boolean;
  is_archived: boolean;
}

@Component({
  name: "CTestingsFilters"
})
export default class CTestingsFilters extends Vue {
  public show_filter: boolean = false;
  public filters: Map<string, boolean> = new Map();
  public filters_count: number = 0;

  public statuses: IStatusOption[] = [{ label: "Все" }, ...testingStatus];
  public current_status: IStatusOption = this.statuses[0];

  public repeats: IRepeatOption[] = [
    { label: "Все" },
    { label: "Регулярный", value: true },
    { label: "Нерегулярный", value: false }
  ];
  public current_repeat: IRepeatOption = this.repeats[0];

  public include_archived: boolean = false;

  public changeStatusFilter() {
    if (this.current_status.value) {
      this.filters.set("status", true);
    } else {
      this.filters.delete("status");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public changeRepeatFilter() {
    if (this.current_repeat.value) {
      this.filters.set("repeat", true);
    } else {
      this.filters.delete("repeat");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public changeIncludeArchivedAFilter() {
    if (this.include_archived) {
      this.filters.set("include_archived", true);
    } else {
      this.filters.delete("include_archived");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public clearFilters() {
    this.current_status = this.statuses[0];
    this.current_repeat = this.repeats[0];
    this.include_archived = false;

    this.filters.delete("status");
    this.filters.delete("repeat");
    this.filters.delete("include_archived");

    this.filters_count = this.filters.size;

    this.apply();
  }

  private apply() {
    this.$emit("apply", {
      status: this.current_status.value,
      repeat: this.current_repeat.value,
      is_archived: this.include_archived
    });
  }
}


import { Vue, Component } from "vue-property-decorator";

@Component
export default class AuthMiddle extends Vue {
  protected created() {
    this.$store.dispatch("updateCurrentUser", {
      authorized: false,
      currentUser: null
    });

    if (this.$route.query.corporate) {
      this.$api.redirectToOAuth();
    }
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import testings_routes from "@/api/routes/testings";

import { EPermission } from "@/enums/permissions";

interface IDataQuestion {
  readonly title?: string;
  incorrect_answers?: number;
  readonly average_time?: number;
  readonly results?: IDataQuestionResult[];
}

interface IDataQuestionResult {
  is_true: boolean;
  is_checked: boolean;
  readonly user_name?: string;
  readonly result_value?: string;
  readonly assignment_id?: string;
  readonly assignment_question_id?: string;
}

@Component({
  name: "VOneTestingCheck",
  components: {
    Preloader
  }
})
export default class VOneTestingCheck extends Vue {
  @Prop({ required: true }) private question_id!: string;

  public preload: boolean = false;

  public question: IDataQuestion | null = null;

  protected async created() {
    await this.loadTestingCheck();
  }

  private async loadTestingCheck() {
    this.preload = true;

    return this.$api
      .get(testings_routes.check(this.question_id))
      .then(({ data: res }: { data: IDataQuestion }) => {
        this.question = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public async setCheck(result: IDataQuestionResult, is_true: boolean) {
    if (!this.question || (result.is_checked && result.is_true === is_true)) {
      return;
    }

    result.is_true = is_true;
    result.is_checked = true;

    return this.$api
      .put(testings_routes.check(result.assignment_question_id!), {
        is_true: is_true
      })
      .then(({ data: res }: { data: IDataQuestion }) => {
        this.question!.incorrect_answers = res.incorrect_answers;

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",

          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testing_checks);
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import TestQuestionVersion from "@/models/unit/unitable/test/question_version";
import TestVersion from "@/models/unit/unitable/test/version";
import ImageView from "@/components/ImageView.vue";
import SubscriptionUnit from "@/models/subscriptions/unit";

@Component({
  components: {
    ImageView
  }
})
export default class SubscriptionUnitResult extends Vue {
  @Prop({ required: true }) test_version!: TestVersion;
  @Prop({ required: true }) subscription_unit!: SubscriptionUnit;

  private questionCount: number = this.test_version.question_versions.length;
  private correctCount: number = 0;
  private correctPercent: number = 0;
  private correctScore: number = this.test_version.score;

  private created() {
    if (this.subscription_unit.isDone) {
      this.correctPercent = this.subscription_unit.passing_percents;
      return;
    }

    this.test_version.question_versions.forEach(qv => {
      if (qv.is_correct) {
        this.correctCount++;
      }
    });

    this.correctPercent = +(
      (this.correctCount / (this.questionCount || 1)) *
      100
    ).toFixed(2);
  }
}

import {
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";

import TestingQuestionSurveyVersion from "@/models/testing/question_survey_version";
import TestingQuestionTextVersion from "@/models/testing/question_text_version";
import TestingQuestionAnswerResult from "@/models/testing/question_answer_result";
import TestingQuestionTextResult from "@/models/testing/question_text_result";

export enum EQuestionType {
  TEXT = 1,
  SINGLE = 2,
  MULTIPLE = 3
}

export const questionType = [
  {
    label: "Одиночный выбор",
    value: EQuestionType.SINGLE
  },
  {
    label: "Множественный выбор",
    value: EQuestionType.MULTIPLE
  },
  {
    label: "Открытый вопрос",
    value: EQuestionType.TEXT
  }
];

export enum EFileType {
  IMAGE = 1,
  VIDEO = 2,
  AUDIO = 3
}

export const fileType = [
  {
    label: "Не использовать",
    value: null
  },
  {
    label: "Картинка",
    value: EFileType.IMAGE
  },
  {
    label: "Видео",
    value: EFileType.VIDEO
  },
  {
    label: "Аудио",
    value: EFileType.AUDIO
  }
];

export default class TestingQuestionVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public title!: string;

  @IsUUID("4")
  @IsOptional()
  public question_id?: string;

  @IsNumber()
  @IsOptional()
  public version?: number;

  @IsEnum(EQuestionType)
  public question_type!: EQuestionType;

  @IsEnum(EFileType)
  @IsOptional()
  public file_type: EFileType | null = null;

  @IsString()
  public file_url?: string;

  @IsNumber()
  public position!: number;

  @IsBoolean()
  public can_positive: boolean = false;

  @IsUUID("4")
  @IsOptional()
  public skill_id: string | null = null;

  @IsNumber()
  @IsOptional()
  public positive_level: number = 0;

  @IsBoolean()
  public can_negative: boolean = false;

  @IsNumber()
  @IsOptional()
  public negative_level: number = 0;

  @IsUUID("4")
  @IsOptional()
  public auditor_id?: string;

  @IsString()
  public questionable_version_type!: string;

  public result_count: number = 0;

  public questionable:
    | TestingQuestionSurveyVersion
    | TestingQuestionTextVersion = new TestingQuestionSurveyVersion();

  public answer_results?: TestingQuestionAnswerResult[] = [];

  public text_result?: TestingQuestionTextResult = new TestingQuestionTextResult();

  public get isText() {
    return this.question_type === EQuestionType.TEXT;
  }

  public get isSingle() {
    return this.question_type === EQuestionType.SINGLE;
  }

  public get isMultiple() {
    return this.question_type === EQuestionType.MULTIPLE;
  }
}

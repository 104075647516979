
import { Component, Vue, Prop } from "vue-property-decorator";

import programs_routes from "@/api/routes/programs";

import { IProgramAgreement } from "@/models/program/agreement";

@Component({
  name: "CProgramApproval"
})
export default class CProgramApproval extends Vue {
  @Prop({ required: true }) public agreement_id!: string;

  public agreement: IProgramAgreement | null = null;

  protected async created() {
    await this.loadAgreement();
  }

  private async loadAgreement() {
    return this.$api
      .get(programs_routes.agreement(this.agreement_id))
      .then(({ data: res }: { data: IProgramAgreement }) => {
        this.agreement = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public closeApproval() {
    this.$emit("close", true);
  }

  public delegateApproval() {
    this.$emit("delegate", this.agreement);
  }

  public showProgram() {
    if (this.agreement) {
      this.$emit("onVisited", this.agreement.program_id);
    }
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import skills_routes from "@/api/routes/skills";

import {
  EAktStatus,
  EAktType,
  aktTypes,
  aktStatuses
} from "@/models/akt/question_version";
import SkillCategory, { ESkillCategory } from "@/models/skill/category";
import SkillLocal from "@/models/skill/local";
import { IAktTag } from "@/models/akt/tag";

export interface IFilter {
  akt_type_id: EAktType | null;
  akt_status_id: EAktStatus | null;
  skill_category_id: ESkillCategory | null;
  skill_id: string | null;
  tag_ids: string[] | null;
}

@Component({
  name: "CAktQuestionFilter"
})
export default class CAktQuestionFilter extends Vue {
  public show_filter: boolean = false;
  public filters: Map<string, boolean> = new Map();
  public filters_count: number = 0;

  @Prop({ required: true }) public tags!: IAktTag[];
  public tag_ids: string[] | null = null;

  @Prop({ required: false, default: false })
  public disabled_akt_type_id!: boolean;

  @Prop({ required: false, default: null })
  public akt_type_id!: EAktType | null;

  public akt_types = aktTypes;
  public selected_akt_type_id: EAktType | null = null;

  @Prop({ required: false, default: false })
  public disabled_akt_status_id!: boolean;

  @Prop({ required: false, default: null })
  public akt_status_id!: EAktStatus | null;

  public akt_statuses = aktStatuses;
  public selected_akt_status_id: EAktStatus | null = null;

  public skill_categories: SkillCategory[] = [];
  public skill_category_id: ESkillCategory | null = null;

  public skills: SkillLocal[] = [];
  public skill_id: string | null = null;

  protected async created() {
    this.setDefaultAktFilters();

    await Promise.all([this.loadSkillCategories()]);
  }

  private setDefaultAktFilters() {
    this.tag_ids = [];
    this.selected_akt_type_id = this.akt_type_id;

    if (this.selected_akt_type_id) {
      this.filters.set("akt_type", true);
    }

    this.selected_akt_status_id = this.akt_status_id;
    if (this.selected_akt_status_id) {
      this.filters.set("akt_status", true);
    }

    this.filters_count = this.filters.size;
  }

  public clearFilters() {
    this.filters.clear();

    this.skill_category_id = null;
    this.skill_id = null;

    this.skills = [];

    this.setDefaultAktFilters();

    this.apply();
  }

  public changeAktTypeFilter() {
    if (this.selected_akt_type_id) {
      this.filters.set("akt_type", true);
    } else {
      this.filters.delete("akt_type");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public changeAktStatusFilter() {
    if (this.selected_akt_status_id) {
      this.filters.set("akt_status", true);
    } else {
      this.filters.delete("akt_status");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public async changeSkillCategoryFilter() {
    this.skill_id = null;
    this.filters.delete("skill");

    if (this.skill_category_id) {
      this.filters.set("skill_category", true);

      await this.loadSkills();
    } else {
      this.filters.delete("skill_category");

      this.skills = [];
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public changeSkillFilter() {
    if (this.skill_id) {
      this.filters.set("skill", true);
    } else {
      this.filters.delete("skill");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public changeTagFilter() {
    if (this.tag_ids?.length) {
      this.filters.set("tag", true);
    } else {
      this.filters.delete("tag");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  private apply() {
    this.$emit("apply", {
      akt_type_id: this.selected_akt_type_id,
      akt_status_id: this.selected_akt_status_id,
      skill_category_id: this.skill_category_id,
      skill_id: this.skill_id,
      tag_ids: this.tag_ids
    });
  }

  private async loadSkillCategories() {
    return this.$api
      .get(skills_routes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skill_categories = res;
      });
  }

  private async loadSkills() {
    this.skills = [];

    if (this.skill_category_id) {
      return this.$api
        .get(skills_routes.skills, {
          params: {
            paginate: false,
            includes: "all",
            category_id: this.skill_category_id
          }
        })
        .then(({ data: res }: { data: { skills: SkillLocal[] } }) => {
          this.skills = res.skills;
        });
    }
  }
}

export enum EAdmissionPermission {
  USERS = 1,
  POSITIONS = 2,
  EDUCATION_PROGRAMS = 3,
  STRUCTURES = 4,
  PRIORITIES = 5,
  ANALYTICS = 6,
  AKT_QUESTIONS = 7,
  AKT_TEMPLATES = 8,
  AKT_SETTINGS = 9,
  AKT_COMPLETED = 10,
  SKILLS = 11,
  INVENTORIES = 12,
  BLOCKS = 13,
  SKILL_SIGNS = 14,
  USER_SIGNS = 15,
  TESTINGS = 16
}

export enum EAdmissionPermissionLeader {
  TEAMS = 17,
  DASHBOARDS = 18
}

export const AdmissionPermissionText = {
  [EAdmissionPermission.USERS]: "Сотрудники",
  [EAdmissionPermission.POSITIONS]: "Должности",
  [EAdmissionPermission.EDUCATION_PROGRAMS]: "Программы развития",
  [EAdmissionPermission.STRUCTURES]: "Орг. Структура",
  [EAdmissionPermission.PRIORITIES]: "Приоритеты",
  [EAdmissionPermission.ANALYTICS]: "Аналитика",
  [EAdmissionPermission.AKT_QUESTIONS]: "Вопросы ЕПЗ",
  [EAdmissionPermission.AKT_TEMPLATES]: "Шаблоны ЕПЗ",
  [EAdmissionPermission.AKT_SETTINGS]: "Настройки ЕПЗ",
  [EAdmissionPermission.AKT_COMPLETED]: "Завершенные ЕПЗ",
  [EAdmissionPermission.SKILLS]: "Навыки",
  [EAdmissionPermission.INVENTORIES]: "Группы оборудования",
  [EAdmissionPermission.BLOCKS]: "Группы навыков",
  [EAdmissionPermission.SKILL_SIGNS]: "Признаки навыков",
  [EAdmissionPermission.USER_SIGNS]: "Признаки сотрудников",
  [EAdmissionPermission.TESTINGS]: "Тестирование"
};

export enum EAdmissionLevel {
  READ = 1,
  WRITE = 2
}

export class AdmissionPermission {
  public readonly id!: string | null;

  public factory_ids: string[] = [];
  public enabled: boolean = false;
  public permission_id!: EAdmissionPermission;
  public admission_level_id!: EAdmissionLevel;
  public only_user_workplaces: boolean = false;

  constructor(permission_id: EAdmissionPermission) {
    this.permission_id = permission_id;

    this.admission_level_id =
      this.permission_id >= 6 ? EAdmissionLevel.WRITE : EAdmissionLevel.READ;
  }

  public get allowChangeOnlyUserWorkplaces() {
    return this.permission_id === EAdmissionPermission.ANALYTICS;
  }

  public get allowChangeAdmissionLevel() {
    return this.permission_id < 6;
  }

  public get allowChangeFactories() {
    return this.permission_id <= 6;
  }
}

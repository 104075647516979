
import { Component, Vue } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";

import Preloader from "@/components/Preloader.vue";
import CRangeDatePicker from "@/components/RangeDatePicker.vue";
import Search from "@/components/Search.vue";
import CAktPassedTestingsEmptyResult from "./components/EmptyTestings.vue";

import {
  EAktAssignmentUserWorkplaceResultStatus,
  EAktAssignmentUserWorkplaceStatus,
  aktWorkplaceResultStatuses
} from "@/models/akt/assignment_user_workplace";
import { IAktTemplate } from "@/models/akt/template";

interface IWorkplace {
  readonly id: string;
  readonly status_id: EAktAssignmentUserWorkplaceStatus;
  readonly result_status_id: EAktAssignmentUserWorkplaceResultStatus;
  readonly learning_time: number;
  readonly passing_percent: number;
  readonly completion_percent: number;
  readonly template_id: string;
  readonly template_title: string;
  readonly user_id: string;
  readonly user_full_name: string;
  readonly assignment_starts_at: string;
  readonly assignment_real_starts_at: string;
}

interface IData {
  current_page: number;
  next_page: boolean;
  workplaces: IWorkplace[];
}

interface IDataTemplates {
  templates: IAktTemplate[];
}

interface ITemplate {
  id: string;
  label: string;
}

@Component({
  name: "VAktPassedTesting",
  components: {
    Preloader,
    Search,
    CAktPassedTestingsEmptyResult,
    CRangeDatePicker
  }
})
export default class VAktPassedTesting extends Vue {
  public preload: boolean = false;
  public preload_infinite: boolean = false;
  protected watchers: Function[] = [];

  public aktWorkplaceResultStatuses = aktWorkplaceResultStatuses;
  public EAktAssignmentUserWorkplaceResultStatus = EAktAssignmentUserWorkplaceResultStatus;

  public search: string = "";

  public show_filter: boolean = false;
  public filters: Map<string, boolean> = new Map();
  public filters_count: number = 0;

  public per_page: number = 100;
  public current_page: number = 0;
  public next_page: boolean = false;

  public current_date: Date = new Date();
  public target_year: number = this.current_date.getFullYear();
  public only_my_team: boolean = true;
  public available_years: number[] = [this.target_year];
  public filter_templates: ITemplate[] = [];
  public template: ITemplate | null = null;

  public range_date: string[] = this.$store.state.rangeDate;

  public min_date: Date = new Date(this.target_year, 0, 1);
  public max_date: Date = new Date(this.target_year + 1, 0, 1);

  public akt_workplace_status: { label: string; value: number } | null = null;

  public workplaces: IWorkplace[] = [];

  protected async created() {
    this.preload = true;

    this.filters.set("year", true);
    this.filters.set("team", true);
    this.filters_count = 2;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;

          await this.loadTestings();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.rangeDate,
        rangeDate => {
          this.range_date = rangeDate;
          if (this.range_date.length > 0) {
            this.changeRangeDate();
          }
        }
      )
    );

    await Promise.all([
      this.loadTestings(),
      this.loadYears(),
      this.loadTemplates()
    ]).then(() => (this.preload = false));
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadTestings(page: number = 0) {
    this.preload_infinite = true;

    const start_date =
      this.range_date[0] != null ? new Date(this.range_date[0]) : this.min_date;

    const end_date =
      this.range_date[1] != null ? new Date(this.range_date[1]) : this.max_date;

    end_date.setHours(23);
    end_date.setMinutes(59);
    end_date.setSeconds(59);
    end_date.setMilliseconds(999);

    return this.$api
      .get(akt_routes.get_responsible_testings, {
        params: {
          year: this.target_year,
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search,
          only_my_team: this.only_my_team,
          status_id: this.akt_workplace_status?.value || "",
          template_id: this.template?.id,
          start_date,
          end_date
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        if (this.current_page === 0) {
          this.workplaces = res.workplaces;
        } else {
          this.workplaces.concat(...res.workplaces);
        }
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  protected async loadTemplates() {
    return this.$api
      .get(akt_routes.templates_actual, {
        params: {
          paginate: false,
          include_archived: false
        }
      })
      .then(({ data: res }: { data: IDataTemplates }) => {
        this.filter_templates = res.templates.map(t => ({
          id: t.id,
          label: t.template_version_title
        }));
      });
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadTestings(this.current_page + 1);
    }
  }

  protected async loadYears() {
    return this.$api
      .get(akt_routes.assignment_available_years)
      .then(({ data: res }: { data: number[] }) => {
        this.available_years = res;
      });
  }

  public async clearFilters() {
    this.target_year = this.current_date.getFullYear();
    this.only_my_team = true;
    this.akt_workplace_status = null;
    this.template = null;

    this.min_date = new Date(this.target_year, 0, 1);
    this.max_date = new Date(this.target_year + 1, 0, 1);

    this.range_date = [];
    this.$store.commit("rangeDate", this.range_date);

    this.filters.set("team", true);
    this.filters.delete("status");
    this.filters.delete("template");
    this.filters.delete("range");

    this.filters_count = this.filters.size;

    await this.loadTestings();
  }

  public async changeYear() {
    if (!this.target_year) {
      this.target_year = this.current_date.getFullYear();
    }

    this.min_date = new Date(this.target_year, 0, 1);
    this.max_date = new Date(this.target_year + 1, 0, 1);

    this.range_date = [];
    this.$store.commit("rangeDate", this.range_date);

    await this.loadTestings();
  }

  public changeRangeDate() {
    this.filters.set("range", true);

    this.filters_count = this.filters.size;

    this.loadTestings();
  }

  public async changeStatus() {
    if (this.akt_workplace_status) {
      this.filters.set("status", true);
    } else {
      this.filters.delete("status");
    }

    this.filters_count = this.filters.size;

    await this.loadTestings();
  }

  public async changeOnlyMyTeam() {
    if (this.only_my_team) {
      this.filters.set("team", true);
    } else {
      this.filters.delete("team");
    }

    this.filters_count = this.filters.size;

    await this.loadTestings();
  }

  public async changeTemplate() {
    this.filters.set("template", true);

    this.filters_count = this.filters.size;

    await this.loadTestings();
  }
}


import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import testings_routes from "@/api/routes/testings";
import users_routes from "@/api/routes/users";
import structure_routes from "@/api/routes/structures";

import { EPermission } from "@/enums/permissions";
import TestingTestingVersion from "@/models/testing/testing_version";
import TestingQuestionVersion from "@/models/testing/question_version";
import TestingComment from "@/models/testing/comment";
import UserUser from "@/models/user/user";
import StructureFactory from "@/models/structure/factory";

import CTestingsChangeSettings from "./TestingChangeSettings.vue";
import CTestingsChangeQuestions from "./TestingChangeQuestions.vue";
import CTestingsChangeEmployees from "./TestingChangeEmployees.vue";
import CTestingsChangeComments from "./TestingChangeComments.vue";
import CTestingsChangeApproval from "./TestingChangeApproval.vue";

export interface IChangingTesting {
  id: string | null;
  parent_folder_id: string | null;
}

export interface IChangedTesting {
  id: string;
  parent_folder_id: string;
  name: string;
}

@Component({
  name: "CTestingsChange",
  components: {
    CTestingsChangeSettings,
    CTestingsChangeQuestions,
    CTestingsChangeEmployees,
    CTestingsChangeComments,
    CTestingsChangeApproval
  }
})
export default class CTestingsChange extends Vue {
  private watchers: Function[] = [];

  public parent_folder_id: string | null = null;
  public testing_id: string | null = null;

  public testing: TestingTestingVersion | null = null;
  public questions: TestingQuestionVersion[] = [];
  public comments: TestingComment[] = [];
  public factories: StructureFactory[] = [];

  public responsibles: UserUser[] = [];
  public reconciliations: UserUser[] = [];

  public testing_tabs: string[] = [
    "Настройки",
    "Вопросы",
    "Сотрудники",
    "Комментарии",
    "Согласование"
  ];
  public current_testing_tab_idx: number = 0;

  public current_user: IJWTUser | null = this.$store.state.currentUser;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changingTesting,
        async (changingTesting: IChangingTesting | null) => {
          if (changingTesting != null) {
            this.testing_id = changingTesting.id;
            this.parent_folder_id = changingTesting.parent_folder_id;

            await Promise.all([
              this.loadTesting(),
              this.loadQuestions(),
              this.loadComments()
            ]);

            this.showModal();
          } else {
            this.testing = null;
            this.questions = [];
            this.comments = [];
          }
        }
      )
    );

    await Promise.all([
      this.loadFactories(),
      this.loadReconciliations(),
      this.loadResponsibles()
    ]);
  }

  private async loadReconciliations() {
    let only_my = true;

    if (this.current_user?.is_admin) {
      only_my = false;
    }

    return this.$api
      .get(users_routes.users, {
        params: {
          only_my,
          paginate: false,
          permission: EPermission.testings
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.reconciliations = res.users;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadResponsibles() {
    let only_my = true;

    if (this.current_user?.is_admin) {
      only_my = false;
    }

    return this.$api
      .get(users_routes.users, {
        params: {
          only_my,
          paginate: false,
          permission: EPermission.testing_checks
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.responsibles = res.users;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadFactories() {
    return this.$api
      .get(structure_routes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadTesting() {
    if (!this.testing_id) {
      this.testing = new TestingTestingVersion();
    } else {
      return this.$api
        .get(testings_routes.testing(this.testing_id))
        .then(({ data: res }: { data: TestingTestingVersion }) => {
          this.testing = plainToClass(TestingTestingVersion, res);
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  private async loadQuestions() {
    if (!this.testing_id) {
      return;
    }

    return this.$api
      .get(testings_routes.show_question(this.testing_id))
      .then(({ data: res }: { data: TestingQuestionVersion[] }) => {
        this.questions = plainToClass(TestingQuestionVersion, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadComments() {
    if (!this.testing_id) {
      return;
    }

    return this.$api
      .get(testings_routes.comments, {
        params: {
          id: this.testing_id
        }
      })
      .then(({ data: res }: { data: TestingComment[] }) => {
        this.comments = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("change_testing_modal");
  }

  public hideModal() {
    this.$modal.hide("change_testing_modal");

    this.$store.commit("changingTesting", null);
  }

  public async sendToApproval() {
    const res = await this.saveTesting(false);

    if (typeof res !== "string") {
      return;
    }

    if (!this.testing?.id) {
      return;
    }

    return this.$api
      .put(testings_routes.send_to_approval(this.testing!.id), {})
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Отправлен на согласование",
          speed: 500
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async saveTesting(hide_modal: boolean = true) {
    if (this.testing?.id) {
      return this.updateTesting(hide_modal);
    } else {
      return this.createTesting(hide_modal);
    }
  }

  private async updateTesting(hide_modal: boolean) {
    if (!this.testing) {
      return;
    }

    return this.$api
      .put(testings_routes.testing(this.testing_id!), {
        testing: this.testing,
        questions: this.questions,
        reconciliations: this.testing.reconciliations
      })
      .then(({ data: res }: { data: TestingTestingVersion }) => {
        this.$store.commit("changedTesting", {
          id: res.testing_id,
          parent_folder_id: this.parent_folder_id,
          name: res.name
        });

        if (hide_modal) {
          this.$notify({
            group: "notifications",
            type: "success",
            text: "Изменения сохранены",
            speed: 500
          });

          this.hideModal();
        }

        return res.id;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async createTesting(hide_modal: boolean) {
    if (!this.testing) {
      return;
    }

    return this.$api
      .post(testings_routes.testings, {
        folder_id: this.parent_folder_id,
        testing: this.testing,
        questions: this.questions,
        reconciliations: this.testing.reconciliations
      })
      .then(({ data: res }: { data: TestingTestingVersion }) => {
        this.testing!.id = res.id;

        this.$store.commit("changedTesting", {
          id: res.testing_id,
          parent_folder_id: this.parent_folder_id,
          name: res.name
        });

        if (hide_modal) {
          this.$notify({
            group: "notifications",
            type: "success",
            text: "Изменения сохранены",
            speed: 500
          });

          this.hideModal();
        }

        return res.id;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testings);
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";
import { plainToClass } from "class-transformer";

import role_routes from "@/api/routes/roles";
import skill_routes from "@/api/routes/skills";
import pillar_routes from "@/api/routes/pillars";
import sign_routes from "@/api/routes/signs";
import testings_routes from "@/api/routes/testings";
import program_routes from "@/api/routes/programs";
import block_routes from "@/api/routes/blocks";
import user_routes from "@/api/routes/users";
import position_routes from "@/api/routes/positions";

import CSkillFilter from "@/components/SkillFilter.vue";
import CPaginatedSelect from "@/components/Select.vue";

import { ERole } from "@/enums/roles";
import AnalyticsFilter from "@/models/analytics/filter";
import { ESubscriptionProgressStatus } from "@/models/subscriptions/program";
import { ETaskCategory, ETaskStatus } from "@/models/task/task";
import { ESubscriptionStatus } from "@/models/subscriptions/program";
import { ETestingAssignmentStatus } from "@/models/testing/assignment";
import RoleRole from "@/models/role/role";
import PillarPillar from "@/models/pillar/pillar";
import SkillCategory from "@/models/skill/category";
import PositionLocal from "@/models/position/local";
import SkillLocal from "@/models/skill/local";
import UserUser from "@/models/user/user";
import BlockBlock from "@/models/block/block";
import SignUser from "@/models/sign/user";
import { IDataSearchTesting } from "@/models/testing/testing_version";
import IProgram from "@/views/constructor/programs/index/interfaces";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataPosition extends IData {
  readonly positions: PositionLocal[];
}

interface IDataSkill extends IData {
  readonly skills: SkillLocal[];
}

interface IDataUser extends IData {
  readonly users: UserUser[];
}

interface IDataBlock extends IData {
  readonly blocks: BlockBlock[];
}

interface IDataSigns extends IData {
  readonly signUsers: SignUser[];
}

interface IDataPrograms extends IData {
  readonly programs: IProgram[];
}

interface IDataTesting extends IData {
  readonly searchTesting: IDataSearchTesting[];
}

interface IMentorData {
  id: string | null;
  user_name: string;
}

interface IFilterOption {
  id?: number | number[] | boolean | null;
  name: string;
}

@Component({
  name: "CAnalyticsTableHeader",
  components: {
    CSkillFilter,
    CPaginatedSelect
  }
})
export default class CAnalyticsTableHeader extends Vue {
  @Prop({ required: true }) public filter!: AnalyticsFilter;

  public readonly ru = ru;

  public progress: IFilterOption[] = [
    { id: null, name: "Все" },
    {
      id: ESubscriptionProgressStatus.SLIGHTLY_BEHIND,
      name: "Немного отстает"
    },
    {
      id: ESubscriptionProgressStatus.MAKE_PROGRESS,
      name: "Успевает"
    },
    {
      id: ESubscriptionProgressStatus.FAR_BEHIND,
      name: "Сильно отстает"
    }
  ];

  public task_categories: IFilterOption[] = [
    { id: null, name: "Все" },
    {
      name: "Индивидуальное",
      id: ETaskCategory.INDIVIDUAL
    },
    {
      name: "Практическое",
      id: ETaskCategory.PRACTICAL
    }
  ];

  public self_educations: IFilterOption[] = [
    { id: null, name: "Все" },
    {
      name: "Да",
      id: true
    },
    {
      name: "Нет",
      id: false
    }
  ];

  public subscription_progress_statuses: IFilterOption[] = [
    { id: null, name: "Все" },
    {
      id: ESubscriptionStatus.NOT_STARTED,
      name: "Не начата"
    },
    {
      id: ESubscriptionStatus.AWAITING_CONFIRMATION,
      name: "Ожидает подтверждения"
    },
    {
      id: ESubscriptionStatus.AWAITING_REPEAT_PASS,
      name: "Ожидает перепрохождения"
    },
    {
      id: ESubscriptionStatus.AWAITING_CONTINUE,
      name: "Ожидает продолжения обучения"
    },
    {
      id: ESubscriptionStatus.AWAITING_COMPLETION,
      name: "Ожидает завершения программы"
    },
    {
      id: ESubscriptionStatus.IN_PROGRESS,
      name: "В процессе"
    }
  ];

  public testing_progress_statuses: IFilterOption[] = [
    { id: null, name: "Все" },
    {
      id: ETestingAssignmentStatus.APPOINTED,
      name: "Новый"
    },
    {
      id: ETestingAssignmentStatus.ON_THE_RUN,
      name: "В процессе"
    },
    {
      id: ETestingAssignmentStatus.PAUSED,
      name: "На паузе"
    },
    {
      id: ETestingAssignmentStatus.ON_APPROVED,
      name: "На проверке"
    },
    {
      id: ETestingAssignmentStatus.UN_SUCCESS,
      name: "Не успешно"
    },
    {
      id: ETestingAssignmentStatus.SUCCESS,
      name: "Успешно"
    }
  ];

  public task_statuses: IFilterOption[] = [
    { id: null, name: "Все" },
    {
      id: ETaskStatus.WORK,
      name: "В работе"
    },
    {
      id: ETaskStatus.VERIFICATION,
      name: "На проверке"
    },
    {
      id: ETaskStatus.FAILED,
      name: "Не успешно"
    },
    {
      id: ETaskStatus.DONE,
      name: "Успешно"
    }
  ];

  public roles: RoleRole[] = [{ id: null, translated_name: "Все" }];
  public mentors: IMentorData[] = [{ id: null, user_name: "Все" }];
  public pillars: PillarPillar[] = [{ id: null, full_name: "Все" }];
  public categories: SkillCategory[] = [{ id: null, value: "Все" }];

  private readonly per_page: number = 50;
  private current_page_supervisor: number = 0;
  private current_page_position: number = 0;
  private current_page_block: number = 0;
  private current_page_program: number = 0;
  private current_page_signs: number = 0;
  private current_page_testing: number = 0;

  private reloadPositionFn: Function | null = null;
  private reloadProgramFn: Function | null = null;

  @Watch("filter.factory_id")
  public changeFactoryFilter() {
    if (this.filter.factory_id) {
      if (this.reloadPositionFn) {
        this.reloadPositionFn.call(null);
      }

      if (this.reloadProgramFn) {
        this.reloadProgramFn.call(null);
      }
    } else {
      this.filter.position_id = null;
      this.filter.program_id = null;
    }
  }

  protected async created() {
    await Promise.all([
      this.loadRoles(),
      this.loadPillars(),
      this.loadSkillCategories(),
      this.loadMentors()
    ]);
  }

  public async loadSings(page: number = 0) {
    return this.$api
      .get(sign_routes.sign_users, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataSigns }) => {
        if (
          this.current_page_signs !== res.current_page ||
          this.current_page_signs === 0
        ) {
          this.current_page_signs = res.current_page;

          if (res.signUsers.length) {
            return res.signUsers;
          } else {
            return [];
          }
        }

        return [];
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async loadTestings(page: number = 0, search_phrase: string) {
    return this.$api
      .get(testings_routes.testings, {
        params: {
          join_folders: true,
          page,
          per_page: this.per_page,
          paginate: true,
          search: search_phrase
        }
      })
      .then(({ data: res }: { data: IDataTesting }) => {
        if (
          this.current_page_testing !== res.current_page ||
          this.current_page_testing === 0
        ) {
          this.current_page_testing = res.current_page;

          if (res.searchTesting.length) {
            return res.searchTesting;
          } else {
            return [];
          }
        }

        return [];
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async loadBlocks(page: number = 0) {
    return this.$api
      .get(block_routes.blocks, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataBlock }) => {
        if (
          this.current_page_block !== res.current_page ||
          this.current_page_block === 0
        ) {
          this.current_page_block = res.current_page;

          if (res.blocks.length) {
            return res.blocks;
          } else {
            return [];
          }
        }

        return [];
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async loadPrograms(page: number = 0) {
    return this.$api
      .get(program_routes.edits, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          factory_id: this.filter.factory_id
        }
      })
      .then(({ data: res }: { data: IDataPrograms }) => {
        if (
          this.current_page_program !== res.current_page ||
          this.current_page_program === 0
        ) {
          this.current_page_program = res.current_page;

          if (res.programs.length) {
            return res.programs;
          } else {
            return [];
          }
        }

        return [];
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async loadSupervisor(page: number = 0, search_phrase: string) {
    return this.$api
      .get(user_routes.users, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          role: ERole.supervisor,
          search: search_phrase
        }
      })
      .then(({ data: res }: { data: IDataUser }) => {
        if (
          this.current_page_supervisor !== res.current_page ||
          this.current_page_supervisor === 0
        ) {
          this.current_page_supervisor = res.current_page;

          if (res.users.length) {
            return res.users;
          } else {
            return [];
          }
        }

        return [];
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public setReloadPosition(reloadFn: Function) {
    this.reloadPositionFn = reloadFn;
  }

  public setReloadProgram(reloadFn: Function) {
    this.reloadProgramFn = reloadFn;
  }

  public async loadPosition(page: number = 0) {
    return this.$api
      .get(position_routes.positions, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          factory_id: this.filter.factory_id
        }
      })
      .then(({ data: res }: { data: IDataPosition }) => {
        if (
          this.current_page_position !== res.current_page ||
          this.current_page_position === 0
        ) {
          this.current_page_position = res.current_page;

          if (res.positions.length) {
            return res.positions;
          } else {
            return [];
          }
        }

        return [];
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadRoles() {
    return this.$api
      .get(role_routes.roles)
      .then(({ data: res }: { data: RoleRole[] }) => {
        this.roles.push(...plainToClass(RoleRole, res));
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadPillars() {
    return this.$api
      .get(pillar_routes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillars.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadSkillCategories() {
    return this.$api
      .get(skill_routes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.categories.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async loadMentors(assign: boolean = false) {
    return this.$api
      .get(skill_routes.mentors, { params: { skill_id: this.filter.skill_id } })
      .then(({ data: res }: { data: IMentorData[] }) => {
        this.mentors = res;

        if (assign) {
          this.apply();
        }
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public apply() {
    this.$emit("apply", true);
  }

  public educationCountDec() {
    if (this.filter.show_education_count_days) {
      if (
        this.filter.education_count_days != null &&
        this.filter.education_count_days > 1
      ) {
        this.filter.education_count_days--;
      } else {
        this.filter.education_count_days = null;
      }
    } else {
      this.filter.education_count_days = null;
    }

    this.apply();
  }

  public educationCountInc() {
    if (this.filter.show_education_count_days) {
      if (this.filter.education_count_days != null) {
        this.filter.education_count_days++;
      } else {
        this.filter.education_count_days = 1;
      }
    } else {
      this.filter.education_count_days = null;
    }

    this.apply();
  }

  public clearDate(key: "program_starts_at" | "program_ends_at") {
    this.filter[key] = null;

    this.apply();
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import CPeriodDatePicker from "@/components/PeriodDatePicker.vue";
import CSearchTemplate from "@/components/akt/SearchTemplate.vue";

import akt_routes from "@/api/routes/akt";

import UserUser from "@/models/user/user";
import {
  AssignmentUser,
  AssignmentUserBase
} from "@/models/akt/assignment_user";
import {
  AssignmentUserWorkplace,
  EAktAssignmentUserWorkplaceStatus,
  EAktAssignmentUserWorkplaceType,
  aktWorkplaceTypes
} from "@/models/akt/assignment_user_workplace";
import { IAktTemplate } from "@/models/akt/template";

import CTeamAktAssignmentNewAttempt from "./akt/NewAttempt.vue";
import CTeamAktAssignmentResults from "./akt/Results.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly templates: IAktTemplate[];
}

@Component({
  name: "CTeamAkt",
  components: {
    Preloader,
    CSearchTemplate,
    CPeriodDatePicker,
    CTeamAktAssignmentNewAttempt,
    CTeamAktAssignmentResults
  }
})
export default class CTeamAkt extends Vue {
  @Prop({ required: true }) public user!: UserUser;

  public preload: boolean = false;
  public templates: IAktTemplate[] | null = null;

  public available_assignment_users: AssignmentUserBase[] = [];
  public selected_assignment_user: AssignmentUserBase | null = null;

  public assignment_user: AssignmentUser | null = null;

  public workplaces: AssignmentUserWorkplace[] = [];
  public workplace_in_reattempt: AssignmentUserWorkplace | null = null;
  public workplace_in_reattempt_index: number | null = null;

  public show_akt_results: boolean = false;

  public akt_workplace_types = aktWorkplaceTypes;
  public EAktAssignmentUserWorkplaceStatus = EAktAssignmentUserWorkplaceStatus;
  public EAktAssignmentUserWorkplaceType = EAktAssignmentUserWorkplaceType;

  public current_year: number = new Date().getFullYear();

  protected async created() {
    this.preload = true;

    await Promise.all([this.loadAssignmentUsers(), this.loadTemplates()]);

    this.preload = false;
  }

  public reduceAktTypeFn(akt_type: { value: number }) {
    return akt_type.value;
  }

  private async loadAssignmentUsers() {
    return this.$api
      .get(akt_routes.assignments(this.user.id!), {
        params: {
          starts_year: this.current_year,
          include_workplaces: false
        }
      })
      .then(async ({ data: res }: { data: AssignmentUser[] }) => {
        this.available_assignment_users = plainToClass(AssignmentUser, res);

        if (this.available_assignment_users.length) {
          this.selected_assignment_user = this.available_assignment_users[0];

          await this.loadAssignmentUser();
        }
      });
  }

  private async loadTemplates() {
    return this.$api
      .get(akt_routes.templates_actual, {
        params: {
          paginate: false,
          include_archived: false
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.templates = res.templates;
      });
  }

  private async loadAssignmentUser() {
    if (!this.selected_assignment_user) {
      return;
    }

    return this.$api
      .get(akt_routes.assignment_user(this.selected_assignment_user.id), {
        params: {
          starts_year: this.current_year
        }
      })
      .then(({ data: res }: { data: AssignmentUser }) => {
        if (Object.keys(res).length) {
          this.assignment_user = plainToClass(AssignmentUser, res);

          const last_attempts = this.assignment_user.workplaces.reduce<{
            [user_workplace_id: string]: AssignmentUserWorkplace;
          }>((acc, curr) => {
            if (curr.attempt_number > 0) {
              if (
                curr.status_id === EAktAssignmentUserWorkplaceStatus.NOT_STARTED
              ) {
                if (acc[curr.user_workplace_id]) {
                  if (
                    curr.attempt_number >
                    acc[curr.user_workplace_id].attempt_number
                  ) {
                    acc[curr.user_workplace_id] = plainToClass(
                      AssignmentUserWorkplace,
                      curr
                    );
                  }
                } else {
                  acc[curr.user_workplace_id] = plainToClass(
                    AssignmentUserWorkplace,
                    curr
                  );
                }
              } else if (
                acc[curr.user_workplace_id] &&
                curr.attempt_number > acc[curr.user_workplace_id].attempt_number
              ) {
                delete acc[curr.user_workplace_id];
              }
            }

            return acc;
          }, {});

          this.workplaces = this.assignment_user.workplaces.reduce<
            AssignmentUserWorkplace[]
          >((acc, curr) => {
            if (curr.attempt_number === 0) {
              if (last_attempts[curr.user_workplace_id]) {
                curr.last_attempt = last_attempts[curr.user_workplace_id];
              }

              acc.push(curr);
            }

            return acc;
          }, []);
        } else {
          this.assignment_user = null;
          this.workplaces = [];
        }
      });
  }

  public async changeSelectedAssignment() {
    if (!this.selected_assignment_user) {
      this.selected_assignment_user = this.available_assignment_users[0];
    }

    await this.loadAssignmentUser();
  }

  public changeWorkplaceTemplate(
    template_id: string,
    workplace: AssignmentUserWorkplace
  ) {
    workplace.template_id = template_id;
  }

  public changeWorkplaceAttempt(index: number) {
    if (this.workplaces[index]?.last_attempt) {
      this.workplace_in_reattempt = this.workplaces[index].last_attempt!;
      this.workplace_in_reattempt_index = index;
    } else {
      this.workplace_in_reattempt = null;
      this.workplace_in_reattempt_index = null;
    }
  }

  public changeWorkplaceAttemptResult(attempt: AssignmentUserWorkplace | null) {
    if (attempt && this.workplace_in_reattempt_index) {
      const workplace = this.workplaces[this.workplace_in_reattempt_index];

      this.$set(workplace, "last_attempt", attempt);
    }

    this.workplace_in_reattempt = null;
    this.workplace_in_reattempt_index = null;
  }

  public async changeStartsAt(starts_at: string) {
    if (this.assignment_user) {
      await this.saveAssignmentUserDates({ starts_at });
    }
  }

  public async changeEndsAt(ends_at: string) {
    if (this.assignment_user) {
      await this.saveAssignmentUserDates({ ends_at });
    }
  }

  public showResults() {
    this.show_akt_results = true;
  }

  public hideResults() {
    this.show_akt_results = false;
  }

  public async changeWorkplaces() {
    if (this.assignment_user) {
      return this.$api
        .put(akt_routes.put_assignment_user_workplaces, {
          workplaces: this.workplaces
        })
        .then(() => {
          this.$notify({
            group: "notifications",
            type: "success",
            text: "Вы успешно назначили ЕПЗ",
            speed: 500
          });
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected async saveAssignmentUserDates(data: {
    starts_at?: string;
    ends_at?: string;
  }) {
    if (this.assignment_user) {
      return this.$api
        .put(akt_routes.assignment_user(this.assignment_user.id), data)
        .then(() => {
          if (data.starts_at) {
            this.assignment_user!.starts_at = data.starts_at;
          }

          if (data.ends_at) {
            this.assignment_user!.ends_at = data.ends_at;
          }

          this.$notify({
            group: "notifications",
            type: "success",
            text: "Даты назначения ЕПЗ изменены",
            speed: 500
          });
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }
}

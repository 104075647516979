
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import structure_routes from "@/api/routes/structures";

import StructureInfinity from "@/models/structure/infinity";

export interface IChangeEvent {
  infinity_id: string;
  workplace_name: string;
}

@Component({
  name: "CAnalyticsInfinityTree"
})
export default class CAnalyticsInfinityTree extends Vue {
  @Prop({ required: true })
  public factory_id!: string;

  @Prop({ required: false, default: null })
  public parent_id!: string | null;

  @Prop({ required: false, default: () => [] })
  public started_infinities!: StructureInfinity[];

  @Prop({ required: false, default: null })
  public started_infinity_id!: string | null;

  @Prop({ required: false, default: false })
  public disabled!: boolean;

  public infinities: StructureInfinity[] = [];
  public selected_infinity: StructureInfinity | null = null;

  protected async created() {
    if (this.started_infinities.length) {
      this.infinities = this.started_infinities;

      if (this.started_infinity_id) {
        const infinity = this.started_infinities.find(
          i => i.id === this.started_infinity_id
        );

        if (infinity) {
          this.selected_infinity = infinity;

          this.changeInfinity();
        }
      }

      this.apply();
    } else {
      await this.loadInfinities();
    }
  }

  private async loadInfinities() {
    return this.$api
      .get(structure_routes.infinities, {
        params: {
          factory_id: this.factory_id,
          parent_id: this.parent_id
        }
      })
      .then(({ data: res }: { data: StructureInfinity[] }) => {
        this.infinities = res;
      });
  }

  @Watch("factory_id")
  @Watch("parent_id")
  protected async changeFactoryOrParentIds() {
    this.infinities = [];
    this.selected_infinity = null;

    await this.loadInfinities();
  }

  private apply() {
    this.$emit("apply", true);
  }

  public changeInfinity() {
    if (this.selected_infinity) {
      this.$emit("changeInfinity", {
        infinity_id: this.selected_infinity.id,
        workplace_name: this.selected_infinity?.full_name
      });
    } else {
      this.$emit("changeInfinity", null);
    }
  }

  public changeChildrenInfinity(data: IChangeEvent | null) {
    if (data) {
      this.$emit("changeInfinity", {
        infinity_id: data.infinity_id,
        workplace_name: `${this.selected_infinity!.full_name} > ${
          data.workplace_name
        }`
      });
    } else {
      this.$emit("changeInfinity", {
        infinity_id: this.selected_infinity!.id,
        workplace_name: this.selected_infinity!.full_name
      });
    }
  }
}

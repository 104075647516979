
import { Component, Prop, Vue } from "vue-property-decorator";

import subscriptionsRoutes from "@/api/routes/subscriptions";

import Preloader from "@/components/Preloader.vue";
import {
  ESubscriptionStatus,
  IDataPrograms,
  IStudentProgramsData
} from "@/models/subscriptions/program";

import { ru } from "vuejs-datepicker/dist/locale";

@Component({
  components: {
    Preloader
  },
  data: () => {
    return {
      ru,
      ESubscriptionStatus
    };
  }
})
export default class CTeamLearningPrograms extends Vue {
  @Prop({ required: true }) protected user_id!: string;
  @Prop({ default: false }) protected student!: boolean;

  protected preload: boolean = false;
  protected infinite_preload: boolean = false;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected programs_count_in_horizon: number = 0;

  protected programs_with_max_priority: IStudentProgramsData[] = [];
  protected programs_with_priority: IStudentProgramsData[] = [];
  protected programs_without_priority: IStudentProgramsData[] = [];

  protected async created() {
    this.preload = true;

    await this.loadPrograms();

    this.preload = false;
  }

  protected async loadPrograms(page: number = 0) {
    this.infinite_preload = true;

    return this.$api
      .get(subscriptionsRoutes.student_programs(this.user_id), {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          with_priority: true
        }
      })
      .then(({ data: res }: { data: IDataPrograms }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        res.programs.forEach((program, index) => {
          if (index === 0 && program.priority_programs_count) {
            this.programs_count_in_horizon = program.priority_programs_count;
          }

          if (typeof program.priority === "number" && program.priority === 0) {
            this.programs_with_max_priority.push(program);
          } else if (
            program.in_horizon &&
            ![ESubscriptionStatus.DONE, ESubscriptionStatus.FAILED].includes(
              program.program_status
            )
          ) {
            this.programs_with_priority.push(program);
          } else {
            this.programs_without_priority.push(program);
          }
        });
      })
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadPrograms(this.current_page + 1);
    }
  }

  protected async changeStartsAt(program: IStudentProgramsData) {
    return this.$api
      .put(subscriptionsRoutes.subscription_program(program.id), {
        starts_at: program.starts_at,
        deadline_at: program.deadline_at
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async changeDeadlineAt(program: IStudentProgramsData) {
    return this.$api
      .put(subscriptionsRoutes.subscription_program(program.id), {
        starts_at: program.starts_at,
        deadline_at: program.deadline_at
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

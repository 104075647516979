
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CWorkplacePlaceholder from "@/components/WorkplacePlaceholder.vue";

import userRoutes from "@/api/routes/users";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import SkillCategory from "@/models/skill/category";
import PillarPillar from "@/models/pillar/pillar";
import pillarRoutes from "@/api/routes/pillars";
import blockRoutes from "@/api/routes/blocks";
import skillRoutes from "@/api/routes/skills";
import users from "@/api/routes/users";
import { IUserWorkplace } from "@/models/user/workplace";

interface IData {
  readonly difference: number;
  readonly increase: number;
  readonly difference_m?: number;
  readonly difference_f?: number;
  readonly difference_c?: number;
}

export interface IDataBlock extends IData {
  readonly blocks: BlockBlock[];
}

@Component({
  components: {
    Preloader,
    CWorkplacePlaceholder
  }
})
export default class VUserSkill extends Vue {
  private preload: boolean = false;
  private showFilter: boolean = false;

  private categoriesFilter: SkillCategory[] = [{ id: 0, value: "Все навыки" }];
  private currentCategoryFilter = this.categoriesFilter[0];

  private pillars: PillarPillar[] = [];
  private pillarsFilter: PillarPillar[] = [
    { id: "", full_name: "Все" },
    { id: "null", full_name: "Без колонны" }
  ];
  private currentPillarFilter = this.pillarsFilter[0];

  private blocksFilter: BlockBlock[] = [{ id: "", full_name: "Все" }];
  private currentBlockFilter = this.blocksFilter[0];

  private filters: Map<string, boolean> = new Map();

  private increase: number = 0;
  private difference: number = 0;

  protected workplaces: IUserWorkplace[] = [];
  protected currentWorkplace: IUserWorkplace | null = null;

  private blocks: BlockBlock[] = [];
  private mobileExpanded: number = -1;

  private colors: string[] = ["#1E64AA", "#17B169", "#FFAB00", "#78283A"];

  private created() {
    this.preload = true;

    Promise.all([
      this.loadWorkplaces(),
      this.loadBlocks(),
      this.loadPillars(),
      this.loadSkillCategories()
    ]).then(() => {
      this.preload = false;
    });
  }

  private loadPillars() {
    this.$api
      .get(pillarRoutes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillars = res;

        this.pillarsFilter = this.pillarsFilter.concat(res);
      });
  }

  private loadBlocks() {
    this.$api
      .get(blockRoutes.blocks)
      .then(({ data: res }: { data: IDataBlock }) => {
        this.blocksFilter = this.blocksFilter.concat(res.blocks);
      });
  }

  private loadSkillCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.categoriesFilter.push(...res.slice(0, 3));
      });
  }

  private loadSkills() {
    if (!this.currentWorkplace) {
      return;
    }
    this.$api
      .get(userRoutes.skillsMe, {
        params: {
          category_id: this.currentCategoryFilter?.id,
          pillar_id: this.currentPillarFilter?.id,
          block_id: this.currentBlockFilter?.id,
          user_workplace_id: this.currentWorkplace?.id
        }
      })
      .then(({ data: res }: { data: IDataBlock }) => {
        this.blocks = res.blocks;
        this.increase = res.increase;
        this.difference = res.difference;
      });
  }

  protected async loadWorkplaces() {
    return this.$api
      .get(users.workplacesMe)
      .then(async ({ data: res }: { data: Array<IUserWorkplace> }) => {
        this.workplaces = res;

        this.currentWorkplace =
          this.workplaces.find(w => w.is_main) || this.workplaces[0];

        await this.loadSkills();
      });
  }

  private clearSkills() {
    this.blocks = [];
    this.loadSkills();
  }

  private changeCategory(tab: SkillCategory) {
    if (this.categoriesFilter[0] !== this.currentCategoryFilter) {
      this.filters.set("category", true);
    } else {
      this.filters.delete("category");
    }

    this.currentCategoryFilter = tab;

    this.clearSkills();
  }

  private changePillar() {
    if (this.pillarsFilter[0] !== this.currentPillarFilter) {
      this.filters.set("pillar", true);
    } else {
      this.filters.delete("pillar");
    }

    this.clearSkills();
  }

  private changeBlock() {
    if (this.blocksFilter[0] !== this.currentBlockFilter) {
      this.filters.set("block", true);
    } else {
      this.filters.delete("block");
    }

    this.clearSkills();
  }

  private clearFilters() {
    this.currentBlockFilter = this.blocksFilter[0];
    this.currentPillarFilter = this.pillarsFilter[0];
    this.filters.clear();
    this.clearSkills();
  }

  private countPercent(skill: IBlockSkill) {
    if (skill.current_level == 0 && skill.target_level == 0) {
      return 0;
    } else if (skill.current_level! >= skill.target_level!) {
      return 100;
    } else {
      return (skill.current_level! / skill.target_level!) * 100;
    }
  }

  private showMobileSkills(index: number) {
    this.mobileExpanded = index;
  }
}


import { Component, Vue } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import { IAktTemplate } from "@/models/akt/template";
import { aktTextTypes } from "@/models/akt/template_version";
import { EPermission } from "@/enums/permissions";
import { IAktTag } from "@/models/akt/tag";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly templates: IAktTemplate[];
}

@Component({
  name: "VAktTemplates",
  components: {
    Preloader,
    Search,
    CInfiniteScroll
  }
})
export default class VAktTemplates extends Vue {
  public current_user: IJWTUser | null = this.$store.state.currentUser;

  public preload: boolean = false;
  public preload_infinite: boolean = false;

  public show_filter: boolean = false;
  public filters_count: number = 0;
  public filters: Map<string, boolean> = new Map();

  public search: string = this.$store.state.search;
  public include_archived: boolean = false;
  public can_reanswer: boolean | null = null;

  public can_reanswer_options = [
    { label: "Да", value: true },
    { label: "Нет", value: false }
  ];

  public akt_text_types = aktTextTypes;
  public akt_text_type_id: number | null = null;

  public tags: IAktTag[] = [];
  public tag_ids: string[] | null = null;

  public to_archive_template_idx: number | null = null;

  public noty_error: boolean = false;
  public noty_text: string = "";

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;
  public templates: IAktTemplate[] = [];

  protected watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;
          await this.clearTemplates();
        }
      )
    );

    this.preload = true;

    await Promise.all([this.loadTemplates(), this.loadTags()]);

    this.preload = false;
  }

  protected async loadTemplates(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(akt_routes.templates, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search,
          akt_text_type_id: this.akt_text_type_id,
          can_reanswer: this.can_reanswer,
          include_archived: this.include_archived,
          tag_ids: this.tag_ids
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.templates.push(...res.templates);
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadTemplates(this.current_page + 1);
    }
  }

  private async loadTags() {
    return this.$api
      .get(akt_routes.tags)
      .then(({ data: res }: { data: IAktTag[] }) => {
        this.tags = res;
      });
  }

  public async clearFilters() {
    this.akt_text_type_id = null;
    this.tag_ids = [];
    this.can_reanswer = null;
    this.include_archived = false;

    this.filters.clear();
    this.filters_count = this.filters.size;

    await this.clearTemplates();
  }

  public async changeTagFilter() {
    if (this.tag_ids?.length) {
      this.filters.set("tag", true);
    } else {
      this.filters.delete("tag");
    }

    this.filters_count = this.filters.size;

    await this.clearTemplates();
  }

  public async changeAktTextType() {
    if (this.akt_text_type_id) {
      this.filters.set("akt_text_type_id", true);
    } else {
      this.filters.delete("akt_text_type_id");
    }

    this.filters_count = this.filters.size;

    await this.clearTemplates();
  }

  public async changeCanReanswer() {
    if (typeof this.can_reanswer === "boolean") {
      this.filters.set("can_reanswer", true);
    } else {
      this.filters.delete("can_reanswer");
    }

    this.filters_count = this.filters.size;

    await this.clearTemplates();
  }

  public async changeIsArchive() {
    if (this.include_archived) {
      this.filters.set("include_archived", true);
    } else {
      this.filters.delete("include_archived");
    }

    this.filters_count = this.filters.size;

    await this.clearTemplates();
  }

  protected async clearTemplates() {
    this.current_page = 0;
    this.templates = [];

    await this.loadTemplates();
  }

  public archiveTemplateConfirm(index: number) {
    this.to_archive_template_idx = index;

    this.$modal.show("archiveTemplateModal");
  }

  public archiveTemplateCancel() {
    this.to_archive_template_idx = null;

    this.$modal.hide("archiveTemplateModal");
  }

  public async archiveTemplate() {
    if (this.to_archive_template_idx != null) {
      const template = this.templates[this.to_archive_template_idx];

      return this.$api
        .post(akt_routes.archived(template.id), {})
        .then(() => {
          if (this.to_archive_template_idx != null) {
            this.$delete(this.templates, this.to_archive_template_idx);
          }

          this.archiveTemplateCancel();
        })
        .catch(({ response: res }) => {
          this.noty_error = true;
          this.noty_text = res.data.error;
          this.$modal.show("modal_notify");
        });
    }
  }

  public beforeUpdateTemplate(id: string) {
    if (!this.canWrite()) {
      return;
    }

    this.$router.push({ name: "akt_templates_edit", params: { id } });
  }

  public beforeCreateTemplate() {
    this.$router.push({ name: "akt_templates_new" });
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.akt_templates);
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CStructure, { IStructureProp } from "@/components/Structure.vue";
import Upload from "@/components/Upload.vue";
import Preloader from "@/components/Preloader.vue";

import skillRoutes from "@/api/routes/skills";
import aktRoutes from "@/api/routes/akt";
import importJobsRoutes from "@/api/routes/import_jobs";

import SkillCategory from "@/models/skill/category";
import UserUser from "@/models/user/user";
import StructureFactory from "@/models/structure/factory";
import SkillLocal, { ESkillCategory } from "@/models/skill/local";
import { EAktQuestionType } from "@/models/akt/question";
import { ImportJob } from "@/models/import/import_job";
import { EImportType } from "@/enums/import_type";
import { ImportError } from "@/models/import/import_error";
import structureRoutes from "@/api/routes/structures";
import { EImportCompletionCode } from "@/enums/import_completion_code";
import userRoutes from "@/api/routes/users";

interface IDataImport {
  readonly id?: string;
  skill_id?: string | null;
  category_id?: number;
  factory_id?: string | null;
  infinity_id?: string | null;
  exit_code?: EImportCompletionCode | null;
  readonly responsibles?: UserUser[];
  readonly questions?: IDataQuestion[];
  readonly errors: ImportError[];
}

interface IDataQuestion {
  id: string;
  status: EImportCompletionCode;
  row_number: number;
  title: string;
  akt_question_type_id: number;
  akt_type_id: number;
  error: string;
  answers: { value: string; is_correct: boolean }[];
}

@Component({
  components: {
    Upload,
    CStructure,
    Preloader
  },
  data: () => {
    return {
      EAktQuestionType,
      ESkillCategory,
      EImportCompletionCode
    };
  }
})
export default class VAktImportEdit extends Vue {
  @Prop({ required: false }) id?: string;

  private preload: boolean = true;

  protected acceptFiles: string[] = ["text/csv"];

  protected skillCategories: SkillCategory[] = [];

  protected skills: SkillLocal[] = [];

  private file_url: string | null = null;

  protected factory_uec: boolean = false;

  protected question: IDataImport = {
    skill_id: null,
    questions: [],
    responsibles: [],
    errors: []
  };

  protected structure: IStructureProp = {};
  protected factories: StructureFactory[] = [];

  protected users: UserUser[] = [];

  protected async created() {
    await Promise.all([
      this.loadCategories(),
      this.loadFactories(),
      this.loadUsers(),
      this.loadImportData()
    ]);

    this.preload = false;
  }

  @Watch("id")
  protected async loadImportData() {
    if (this.id !== "new") {
      await this.loadImportedQuestions(this.id!).then(async () => {
        await this.loadSkills();
      });
    }
  }

  protected async createImportJob() {
    if (!this.file_url) {
      return;
    }

    return this.$api
      .post(importJobsRoutes.import_jobs, {
        import_type: EImportType.AKT_QUESTIONS,
        file_url: this.file_url,
        question: this.question
      })
      .then(({ data: res }: { data: ImportJob }) => {
        this.$router.push({
          name: "akt_import_question",
          params: { id: res.targetable_id! }
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async changeSkillCategory() {
    this.question.skill_id = null;
    this.question.factory_id = null;
    this.question.infinity_id = null;

    await this.loadSkills();
  }

  protected async changeStructure(obj: IStructureProp) {
    this.question.infinity_id = obj.infinity_id || null;

    await this.loadSkills();
  }

  protected async changeFactory() {
    this.question.infinity_id = null;

    await this.loadSkills();
  }

  protected async loadImportedQuestions(id: string) {
    return this.$api
      .get(aktRoutes.imported_questions(id))
      .then(({ data: res }: { data: IDataImport }) => {
        this.question = res;
        this.structure.infinity_id = res.infinity_id;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadUsers() {
    return this.$api
      .get(userRoutes.users, {
        params: {
          paginate: false
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.users = res.users;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategories = res.slice(0, 3);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSkills() {
    this.preload = true;
    return this.$api
      .get(skillRoutes.skills, {
        params: {
          paginate: false,
          includes: "all",
          factory_id: this.question.factory_id,
          infinity_id: this.question.infinity_id,
          category_id: this.question.category_id
        }
      })
      .then(({ data: res }: { data: { skills: SkillLocal[] } }) => {
        this.skills = res.skills;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }
}

import { IsOptional, IsUUID, IsString, IsInt } from "class-validator";

import BlockBlock from "../block/block";

export default class InventoryInventory {
  @IsOptional()
  @IsUUID("4")
  public readonly id?: string;

  @IsString()
  public full_name: string = "";

  public blocks?: BlockBlock[] = [];

  constructor(data?: any) {
    if (data) {
      this.id = this.id || data.id;
    }
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class VideoView extends Vue {
  @Prop() private url!: string;
  @Prop({ default: () => "small" }) private size!:
    | "small"
    | "middle"
    | "large"
    | "responsive";

  private videoUrl = this.$api.staticUploaderUrl + this.url;

  protected created() {
    this.changeUrl();
  }

  @Watch("url")
  protected changeUrl() {
    this.videoUrl = RegExp(this.$api.regexUrl).test(this.url)
      ? this.url
      : this.$api.staticUploaderUrl + this.url;
  }
}

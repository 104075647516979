export default {
  applications: `/v1/applications/applications`,
  application: (id: string) => `/v1/applications/applications/${id}`,
  to_work: (id: string) => `/v1/applications/applications/${id}/to_work`,
  approval: (id: string) => `/v1/applications/applications/${id}/approval`,
  calendar: `/v1/applications/applications/calendar/event`,
  withdraw: (id: string) => `/v1/applications/applications/${id}/withdraw`,
  planned: (id: string) => `/v1/applications/applications/${id}/planned`,
  end_application: (id: string) =>
    `/v1/applications/applications/${id}/end_application`,
  students: "/v1/applications/students",
  student: (id: string) => `/v1/applications/students/${id}`,
  student_application: (id: string, application_id: string) =>
    `/v1/applications/${application_id}/students/${id}`,
  update_all_graduate: (application_id: string) =>
    `/v1/applications/${application_id}/students/update_all_graduate`,
  this_student: (application_id: string) =>
    `/v1/applications/${application_id}/students/this_student`,
  comment: "/v1/applications/comments",
  reconciliations: "/v1/applications/reconciliations",
  intervals: "/v1/applications/intervals",
  interval: (id: string) => `/v1/applications/intervals/${id}`,
  update_all_intervals: (id: string) =>
    `/v1/applications/${id}/intervals/update_all`,
  confirm: (id: string, conciliator_id: string) =>
    `/v1/applications/${id}/reconciliations/${conciliator_id}/confirm`,
  cancel: (id: string, conciliator_id: string) =>
    `/v1/applications/${id}/reconciliations/${conciliator_id}/cancel`,
  protocols: `/v1/applications/protocols`,
  protocol: (id: string) => `/v1/applications/protocols/${id}`,
  certificates: `/v1/applications/certificates`,
  certificate: (id: string) => `/v1/applications/certificates/${id}`,
  protocol_files: `/v1/applications/protocol_files`,
  protocol_file: (id: string) => `/v1/applications/protocol_files/${id}`,
  certificate_files: `/v1/applications/certificate_files`,
  certificate_file: (id: string) => `/v1/applications/certificate_files/${id}`,
  export: `/v1/applications/plannings/export`,
  plannings: `/v1/applications/plannings`,
  plan_choice: `/v1/applications/planning/plan_choice`,
  current_skill_choice: `/v1/applications/planning/current_skill_choice`,
  my_approvals: `/v1/applications/my_approvals`
};

import { IsUUID } from "class-validator";
import TestingQuestionAnswerVersion from "@/models/testing/question_answer_version";

export default class TestingQuestionSurveyVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly survey_id!: string;

  public answer_versions: TestingQuestionAnswerVersion[] = [];
}

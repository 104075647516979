import { IsUUID, IsOptional, IsString, IsBoolean } from "class-validator";

import { v4 } from "uuid";

export interface IUserWorkplace {
  id: string;
  is_main: boolean;
  structure_name: string;
  structure_phase: number;

  factory_full_name: string;
  factory_id: string;
  infinity_full_name: string | null;
  infinity_level_name: string | null;
  infinity_id: string | null;
}

export default class UserWorkplace {
  @IsUUID("4")
  public readonly id!: string;

  constructor() {
    this.id = v4();
  }

  @IsUUID("4")
  public factory_id?: string | null;

  @IsString()
  public factory_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public workshop_id?: string | null;

  @IsString()
  public workshop_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public infinity_id?: string | null;

  @IsString()
  public infinity_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public workline_id?: string | null;

  @IsString()
  public workline_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public equipment_id?: string | null;

  @IsString()
  public equipment_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public position_id?: string | null;

  @IsString()
  public position_name: string = "";

  @IsBoolean()
  public is_main: boolean = false;

  @IsOptional()
  @IsUUID("4")
  public current_position_level_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public next_position_level_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public target_position_level_id?: string | null;
}


import { Component, Vue, Prop } from "vue-property-decorator";

import CSkillFilter from "@/components/SkillFilter.vue";
import CImageView from "@/components/ImageView.vue";
import CAudioView from "@/components/audioView.vue";
import CVideoView from "@/components/VideoView.vue";

import TestingTestingVersion from "@/models/testing/testing_version";
import TestingQuestionVersion, {
  questionType,
  fileType,
  EFileType
} from "@/models/testing/question_version";

interface ISkillEvent {
  readonly label: string;
  readonly value: boolean;
}

@Component({
  name: "CTestingsApprovalQuestions",
  components: {
    CSkillFilter,
    CImageView,
    CAudioView,
    CVideoView
  }
})
export default class CTestingsApprovalQuestions extends Vue {
  @Prop({ required: true }) public testing!: TestingTestingVersion;
  @Prop({ required: true }) public questions!: TestingQuestionVersion[];

  public disabled: boolean = true;

  public question_types = questionType;
  public file_types = fileType;
  public EFileType = EFileType;

  public skill_events: ISkillEvent[] = [
    { label: "Оставить без изменений текущий уровень навыка", value: false },
    { label: "Изменить уровень навыка", value: true }
  ];
}

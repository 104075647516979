
import { Component, Prop, Vue } from "vue-property-decorator";

import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import ImageView from "@/components/ImageView.vue";
import CAudioView from "@/components/audioView.vue";
import VideoView from "@/components/VideoView.vue";

import testingsRoutes from "@/api/routes/testings";

import TestingQuestionVersion, {
  EFileType
} from "@/models/testing/question_version";
import TestingAssignmentQuestion from "@/models/testing/assignment_question";

@Component({
  name: "CTestingQuestion",
  components: {
    Preloader,
    ImageView,
    CAudioView,
    VideoView
  },
  data: () => {
    return { EFileType };
  }
})
export default class CTestingQuestion extends Vue {
  @Prop({ required: true }) question_assignment!: TestingAssignmentQuestion;

  private preload: boolean = false;
  private currentQuestion: TestingQuestionVersion = new TestingQuestionVersion();

  private textResult: string = "";

  private singleChecked: string = "";
  private multipleChecked: string[] = [];

  public created() {
    this.preload = true;
    Promise.all([this.loadQuestion()]).then(() => {
      this.preload = false;
    });
  }

  private loadQuestion() {
    this.$api
      .get(testingsRoutes.question(this.question_assignment!.question_id!))
      .then(({ data: res }: { data: TestingQuestionVersion }) => {
        this.currentQuestion = plainToClass(TestingQuestionVersion, res);

        this.textResult = "";
        this.singleChecked = "";
        this.multipleChecked = [];
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private sendResult() {
    if (
      !this.multipleChecked.length &&
      this.singleChecked === "" &&
      this.textResult === ""
    ) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Укажите ответ",
        speed: 500
      });
      return;
    }

    if (this.currentQuestion) {
      if (this.currentQuestion.isText) {
        this.$api
          .post(
            testingsRoutes.save_assignment_text_result(
              this.question_assignment.id!
            ),
            {
              value: this.textResult
            }
          )
          .then(() => {
            this.nextQuestion();
          })
          .catch(({ response: res }) => {
            this.$notify({
              group: "notifications",
              type: "error",
              text: res.data.error,
              speed: 500
            });
          });
      } else if (this.currentQuestion.isSingle) {
        this.$api
          .post(
            testingsRoutes.save_assignment_answer_result(
              this.question_assignment.id!
            ),
            {
              answer_version_ids: [this.singleChecked]
            }
          )
          .then(() => {
            this.nextQuestion();
          })
          .catch(({ response: res }) => {
            this.$notify({
              group: "notifications",
              type: "error",
              text: res.data.error,
              speed: 500
            });
          });
      } else if (this.currentQuestion.isMultiple) {
        this.$api
          .post(
            testingsRoutes.save_assignment_answer_result(
              this.question_assignment.id!
            ),
            {
              answer_version_ids: this.multipleChecked
            }
          )
          .then(() => {
            this.nextQuestion();
          })
          .catch(({ response: res }) => {
            this.$notify({
              group: "notifications",
              type: "error",
              text: res.data.error,
              speed: 500
            });
          });
      }
    }
  }

  private nextQuestion(skip: boolean = false) {
    this.$emit("next", skip);
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

import ApplicationApplication from "@/models/application/application";
import ApplicationStudent from "@/models/application/student";
import applicationRoutes from "@/api/routes/applications";

@Component
export default class CGraduates extends Vue {
  @Prop({ required: true })
  protected currentApplication!: ApplicationApplication;
  @Prop({ required: true }) protected isClose!: boolean;

  private graduateError: string = "";

  private graduates: ApplicationStudent[] = [];

  private get graduatesIsGraduateLength() {
    return this.graduates.filter(g => g.is_graduate).length;
  }

  protected async created() {
    await this.loadGraduates();
  }

  private async loadGraduates() {
    return this.$api
      .get(applicationRoutes.students, {
        params: {
          application_id: this.currentApplication.id
        }
      })
      .then(({ data: res }: { data: ApplicationStudent[] }) => {
        this.graduates = res;
      })
      .catch(({ response: res }) => {
        this.graduateError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateAllGraduates() {
    if (this.isClose) {
      return;
    }

    const isGraduate = this.graduatesIsGraduateLength === 0;
    await this.$api
      .put(applicationRoutes.update_all_graduate(this.currentApplication.id), {
        is_graduate: isGraduate
      })
      .then(({ data: res }: { data: ApplicationStudent[] }) => {
        this.graduates = res;
      })
      .catch(({ response: res }) => {
        this.graduateError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async updateGraduate(index: number) {
    if (this.isClose) {
      return;
    }

    const graduate = this.graduates[index];

    await this.$api
      .put(applicationRoutes.student(graduate.id!), {
        is_graduate: !graduate.is_graduate
      })
      .then(({ data: res }: { data: ApplicationStudent }) => {
        this.$set(this.graduates, index, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private getTypeCheckIcon() {
    return this.graduatesIsGraduateLength === 0
      ? ""
      : this.graduatesIsGraduateLength === this.graduates.length
      ? "/img/icon-plus.svg"
      : "/img/icon-minus.svg";
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";
import { classToClass } from "class-transformer";

import user_routes from "@/api/routes/users";

import CPaginatedSelect from "@/components/PaginatedSelect.vue";

import { phases } from "@/consts";
import TaskTask, { taskCategories } from "@/models/task/task";
import UserUser from "@/models/user/user";
import TaskSkillBlock from "@/models/task/skillBlock";
import SkillCategory from "@/models/skill/category";
import TaskSkill from "@/models/task/skill";

interface IResponsibleData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

@Component({
  name: "CTaskChangeSettings",
  components: {
    CPaginatedSelect
  }
})
export default class CTaskChangeSettings extends Vue {
  @Prop({ required: true }) public task!: TaskTask;
  @Prop({ required: true }) public skills!: TaskSkillBlock[];
  @Prop({ required: true }) public skill_categories!: SkillCategory[];

  public filtered_skills: TaskSkillBlock[] = classToClass(this.skills);

  public filtered_skill_categories: SkillCategory[] = [
    { value: "Все", id: null },
    ...this.skill_categories
  ];
  public selected_skill_category = this.filtered_skill_categories[0];

  public ru = ru;
  public phases = phases;
  public categories = taskCategories;

  private current_user: IJWTUser | null = this.$store.state.currentUser;

  public responsibles_per_page: number = 100;

  public skill_search: string = "";

  public changeResponsible(responsible: UserUser) {
    this.task.responsible = responsible;
  }

  public async loadResponsibles(
    page: number = 0,
    search_query: string
  ): Promise<UserUser[]> {
    const exclude_current_user: boolean =
      this.task.user_id == this.current_user!.id;

    return this.$api
      .get(user_routes.users, {
        params: {
          page,
          per_page: this.responsibles_per_page,
          paginate: true,
          search: search_query,
          exclude_current_user
        }
      })
      .then(({ data: res }: { data: IResponsibleData }) => {
        if (res.users.length) {
          return res.users;
        }

        return [];
      });
  }

  public showSkillLevels(skill_id: string) {
    if (this.task.can_assignment || this.task.cant_change_skill_level) {
      return false;
    }

    return !!this.task.skills[skill_id];
  }

  public changeSkillCategoryFilter() {
    if (!this.selected_skill_category) {
      this.selected_skill_category = this.filtered_skill_categories[0];
    }

    this.filterSkills();
  }

  public changeSkillSearchFilter() {
    this.filterSkills();
  }

  private filterSkills() {
    this.filtered_skills = classToClass(this.skills).map(block => {
      const skills = block.skills.filter(skill => {
        let allowed: boolean = true;

        if (this.selected_skill_category.id) {
          allowed = skill.category_id === this.selected_skill_category.id;
        }

        if (this.skill_search?.length) {
          allowed =
            allowed &&
            skill.full_name
              .toLowerCase()
              .includes(this.skill_search.trim().toLowerCase());
        }

        return allowed;
      });

      block.skills = skills;
      block.skills_count = skills.length;

      return block;
    });
  }

  public selectSkill(skill: TaskSkill) {
    if (this.task.skills[skill.id] != null) {
      this.$delete(this.task.skills, skill.id);
    } else {
      this.$set(this.task.skills, skill.id, {
        id: skill.id,
        full_name: skill.full_name,
        negative_level: 0,
        positive_level: 0
      });
    }
  }

  public updatePositiveSkillLevel(skill_id: string, level: number) {
    const skill = this.task.skills[skill_id];

    this.$set(skill, "positive_level", level);
  }

  public updateNegativeSkillLevel(skill_id: string, level: number) {
    const skill = this.task.skills[skill_id];

    this.$set(skill, "negative_level", level);
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import AktQuestionVersion from "@/models/akt/question_version";

@Component({
  name: "CAktQuestionChangeApproval"
})
export default class CAktQuestionChangeApproval extends Vue {
  @Prop({ required: true }) public question_version!: AktQuestionVersion;

  public showCurr() {
    return (
      this.question_version.isShowApprove &&
      this.question_version.responsibles?.length
    );
  }

  public showPrev() {
    return (
      this.question_version.isShowPrevApprove &&
      this.question_version.prev_responsibles?.length
    );
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Debounce } from "../helpers";

@Component({
  name: "CPaginatedSelect"
})
export default class CPaginatedSelect extends Vue {
  @Prop({ required: false, default: true }) public append_to_body!: boolean;
  @Prop({ required: false, default: false }) public searchable!: boolean;
  @Prop({ required: false, default: false }) public disabled!: boolean;
  @Prop({ required: false, default: false }) public multiple!: boolean;
  @Prop({ required: false, default: false }) public is_reloaded!: boolean;
  @Prop({ required: false, default: (c: any) => c }) public reduce!: Function;

  @Prop({ required: true }) private loadFn!: Function;
  @Prop({ required: true }) public model!: any;
  @Prop({ required: true }) public label!: string;
  @Prop({ required: true }) public per_page!: number;

  public next_page: boolean = true;
  private current_page: number = 0;
  private preload_infinite: boolean = false;

  public items: any[] = [];
  public selected_model: any = null;

  private observer: IntersectionObserver | null = null;
  private search: string = "";

  $refs!: {
    load: HTMLLinkElement;
  };

  protected created() {
    this.selected_model = this.model;

    if (this.is_reloaded) {
      this.$emit("reload", this.reload());
    }
  }

  protected mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  }

  public get options() {
    return this.items;
  }

  private async reload() {
    this.current_page = 0;
    this.next_page = true;

    this.items = [];

    await this.loadNextPage();
  }

  public async onOpen() {
    if (this.next_page) {
      await this.$nextTick();

      if (this.observer) {
        this.observer.observe(this.$refs.load);
      }
    }
  }

  public async onClose() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  public async onSearch(query: string) {
    this.search = query;

    await this.debouncedOnSearch();
  }

  public onInput() {
    this.$emit("input", this.selected_model);
  }

  @Watch("search")
  @Debounce(300)
  private async debouncedOnSearch() {
    await this.reload();
  }

  private async loadNextPage() {
    if (this.next_page && !this.preload_infinite) {
      this.preload_infinite = true;

      await this.loadFn
        .call(null, this.current_page, this.search)
        .then((new_items: any[]) => {
          this.items.push(...new_items);

          ++this.current_page;

          this.next_page = new_items.length === this.per_page;
        })
        .finally(() => (this.preload_infinite = false));

      await this.$nextTick();
    }
  }

  public async infiniteScroll([
    { isIntersecting, intersectionRatio, target }
  ]: IntersectionObserverEntry[]) {
    if (isIntersecting && intersectionRatio > 0) {
      const ul = (target as HTMLUListElement).offsetParent;

      let scrollTop = 0;

      if (ul) {
        scrollTop = ul.scrollTop;
      }

      await this.loadNextPage();

      if (ul) {
        ul.scrollTop = scrollTop;
      }
    }
  }
}

import { Type } from "class-transformer";

import UserUser from "../user/user";
import AktSurvey from "./survey";
import AktText from "./text";
import SkillLocal from "../skill/local";
import StructureFactory from "../structure/factory";
import StructureWorkshop from "../structure/workshop";
import StructureWorkline from "../structure/workline";
import StructureEquipment from "../structure/equipment";
import StructureInfinity from "../structure/infinity";
import AktQuestionVersion, { EAktStatus, EAktType } from "./question_version";
import AktResponsible from "./responsible";
import { IAktTag } from "./tag";

export interface IQuestion {
  readonly id: string;
  readonly is_archive: boolean;
  readonly factory_id?: string;
  readonly workshop_id?: string;
  readonly workline_id?: string;
  readonly equipment_id?: string;
  readonly infinity_id?: string;
  readonly akt_question_type_id: EAktQuestionType;
  readonly skill_category_value: string;
  readonly skill_full_name: string;
  readonly question_version_id: string;
  readonly question_version_title: string;
  readonly question_version_annotation: string;
  question_version_akt_status_id: EAktStatus;
  readonly question_version_akt_type_id: EAktType;
  readonly question_version_version: number;
  readonly tags: IAktTag[];
  show_agreement: boolean;
}

export interface IQuestionAdd extends IQuestion {
  selected?: boolean;
}

export const aktQuestionTypes = [
  {
    label: "Открытый вопрос",
    value: 1
  },
  {
    label: "Одиночный выбор",
    value: 2
  },
  {
    label: "Множественный выбор",
    value: 3
  }
];

export enum EAktQuestionType {
  OPEN = 1,
  SINGLE = 2,
  MULTIPLE = 3
}

export default class AktQuestion {
  public id?: string;

  public akt_question_type?: EAktQuestionType;
  public akt_question_type_id?: number;

  public creator_id?: string;
  public creator?: UserUser;

  @Type(() => Object, {
    discriminator: {
      property: "questionable_type",
      subTypes: [
        { value: AktSurvey, name: "Akt::Survey" },
        { value: AktText, name: "Akt::Text" }
      ]
    }
  })
  public questionable?: AktSurvey | AktText;
  public questionable_id?: string;
  public questionable_type?: string;

  public skill_category_id?: number;
  public skill_id?: string | null;
  @Type(() => SkillLocal)
  public skill?: SkillLocal;

  public factory_id?: string | null;
  @Type(() => StructureFactory)
  public factory?: StructureFactory;

  public workshop_id?: string | null;
  @Type(() => StructureWorkshop)
  public workshop?: StructureWorkshop;

  public workline_id?: string | null;
  @Type(() => StructureWorkline)
  public workline?: StructureWorkline;

  public equipment_id?: string | null;
  @Type(() => StructureEquipment)
  public equipment?: StructureEquipment;

  public infinity_id?: string | null;
  @Type(() => StructureInfinity)
  public infinity?: StructureInfinity;

  public versions?: AktQuestionVersion[];
  @Type(() => AktQuestionVersion)
  public last_version?: AktQuestionVersion;

  public actual_version_id?: string;
  @Type(() => AktQuestionVersion)
  public actual_version?: AktQuestionVersion;

  @Type(() => AktResponsible)
  public responsibles?: AktResponsible[];
}

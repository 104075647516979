
import { Component, Prop, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";
import { plainToClass } from "class-transformer";

import task_routes from "@/api/routes/tasks";

import Preloader from "@/components/Preloader.vue";
import CComment from "@/components/Comment.vue";
import CFileDownload from "@/components/FileDownload.vue";

import TaskTask, { ETaskStatus } from "@/models/task/task";

@Component({
  name: "VTask",
  components: {
    Preloader,
    CComment,
    CFileDownload
  }
})
export default class VTask extends Vue {
  @Prop({ required: true }) private task_id!: string;

  public preload: boolean = false;

  public ru = ru;
  public ETaskStatus = ETaskStatus;
  public task: TaskTask | null = null;

  private watchers: Function[] = [];

  public task_new_ends_at: Date | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await this.loadTask();
  }

  private async loadTask() {
    this.preload = true;

    return this.$api
      .get(task_routes.task(this.task_id))
      .then(({ data: res }: { data: TaskTask }) => {
        this.task = plainToClass(TaskTask, res);

        this.task_new_ends_at = this.task.ends_at;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public async verify(status: ETaskStatus) {
    return this.$api
      .put(task_routes.verify(this.task_id), {
        status: status,
        comment: this.task!.comment,
        comment_files: this.task!.comment_files
      })
      .then(({ data: res }: { data: TaskTask }) => {
        this.preload = true;

        this.task = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public showReassignModal() {
    this.$modal.show("reassign_modal");
  }

  public hideReassignModal() {
    this.$modal.hide("reassign_modal");
  }

  public uploadFile(file: string) {
    if (this.task!.comment_files.length < 10) {
      this.task!.comment_files.push({ url: file });
    } else {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Не более 10 файлов",
        duration: 500,
        speed: 500
      });
    }
  }

  public removeCommentFile(index: number) {
    this.task!.comment_files.splice(index, 1);
  }

  public async reassignTask() {
    return this.$api
      .put(task_routes.reassign(this.task_id), {
        ends_at: this.task_new_ends_at
      })
      .then(({ data: res }: { data: TaskTask }) => {
        this.preload = true;

        this.task = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import ImageView from "@/components/ImageView.vue";
import PdfView from "@/components/PdfView.vue";

import aktRoutes from "@/api/routes/akt";

import { AktAssignmentSubscription } from "@/models/akt/assignment_subscription";
import AktTextVersion from "@/models/akt/text_version";
import AktSurveyVersion from "@/models/akt/survey_version";
import {
  EAktAssignmentUserWorkplaceResultStatus,
  EAktAssignmentUserWorkplaceStatus
} from "@/models/akt/assignment_user_workplace";
import { EPermission } from "@/enums/permissions";

export interface IAktSub {
  id: string;
  is_correct: boolean;
  is_checked: boolean;
  workplace: {
    id: string;
    passing_percent: number;
    completion_percent: number;
    status_id: EAktAssignmentUserWorkplaceStatus;
    result_status_id: EAktAssignmentUserWorkplaceResultStatus;
  };
}

@Component({
  name: "CAktTestingSubscriptionResult",
  components: {
    Preloader,
    PdfView,
    ImageView
  }
})
export default class CAktTestingSubscriptionResult extends Vue {
  @Prop({ required: true }) sub_id!: string;
  @Prop({ default: false }) changeResult!: boolean;

  private preload: boolean = false;
  private subscription: AktAssignmentSubscription | null = null;

  private textResult: string = "";

  private singleChecked: string = "";
  private multipleChecked: string[] = [];

  private justChanged: boolean = false;

  private created() {
    this.loadSubscription();
  }

  private setCorrect(is_correct: boolean) {
    if (this.subscription && this.subscription.question_version.isOpen) {
      this.$api
        .put(aktRoutes.put_subscription_correct(this.sub_id), { is_correct })
        .then(({ data: res }: { data: IAktSub }) => {
          this.subscription!.is_correct = res.is_correct;
          this.subscription!.is_checked = res.is_checked;

          this.justChanged = true;
          setTimeout(() => (this.justChanged = false), 4000);

          this.$emit("changeResult", res);
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  private loadSubscription() {
    if (this.sub_id) {
      this.preload = true;
      this.subscription = null;

      this.$api
        .get(aktRoutes.get_subscription_testing(this.sub_id))
        .then(({ data: res }: { data: AktAssignmentSubscription }) => {
          this.subscription = plainToClass(AktAssignmentSubscription, res);

          this.textResult = "";
          this.singleChecked = "";
          this.multipleChecked = [];

          if (this.subscription.question_version.isOpen) {
            this.textResult =
              ((this.subscription.question_version
                .questionable as AktTextVersion).results || [])[0]?.value || "";
          } else if (this.subscription.question_version.isSingle) {
            (this.subscription.question_version
              .questionable as AktSurveyVersion).answer_versions.forEach(av => {
              if ((av.results || []).length) {
                this.singleChecked = av.results![0].answer_version_id;
              }
            });
          } else if (this.subscription.question_version.isMultiple) {
            (this.subscription.question_version
              .questionable as AktSurveyVersion).answer_versions.forEach(av => {
              if ((av.results || []).length) {
                this.multipleChecked.push(av.results![0].answer_version_id);
              }
            });
          }
        })
        .finally(() => {
          this.preload = false;
        });
    }
  }

  private canWrite() {
    return this.$api.canWrite(EPermission.akt_passed_testing);
  }
}

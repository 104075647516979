
import { Component, Vue, Prop } from "vue-property-decorator";

import AnalyticsFilter from "@/models/analytics/filter";

@Component({
  name: "CAnalyticsDisplayOptions"
})
export default class CAnalyticsDisplayOptions extends Vue {
  @Prop({ required: true }) public filter!: AnalyticsFilter;

  public show_filter: boolean = false;

  public apply() {
    this.show_filter = !this.show_filter;

    this.$emit("apply", true);
  }
}

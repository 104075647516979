
import { Component, Prop, Vue } from "vue-property-decorator";
import ApplicationApplication from "@/models/application/application";
import { EPermission } from "@/enums/permissions";
import applicationRoutes from "@/api/routes/applications";
import UserUser from "@/models/user/user";
import { plainToClass } from "class-transformer";

@Component
export default class CApplication extends Vue {
  @Prop({ required: true }) protected applications!: ApplicationApplication[];
  @Prop({ required: true }) protected canWriteInApplication!: boolean;

  protected cardApplicationError: string = "";

  protected currentApplication: ApplicationApplication = new ApplicationApplication();
  protected currentApplicationIndex?: number;

  protected selectedStudents: UserUser[] = [];
  protected selectedReconciliations: UserUser[] = [];

  protected async getApplicationCard() {
    return this.$api
      .get(
        applicationRoutes.application(
          this.applications[this.currentApplicationIndex!]!.id
        )
      )
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.currentApplication = plainToClass(ApplicationApplication, res);
        this.loadSelectedStudents(this.currentApplication);
        this.loadSelectedReconciliation(this.currentApplication);
      })
      .catch(({ response: res }) => {
        this.cardApplicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSelectedStudents(app: ApplicationApplication) {
    return this.$api
      .get(applicationRoutes.students, {
        params: {
          application_id: app.id
        }
      })
      .then(({ data: res }: { data: UserUser[] }) => {
        this.selectedStudents = res;
      })
      .catch(({ response: res }) => {
        this.cardApplicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSelectedReconciliation(app: ApplicationApplication) {
    return this.$api
      .get(applicationRoutes.reconciliations, {
        params: {
          application_id: app.id
        }
      })
      .then(({ data: res }: { data: UserUser[] }) => {
        this.selectedReconciliations = res;
      })
      .catch(({ response: res }) => {
        this.cardApplicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async withdrawApplication() {
    return this.$api
      .put(applicationRoutes.withdraw(this.currentApplication.id), {})
      .then(({ data: res }: { data: ApplicationApplication }) => {
        if (res) {
          this.$delete(this.applications, this.currentApplicationIndex!);
          this.closeWithdrawApplication();
          this.hideApplicationCard();
          this.$emit("clearPlans", true);
        }
      })
      .catch(({ response: res }) => {
        this.cardApplicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async sendApplication() {
    return this.$api
      .put(applicationRoutes.approval(this.currentApplication.id), {})
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$set(
          this.applications,
          this.currentApplicationIndex!,
          plainToClass(ApplicationApplication, res!)
        );
        this.hideApplicationCard();
      })
      .catch(({ response: res }) => {
        this.cardApplicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected showApplicationCard(index: number) {
    this.currentApplicationIndex = index;
    this.getApplicationCard();
    this.$modal.show("cardApplicationModal");
  }

  protected hideApplicationCard() {
    this.$modal.hide("cardApplicationModal");
  }

  protected showWithdrawApplication(index: number) {
    this.currentApplication = this.applications[index];
    this.currentApplicationIndex = index;
    this.$modal.show("withdrawApplicationModal");
  }

  protected closeWithdrawApplication() {
    this.currentApplication = new ApplicationApplication();
    this.currentApplicationIndex = 0;
    this.$modal.hide("withdrawApplicationModal");
  }
}


import { toDateString } from "@/helpers/date";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

@Component({
  name: "CPeriodDatePicker"
})
export default class CPeriodDatePicker extends Vue {
  @Prop({ required: false, default: true }) public show_close_button!: boolean;
  @Prop({ required: false }) public readonly header?: string;
  @Prop({ required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ required: false, default: "left" }) public readonly position!: string;
  @Prop({ required: false, default: "bottom" })
  public readonly position_y!: string;
  @Prop({ required: true }) public starts_at!: string;
  @Prop({ required: true }) public ends_at!: string;

  public curr_starts_at: string = this.starts_at;
  public curr_ends_at: string = this.ends_at;

  public ru = ru;
  public string_date: string | null = null;

  public show_date_picker: boolean = false;

  public togglePicker() {
    if (this.disabled) {
      this.show_date_picker = false;
    } else {
      this.show_date_picker = !this.show_date_picker;
    }
  }

  protected created() {
    this.changeStringDate();
  }

  protected beforeDestroy() {
    this.show_date_picker = false;
  }

  protected changeStringDate() {
    this.string_date = [
      toDateString(this.curr_starts_at),
      toDateString(this.curr_ends_at)
    ].join(" - ");
  }

  public setStartsAt() {
    this.changeStringDate();

    this.$emit("change-starts_at", new Date(this.curr_starts_at).toISOString());
  }

  public setEndsAt() {
    this.changeStringDate();

    this.$emit("change-ends_at", new Date(this.curr_ends_at).toISOString());
  }
}

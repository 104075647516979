import {
  IsUUID,
  IsOptional,
  Min,
  IsInt,
  IsString,
  IsBoolean,
  IsPositive,
  IsEnum
} from "class-validator";

export const ProgressLevel = [
  { id: 1, value: "Теория+Тест", tooltip: "Новичок" },
  { id: 2, value: "Практика", tooltip: "Специалист" },
  {
    id: 3,
    value: "Подтверждение самостоятельной работы",
    tooltip: "Профессионал"
  },
  { id: 4, value: "Обучение других сотрудников", tooltip: "Гуру" }
];

export enum EProgramVersionStatus {
  CREATED = 1, // Создан
  SEND_FOR_APPROVAL = 2, // Отправлен на согласование
  APPROVED = 3, // Утвержден
  NOT_APPROVED = 4 // Не утвержден
}

export default class ProgramVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly program_id!: string;

  @IsInt()
  @IsPositive()
  @Min(1)
  public version: number = 1;

  @IsEnum(EProgramVersionStatus)
  public status_id: EProgramVersionStatus = EProgramVersionStatus.CREATED;

  @IsOptional()
  @IsString()
  public title?: string;

  @Min(1)
  @IsInt()
  public days_to_study!: number;

  @IsOptional()
  @IsString()
  public description?: string;

  @IsBoolean()
  public changes_exist!: boolean;

  @IsInt({ each: true })
  public confirmation_levels!: number[];

  @IsInt({ each: true })
  public mentor_levels!: number[];
}


import { Component, Vue, Prop } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import UserUser from "@/models/user/user";
import TestingAssignment, {
  ETestingAssignmentStatus
} from "@/models/testing/assignment";
import userRoutes from "@/api/routes/users";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataAssignment extends IData {
  readonly assignments: TestingAssignment[];
}

@Component({
  components: {
    Preloader
  },
  data: () => {
    return {
      ETestingAssignmentStatus
    };
  }
})
export default class CTeamTesting extends Vue {
  @Prop({ required: true }) private user!: UserUser;

  private preload: boolean = false;
  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private assignments: TestingAssignment[] = [];

  public created() {
    this.preload = true;
    Promise.all([this.loadAssignments()]).then(() => {
      this.preload = false;
    });
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadAssignments(this.current_page + 1);
    }
  }

  private loadAssignments(page: number = 0) {
    this.$api
      .get(userRoutes.testing_assignments(this.user.id), {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          only_final: false
        }
      })
      .then(({ data: res }: { data: IDataAssignment }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.assignments.push(...res.assignments);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private getStatusColor(status_id: number) {
    return status_id === ETestingAssignmentStatus.APPOINTED
      ? "font-blue font-sm-blue"
      : status_id === ETestingAssignmentStatus.ON_THE_RUN
      ? "font-orange font-sm-orange"
      : status_id === ETestingAssignmentStatus.PAUSED
      ? "font-gray font-sm-gray"
      : status_id === ETestingAssignmentStatus.ON_APPROVED
      ? "font-gray font-sm-gray"
      : status_id === ETestingAssignmentStatus.UN_SUCCESS
      ? "font-red font-sm-red"
      : status_id === ETestingAssignmentStatus.SUCCESS
      ? "font-green font-sm-green"
      : "font-gray font-sm-gray";
  }
}

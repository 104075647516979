
import { Component, Prop, Vue } from "vue-property-decorator";

import { getIconFromUnitableType, getTitleFromUnitableType } from "@/helpers";

import { EUnitableType } from "@/models/unit/unit_version";

@Component({
  methods: {
    getIconFromUnitableType,
    getTitleFromUnitableType
  }
})
export default class ConstructorProgramsMaterialsAdd extends Vue {
  private materials: Array<{ type: string; icon: string }> = Object.keys(
    EUnitableType
  )
    .slice(0, 4)
    .map(t => {
      return {
        type: t,
        icon: getIconFromUnitableType(t)
      };
    });

  private selected(type: string) {
    this.$emit("selected", type);
  }
}

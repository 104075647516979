
import { Component, Vue } from "vue-property-decorator";

import notifications_routes from "@/api/routes/notifications";

import CRangeDatePicker from "@/components/RangeDatePicker.vue";

import NotificationGroup from "@/models/notification/group";

export interface IFilters {
  group_id: string | null;
  range_date: string[];
}

@Component({
  name: "CNotificationsFilters",
  components: {
    CRangeDatePicker
  }
})
export default class CNotificationsFilters extends Vue {
  private watchers: Function[] = [];

  public show_filter: boolean = false;
  public filters: Map<string, boolean> = new Map();
  public filters_count: number = 0;

  public groups: NotificationGroup[] = [{ id: null, name: "Все" }];
  public current_group: NotificationGroup = this.groups[0];

  public range_date: string[] = this.$store.state.rangeDate;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await this.loadGroups();

    this.$store.watch(
      state => state.rangeDate,
      rangeDate => {
        if (rangeDate.length > 1) {
          this.range_date = rangeDate;

          this.changeRangeDate();
        }
      }
    );
  }

  private async loadGroups() {
    return this.$api
      .get(notifications_routes.groups)
      .then(({ data: res }: { data: NotificationGroup[] }) => {
        this.groups.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public changeGroup() {
    if (this.current_group.id) {
      this.filters.set("group", true);
    } else {
      this.filters.delete("group");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public changeRangeDate() {
    if (this.range_date.length > 0) {
      this.filters.set("range_date", true);
    } else {
      this.filters.delete("range_date");
    }

    this.filters_count = this.filters.size;

    this.apply();
  }

  public clearFilters() {
    this.current_group = this.groups[0];

    this.range_date = [];
    this.$store.commit("rangeDate", []);

    this.filters.delete("group");
    this.filters.delete("range_date");

    this.filters_count = this.filters.size;

    this.apply();
  }

  private apply() {
    this.$emit("apply", {
      group_id: this.current_group.id,
      range_date: this.range_date
    });
  }
}

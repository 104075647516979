
import { Component, Vue, Prop } from "vue-property-decorator";

import CCalendar from "@/components/calendar/Calendar.vue";
import Preloader from "@/components/Preloader.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";

import subscriptionRoutes from "@/api/routes/subscriptions";
import userRoutes from "@/api/routes/users";
import unitRoutes from "@/api/routes/units";

import UserUser from "@/models/user/user";
import SubscriptionUnit from "@/models/subscriptions/unit";
import EventVersion from "@/models/unit/unitable/event/version";
import { CalendarTimestamp } from "vuetify";

@Component({
  components: {
    CCalendar,
    Preloader,
    DateTimePicker
  }
})
export default class VTrainerCalendar extends Vue {
  @Prop({ required: true }) private id!: string;
  @Prop({ required: true }) private unit_id!: string;

  private preload: boolean = false;

  private trainer: UserUser | null = null;
  private subscriptionUnit: SubscriptionUnit | null = null;
  private eventVersion: EventVersion | null = null;

  private starts_at: Date | null = null;
  private ends_at: Date | null = null;

  private newEventError: string | null = null;

  private created() {
    this.preload = true;

    this.loadSubscriptionUnit()
      .then(() => {
        this.loadTrainer();
      })
      .finally(() => {
        this.preload = false;
      });
  }

  private dayClick(data: CalendarTimestamp) {
    this.setDate(data.date);

    if (this.canUseDate) {
      this.$modal.show("newEvent");
    }
  }

  private timeClick(data: CalendarTimestamp) {
    this.setDate(data.date);

    if (this.canUseDate) {
      this.$modal.show("newEvent");
    }
  }

  private loadSubscriptionUnit() {
    return this.$api
      .get(subscriptionRoutes.subscription_unit(this.unit_id), {
        params: {
          units: true,
          results: true
        }
      })
      .then(({ data: res }: { data: SubscriptionUnit }) => {
        this.subscriptionUnit = res;
        this.eventVersion = res.unit_version.unitable as EventVersion;
      });
  }

  private loadTrainer() {
    if (this.eventVersion?.trainer_id) {
      this.$api
        .get(userRoutes.user(this.eventVersion.trainer_id))
        .then(({ data: res }: { data: UserUser }) => {
          this.trainer = res;
        });
    }
  }

  private setDate(date: Date | string) {
    this.starts_at = new Date(date);

    this.ends_at = new Date(this.starts_at);
    this.ends_at.setMinutes(
      this.eventVersion!.duration + this.ends_at.getMinutes()
    );
  }

  private sendApproval() {
    this.$api
      .post(unitRoutes.event_results, {
        event_at: this.starts_at,
        event_id: this.eventVersion!.event_id,
        subscription_unit_id: this.subscriptionUnit!.id
      })
      .then(() => {
        this.newEventError = null;

        this.$modal.hide("newEvent");
        this.$store.commit("reloadCalendarEvents", true);
      })
      .catch(({ response: res }) => {
        this.newEventError = res.data.error;
      });
  }

  private get canUseDate(): boolean {
    const currentDate = new Date();

    if (!this.starts_at) {
      return false;
    }

    if (currentDate < this.starts_at) {
      return true;
    }

    return false;
  }
}

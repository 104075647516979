import "reflect-metadata";
import {
  IsBoolean,
  IsDateString,
  IsEnum,
  IsInt,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";

export enum EAktAssignmentUserWorkplaceStatus {
  NOT_STARTED = 1,
  STARTED = 2,
  PAUSED = 3,
  PASSED = 4
}

export enum EAktAssignmentUserWorkplaceResultStatus {
  PENDING = 1,
  SUCCESS = 2,
  UN_SUCCESS = 3,
  WAITING_VERIFY = 4
}

export enum EAktAssignmentUserWorkplaceType {
  ONLINE = 1,
  OFFLINE = 2
}

export const aktWorkplaceResultStatuses = [
  {
    label: "Успешно",
    value: 2
  },
  {
    label: "Не успешно",
    value: 3
  },
  {
    label: "Ожидает проверки",
    value: 4
  }
];

export const aktWorkplaceResultStatusesFull = [
  {
    label: "Успешно",
    value: 2
  },
  {
    label: "Не успешно",
    value: 3
  }
];

export const aktWorkplaceTypes = [
  {
    label: "Онлайн",
    value: 1
  },
  {
    label: "Оффлайн",
    value: 2
  }
];

export class AssignmentUserWorkplace {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly user_workplace_id!: string;

  @IsOptional()
  @IsString()
  public readonly comment?: string;

  @IsOptional()
  @IsUUID("4")
  public template_id?: string;

  @IsOptional()
  @IsBoolean()
  public template_can_reanswer?: boolean;

  @IsOptional()
  @IsInt()
  public template_reanswer_count?: number;

  @IsOptional()
  @IsInt()
  public template_title?: string;

  @IsOptional()
  @IsUUID("4")
  public parent_id?: string;

  @IsOptional()
  @IsDateString()
  public attempt_starts_at?: string;

  @IsOptional()
  @IsDateString()
  public attempt_ends_at?: string;

  @IsOptional()
  @IsBoolean()
  public attempt_all_subs: boolean = true;

  @IsOptional()
  @IsInt()
  public attempt_number: number = 0;

  @IsOptional()
  @IsInt()
  public passing_percent: number = 0;

  @IsOptional()
  @IsInt()
  public completion_percent: number = 0;

  @IsBoolean()
  public is_active: boolean = false;

  public last_attempt?: AssignmentUserWorkplace;

  @IsEnum(EAktAssignmentUserWorkplaceType)
  public type_id: EAktAssignmentUserWorkplaceType =
    EAktAssignmentUserWorkplaceType.ONLINE;

  @IsEnum(EAktAssignmentUserWorkplaceStatus)
  public status_id: EAktAssignmentUserWorkplaceStatus =
    EAktAssignmentUserWorkplaceStatus.NOT_STARTED;

  @IsEnum(EAktAssignmentUserWorkplaceResultStatus)
  public result_status_id: EAktAssignmentUserWorkplaceResultStatus =
    EAktAssignmentUserWorkplaceResultStatus.PENDING;

  @IsUUID("4")
  public readonly factory_id!: string;

  @IsString()
  public readonly factory_full_name!: string;

  @IsOptional()
  @IsUUID("4")
  public readonly workshop_id?: string;

  @IsOptional()
  @IsString()
  public readonly workshop_full_name?: string;

  @IsOptional()
  @IsUUID("4")
  public readonly workline_id?: string;

  @IsOptional()
  @IsString()
  public readonly workline_full_name?: string;

  @IsOptional()
  @IsUUID("4")
  public readonly equipment_id?: string;

  @IsOptional()
  @IsString()
  public readonly equipment_full_name?: string;

  @IsOptional()
  @IsUUID("4")
  public readonly infinity_id?: string;

  @IsOptional()
  @IsString()
  public readonly infinity_full_name?: string;

  @IsOptional()
  @IsUUID("4")
  public readonly position_id?: string;

  @IsOptional()
  @IsString()
  public readonly position_name?: string;

  public get isNotStarted() {
    return this.status_id === EAktAssignmentUserWorkplaceStatus.NOT_STARTED;
  }

  public get isInProgress() {
    return (
      this.status_id === EAktAssignmentUserWorkplaceStatus.STARTED ||
      this.status_id === EAktAssignmentUserWorkplaceStatus.PAUSED
    );
  }

  public get isPassed() {
    return this.status_id === EAktAssignmentUserWorkplaceStatus.PASSED;
  }

  public get isNotPassed() {
    return this.status_id !== EAktAssignmentUserWorkplaceStatus.PASSED;
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import { getIconFromUnitableType } from "@/helpers";
import { EDeviceTypes } from "@/enums/deviceTypes";

import subscriptionRoutes from "@/api/routes/subscriptions";
import SubscriptionProgram, {
  ESubscriptionStatus
} from "@/models/subscriptions/program";
import SubscriptionUnit from "@/models/subscriptions/unit";
import { plainToClass } from "class-transformer";
import { ESkillLevel } from "@/enums/skill_level";
import CFileDownload from "@/components/FileDownload.vue";
import CComment from "@/components/Comment.vue";

@Component({
  components: {
    CComment,
    CFileDownload,
    Preloader
  },
  methods: {
    getIconFromUnitableType
  },
  data: () => {
    return {
      ESubscriptionStatus,
      EDeviceTypes
    };
  }
})
export default class SubscriptionsProgram extends Vue {
  @Prop({ required: true }) private id!: string;
  @Prop({ default: true }) private student!: boolean;

  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected preload: boolean = false;

  protected is_student: boolean = true;
  protected is_skill_has_max_level: boolean = false;

  protected subscription: SubscriptionProgram | null = null;

  protected text: string = "";
  protected comment_files: string[] = [];

  protected units: Map<number, SubscriptionUnit[]> = new Map();

  protected async created() {
    this.preload = true;

    await this.$api
      .get(subscriptionRoutes.subscription_program(this.id), {
        params: {
          units: true,
          inspectors: true
        }
      })
      .then(({ data: res }: { data: SubscriptionProgram }) => {
        this.subscription = plainToClass(SubscriptionProgram, res);

        this.is_student = this.subscription.user_id === this.currentUser?.id;
        this.is_skill_has_max_level =
          Number(this.subscription.current_skill_level) ===
          ESkillLevel.MAX_LEVEL;

        this.units = this.subscription?.subscription_units.reduce(
          (acc, curr) => {
            const key = curr.relation_version_level;

            if (acc.get(key)) {
              acc.get(key)!.push(curr);
            } else {
              acc.set(key, [curr]);
            }

            return acc;
          },
          new Map<number, SubscriptionUnit[]>()
        );
        this.preload = false;
      });
  }

  protected async continue_level() {
    this.preload = true;

    await this.$api
      .put(subscriptionRoutes.continue(this.subscription!.id), {
        level: this.subscription?.current_skill_level! + 1,
        skill_id: this.subscription?.skill_id,
        comment_files: this.comment_files,
        text: this.text
      })
      .then(({ data: res }: { data: SubscriptionProgram }) => {
        this.subscription = plainToClass(SubscriptionProgram, res);

        this.is_skill_has_max_level =
          this.subscription.current_skill_level === ESkillLevel.MAX_LEVEL;

        this.units = this.subscription?.subscription_units.reduce(
          (acc, curr) => {
            const key = curr.relation_version_level;

            if (acc.get(key)) {
              acc.get(key)!.push(curr);
            } else {
              acc.set(key, [curr]);
            }

            return acc;
          },
          new Map<number, SubscriptionUnit[]>()
        );
        this.preload = false;
      });
  }

  protected uploadFile(file: string) {
    if (this.comment_files.length < 10) {
      this.comment_files.push(file);
    } else {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Не более 10 файлов",
        duration: 500,
        speed: 500
      });
    }
  }

  protected removeFile(index: number) {
    this.comment_files.splice(index, 1);
  }
}

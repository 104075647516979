import "reflect-metadata";
import { IsDate, IsString, IsUUID, IsOptional } from "class-validator";

export default class ApplicationProtocolFile {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsUUID("4")
  public protocol_id?: string;

  @IsOptional()
  @IsString()
  public url?: string;

  @IsOptional()
  @IsDate()
  public created_at?: Date;

  public constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.protocol_id = data.protocol_id;
      this.url = data.url || this.url;
      this.created_at = data.created_at || this.created_at;
    }
  }
}

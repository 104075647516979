import "reflect-metadata";
import { Type } from "class-transformer";
import { IsDateString, IsOptional, IsString, IsUUID } from "class-validator";

import { AssignmentUserWorkplace } from "./assignment_user_workplace";
import { Assignment } from "./assignment";

export class AssignmentUserBase {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly assignment_id!: string;

  @IsUUID("4")
  public readonly user_id!: string;

  @IsOptional()
  @IsString()
  public readonly comment?: string | null;

  @IsOptional()
  @IsDateString()
  public starts_at?: string;

  @IsOptional()
  @IsDateString()
  public ends_at?: string;

  @Type(() => Assignment)
  public assignment!: Assignment;
}

export class AssignmentUser extends AssignmentUserBase {
  @Type(() => AssignmentUserWorkplace)
  public workplaces: AssignmentUserWorkplace[] = [];
}

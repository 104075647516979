export default {
  index: `/v1/priorities/priorities`,
  show: (id: string) => `/v1/priorities/priorities/${id}`,
  general: `/v1/priorities/general`,
  set_zero_current_level_in_all_skills: `/v1/priorities/set_zero_current_level_in_all_skills`,
  set_zero_current_level_in_all_skills_structure: `/v1/priorities/set_zero_current_level_in_all_skills_structure`,
  create: `/v1/priorities/priorities`,
  update: (id: string) => `/v1/priorities/priorities/${id}`,
  destroy: (id: string) => `/v1/priorities/priorities/${id}`,
  new_skills: `v1/priorities/new_skills`,
  user: (user_id: string) => `/v1/priorities/users/${user_id}`
};


import { Component, Vue, Prop } from "vue-property-decorator";

import CConstructorProgramsIndexContent from "@/views/constructor/programs/index/Content.vue";

import StructureEquipment from "@/models/structure/equipment";

@Component({
  components: {
    CConstructorProgramsIndexContent
  }
})
export default class CEquipmentConstructor extends Vue {
  @Prop({ required: true }) public factory_id!: string;
  @Prop({ required: true }) public workshop_id!: string;
  @Prop({ required: true }) public workline_id!: string;
  @Prop({ required: true }) public equipment!: StructureEquipment;

  private openTreeProgram: Map<string, boolean> = this.$store.state
    .openTreeProgram;

  protected watchers: Function[] = [];

  protected async created() {
    if (this.openTreeProgram.get(this.equipment.id!)) {
      await this.showEquipment();
    }

    this.watchers.push(
      this.$store.watch(
        state => state.openTreeProgram,
        async openTreeProgram => {
          this.openTreeProgram = openTreeProgram;

          if (this.openTreeProgram.get(this.equipment.id!)) {
            await this.showEquipment();
          }
        }
      )
    );
  }

  public async showEquipment() {
    this.$set(this.equipment, "is_show", !this.equipment.is_show);

    if (this.equipment.is_show) {
      this.openTreeProgram.set(this.equipment.id!, true);
    } else {
      this.openTreeProgram.delete(this.equipment.id!);
    }

    this.$store.commit("openTreeProgram", this.openTreeProgram);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

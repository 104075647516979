import { Type } from "class-transformer";

import UserUser from "../user/user";
import AktSurveyVersion from "./survey_version";
import AktTextVersion from "./text_version";
import AktQuestion, { EAktQuestionType } from "./question";
import AktQuestionComment from "./question_comment";
import AktTag from "./tag";

export enum EAktType {
  PRELIMINARY = 1,
  MAIN_REQUIRED = 2,
  MAIN_OPTIONAL = 3
}

export enum EAktStatus {
  CREATED = 1,
  SEND_FOR_APPROVAL = 2,
  APPROVED = 3,
  NOT_APPROVED = 4,
  REMOVE_FROM_APPROVAL = 5
}

export const aktTypes = [
  {
    label: "Предварительная часть",
    value: EAktType.PRELIMINARY
  },
  {
    label: "Основная часть, обязательный",
    value: EAktType.MAIN_REQUIRED
  },
  {
    label: "Основная часть, необязательный",
    value: EAktType.MAIN_OPTIONAL
  }
];

export const aktStatuses = [
  {
    label: "Создан",
    value: EAktStatus.CREATED
  },
  {
    label: "На согласовании",
    value: EAktStatus.SEND_FOR_APPROVAL
  },
  {
    label: "Утвержден",
    value: EAktStatus.APPROVED
  },
  {
    label: "Не утвержден",
    value: EAktStatus.NOT_APPROVED
  },
  {
    label: "Согласование приостановлено",
    value: EAktStatus.REMOVE_FROM_APPROVAL
  }
];

export default class AktQuestionVersion {
  public id?: string;

  public akt_type?: EAktType;
  public akt_type_id?: number;

  public akt_status?: EAktStatus;
  public akt_status_id?: number;

  public version: number;
  public sub_version: number;
  public is_draft: boolean = true;
  public title?: boolean;
  public annotation?: boolean;
  public use_image: boolean = false;
  public image_url?: string;

  @Type(() => Object, {
    discriminator: {
      property: "questionable_type",
      subTypes: [
        { value: AktSurveyVersion, name: "Akt::SurveyVersion" },
        { value: AktTextVersion, name: "Akt::TextVersion" }
      ]
    }
  })
  public questionable?: AktSurveyVersion | AktTextVersion;
  public questionable_id?: string;
  public questionable_type?: string;

  public creator_id?: string;
  @Type(() => UserUser)
  public creator?: UserUser;

  public editor_id?: string;
  @Type(() => UserUser)
  public editor?: UserUser;

  public question_id?: string;
  @Type(() => AktQuestion)
  public question?: AktQuestion;

  @Type(() => UserUser)
  public responsibles?: UserUser[];

  @Type(() => UserUser)
  public prev_responsibles?: UserUser[];

  @Type(() => AktTag)
  public tags: AktTag[] = [];

  public comment: string = "";

  @Type(() => AktQuestionComment)
  public comments?: AktQuestionComment[];

  constructor() {
    this.question = new AktQuestion();
    this.version = 1;
    this.sub_version = 0;
  }

  public get isOpen() {
    return this.question?.akt_question_type_id === EAktQuestionType.OPEN;
  }

  public get isSingle() {
    return this.question?.akt_question_type_id === EAktQuestionType.SINGLE;
  }

  public get isMultiple() {
    return this.question?.akt_question_type_id === EAktQuestionType.MULTIPLE;
  }

  public get isSendToApprove() {
    return this.akt_status_id === EAktStatus.SEND_FOR_APPROVAL;
  }

  public get isRemoveFromApprove() {
    return this.akt_status_id === EAktStatus.REMOVE_FROM_APPROVAL;
  }

  public get isShowApprove() {
    return (
      this.akt_status_id && this.akt_status_id >= EAktStatus.SEND_FOR_APPROVAL
    );
  }

  public get isShowPrevApprove() {
    return this.version > 1;
  }

  public changeQuestionable() {
    if (this.isOpen) {
      this.questionable = new AktTextVersion();
      this.questionable_type = "Akt::TextVersion";
    } else {
      if (
        !this.questionable ||
        this.questionable_type !== "Akt::SurveyVersion"
      ) {
        this.questionable = new AktSurveyVersion();
        this.questionable_type = "Akt::SurveyVersion";
      }
    }
  }
}

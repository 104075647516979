
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

import skill_routes from "@/api/routes/skills";
import program_routes from "@/api/routes/programs";
import structure_routes from "@/api/routes/structures";
import pillar_routes from "@/api/routes/pillars";
import users_routes from "@/api/routes/users";

import Preloader from "@/components/Preloader.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";

import { phases } from "@/consts";

import { IProgramAuthor, IProgramSettings } from "@/models/program/program";
import SkillLocal, { ESkillCategory } from "@/models/skill/local";

import SkillCategory from "@/models/skill/category";
import BlockBlock from "@/models/block/block";
import blockRoutes from "@/api/routes/blocks";
import PillarPillar from "@/models/pillar/pillar";
import StructureFactory from "@/models/structure/factory";
import UserUser from "@/models/user/user";
import { EPermission } from "@/enums/permissions";

@Component({
  name: "CConstructorProgramsSettings",
  components: {
    Preloader,
    CStructure
  },
  data: () => {
    return {
      phases
    };
  }
})
export default class CConstructorProgramsSettings extends Vue {
  @Prop({ required: true }) private program_id!: string;
  @Prop({ required: true }) private program_version_id!: string;

  @Prop({ required: false, default: false }) public disabled!: boolean;

  @Prop({ required: true }) public settings!: IProgramSettings;
  @Prop({ required: true }) public authors!: IProgramAuthor[] | null;

  @Prop({ default: 30 }) private program_version_days_to_study!: number;

  public preload: boolean = false;

  public update_errors: string | string[] | null = null;
  private update_params: IStructureProp & {
    skill_id?: string | null;
    days_to_study?: number;
    is_self_education?: boolean;
  } = {};

  public pillars_filter: PillarPillar[] = [
    { id: "", full_name: "Все" },
    { id: "null", full_name: "Без колонны" }
  ];
  public current_pillar = this.pillars_filter[0];

  public categories_filter: SkillCategory[] = [{ id: 0, value: "Все" }];
  public current_category = this.categories_filter[0];

  public blocks_filter: BlockBlock[] = [
    { id: "", full_name: "Все" },
    { id: "null", full_name: "Без группы" }
  ];
  public current_block = this.blocks_filter[0];

  public skills: SkillLocal[] = [];
  public current_skill: SkillLocal | null = null;

  @Validations()
  protected validations = {
    current_skill: {
      required
    }
  };

  public factories_filter: StructureFactory[] = [];
  public current_factory: StructureFactory | null = null;
  public factory_id: string | null = null;
  public factory_uec: boolean = false;
  public structure: IStructureProp = {};

  public current_days_to_study = this.program_version_days_to_study;
  public current_days_to_study_error: boolean = false;

  public available_authors: UserUser[] = [];
  public current_author: IProgramAuthor | null = null;

  public current_user: IJWTUser = this.$store.state.currentUser;

  protected async created() {
    this.preload = true;

    this.update_params = {
      workshop_id: this.settings.workshop_id,
      workline_id: this.settings.workline_id,
      equipment_id: this.settings.equipment_id,
      infinity_id: this.settings.infinity_id
    };

    const promises = [
      this.loadFactory(),
      this.loadPillars(),
      this.loadBlocks(),
      this.loadCategories()
    ];

    if (this.current_user.is_admin) {
      promises.push(this.loadAvailableAuthors());
    }

    if (this.authors?.length) {
      this.current_author = this.authors[0];
    }

    await Promise.all(promises).then(async () => {
      await this.loadSkills().then(() => {
        if (this.settings.skill_id) {
          this.current_skill = this.skills.find(
            s => s.id === this.settings.skill_id
          )!;

          if (this.current_skill) {
            this.current_category = this.categories_filter.find(
              c => c.id === this.current_skill!.category_id
            )!;
          }
        }
      });
    });

    this.$store.commit("programSkillCategoryId", this.current_category?.id);

    this.preload = false;
  }

  private async loadAvailableAuthors() {
    return this.$api
      .get(users_routes.users, {
        params: {
          only_my: false,
          paginate: false,
          permission: EPermission.constructor_permission
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.available_authors = res.users;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadFactory() {
    return this.$api
      .get(structure_routes.factory(this.settings.factory_id))
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factories_filter.push(res);

        this.current_factory = res;
        this.factory_id = this.current_factory.id!;
        this.factory_uec = this.current_factory.uec!;
        this.structure = this.update_params;
      });
  }

  private async loadPillars() {
    this.$api
      .get(pillar_routes.pillars)
      .then(({ data: res }: { data: PillarPillar[] }) => {
        this.pillars_filter.push(...res);
      });
  }

  private async loadCategories() {
    return this.$api
      .get(skill_routes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.categories_filter.push(...res);
      });
  }

  private async loadBlocks() {
    return this.$api
      .get(blockRoutes.blocks)
      .then(({ data: res }: { data: { blocks: BlockBlock[] } }) => {
        this.blocks_filter.push(...res.blocks);
      });
  }

  private async loadSkills() {
    this.skills = [];

    return this.$api
      .get(skill_routes.skills, {
        params: {
          paginate: false,
          includes: "all",
          factory_id: this.factory_id,
          current_factory_id: this.factory_id,
          workshop_id: this.structure?.workshop_id,
          workline_id: this.structure?.workline_id,
          equipment_id: this.structure?.equipment_id,
          infinity_id: this.structure?.infinity_id,
          block_id: this.current_block.id,
          pillar_id: this.current_pillar.id,
          category_id: this.current_category.id,
          executed_skill_in_program: true,
          current_skill: this.settings.skill_id,
          only_is_local: false,
          responsible: false
        }
      })
      .then(({ data: res }: { data: { skills: SkillLocal[] } }) => {
        if (
          this.current_skill &&
          res.skills.indexOf(this.current_skill) === undefined
        ) {
          this.skills = [this.current_skill, ...res.skills];
        } else {
          this.skills = res.skills;
        }
      });
  }

  public isSkillWithoutStructure() {
    if (this.current_skill) {
      return [
        ESkillCategory.METHODOLOGICAL,
        ESkillCategory.COMPULSORY_EDUCATION,
        ESkillCategory.COMMON
      ].includes(this.current_skill.category_id);
    }

    return false;
  }

  public async changeCategory() {
    await this.loadSkills();
  }

  public async changeBlock() {
    await this.loadSkills();
  }

  public async changePillar() {
    await this.loadSkills();
  }

  public async changeStructure(structure: IStructureProp) {
    this.structure = structure;

    await this.loadSkills();
  }

  public async changeEducation() {
    this.update_params.is_self_education =
      this.settings.is_self_education ?? false;

    await this.updateProgram();
  }

  public async changeSkill() {
    if (this.isSkillWithoutStructure()) {
      this.update_params = {
        workshop_id: null,
        workline_id: null,
        equipment_id: null,
        infinity_id: null
      };
    } else {
      this.update_params.infinity_id = this.current_skill?.infinity_id || null;
    }

    if (this.current_skill != null) {
      this.current_category = this.categories_filter.find(
        c => c.id === this.current_skill!.category_id
      )!;
    }

    this.settings.is_compulsory_education =
      this.current_skill?.is_compulsory_education || false;

    this.update_params.skill_id = this.current_skill?.id || null;

    this.$store.commit("programSkillCategoryId", this.current_category?.id);

    await this.updateProgram();
  }

  public async changeDaysToStudy() {
    this.update_errors = null;
    this.current_days_to_study_error = false;

    if (this.current_days_to_study < 1) {
      this.update_errors = "Количество дней должно быть больше нуля";
      this.current_days_to_study_error = true;

      return;
    }

    return this.$api
      .put(program_routes.version(this.program_version_id), {
        days_to_study: this.current_days_to_study
      })
      .then(async () => {
        await this.changesExist();

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.update_errors = res.data.error;
        this.current_days_to_study_error = true;
      });
  }

  public async changeAuthor() {
    if (!this.current_author || this.current_author.id_type === "author_id") {
      return;
    }

    return this.$api
      .put(program_routes.change_author(this.program_id), {
        user_id: this.current_author.id
      })
      .then(({ data: res }: { data: IProgramAuthor }) => {
        this.$store.dispatch("toggleProgramEditMessage");

        this.$emit("changedAuthor", res);
      })
      .catch(({ response: res }) => {
        this.update_errors = res.data.error;
      });
  }

  private async changesExist() {
    if (!this.program_version_id) {
      return;
    }

    return this.$api
      .put(program_routes.changes_exist(this.program_version_id!), {})
      .then(() => {
        this.$emit("changesExist", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  private async updateProgram() {
    return this.$api
      .put(program_routes.edit(this.program_id), this.update_params)
      .then(async () => {
        this.update_errors = null;

        await this.changesExist();

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.update_errors = res.data.error;
      });
  }

  public skillTooltip() {
    let tooltip = "Выберите навык";

    if (this.current_skill != null) {
      tooltip = this.current_skill.full_name;

      if (this.current_skill.workplace_full_name != null) {
        tooltip += ` | ${this.current_skill.workplace_full_name}`;
      }
    }

    return tooltip;
  }

  public saveSettings() {
    this.$v.$touch();

    if (this.$v.$anyError) {
      return;
    }

    this.$notify({
      group: "notifications",
      type: "success",
      text: "Изменения сохранены",
      speed: 500
    });

    this.$emit("close", true);
  }
}

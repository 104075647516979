
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import applications from "@/api/routes/applications";
import aktRoutes from "@/api/routes/akt";
import userRoutes from "@/api/routes/users";

import { normalizedMessage } from "@/filters/dashboard";

interface IDataTestsHead {
  readonly units_count: number;
}

interface IDataApplicationHead {
  readonly units_count: number;
}

interface IDataAktHead {
  readonly has_akt: boolean;
}

@Component({
  name: "CDashboardHeads",
  components: {
    Preloader
  }
})
export default class CDashboardHeads extends Vue {
  public preload: boolean = false;
  public normalizedMessage = normalizedMessage;

  public tests_head: IDataTestsHead | null = null;
  public application_head: IDataApplicationHead | null = null;
  public akt_head: boolean = false;

  private current_year: number = new Date().getFullYear();
  private current_user: IJWTUser | null = this.$store.state.currentUser;

  private watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    this.preload = true;

    await Promise.all([
      this.loadAkts(),
      this.loadApplications(),
      this.loadTests()
    ]).finally(() => {
      this.preload = false;
    });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private async loadTests() {
    if (!this.current_user?.id) {
      return;
    }

    return this.$api
      .get(userRoutes.testing_assignments(this.current_user!.id), {
        params: {
          paginate: false,
          only_started: true
        }
      })
      .then(({ data: res }: { data: IDataTestsHead }) => {
        this.tests_head = res;
      });
  }

  private async loadApplications() {
    return this.$api
      .get(applications.my_approvals)
      .then(({ data: res }: { data: IDataApplicationHead }) => {
        this.application_head = res;
      });
  }

  private async loadAkts() {
    if (!this.current_user?.id) {
      return;
    }

    return this.$api
      .get(aktRoutes.not_started_akts(this.current_user!.id), {
        params: {
          starts_year: this.current_year
        }
      })
      .then(({ data: res }: { data: IDataAktHead }) => {
        this.akt_head = res.has_akt;
      });
  }

  public showHead(head: string) {
    if (head === "test") {
      this.$router.push({ name: "me_testings" });
    } else if (head === "application") {
      this.$router.push({ name: "training_applications" });
    } else {
      this.$router.push({ name: "me_akt" });
    }
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CEquipmentSkills from "@/views/skills/components/Equipment.vue";

import structureRoutes from "@/api/routes/structures";
import skillRoutes from "@/api/routes/skills";

import StructureWorkline from "@/models/structure/workline";
import PillarPillar from "@/models/pillar/pillar";
import SkillCategory, { ESkillCategory } from "@/models/skill/category";
import SkillLocal from "@/models/skill/local";
import StructureEquipment from "@/models/structure/equipment";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataSkill extends IData {
  readonly skills: SkillLocal[];
}

@Component({
  components: {
    Preloader,
    CEquipmentSkills
  }
})
export default class CWorklineSkills extends Vue {
  @Prop({ required: true }) private factory_id!: string;
  @Prop({ required: true }) private workshop_id!: string;
  @Prop({ required: true }) private workline!: StructureWorkline;
  @Prop({ required: false }) private pillar?: PillarPillar;
  @Prop({ required: false }) private block?: SkillCategory;
  @Prop({ required: false }) private search?: string;
  @Prop({ required: false }) private currentCompulsory?: boolean;

  private preload: boolean = false;

  private skills: SkillLocal[] = [];

  private equipments: StructureEquipment[] = [];

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;
  private watchers: Function[] = [];

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.editedTreeSkill,
        (editedTreeSkill: SkillLocal) => {
          const currentSkill = this.skills.find(
            i => i.id === editedTreeSkill.id
          );

          if (currentSkill && editedTreeSkill.deleted) {
            this.$delete(this.skills, this.skills.indexOf(currentSkill));
            return;
          }

          if (currentSkill) {
            if (
              editedTreeSkill.factory_id === this.factory_id &&
              editedTreeSkill.workshop_id === this.workshop_id &&
              editedTreeSkill.workline_id === this.workline.id &&
              !editedTreeSkill.equipment_id &&
              !editedTreeSkill.infinity_id
            ) {
              this.$set(
                this.skills,
                this.skills.indexOf(currentSkill),
                editedTreeSkill
              );
            } else {
              this.$delete(this.skills, this.skills.indexOf(currentSkill));
            }
          } else {
            if (
              editedTreeSkill.factory_id === this.factory_id &&
              editedTreeSkill.workshop_id === this.workshop_id &&
              editedTreeSkill.workline_id === this.workline.id &&
              !editedTreeSkill.equipment_id &&
              !editedTreeSkill.infinity_id
            ) {
              this.skills.push(editedTreeSkill);
            }
          }
        }
      )
    );
  }

  @Watch("pillar")
  @Watch("block")
  @Watch("search")
  @Watch("currentCompulsory")
  private async clearSkills() {
    if (!this.workline.is_show) {
      return;
    }

    this.skills = [];
    this.current_page = 0;
    await this.loadSkills();
  }

  private async infiniteHandler() {
    if (this.next_page) {
      await this.loadSkills(this.current_page + 1);
    }
  }

  private async loadSkills(page: number = 0) {
    return this.$api
      .get(skillRoutes.skills, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          category_id: ESkillCategory.FUNCTIONAL_ID,
          pillar_id: this.pillar?.id,
          block_id: this.block?.id,
          factory_id: this.factory_id,
          workshop_id: this.workshop_id,
          workline_id: this.workline.id,
          search: this.search,
          only_is_local: false,
          is_compulsory_education: this.currentCompulsory
        }
      })
      .then(({ data: res }: { data: IDataSkill }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.skills.push(...res.skills);
      });
  }

  private async loadEquipments() {
    return this.$api
      .get(structureRoutes.equipments, {
        params: { workline_id: this.workline.id }
      })
      .then(({ data: res }: { data: StructureEquipment[] }) => {
        this.equipments = res;
      });
  }

  private async showWorkline() {
    this.$set(this.workline, "is_show", !this.workline.is_show);
    this.preload = true;

    await Promise.all([this.loadEquipments(), this.clearSkills()]);

    this.preload = false;
  }

  private changeSkill(skill: SkillLocal) {
    this.$emit("change", skill);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

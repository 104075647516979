import { IsUUID, IsString, IsInt, IsPositive } from "class-validator";

import { EUnitableType } from "../unit/unit_version";

export default class RelationVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly relation_id!: string;

  @IsUUID("4")
  public readonly program_version_id!: string;

  @IsUUID("4")
  public unit_version_id!: string;

  @IsString()
  public unit_version_title: string = "";

  @IsString()
  public unit_version_unitable_type?: EUnitableType;

  @IsInt()
  @IsPositive()
  public position: number = 0;

  @IsInt()
  @IsPositive()
  public level!: number;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.relation_id = data.id;
      this.level = data.level;
      this.program_version_id = data.program_version_id;
      this.unit_version_id = data.unit_version_id;
      this.unit_version_title = data.unit_version_title;
      this.position = data.position;
      this.unit_version_unitable_type = data.unit_version_unitable_type;
    }
  }
}

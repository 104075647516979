
import { Component, Vue } from "vue-property-decorator";

import task_routes from "@/api/routes/tasks";
import structure_routes from "@/api/routes/structures";
import skill_routes from "@/api/routes/skills";

import { EPermission } from "@/enums/permissions";
import TaskTask, { taskGroupTabs } from "@/models/task/task";

import CTaskChangeEmployees from "./TaskChangeEmployees.vue";
import CTaskChangeSettings from "./TaskChangeSettings.vue";
import CTaskChangeMaterials from "./TaskChangeMaterials.vue";
import StructureFactory from "@/models/structure/factory";
import TaskSkillBlock from "@/models/task/skillBlock";
import SkillCategory, { ESkillCategory } from "@/models/skill/category";

export interface ITaskCreate {
  task_id: null;
}

@Component({
  name: "CTaskCreateGroup",
  components: {
    CTaskChangeEmployees,
    CTaskChangeSettings,
    CTaskChangeMaterials
  }
})
export default class CTaskCreateGroup extends Vue {
  public task: ITaskCreate | null = null;

  public new_task: TaskTask = new TaskTask();

  public factories: StructureFactory[] = [];
  public skills: TaskSkillBlock[] = [];
  public skill_categories: SkillCategory[] = [];

  public task_tabs = taskGroupTabs;
  public current_tab_idx: number = 0;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await Promise.all([
      this.loadFactories(),
      this.loadSkills(),
      this.loadSkillCategories()
    ]);

    this.watchers.push(
      this.$store.watch(
        state => state.creatingGroupTask,
        (creatingGroupTask: ITaskCreate | null) => {
          this.task = creatingGroupTask;

          if (this.task) {
            this.current_tab_idx = 0;
            this.new_task = new TaskTask();

            this.showModal();
          }
        }
      )
    );
  }

  private async loadFactories() {
    return this.$api
      .get(structure_routes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadSkillCategories() {
    return this.$api
      .get(skill_routes.categories, {
        params: { exclude_id: ESkillCategory.LEADERSHIP_ID }
      })
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skill_categories = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadSkills() {
    return this.$api
      .get(task_routes.global_skills)
      .then(({ data: res }: { data: TaskSkillBlock[] }) => {
        this.skills = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async createGroup() {
    return await this.$api
      .post(task_routes.group_create, this.new_task)
      .then(({ data: res }: { data: TaskTask[] }) => {
        this.$store.commit("createdGroupTask", res);

        this.$notify({
          group: "notifications",
          type: "success",
          text: `
              Задачи назначены.
              Задания могут не отображаться в списке, т.к. вы можете не является ответственным.
            `,
          speed: 500
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("create_task_modal");
  }

  public hideModal() {
    this.$modal.hide("create_task_modal");
    this.$store.commit("creatingGroupTask", null);
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.staffs_and_groups);
  }
}

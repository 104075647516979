import {
  IsUUID,
  IsString,
  IsInt,
  IsOptional,
  IsArray,
  IsBoolean
} from "class-validator";

export interface ISignSkill {
  readonly id: string;
  readonly full_name: string;
  readonly workplace_full_name: string;
}

export default class SignSign {
  @IsOptional()
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public short_name: string = "";

  @IsString()
  public full_name: string = "";

  @IsString()
  public readonly color: string = "";

  @IsBoolean()
  public is_local?: boolean = true;

  @IsOptional()
  @IsArray()
  public skills?: ISignSkill[] | null = [];

  @IsOptional()
  @IsInt()
  public readonly skill_count?: number;

  public show_skills: boolean = false;
}

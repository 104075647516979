
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import CWorkshopConstructor from "@/views/constructor/programs/index/Workshop.vue";
import Preloader from "@/components/Preloader.vue";
import CInfinityConstructor from "@/views/constructor/programs/index/Infinity.vue";
import CConstructorProgramsIndexContent from "@/views/constructor/programs/index/Content.vue";

import structure_routes from "@/api/routes/structures";

import StructureFactory from "@/models/structure/factory";
import StructureWorkshop from "@/models/structure/workshop";
import StructureInfinity from "@/models/structure/infinity";

@Component({
  name: "CFactoryConstructor",
  components: {
    Preloader,
    CWorkshopConstructor,
    CInfinityConstructor,
    CConstructorProgramsIndexContent
  }
})
export default class CFactoryConstructor extends Vue {
  @Prop({ required: true }) public factory!: StructureFactory;

  @Prop({ required: false }) public block_id?: string;
  @Prop({ required: false }) public category_id?: number;
  @Prop({ required: false }) public skill_id?: string;

  public preload: boolean = false;

  public workshops: StructureWorkshop[] = [];
  public infinities: StructureInfinity[] = [];

  protected async created() {
    await this.loadStructure();
  }

  @Watch("factory")
  protected async loadStructure() {
    this.preload = true;

    if (this.factory.uec) {
      await this.loadWorkshops();
    } else {
      await this.loadInfinities();
    }

    this.preload = false;
  }

  private async loadWorkshops() {
    return this.$api
      .get(structure_routes.workshops, {
        params: { factory_id: this.factory.id }
      })
      .then(({ data: res }: { data: StructureWorkshop[] }) => {
        this.workshops = res;
      });
  }

  private async loadInfinities() {
    return this.$api
      .get(structure_routes.infinities, {
        params: { factory_id: this.factory.id }
      })
      .then(({ data: res }: { data: StructureInfinity[] }) => {
        this.infinities = res;
      });
  }
}

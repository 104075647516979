
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import VFactory from "./structures/Factory.vue";
import FactoryUec from "./structures/FactoryUec.vue";
import CSkillRadio from "@/components/SkillRadio.vue";

import structureRoutes from "@/api/routes/structures";
import inventoryRoutes from "@/api/routes/inventories";
import skillRoutes from "@/api/routes/skills";

import StructureFactory from "@/models/structure/factory";
import InventoryInventory from "@/models/inventory/inventory";
import { EPermission } from "@/enums/permissions";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import CLevelSelector from "../components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "../helpers/update_skill_checkboxes_state";
import { EThreeStateCheckbox } from "../enums/three_state_checkbox";
import SkillCategory from "../models/skill/category";
import { plainToClass, plainToClassFromExist } from "class-transformer";
import { EImportType } from "../enums/import_type";
import CFileDownload from "../components/FileDownload.vue";
import CImportModal from "../components/ImportModal.vue";

import { phasesFactory } from "@/consts";
import { onDisplayZeroLevelSkillsChanged } from "../helpers/matrix_helper";

@Component({
  components: {
    Preloader,
    VFactory,
    FactoryUec,
    CSkillRadio,
    CLevelSelector,
    CFileDownload,
    CImportModal
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox,
      EImportType,
      phasesFactory
    };
  }
})
export default class Structures extends Vue {
  protected saveLoader: boolean = false;
  protected toggleSkillBlocks: boolean = false;

  protected factories: StructureFactory[] = [];
  protected currentFactory: StructureFactory | null = null;

  protected editFactory: StructureFactory = new StructureFactory();
  protected editFactoryIndex: number | null = null;
  protected editFactoryErrors: string | string[] | null = null;

  protected inventories: InventoryInventory[] = [];
  protected editInventory: InventoryInventory | null = null;

  protected tabs = ["Завод"];
  protected currentTab: number = 0;

  $refs!: {
    tabs: HTMLFormElement;
    blocks: HTMLFormElement[];
  };

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected watchers: Function[] = [];

  private currentBlockFilter: BlockBlock = defaultBlocks[0];
  protected blocksFilter: BlockBlock[] = [];
  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoriesFilter: SkillCategory = this
    .skillCategoriesFilter[0];
  protected searchSkills: string = "";
  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills = false;

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    await Promise.all([
      this.loadFactories(),
      this.loadInventories(),
      this.loadCategories()
    ]);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories, {
        params: {
          only_my: !this.currentUser?.is_admin
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;

        if (this.factories.length) {
          this.currentFactory = { ...this.factories[0] };
        }
      });
  }

  private loadCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(
      this.editFactory!.blocks![blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  protected tabScrollRight() {
    this.$refs.tabs.scrollLeft += 100;
  }

  protected tabScrollLeft() {
    this.$refs.tabs.scrollLeft -= 100;
  }

  protected isShowArrows() {
    return this.$refs.tabs.scrollWidth < this.$refs.tabs.clientWidth;
  }

  protected async loadFactory(
    id: string,
    inventory_id: string,
    update_full: boolean = true
  ) {
    this.currentBlockFilter = defaultBlocks[0];
    this.currentSkillCategoriesFilter = this.skillCategoriesFilter[0];
    this.searchSkills = "";
    this.selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;

    return this.$api
      .get(structureRoutes.factory(id), {
        params: {
          matrix: true,
          inventory_id
        }
      })
      .then(({ data: res }: { data: StructureFactory }) => {
        if (update_full) {
          this.editFactory = res;
        } else {
          this.editFactory.blocks = res.blocks;
        }
        this.blocksFilter = plainToClassFromExist(
          this.blocksFilter,
          res.blocks
        );

        this.displayZeroLevelSkills = false;
        this.editFactory.blocks = onDisplayZeroLevelSkillsChanged(
          this.editFactory.blocks || [],
          this.displayZeroLevelSkills
        );
      });
  }

  protected changeSkillFilter() {
    this.editFactory.blocks = [];
    this.blocksFilter.forEach(block => {
      if (
        block.id === this.currentBlockFilter.id ||
        !this.currentBlockFilter.id
      ) {
        const block_copy = plainToClass(BlockBlock, block);
        block_copy.skills = block_copy.skills?.filter(
          skill =>
            (skill.category_id === this.currentSkillCategoriesFilter.id ||
              !this.currentSkillCategoriesFilter.id) &&
            (skill.full_name
              ?.toLowerCase()
              .includes(this.searchSkills.trim()?.toLowerCase()) ||
              !this.searchSkills.trim())
        );
        if (block_copy.skills?.length) {
          this.editFactory.blocks?.push(block_copy);
        }
      }

      this.editFactory.blocks = onDisplayZeroLevelSkillsChanged(
        this.editFactory.blocks || [],
        this.displayZeroLevelSkills
      );
    });

    this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
      this.$refs,
      this.editFactory.blocks,
      this.selectAllSkillsCheckboxState
    );
  }

  protected async loadInventories() {
    return this.$api
      .get(inventoryRoutes.inventories)
      .then(({ data: res }: { data: InventoryInventory[] }) => {
        this.inventories = res;
      });
  }

  protected showModal() {
    this.$modal.show("editFactory");
  }

  protected hideModal() {
    this.$modal.hide("editFactory");
  }

  protected showFactory(factory: StructureFactory) {
    this.currentFactory = { ...factory };
  }

  protected async changeInventory(obj: InventoryInventory) {
    await this.loadFactory(this.editFactory!.id! || "new", obj.id!, false);
  }

  protected async beforeCreateFactory() {
    this.editFactoryIndex = null;
    this.editFactoryErrors = null;
    this.editInventory = null;

    await this.loadFactory("new", "");

    this.showModal();
  }

  protected async createFactory() {
    this.editFactory.inventory_id = this.editInventory?.id || null;
    this.editFactory.full_name = this.editFactory.full_name?.trim();
    this.editFactory.level_name = this.editFactory.level_name?.trim();
    this.saveLoader = true;

    return this.$api
      .post(structureRoutes.factories, this.editFactory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factories.push(res);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editFactoryErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async beforeUpdateFactory(index: number) {
    const factory = this.factories[index];
    this.saveLoader = true;
    this.editFactoryIndex = index;
    this.editFactoryErrors = null;
    this.editInventory = this.inventories.find(
      i => i.id === factory.inventory_id
    )!;

    await this.loadFactory(factory.id!, factory.inventory_id!);

    this.saveLoader = false;
    this.showModal();
  }

  protected async updateFactory() {
    this.editFactory.inventory_id = this.editInventory?.id || null;
    this.editFactory.full_name = this.editFactory.full_name?.trim();
    this.editFactory.level_name = this.editFactory.level_name?.trim();
    this.saveLoader = true;

    return this.$api
      .put(structureRoutes.factory(this.editFactory.id!), this.editFactory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.$set(this.factories, this.editFactoryIndex!, res);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editFactoryErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async destroyFactory() {
    return this.$api
      .post(structureRoutes.factory_archived(this.editFactory.id!), {})
      .then(() => {
        this.$delete(this.factories, this.editFactoryIndex!);

        this.currentFactory = { ...this.factories[0] };
        this.$modal.hide("destroyFactoryModal");
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editFactoryErrors = res.data.error;
      });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.structures);
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Debounce } from "../helpers";

@Component({
  name: "CPaginatedSelect"
})
export default class CPaginatedSelect extends Vue {
  @Prop({ required: true }) private model!: any;
  @Prop({ required: true }) private field!: string;
  @Prop({ required: true }) private label!: string;
  @Prop({ required: true }) private value!: string;
  @Prop({ required: false, default: (c: any) => c }) private reduce!: Function;
  @Prop({ required: false, default: false }) private disabled!: boolean;
  @Prop({ required: false, default: false }) private searchable!: boolean;
  @Prop({ required: false, default: false }) private isReloaded!: boolean;
  @Prop({ required: false, default: false }) private multiple!: boolean;
  @Prop({ required: false, default: true }) private appendToBody!: boolean;

  @Prop() private label_from_model: string | undefined;
  @Prop() private defaultSelect: string | undefined;
  /*** returns Promise<any[]> ***/
  @Prop() private callbackFn!: Function;

  private observer!: IntersectionObserver;

  private search_phrase = "";

  private items: any[] = [];

  private next_page: boolean = true;
  private current_page: number = 0;

  private last_input: any = null;

  private async created() {
    if (this.isReloaded) {
      this.$emit("reload", this.loadItems);
    }

    await this.loadItems();
  }

  protected async loadItems() {
    this.current_page = 0;
    this.next_page = true;
    this.items = [];

    if (this.defaultSelect) {
      const defaultSelectObject: { [index: string]: any } = {};
      defaultSelectObject[this.label] = this.defaultSelect;
      defaultSelectObject[this.value] = null;
      this.items = [defaultSelectObject];
    }

    if (this.disabled) {
      this.current_page = 0;
      return;
    }

    await this.nextPage();
  }

  private get options(): any[] {
    if (this.items.every(item => item[this.value] !== this.model[this.field])) {
      if (this.last_input) {
        return [...this.items, this.last_input];
      } else if (this.label_from_model) {
        return [
          ...this.items,
          {
            [this.value]: this.model[this.field],
            [this.label]: this.model[this.label_from_model]
          }
        ];
      } else {
        return this.items;
      }
    } else {
      return this.items;
    }
  }

  private mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll);
  }

  private async onOpen() {
    if (this.next_page) {
      await this.$nextTick();
      this.observer.observe(this.$refs.load as Element);
    }
  }

  private onClose() {
    if (this.search_phrase !== "") {
      this.items = [];
      this.next_page = true;
      this.current_page = 0;
    }
    this.observer.disconnect();
  }

  private onInput(data: any) {
    this.last_input = this.items.find(item => item[this.field] === data);

    this.$emit("input", data);
  }

  async infiniteScroll(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) {
    if (entries[0].isIntersecting && entries[0].intersectionRatio > 0) {
      const ul = (entries[0].target as HTMLElement).offsetParent;
      let scrollTop = 0;
      if (ul) {
        scrollTop = ul.scrollTop;
      }

      await this.nextPage();

      await this.$nextTick();
      if (this.current_page > 1 && ul) {
        ul.scrollTop = scrollTop;
      }
    }
  }

  private async nextPage() {
    if (this.next_page) {
      this.next_page = false;

      await this.callbackFn
        .call(null, this.current_page, this.search_phrase)
        .then((newItems: any) => {
          this.items.push(...newItems);

          ++this.current_page;
          this.next_page = !!newItems.length;
        });
    }
  }

  private async onSearch(search_phrase: string) {
    this.search_phrase = search_phrase;

    await this.debouncedSearch();
  }

  @Watch("search_phrase")
  @Debounce(300)
  private async debouncedSearch() {
    this.next_page = true;
    await this.loadItems();
    await this.$nextTick();

    if (this.$refs.load && this.next_page) {
      this.observer.observe(this.$refs.load as Element);
    }
  }
}

import "reflect-metadata";
import { Type } from "class-transformer";

import UserUser from "../user/user";
import AktTemplateResponsible from "./template_responsible";
import AktRelationVersion from "./relation_version";
import AktTag from "./tag";

export const aktTextTypes = [
  {
    label: "Базовый",
    value: 1
  },
  {
    label: "Углубленный",
    value: 2
  }
];

export const aktRanswerCount = [
  {
    label: "1 попытка",
    value: 1
  },
  {
    label: "2 попытки",
    value: 2
  },
  {
    label: "3 попытки",
    value: 3
  }
];

export enum EAktTextType {
  BASE = 1,
  DEEP = 2
}

export default class AktTemplateVersion {
  public readonly id!: string;

  public title?: string;
  public version?: string;
  public is_draft?: boolean;
  public template_id?: string;
  public template_is_archive?: boolean;
  public akt_text_type_id?: EAktTextType;

  public mastery_score?: number;
  public min_question_count?: number;
  public max_question_count?: number;

  public can_reanswer?: boolean;
  public reanswer_count?: number;

  public pass_interval?: number;
  public pass_time?: number;
  public pass_time_text?: number;

  public creator_id?: string;
  @Type(() => UserUser)
  public creator?: UserUser;

  public editor_id?: string;
  @Type(() => UserUser)
  public editor?: UserUser;

  @Type(() => UserUser)
  public responsibles?: UserUser[];

  @Type(() => AktTemplateResponsible)
  public relation_preliminaries?: AktRelationVersion[];

  @Type(() => AktTemplateResponsible)
  public relation_main_requireds?: AktRelationVersion[];

  @Type(() => AktTemplateResponsible)
  public relation_main_optionals?: AktRelationVersion[];

  @Type(() => AktTag)
  public tags: AktTag[] = [];

  public actions: {
    [skill_id: string]: { success_to: number; un_success_to: number };
  } = {};
}

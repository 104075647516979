var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-subscriptions-unit-practical"}},[_c('h3',{staticClass:"d-xs-none"},[_vm._v(_vm._s(_vm.subscription_unit.unit_version_title))]),_c('p',{staticClass:"t-12"},[_vm._v(" "+_vm._s(_vm.subscription_unit.unit_version.unitable.description)+" ")]),(_vm.subscription_unit.unit_version.unitable.url)?_c('div',{staticClass:"practical_description content_pre"},[(
        _vm.subscription_unit.unit_version.unitable.isImage() ||
          _vm.subscription_unit.unit_version.unitable.isVideo() ||
          _vm.subscription_unit.unit_version.unitable.isAudio() ||
          _vm.subscription_unit.unit_version.unitable.isPdf()
      )?_c('div',{staticClass:"download-document-wrap"},[(_vm.subscription_unit.unit_version.unitable.isImage())?_c('ImageView',{attrs:{"url":_vm.subscription_unit.unit_version.unitable.url,"size":'responsive'}}):(_vm.subscription_unit.unit_version.unitable.isVideo())?_c('VideoView',{attrs:{"size":'responsive',"url":_vm.subscription_unit.unit_version.unitable.url}}):(_vm.subscription_unit.unit_version.unitable.isAudio())?_c('CAudioView',{attrs:{"url":_vm.subscription_unit.unit_version.unitable.url}}):(_vm.subscription_unit.unit_version.unitable.isPdf())?_c('div',{attrs:{"id":"pdf_view"}},[_c('PdfView',{attrs:{"url":_vm.subscription_unit.unit_version.unitable.url,"preview":false}})],1):_vm._e(),_c('CFileDownload',{staticClass:"download-document",attrs:{"url":_vm.subscription_unit.unit_version.unitable.url.replace(
            _vm.subscription_unit.unit_version.unitable.url.split('.').pop(),
            _vm.subscription_unit.unit_version.unitable.file_type
          ),"classLink":'download-icon'}},[_c('inline-svg',{staticClass:"w-24 h-24",attrs:{"src":'/img/icon-import.svg'}})],1)],1):_c('div',[_c('div',{staticClass:"document-bl t-24"},[_c('div',{staticClass:"row null align-items-center"},[_c('div',{staticClass:"col-auto d-xs-none"},[_c('div',{staticClass:"w-160 h-160 document-bl__icon"},[_c('inline-svg',{staticClass:"w-56 h-56",attrs:{"src":'/img/icon-file.svg'}})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"it-24 ib-24 il-24 ir-24 il-xs-16 ir-xs-16"},[_c('h4',{staticClass:"font-bold font-tilte font-18"},[_vm._v("Документ")]),_c('p',{staticClass:"font-gray font-12 font-uppercase t-8 font-wrapall"},[_vm._v(" "+_vm._s(_vm.subscription_unit.unit_version.unitable.file_type)+" ")]),_c('div',{staticClass:"t-24"},[_c('CFileDownload',{staticClass:"download-document",attrs:{"url":_vm.subscription_unit.unit_version.unitable.url.replace(
                      _vm.subscription_unit.unit_version.unitable.url
                        .split('.')
                        .pop(),
                      _vm.subscription_unit.unit_version.unitable.file_type
                    ),"classLink":'btn btn-min'}},[_c('inline-svg',{staticClass:"w-18 h-18",attrs:{"src":'/img/icon-import.svg'}}),_c('span',[_vm._v("Скачать")])],1)],1)])])])])])]):_vm._e(),_c('SubscriptionUnitLearningTime',{attrs:{"subscription_unit_id":_vm.subscription_unit.id}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  subscription_program: (id: string) => `/v1/subscriptions/programs/${id}`,
  subscription_unit: (id: string) => `/v1/subscriptions/units/${id}`,
  student_programs: (id: string) => `/v1/subscriptions/${id}`,
  assignment_programs: (id: string) =>
    `/v1/subscriptions/${id}/assignment_programs`,
  calculate_unit: (id: string) => `/v1/subscriptions/units/${id}/calculate`,
  reassign: (id: string) => `/v1/subscriptions/units/${id}/reassign`,
  my_programs: `/v1/subscriptions/my_programs`,
  my_tasks: `/v1/subscriptions/my_tasks`,
  my_tests: `/v1/subscriptions/my_tests`,
  progress: `/v1/subscriptions/progress`,
  continue: (id: string) => `/v1/subscriptions/${id}/continue`,
  confirmation_info: (id: string) =>
    `/v1/subscriptions/${id}/confirmation_info`,
  confirm_program_result: (id: string) =>
    `/v1/subscriptions/${id}/confirm_program_result`,
  delegate_confirmation_program: (id: string) =>
    `/v1/subscriptions/${id}/delegate_confirmation_program`,
  not_started_unit: (id: string) =>
    `/v1/subscriptions/units/${id}/not_started_unit`
};

import {
  IsBoolean,
  IsDate,
  IsDecimal,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";

interface IDocumentFileData {
  id: string;
  url: string;
}

export default class UserDocument {
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public name!: string;

  @IsString()
  public provider!: string;

  @IsDecimal()
  public price: number = 0;

  @IsDate()
  public start_education_at!: Date;

  @IsDate()
  public end_education_at!: Date;

  public document_files: IDocumentFileData[] = [];
}

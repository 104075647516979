import { IsString, IsUUID } from "class-validator";

export default class TestingQuestionTextVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public value!: string;

  @IsUUID("4")
  public readonly text_id!: string;
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import akt_routes from "@/api/routes/akt";

import {
  AssignmentUser,
  AssignmentUserBase
} from "@/models/akt/assignment_user";
import {
  AssignmentUserWorkplace,
  EAktAssignmentUserWorkplaceType
} from "@/models/akt/assignment_user_workplace";
import { IAktTest } from "@/models/akt/assignment_subscription";

import CTeamAktAssignmentOfflineResult from "./OfflineResult.vue";
import CTeamAktAssignmentOnlineResult from "./OnlineResult.vue";
import CTeamAktAssignmentEmptyResult from "./EmptyResult.vue";

@Component({
  name: "CTeamAktAssignmentResults",
  components: {
    CTeamAktAssignmentOfflineResult,
    CTeamAktAssignmentOnlineResult,
    CTeamAktAssignmentEmptyResult
  }
})
export default class CTeamAktAssignmentResults extends Vue {
  @Prop({ required: true }) public readonly show!: boolean;
  @Prop({ required: true }) public readonly user_id!: string;

  @Prop({ required: true }) public readonly current_year!: number;
  @Prop({ required: false }) public readonly current_assignment_user_id!:
    | string
    | null;

  public EAktAssignmentUserWorkplaceType = EAktAssignmentUserWorkplaceType;

  public available_years: number[] = [this.current_year];
  public selected_year: number = this.current_year;

  public assignment_user_id: string | null = this.current_assignment_user_id;
  public available_assignment_users: AssignmentUserBase[] = [];
  public selected_assignment_user: AssignmentUserBase | null = null;

  public workplaces: { [id: string]: AssignmentUserWorkplace } = {};
  public passed_tests: IAktTest[] = [];

  protected async created() {
    await Promise.all([this.loadYears(), this.changeYear()]);
  }

  public async changeYear() {
    await this.loadAssignmentUsers();

    if (this.assignment_user_id) {
      await this.loadPassedTests();
    } else {
      this.passed_tests = [];
    }
  }

  public async changeSelectedAssignment() {
    if (!this.selected_assignment_user) {
      this.selected_assignment_user = this.available_assignment_users[0];
    }

    await this.loadAssignmentUser();
  }

  private async loadAssignmentUsers() {
    return this.$api
      .get(akt_routes.assignments(this.user_id), {
        params: {
          starts_year: this.selected_year,
          include_workplaces: false
        }
      })
      .then(async ({ data: res }: { data: AssignmentUser[] }) => {
        this.available_assignment_users = plainToClass(AssignmentUser, res);

        if (this.available_assignment_users.length) {
          this.selected_assignment_user = this.available_assignment_users[0];

          await this.loadAssignmentUser();
        } else {
          this.selected_assignment_user = null;
          this.assignment_user_id = null;
          this.workplaces = {};
        }
      });
  }

  private async loadAssignmentUser() {
    if (!this.selected_assignment_user) {
      return;
    }

    return this.$api
      .get(akt_routes.assignment_user(this.selected_assignment_user.id), {
        params: {
          starts_year: this.selected_year
        }
      })
      .then(({ data: res }: { data: AssignmentUser }) => {
        if (Object.keys(res).length) {
          const assignment_user = plainToClass(AssignmentUser, res);
          this.assignment_user_id = assignment_user.id;

          this.workplaces = assignment_user.workplaces.reduce<{
            [id: string]: AssignmentUserWorkplace;
          }>((acc, curr) => {
            acc[curr.id] = curr;

            return acc;
          }, {});
        } else {
          this.assignment_user_id = null;
          this.workplaces = {};
        }
      });
  }

  private async loadYears() {
    return this.$api
      .get(akt_routes.assignment_available_years)
      .then(({ data: res }: { data: number[] }) => {
        this.available_years = res;
      });
  }

  private async loadPassedTests() {
    if (this.assignment_user_id) {
      return this.$api
        .get(
          akt_routes.get_assignment_user_passed_testings(
            this.assignment_user_id
          )
        )
        .then(({ data: res }: { data: IAktTest[] }) => {
          this.passed_tests = res;
        });
    }
  }

  @Watch("show")
  protected toggleModal() {
    if (this.show) {
      this.openModal();
    } else {
      this.closeModal();
    }
  }

  private openModal() {
    this.$modal.show("assignment-results-modal");
  }

  private closeModal() {
    this.$modal.hide("assignment-results-modal");
  }

  public emit() {
    this.$emit("input", false);
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import aktRoutes from "@/api/routes/akt";
import userRoutes from "@/api/routes/users";

import Preloader from "@/components/Preloader.vue";
import CAktTemplateHeader from "./components/Header.vue";
import CAktQuestionFilter, { IFilter } from "../components/QuestionFilter.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import AktTemplateVersion, {
  aktTextTypes
} from "@/models/akt/template_version";
import UserUser from "@/models/user/user";
import { EAktType, EAktStatus } from "@/models/akt/question_version";
import { EAktQuestionType, IQuestionAdd } from "@/models/akt/question";
import AktRelationVersion from "@/models/akt/relation_version";
import { actionOptions } from "@/models/akt/template_action";
import { IAktTag } from "@/models/akt/tag";
import { EPermission } from "@/enums/permissions";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly questions: IQuestionAdd[];
}

interface ISkillAction {
  id: string;
  full_name: string;
  success_to: number;
  un_success_to: number;
  questions: { [question_id: string]: string };
}

@Component({
  components: {
    Preloader,
    CAktTemplateHeader,
    CAktQuestionFilter,
    CInfiniteScroll
  },
  data: () => {
    return {
      aktTextTypes,
      EAktType,
      EAktStatus,
      actionOptions
    };
  }
})
export default class VAktTemplateEdit extends Vue {
  @Prop({ required: true }) protected id!: string;

  protected preload: boolean = false;
  protected infinite_preload: boolean = false;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected template_version: AktTemplateVersion | null = null;
  protected pass_time: { mm?: string; ss?: string } = {};
  protected pass_time_text: { mm?: string; ss?: string } = {};

  public total_pass_time: number = 0;

  protected users: UserUser[] = [];
  protected selectedUsers: UserUser[] = [];

  public tags: IAktTag[] = [];

  protected currentAktTypeId: EAktType = EAktType.PRELIMINARY;
  protected searchQuestion: string = "";

  protected filters: IFilter = {
    akt_status_id: EAktStatus.APPROVED,
    akt_type_id: this.currentAktTypeId,
    skill_category_id: null,
    skill_id: null,
    tag_ids: null
  };

  protected questions: IQuestionAdd[] = [];
  protected preliminaryQuestions: Map<string, AktRelationVersion> = new Map();
  protected mainRequiredQuestions: Map<string, AktRelationVersion> = new Map();
  protected mainOptionalQuestions: Map<string, AktRelationVersion> = new Map();

  protected skillsWithQuestions: { [skill_id: string]: ISkillAction } = {};

  protected titleErrors: string | null = null;
  protected minQuestionCountErrors: string | null = null;
  protected maxQuestionCountErrors: string | null = null;
  protected masteryScoreErrors: string | null = null;
  protected canReanswerErrors: string | null = null;
  protected reanswerCountErrors: string | null = null;
  protected passIntervalErrors: string | null = null;
  protected aktTextTypeIdErrors: string | null = null;
  protected passTimeErrors: string | null = null;
  protected passTimeTextErrors: string | null = null;
  protected responsiblesErrors: string | null = null;
  public tagsErrors: string | null = null;

  protected notyError: boolean = false;
  protected notyText: string = "";

  protected async created() {
    this.preload = true;

    await Promise.all([
      this.loadTemplateVersion(),
      this.loadUsers(),
      this.loadTags()
    ]);

    this.preload = false;
  }

  public tagCreateOption(new_tag: string) {
    return { value: new_tag };
  }

  protected async loadTemplateVersion() {
    return this.$api
      .get(aktRoutes.template(this.id))
      .then(({ data: res }: { data: AktTemplateVersion }) => {
        this.template_version = plainToClass(AktTemplateVersion, res);

        if (this.template_version.responsibles?.length) {
          this.selectedUsers = res.responsibles!;
        }

        if (this.template_version.relation_preliminaries?.length) {
          this.template_version.relation_preliminaries.forEach(rel => {
            this.preliminaryQuestions.set(rel.question_id, rel);
            this.addToSkillWithQuestionMap(rel, false);
          });
        }

        if (this.template_version.relation_main_requireds?.length) {
          this.template_version.relation_main_requireds.forEach(rel => {
            this.mainRequiredQuestions.set(rel.question_id, rel);
            this.addToSkillWithQuestionMap(rel, false);
          });
        }

        if (this.template_version.relation_main_optionals?.length) {
          this.template_version.relation_main_optionals.forEach(rel => {
            this.mainOptionalQuestions.set(rel.question_id, rel);
            this.addToSkillWithQuestionMap(rel, false);
          });
        }

        Object.entries(this.template_version.actions).forEach(
          ([skill_id, action]) => {
            if (this.skillsWithQuestions[skill_id]) {
              this.skillsWithQuestions[skill_id].success_to = action.success_to;
              this.skillsWithQuestions[skill_id].un_success_to =
                action.un_success_to;
            }
          }
        );

        if (this.template_version.pass_time) {
          const minutes = Math.floor(this.template_version.pass_time / 60);
          const seconds = this.template_version.pass_time % 60;

          this.pass_time["mm"] = minutes > 9 ? `${minutes}` : `0${minutes}`;
          this.pass_time["ss"] = seconds > 9 ? `${seconds}` : `0${seconds}`;
        }

        if (this.template_version.pass_time_text) {
          const minutes = Math.floor(this.template_version.pass_time_text / 60);
          const seconds = this.template_version.pass_time_text % 60;

          this.pass_time_text["mm"] =
            minutes > 9 ? `${minutes}` : `0${minutes}`;
          this.pass_time_text["ss"] =
            seconds > 9 ? `${seconds}` : `0${seconds}`;
        }

        this.calcTotalPassTime();
      });
  }

  protected async addToSkillWithQuestionMap(
    rel: AktRelationVersion,
    create: boolean = true
  ) {
    if (!this.skillsWithQuestions[rel.skill_id]) {
      const action: ISkillAction = {
        id: rel.skill_id,
        full_name: rel.skill_full_name,
        success_to: 5,
        un_success_to: 5,
        questions: {}
      };

      this.$set(this.skillsWithQuestions, rel.skill_id, action);

      if (create) {
        await this.createAction(action);
      }
    }

    if (!this.skillsWithQuestions[rel.skill_id].questions[rel.question_id]) {
      this.$set(
        this.skillsWithQuestions[rel.skill_id].questions,
        rel.question_id,
        rel.question_version_title
      );
    }
  }

  protected async delFromSkillWithQuestionMap(rel: AktRelationVersion) {
    if (this.skillsWithQuestions[rel.skill_id]) {
      if (this.skillsWithQuestions[rel.skill_id].questions[rel.question_id]) {
        this.$delete(
          this.skillsWithQuestions[rel.skill_id].questions,
          rel.question_id
        );
      }

      if (
        !Object.keys(this.skillsWithQuestions[rel.skill_id].questions).length
      ) {
        const action = this.skillsWithQuestions[rel.skill_id];

        await this.destroyAction(action);

        this.$delete(this.skillsWithQuestions, rel.skill_id);
      }
    }
  }

  protected async loadUsers() {
    return this.$api
      .get(userRoutes.users, {
        params: {
          only_my: false,
          paginate: false,
          permission: EPermission.akt_passed_testing
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.users = res.users;
      });
  }

  private async loadTags() {
    return this.$api
      .get(aktRoutes.tags)
      .then(({ data: res }: { data: IAktTag[] }) => {
        this.tags = res;
      });
  }

  protected async updateTitle() {
    return this.$api
      .put(aktRoutes.template(this.id), {
        title: this.template_version!.title
      })
      .then(() => {
        this.titleErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.titleErrors = res.data.error;
      });
  }

  protected async updateMinQuestionCount() {
    if (this.template_version?.min_question_count) {
      this.template_version.min_question_count = +this.template_version
        .min_question_count;
    }

    if (
      this.template_version!.max_question_count! <
      this.template_version!.min_question_count!
    ) {
      this.minQuestionCountErrors =
        "Минимальное количество вопросов должно быть не больше максимального";

      return;
    }

    return this.$api
      .put(aktRoutes.template(this.id), {
        min_question_count: this.template_version!.min_question_count
      })
      .then(() => {
        this.minQuestionCountErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.minQuestionCountErrors = res.data.error;
      });
  }

  protected async updateMaxQuestionCount() {
    if (this.template_version?.max_question_count) {
      this.template_version.max_question_count = +this.template_version
        .max_question_count;
    }

    if (
      this.template_version!.max_question_count! <
      this.template_version!.min_question_count!
    ) {
      this.maxQuestionCountErrors =
        "Максимальное количество вопросов должно быть не меньше минимального";

      return;
    }

    return this.$api
      .put(aktRoutes.template(this.id), {
        max_question_count: this.template_version!.max_question_count
      })
      .then(() => {
        this.maxQuestionCountErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.maxQuestionCountErrors = res.data.error;
      });
  }

  protected async updateMasteryScore() {
    this.$api
      .put(aktRoutes.template(this.id), {
        mastery_score: this.template_version!.mastery_score
      })
      .then(() => {
        this.masteryScoreErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.masteryScoreErrors = res.data.error;
      });
  }

  protected async updateCanReanswer() {
    return this.$api
      .put(aktRoutes.template(this.id), {
        can_reanswer: this.template_version!.can_reanswer
      })
      .then(() => {
        this.canReanswerErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.canReanswerErrors = res.data.error;
      });
  }

  protected async updateReanswerCount() {
    return this.$api
      .put(aktRoutes.template(this.id), {
        reanswer_count: this.template_version!.reanswer_count
      })
      .then(() => {
        this.reanswerCountErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.reanswerCountErrors = res.data.error;
      });
  }

  protected async updatePassInterval() {
    return this.$api
      .put(aktRoutes.template(this.id), {
        pass_interval: this.template_version!.pass_interval
      })
      .then(() => {
        this.passIntervalErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.passIntervalErrors = res.data.error;
      });
  }

  protected async updateAktTextTypeId() {
    return this.$api
      .put(aktRoutes.template(this.id), {
        akt_text_type_id: this.template_version!.akt_text_type_id
      })
      .then(() => {
        this.aktTextTypeIdErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.aktTextTypeIdErrors = res.data.error;
      });
  }

  protected async updatePassTime() {
    const pass_time =
      parseInt(this.pass_time["mm"]!, 10) * 60 +
      parseInt(this.pass_time["ss"]!, 10);

    return this.$api
      .put(aktRoutes.template(this.id), {
        pass_time
      })
      .then(() => {
        this.passTimeErrors = null;
        this.template_version!.pass_time = pass_time;

        this.calcTotalPassTime();

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.passTimeErrors = res.data.error;
      });
  }
  protected async updatePassTimeText() {
    const pass_time_text =
      parseInt(this.pass_time_text["mm"]!, 10) * 60 +
      parseInt(this.pass_time_text["ss"]!, 10);

    return this.$api
      .put(aktRoutes.template(this.id), {
        pass_time_text
      })
      .then(() => {
        this.passTimeTextErrors = null;
        this.template_version!.pass_time_text = pass_time_text;

        this.calcTotalPassTime();

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.passTimeTextErrors = res.data.error;
      });
  }

  public calcTotalPassTime() {
    let survey_count = 0;
    let text_count = 0;

    this.preliminaryQuestions.forEach(r => {
      if (r.question_type_id === EAktQuestionType.OPEN) {
        text_count++;
      } else {
        survey_count++;
      }
    });

    this.mainOptionalQuestions.forEach(r => {
      if (r.question_type_id === EAktQuestionType.OPEN) {
        text_count++;
      } else {
        survey_count++;
      }
    });

    this.mainRequiredQuestions.forEach(r => {
      if (r.question_type_id === EAktQuestionType.OPEN) {
        text_count++;
      } else {
        survey_count++;
      }
    });

    const survey_pass_time =
      (survey_count * (this.template_version!.pass_time ?? 0)) / 60;
    const text_pass_time =
      (text_count * (this.template_version!.pass_time_text ?? 0)) / 60;

    this.total_pass_time = Math.floor(survey_pass_time + text_pass_time);
  }

  public async updateTags() {
    const res = this.template_version!.tags.reduce<{
      tags: IAktTag[];
      values: Set<string>;
    }>(
      (acc, curr) => {
        if (!acc.values.has(curr.value)) {
          acc.tags.push(curr);
          acc.values.add(curr.value);
        }

        return acc;
      },
      { tags: [], values: new Set([]) }
    );

    this.template_version!.tags = res.tags;

    return this.$api
      .post(aktRoutes.sync_tags(this.id), {
        tags: this.template_version!.tags
      })
      .then(() => {
        this.tagsErrors = null;

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.tagsErrors = res.data.error;
      });
  }

  protected async updateResponsibles() {
    return this.$api
      .post(aktRoutes.sync_responsibles(this.id), {
        responsibles: this.selectedUsers
      })
      .then(() => {
        this.responsiblesErrors = null;

        this.template_version!.responsibles = { ...this.selectedUsers };

        this.$store.dispatch("toggleProgramEditMessage");
      })
      .catch(({ response: res }) => {
        this.responsiblesErrors = res.data.error;
      });
  }

  protected showModal() {
    this.$modal.show("addQuestionModal");
  }

  protected hideModal() {
    this.$modal.hide("addQuestionModal");
  }

  protected async beforeAddQuestion(akt_type_id: EAktType) {
    this.currentAktTypeId = akt_type_id;
    this.filters.akt_type_id = akt_type_id;

    this.filters.tag_ids = null;
    this.filters.skill_category_id = null;
    this.filters.skill_id = null;

    await this.clearActualQuestions();

    this.showModal();
  }

  protected async searchQuestionEnter() {
    this.questions = [];

    await this.clearActualQuestions();
  }

  protected async clearActualQuestions() {
    this.current_page = 0;
    this.questions = [];
    return await this.loadActualQuestions();
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadActualQuestions(this.current_page + 1);
    }
  }

  protected async loadActualQuestions(page: number = 0) {
    this.infinite_preload = true;

    let excluded_ids: string[] = [];

    switch (this.currentAktTypeId) {
      case EAktType.PRELIMINARY:
        excluded_ids = Array.from(this.preliminaryQuestions.keys());
        break;
      case EAktType.MAIN_REQUIRED:
        excluded_ids = Array.from(this.mainRequiredQuestions.keys());
        break;
      case EAktType.MAIN_OPTIONAL:
        excluded_ids = Array.from(this.mainOptionalQuestions.keys());
        break;
    }

    return this.$api
      .post(aktRoutes.questions_index_post, {
        page,
        per_page: this.per_page,
        paginate: true,
        draft: false,
        search: this.searchQuestion,
        include_archived: false,
        excluded_ids,
        ...this.filters
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.questions.push(...res.questions);
      })
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  protected selectQuestion(question: IQuestionAdd) {
    if (typeof question.selected === "undefined") {
      this.$set(question, "selected", true);
    } else {
      this.$set(question, "selected", !question.selected);
    }
  }

  protected async addQuestion() {
    const questions = [...this.questions.filter(q => q.selected)];
    const totalSize =
      this.preliminaryQuestions.size +
      this.mainOptionalQuestions.size +
      this.mainRequiredQuestions.size +
      questions.length;

    if (
      this.template_version!.max_question_count &&
      this.template_version!.max_question_count < totalSize
    ) {
      this.notyError = true;
      this.notyText = "Превышено число вопросов";
      this.$modal.show("modal_notify_edit");

      return;
    }

    return this.$api
      .post(aktRoutes.create_relation(this.id), {
        akt_type_id: this.currentAktTypeId,
        questions
      })
      .then(({ data: res }: { data: AktRelationVersion[] }) => {
        switch (this.currentAktTypeId) {
          case EAktType.PRELIMINARY:
            this.template_version!.relation_preliminaries!.push(...res);
            res.forEach(rel => {
              this.preliminaryQuestions.set(rel.question_id, rel);
              this.addToSkillWithQuestionMap(rel);
            });
            break;
          case EAktType.MAIN_REQUIRED:
            this.template_version!.relation_main_requireds!.push(...res);
            res.forEach(rel => {
              this.mainRequiredQuestions.set(rel.question_id, rel);
              this.addToSkillWithQuestionMap(rel);
            });
            break;
          case EAktType.MAIN_OPTIONAL:
            this.template_version!.relation_main_optionals!.push(...res);
            res.forEach(rel => {
              this.mainOptionalQuestions.set(rel.question_id, rel);
              this.addToSkillWithQuestionMap(rel);
            });
            break;
        }

        this.calcTotalPassTime();

        this.hideModal();
      });
  }

  protected async destroyRelation(
    relation: AktRelationVersion,
    index: number,
    akt_type_id: EAktType
  ) {
    if (window.confirm("Вы точно хотите удалить вопрос?")) {
      return this.$api
        .destroy(aktRoutes.destroy_relation(relation.id))
        .then(() => {
          switch (akt_type_id) {
            case EAktType.PRELIMINARY:
              this.$delete(
                this.template_version!.relation_preliminaries!,
                index
              );
              this.preliminaryQuestions.delete(relation.question_id);
              this.delFromSkillWithQuestionMap(relation);
              break;
            case EAktType.MAIN_REQUIRED:
              this.$delete(
                this.template_version!.relation_main_requireds!,
                index
              );
              this.mainRequiredQuestions.delete(relation.question_id);
              this.delFromSkillWithQuestionMap(relation);
              break;
            case EAktType.MAIN_OPTIONAL:
              this.$delete(
                this.template_version!.relation_main_optionals!,
                index
              );
              this.mainOptionalQuestions.delete(relation.question_id);
              this.delFromSkillWithQuestionMap(relation);
              break;
          }

          this.calcTotalPassTime();
        })
        .catch(({ response: res }) => {
          this.notyError = true;
          this.notyText = res.data.error;
          this.$modal.show("modal_notify_edit");
        });
    }
  }

  protected async publish() {
    this.preload = true;

    await this.loadTemplateVersion();

    this.preload = false;
  }

  protected async createAction(action: ISkillAction) {
    return this.$api
      .post(aktRoutes.post_put_template_skill_action, {
        template_version_id: this.template_version!.id,
        skill_id: action.id
      })
      .catch(({ response: res }) => {
        this.notyError = true;
        this.notyText = res.data.error;
        this.$modal.show("modal_notify_edit");
      });
  }

  protected async changeAction(action: ISkillAction) {
    if (typeof action.un_success_to !== "number") {
      action.un_success_to = 5;
    }

    if (typeof action.success_to !== "number") {
      action.success_to = 5;
    }

    return this.$api
      .put(aktRoutes.post_put_template_skill_action, {
        template_version_id: this.template_version!.id,
        skill_id: action.id,
        success_to: action.success_to,
        un_success_to: action.un_success_to
      })
      .then(() => {
        this.notyError = false;
        this.notyText = "Изменения сохранены";
        this.$modal.show("modal_notify_edit");
      })
      .catch(({ response: res }) => {
        this.notyError = true;
        this.notyText = res.data.error;
        this.$modal.show("modal_notify_edit");
      });
  }

  protected async destroyAction(action: ISkillAction) {
    return this.$api
      .destroy(
        aktRoutes.delete_template_skill_action(
          this.template_version!.id,
          action.id
        )
      )
      .catch(({ response: res }) => {
        this.notyError = true;
        this.notyText = res.data.error;
        this.$modal.show("modal_notify_edit");
      });
  }

  protected async changeFilter(obj: IFilter) {
    this.filters = obj;

    this.questions = [];
    await this.clearActualQuestions();
  }
}

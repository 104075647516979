export default class AktQuestionComment {
  public readonly id!: string;

  public question_id?: string;

  public comment?: string;

  public created_at?: string;

  public creator_id?: string;
  public creator_full_name?: string;
}

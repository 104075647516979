import { Type } from "class-transformer";

import AktSurvey from "./survey";
import UserUser from "../user/user";
import AktAnswerVersion from "./answer_version";

export default class AktAnswer {
  public readonly id!: string;

  public position?: number;
  public is_correct?: boolean;

  public survey_id?: string;
  public survey?: AktSurvey;

  public creator_id?: string;
  @Type(() => UserUser)
  public creator?: UserUser;

  public editor_id?: string;
  @Type(() => UserUser)
  public editor?: UserUser;

  public versions?: AktAnswerVersion[];
  public last_version?: AktAnswerVersion;

  constructor() {
    this.is_correct = false;
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import CStructure, { IStructureProp } from "@/components/Structure.vue";

import skillRoutes from "@/api/routes/skills";
import blockRoutes from "@/api/routes/blocks";

import SkillLocal from "@/models/skill/local";
import BlockBlock from "@/models/block/block";
import SkillCategory from "@/models/skill/category";
import { plainToClass } from "class-transformer";
import StructureFactory from "@/models/structure/factory";
import structureRoutes from "@/api/routes/structures";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface ISkillData extends IData {
  readonly skills: SkillLocal[];
}

interface IDataBlock extends IData {
  readonly blocks: BlockBlock[];
}

@Component({
  name: "CSkillFilter",
  components: {
    CStructure
  }
})
export default class CSkillFilter extends Vue {
  @Prop({ required: false, default: false })
  protected isCompulsoryEducation!: boolean;

  @Prop({ required: false, default: false })
  protected idOnly!: boolean;

  @Prop({ required: false, default: false })
  protected hideInput!: boolean;

  @Prop({ required: false, default: true })
  protected appendToBody!: boolean;

  @Prop({ required: false, default: false })
  protected disabled!: boolean;

  @Prop({ required: false, default: "" })
  protected errorInput!: string;

  @Prop() protected value?: SkillLocal | string;

  protected showSelect: boolean = false;

  protected skills: SkillLocal[] = [];
  protected currentSkill?: SkillLocal = new SkillLocal();

  protected search: string = "";

  protected blocksFilter: BlockBlock[] = [
    { id: "", full_name: "Все" },
    { id: "null", full_name: "Без группы" }
  ];
  protected currentBlockFilter: BlockBlock = this.blocksFilter[0];

  protected skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  protected currentSkillCategoriesFilter: SkillCategory = this
    .skillCategoriesFilter[0];

  protected factories: StructureFactory[] = [];

  protected factoryFilter: StructureFactory | null = null;
  protected factory_id: string | null = null;
  protected factory_uec: boolean = false;
  protected structure: IStructureProp = {};

  protected async created() {
    if (this.idOnly) {
      if (typeof this.value === "string") {
        await this.getCurrentSkill(this.value);
      }
    }

    await Promise.all([
      this.loadFactories(),
      this.loadBlocks(),
      this.loadCategories(),
      this.loadFactories()
    ]);
  }

  @Watch("value")
  protected async changeCurrentSkill() {
    if (!this.value) {
      this.currentSkill = new SkillLocal();
    }
    if (this.idOnly) {
      if (typeof this.value === "string") {
        await this.getCurrentSkill(this.value);
      }
    } else {
      if (this.value instanceof SkillLocal) {
        this.currentSkill = this.value;
      }
    }
  }

  private async changeFactory() {
    this.factoryFilter = this.factoryFilter ?? this.factories[0];
    this.factory_id = this.factoryFilter?.id!;
    this.factory_uec = this.factoryFilter?.uec!;
    await this.loadSkills();
  }

  private async changeStructureFilter(structure: IStructureProp) {
    this.structure = structure;
    await this.loadSkills();
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;

        this.factoryFilter = res[0];

        this.changeFactory();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadBlocks() {
    return this.$api
      .get(blockRoutes.blocks)
      .then(({ data: res }: { data: IDataBlock }) => {
        this.blocksFilter.push(...res.blocks);
      });
  }

  protected async loadCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected async getCurrentSkill(id: string) {
    return this.$api
      .get(skillRoutes.skill(id), {})
      .then(({ data: res }: { data: SkillLocal }) => {
        this.currentSkill = plainToClass(SkillLocal, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSkills() {
    return this.$api
      .get(skillRoutes.skills, {
        params: {
          paginate: false,
          factory_id: this.factory_id,
          infinity_id: this.structure.infinity_id,
          block_id: this.currentBlockFilter.id,
          category_id: this.currentSkillCategoriesFilter.id,
          search: this.search,
          is_compulsory_education: this.isCompulsoryEducation
        }
      })
      .then(({ data: res }: { data: ISkillData }) => {
        this.skills = plainToClass(SkillLocal, res.skills);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async selectSkill(index: number) {
    this.currentSkill = this.skills[index];

    if (this.idOnly && this.currentSkill instanceof SkillLocal) {
      this.$store.commit("currentSkill", this.currentSkill.id);
      this.$emit("input", this.currentSkill.id);
    } else {
      this.$store.commit("currentSkill", this.currentSkill);
      this.$emit("input", this.currentSkill);
    }

    this.showSelect = false;
  }

  protected async selectCurrentSkill() {
    if (this.idOnly && this.currentSkill instanceof SkillLocal) {
      this.$store.commit("currentSkill", this.currentSkill.id);
      this.$emit("input", this.currentSkill.id);
    } else {
      this.$store.commit("currentSkill", this.currentSkill);
      this.$emit("input", this.currentSkill);
    }

    if (this.hideInput) {
      this.currentSkill = new SkillLocal();
    }
  }

  protected beforeDestroy() {
    this.$store.commit("currentSkill", null);
    this.showSelect = false;
  }
}

import "reflect-metadata";

import {
  IsUUID,
  IsString,
  IsInt,
  IsEnum,
  IsPositive,
  IsBoolean,
  IsOptional
} from "class-validator";
import { Type } from "class-transformer";

import UnitVersion, { EUnitableType } from "../unit/unit_version";
import { ESubscriptionStatus } from "@/models/subscriptions/program";

export default class SubscriptionUnit {
  @IsUUID("4")
  public readonly id!: string;

  @IsEnum(EUnitableType)
  public readonly unit_version_unitable_type!: EUnitableType;

  @IsString()
  public readonly unit_version_title!: string;

  @IsInt()
  @IsPositive()
  public readonly relation_version_position!: number;

  @IsInt()
  @IsPositive()
  public readonly relation_version_level!: number;

  @IsInt()
  public passing_percents: number = 0;

  @IsEnum(ESubscriptionStatus)
  public status!: ESubscriptionStatus;

  @IsOptional()
  @IsBoolean()
  public subscription_unit_is_started?: boolean;

  @Type(() => UnitVersion)
  public unit_version!: UnitVersion;

  public get isAwaitingRepeatPass() {
    return this.status === ESubscriptionStatus.AWAITING_REPEAT_PASS;
  }

  public get isDone() {
    return this.status === ESubscriptionStatus.DONE;
  }
}

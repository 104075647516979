
import { Vue, Component } from "vue-property-decorator";

import { ISignInResponse } from "@/api/routes/auth";

@Component
export default class AuthCallback extends Vue {
  protected authorizeErrors: string | null = null;

  protected async created() {
    this.$store.dispatch("updateCurrentUser", {
      authorized: false,
      currentUser: null
    });

    await this.parseHashValues();
  }

  // private getHashParams() {
  //   const hashParams: { [key: string]: string } = {};

  //   let e: RegExpExecArray | null;

  //   const a: RegExp = /\+/g,
  //     r: RegExp = /([^&;=]+)=?([^&;]*)/g,
  //     d: (s: string) => string = function(s: string) {
  //       return decodeURIComponent(s.replace(a, " "));
  //     },
  //     q: string = window.location.hash.substring(1);

  //   while ((e = r.exec(q))) hashParams[d(e[1])] = d(e[2]);

  //   return hashParams;
  // }

  protected async parseHashValues() {
    let token = location.hash.substring(1);
    token = JSON.parse(
      '{"' +
        decodeURI(token)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );

    return this.$api
      .authorizeByOpenID(token)
      .then((res: ISignInResponse) => {
        window.postMessage(res, "*");
        this.$router.push({ name: "me_dashboard" });
      })
      .catch(({ response: res }) => {
        switch (res.status) {
          case 400:
            this.authorizeErrors = "Некорректные данные";
            break;
          case 404:
            this.authorizeErrors = "Пользователь не найден";
            break;
          case 502:
          case 503:
          case 504:
            this.authorizeErrors = "Сервис недоступен";
            break;
        }
      });
  }
}

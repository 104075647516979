
import { Component, Vue, Prop } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";

import CFileDownload from "@/components/FileDownload.vue";

import TestingTestingVersion from "@/models/testing/testing_version";
import TestingComment from "@/models/testing/comment";
import TestingCommentFile from "@/models/testing/comment_file";

@Component({
  name: "CTestingsChangeComments",
  components: {
    CFileDownload
  }
})
export default class CTestingsChangeComments extends Vue {
  @Prop({ required: true }) public testing!: TestingTestingVersion;
  @Prop({ required: true }) public comments!: TestingComment[];

  $refs!: {
    comment_files: HTMLFormElement;
    comment_files_form: HTMLFormElement;
  };

  public comment: TestingComment = new TestingComment();
  public comment_files: File[] = [];
  public comment_file_urls: TestingCommentFile[] = [];

  public addCommentFile() {
    this.comment_files.push(...this.$refs.comment_files.files);
    this.$refs.comment_files_form.reset();
  }

  public removeCommentFile(index: number) {
    this.comment_files.splice(index, 1);
  }

  private async sendFile(file: File) {
    const formData = new FormData();
    formData.append("file", file!);

    return this.$api.upload(formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  public async sendMessage() {
    if (this.comment.text.length < 1 && !this.comment_files.length) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Нельзя отправить пустой комментарий",
        speed: 500
      });
      return;
    }

    if (this.comment_files.length) {
      await Promise.all(
        this.comment_files.map(f =>
          this.sendFile(f)
            .then(async ({ data: res }: { data: { url: string } }) => {
              const file = res.url.replace(
                res.url.split(".").pop()!,
                f.name.split(".").pop()!
              );

              this.comment_file_urls.push(
                new TestingCommentFile({ url: file })
              );
            })
            .catch(({ response: res }) => {
              this.$notify({
                group: "notifications",
                type: "error",
                text: res.data.error,
                speed: 500
              });
            })
        )
      ).then(async () => {
        await this.sendComment();
      });
    } else {
      await this.sendComment();
    }
  }

  private async sendComment() {
    return this.$api
      .post(testings_routes.comments, {
        id: this.testing.testing_id,
        text: this.comment.text,
        comment_files: this.comment_file_urls
      })
      .then(({ data: res }: { data: TestingComment }) => {
        this.comment_file_urls = [];
        this.comment_files = [];
        this.comments.unshift(res);

        this.comment = new TestingComment();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

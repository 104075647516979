
import { Component, Vue } from "vue-property-decorator";

import aktRoutes from "@/api/routes/akt";

import CAktTemplateHeader from "./components/Header.vue";
import AktTemplateVersion from "@/models/akt/template_version";

@Component({
  components: {
    CAktTemplateHeader
  }
})
export default class VAktTemplateNew extends Vue {
  protected template_version: AktTemplateVersion = new AktTemplateVersion();
  protected titleErrors: string | null = null;
  protected updateInProgress: boolean = false;

  public async updateTitle() {
    if (this.updateInProgress) {
      return;
    }

    this.updateInProgress = true;

    return this.$api
      .post(aktRoutes.templates, {
        title: this.template_version!.title
      })
      .then(({ data: res }: { data: { id: string } }) => {
        this.$store.dispatch("toggleProgramEditMessage");

        this.$router.push({
          name: "akt_templates_edit",
          params: { id: res.id }
        });
      })
      .catch(({ response: res }) => {
        this.titleErrors = res.data.error;
        this.updateInProgress = false;
      });
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import ImageView from "@/components/ImageView.vue";
import VideoView from "@/components/VideoView.vue";
import CAudioView from "@/components/audioView.vue";
import PdfView from "@/components/PdfView.vue";
import CFileDownload from "@/components/FileDownload.vue";

import userRoutes from "@/api/routes/users";
import unitRoutes from "@/api/routes/units";
import subscriptionRoutes from "@/api/routes/subscriptions";

import UserUser from "@/models/user/user";
import SubscriptionProgram from "@/models/subscriptions/program";
import EventVersion from "@/models/unit/unitable/event/version";
import { IEvent } from "@/helpers/calendar_helper";

@Component({
  components: {
    DateTimePicker,
    Preloader,
    ImageView,
    VideoView,
    CAudioView,
    PdfView,
    CFileDownload
  }
})
export default class CCalendarEvent extends Vue {
  @Prop({ required: true }) selectedEvent!: IEvent;
  private preload: boolean = false;

  private editableEvent: boolean = false;
  private employer: UserUser | null = null;
  private eventInfo: EventVersion | null = null;

  private created() {
    this.preload = true;

    Promise.all([this.loadEmployer(), this.loadEvent()]).then(() => {
      this.preload = false;
    });
  }

  private loadEvent() {
    this.$api
      .get(unitRoutes.event_result(this.selectedEvent.target_id))
      .then(({ data: res }: { data: EventVersion }) => {
        this.eventInfo = plainToClass(EventVersion, res);
      });
  }

  private loadEmployer() {
    this.$api
      .get(
        subscriptionRoutes.subscription_program(
          this.selectedEvent.target_detail_id!
        )
      )
      .then(({ data: res }: { data: SubscriptionProgram }) => {
        this.$api
          .get(userRoutes.user(res.user_id))
          .then(({ data: res }: { data: UserUser }) => {
            this.employer = res;
          });
      });
  }

  private setNewDate(date: Date) {
    this.selectedEvent.starts_at = date;
    this.selectedEvent.ends_at = new Date(
      this.selectedEvent.starts_at.getTime() +
        this.selectedEvent.target_duration! * 60 * 1000
    );
  }

  private reschedule() {
    this.$api
      .put(unitRoutes.reschedule, {
        id: this.selectedEvent.target_reschedule_id,
        event_at: this.selectedEvent.starts_at
      })
      .then(() => {
        this.$emit("changeEvent", this.selectedEvent);
      })
      .catch(({ response: res }) => {
        this.$emit("calendarError", res.data.error);
      });
  }

  private confirm() {
    this.$api
      .put(unitRoutes.confirm, {
        id: this.selectedEvent.target_reschedule_id,
        confirmed: true
      })
      .then(() => {
        this.selectedEvent.confirmed = true;

        this.$emit("changeEvent", this.selectedEvent);
      })
      .catch(({ response: res }) => {
        this.selectedEvent.confirmed = false;

        this.$emit("calendarError", res.data.error);
      });
  }

  private cancelEdit() {
    this.$emit("cancelEdit");
  }
}

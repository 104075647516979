
import { Component, Vue, Prop } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

import DateTimePicker from "@/components/DateTimePicker.vue";

import TestingTestingVersion, {
  repeatType
} from "@/models/testing/testing_version";
import UserUser from "@/models/user/user";

interface IUnlimitedOption {
  readonly label: string;
  readonly value?: boolean;
}

@Component({
  name: "CTestingsChangeSettings",
  components: {
    DateTimePicker
  }
})
export default class CTestingsChangeSettings extends Vue {
  @Prop({ required: true }) public testing!: TestingTestingVersion;
  @Prop({ required: true }) public reconciliations!: UserUser[];
  @Prop({ required: false, default: false }) public disabled!: boolean;

  public repeatType = repeatType;
  public ru = ru;

  public completion_interval: { mm?: string; ss?: string } = {
    mm: "02",
    ss: "00"
  };

  public unlimited: IUnlimitedOption[] = [
    { label: "Да", value: true },
    { label: "Нет", value: false }
  ];

  protected created() {
    if (this.testing.completion_interval) {
      const minutes = Math.floor(this.testing.completion_interval / 60);
      const seconds = this.testing.completion_interval % 60;

      this.completion_interval["mm"] =
        minutes > 9 ? `${minutes}` : `0${minutes}`;
      this.completion_interval["ss"] =
        seconds > 9 ? `${seconds}` : `0${seconds}`;
    }
  }

  public changeIsUnlimited() {
    if (this.testing.is_unlimited) {
      this.testing.starts_at = undefined;
      this.testing.ends_at = undefined;
    } else {
      this.testing.starts_at = new Date();
      this.testing.ends_at = new Date();
    }
  }

  public changeCanRepeat() {
    if (this.testing.can_repeat) {
      this.testing.starts_repeat_at = new Date();
      this.testing.ends_repeat_at = new Date();
    } else {
      this.testing.starts_repeat_at = undefined;
      this.testing.ends_repeat_at = undefined;
    }
  }

  public setStartsAt(starts_at: Date) {
    this.testing.starts_at = starts_at;
  }

  public setEndsAt(ends_at: Date) {
    this.testing.ends_at = ends_at;
  }

  public updateCompletionInterval() {
    this.testing.completion_interval =
      parseInt(this.completion_interval["mm"]!, 10) * 60 +
      parseInt(this.completion_interval["ss"]!, 10);
  }
}


import { Component, Vue } from "vue-property-decorator";

import CWindRoseChart from "@/components/WindRoseChart.vue";

@Component({
  components: {
    CWindRoseChart
  }
})
export default class VDashboard extends Vue {}

import { IsUUID, IsEnum } from "class-validator";

export type TQuestion = "SINGLE" | "MULTIPLE";

interface IQuestion {
  label: string;
  value: TQuestion;
}

export const questionTypes: IQuestion[] = [
  {
    label: "Одиночный выбор",
    value: "SINGLE"
  },
  {
    label: "Множественный выбор",
    value: "MULTIPLE"
  }
];

export default class TestQuestion {
  @IsUUID("4")
  public readonly id!: string;

  public type: TQuestion = "SINGLE";
}

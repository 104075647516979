
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import subscriptions from "@/api/routes/subscriptions";

import ImageView from "@/components/ImageView.vue";
import VideoView from "@/components/VideoView.vue";
import SubscriptionUnitLearningTime from "@/components/SubscriptionUnitLearningTime.vue";
import CAudioView from "@/components/audioView.vue";
import CFileDownload from "@/components/FileDownload.vue";
import PdfView from "@/components/PdfView.vue";

import SubscriptionUnit from "@/models/subscriptions/unit";

@Component({
  components: {
    ImageView,
    VideoView,
    CAudioView,
    PdfView,
    CFileDownload,
    SubscriptionUnitLearningTime
  }
})
export default class SubscriptionUnitPractical extends Vue {
  @Prop({ required: true }) subscription_unit!: SubscriptionUnit;

  protected async created() {
    await this.unitCalculate();
  }

  @Watch("subscription_unit")
  protected async unitCalculate() {
    await this.$api.get(
      subscriptions.calculate_unit(this.subscription_unit.id)
    );
  }
}

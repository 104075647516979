
import { ref, Ref } from "vue";
import { Component, Vue, Prop } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";
import positions_routes from "@/api/routes/positions";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";

import TestingTestingVersion from "@/models/testing/testing_version";
import StructureFactory from "@/models/structure/factory";
import PositionLocal from "@/models/position/local";
import UserUser from "@/models/user/user";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataSubjects extends IData {
  readonly all_subjects: UserUser[];
}

interface IDataNotSubjects extends IData {
  readonly not_subjects: UserUser[];
}

@Component({
  name: "CTestingsChangeEmployees",
  components: {
    CInfiniteScroll,
    CStructure
  }
})
export default class CTestingsChangeEmployees extends Vue {
  @Prop({ required: true }) public testing!: TestingTestingVersion;
  @Prop({ required: true }) public factories!: StructureFactory[];

  public searched_user: string = "";

  public factories_options: StructureFactory[] = [
    { full_name: "Все", level_name: "Уровень", skills: [] }
  ];
  public selected_factory: StructureFactory | null = this.factories_options[0];

  public positions: PositionLocal[] = [{ name: "Все" }];
  public selected_position: PositionLocal | null = this.positions[0];

  public structure: IStructureProp = {};

  private per_page: number = 100;

  public key_signed_employees: number = 0; // для ререндера
  public checked_signed_employees_count: number = 0;
  public selected_all_signed_employees: boolean = false;
  private infinite_preload_signed_employees: boolean = false;
  private current_page_signed_employees: number = 0;
  private next_page_signed_employees: boolean = false;
  public signed_employees: Ref<Map<string, UserUser>> = ref(new Map());

  public key_unsigned_employees: number = 0; // для ререндера
  public checked_unsigned_employees_count: number = 0;
  public selected_all_unsigned_employees: boolean = false;
  private infinite_preload_unsigned_employees: boolean = false;
  private current_page_unsigned_employees: number = 0;
  private next_page_unsigned_employees: boolean = false;
  public unsigned_employees: Ref<Map<string, UserUser>> = ref(new Map());

  public key_employees: number = 0; // для ререндера

  public employees_to_add: Map<string, UserUser> = new Map();
  public employees_to_remove: Map<string, UserUser> = new Map();
  private can_repeat: boolean = false;

  protected async created() {
    this.signed_employees.value = new Map();
    this.unsigned_employees.value = new Map();
    this.can_repeat = this.testing.can_repeat;

    this.factories_options.push(...this.factories);

    if (this.testing.id) {
      await Promise.all([
        this.loadSignedEmployees(),
        this.loadUnsignedEmployees()
      ]);

      this.key_employees++;
    }
  }

  public checkAllIconSignedEmployees() {
    return this.checked_signed_employees_count === 0
      ? null
      : this.selected_all_signed_employees
      ? "/img/icon-plus.svg"
      : "/img/icon-minus.svg";
  }

  public checkAllSignedEmployees() {
    if (this.selected_all_signed_employees) {
      this.signed_employees.value.forEach(user => {
        user.is_check = false;
      });

      this.checked_signed_employees_count = 0;
      this.selected_all_signed_employees = false;
    } else {
      this.signed_employees.value.forEach(user => {
        user.is_check = true;
      });

      this.checked_signed_employees_count = this.signed_employees.value.size;
      this.selected_all_signed_employees = true;
    }
  }

  public async removeAllCheckedSignedEmployees() {
    this.signed_employees.value.forEach(user => {
      if (user.is_check) {
        user.is_check = false;
        this.unsigned_employees.value.set(user.id, user);
        this.signed_employees.value.delete(user.id);

        this.employees_to_remove.set(user.id, user);
      }
    });

    this.checked_signed_employees_count = 0;
    this.selected_all_unsigned_employees = false;
    this.selected_all_signed_employees = false;

    await this.removeSubjects();
  }

  public checkAllIconUnsignedEmployees() {
    return this.checked_unsigned_employees_count === 0
      ? null
      : this.selected_all_unsigned_employees
      ? "/img/icon-plus.svg"
      : "/img/icon-minus.svg";
  }

  public checkAllUnsignedEmployees() {
    if (this.selected_all_unsigned_employees) {
      this.unsigned_employees.value.forEach(user => {
        user.is_check = false;
      });

      this.checked_unsigned_employees_count = 0;
      this.selected_all_unsigned_employees = false;
    } else {
      this.unsigned_employees.value.forEach(user => {
        user.is_check = true;
      });

      this.checked_unsigned_employees_count = this.unsigned_employees.value.size;
      this.selected_all_unsigned_employees = true;
    }
  }

  public async addAllCheckedUnsignedEmployees() {
    this.unsigned_employees.value.forEach(user => {
      if (user.is_check) {
        user.is_check = false;
        this.signed_employees.value.set(user.id, user);
        this.unsigned_employees.value.delete(user.id);

        this.employees_to_add.set(user.id, user);
      }
    });

    this.checked_unsigned_employees_count = 0;
    this.selected_all_unsigned_employees = false;
    this.selected_all_signed_employees = false;

    if (this.can_repeat) {
      this.$modal.show("iteration_modal");
    } else {
      await this.addSubjects(true);
    }
  }

  public checkSignedEmployees(user: UserUser) {
    this.$set(user, "is_check", !user.is_check);

    this.checked_signed_employees_count += user.is_check ? 1 : -1;
    this.selected_all_signed_employees =
      this.checked_signed_employees_count === this.signed_employees.value.size;
  }

  public checkUnsignedEmployees(user: UserUser) {
    this.$set(user, "is_check", !user.is_check);

    this.checked_unsigned_employees_count += user.is_check ? 1 : -1;
    this.selected_all_unsigned_employees =
      this.checked_unsigned_employees_count ===
      this.unsigned_employees.value.size;
  }

  private async loadPositions() {
    if (!this.selected_factory?.id) {
      return;
    }

    return this.$api
      .get(positions_routes.positions, {
        params: {
          factory_id: this.selected_factory.id,
          paginate: false,
          matrices: false
        }
      })
      .then(({ data: res }: { data: { positions: PositionLocal[] } }) => {
        this.positions.push(...res.positions);
      });
  }

  public async searchUsers() {
    await this.clearUnsignedEmployees();
  }

  public async changeFactory() {
    this.positions = [{ name: "Все" }];
    this.selected_position = this.positions[0];
    this.structure = {};

    await Promise.all([this.loadPositions(), this.clearUnsignedEmployees()]);
  }

  public async changeStructureFilter(structure: IStructureProp) {
    this.structure = structure;

    await this.clearUnsignedEmployees();
  }

  public async changePosition() {
    await this.clearUnsignedEmployees();
  }

  public async infiniteHandlerSignedEmployees() {
    if (
      this.next_page_signed_employees &&
      !this.infinite_preload_signed_employees
    ) {
      await this.loadSignedEmployees(this.current_page_signed_employees + 1);
    }
  }

  private async loadSignedEmployees(page: number = 0) {
    this.infinite_preload_signed_employees = true;

    return this.$api
      .get(testings_routes.subjects, {
        params: {
          testing_id: this.testing.testing_id,
          page,
          per_page: this.per_page,
          paginate: true,
          is_select_all: this.selected_all_signed_employees
        }
      })
      .then(({ data: res }: { data: IDataSubjects }) => {
        this.current_page_signed_employees = res.current_page;
        this.next_page_signed_employees = res.next_page;

        res.all_subjects.forEach(s => {
          this.signed_employees.value.set(s.id, s);

          if (this.selected_all_signed_employees) {
            s.is_check = true;

            this.checked_signed_employees_count++;
          }
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.infinite_preload_signed_employees = false;
        this.key_signed_employees++;
      });
  }

  private async clearUnsignedEmployees() {
    this.current_page_unsigned_employees = 0;
    this.next_page_unsigned_employees = false;
    this.selected_all_unsigned_employees = false;

    this.unsigned_employees.value = new Map();

    await this.loadUnsignedEmployees();
  }

  public async infiniteHandlerUnsignedEmployees() {
    if (
      this.next_page_unsigned_employees &&
      !this.infinite_preload_unsigned_employees
    ) {
      await this.loadUnsignedEmployees(
        this.current_page_unsigned_employees + 1
      );
    }
  }

  public async removeSubjects() {
    if (!this.employees_to_remove.size) {
      return;
    }

    return this.$api
      .put(testings_routes.remove_subjects, {
        id: this.testing.testing_id,
        subjects: Array.from(this.employees_to_remove.values())
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        this.employees_to_remove.clear();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async addSubjects(add_now: boolean) {
    if (!this.employees_to_add.size) {
      this.$modal.hide("iteration_modal");

      return;
    }

    return this.$api
      .put(testings_routes.assign_testings, {
        id: this.testing.testing_id,
        subjects: Array.from(this.employees_to_add.values()),
        add_now
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        this.employees_to_add.clear();

        this.$modal.hide("iteration_modal");
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadUnsignedEmployees(page: number = 0) {
    this.infinite_preload_unsigned_employees = true;

    return this.$api
      .get(testings_routes.not_subject, {
        params: {
          search: this.searched_user,
          factory_id: this.selected_factory?.id,
          infinity_id: this.structure?.infinity_id,
          position_id: this.selected_position?.id,
          testing_id: this.testing.testing_id,
          page,
          per_page: this.per_page,
          paginate: true,
          is_select_all: this.selected_all_unsigned_employees
        }
      })
      .then(({ data: res }: { data: IDataNotSubjects }) => {
        this.current_page_unsigned_employees = res.current_page;
        this.next_page_unsigned_employees = res.next_page;

        res.not_subjects.forEach(s => {
          if (!this.signed_employees.value.has(s.id)) {
            this.unsigned_employees.value.set(s.id, s);

            if (this.selected_all_unsigned_employees) {
              s.is_check = true;

              this.checked_unsigned_employees_count++;
            }
          }
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.infinite_preload_unsigned_employees = false;
        this.key_unsigned_employees++;
      });
  }
}

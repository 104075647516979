
import { Component, Vue, Prop } from "vue-property-decorator";

import programs_routes from "@/api/routes/programs";

import { IProgramAgreementHistory } from "@/models/program/agreement";
import CProgramAgreementHistoryItem from "@/views/constructor/programs/components/AgreementHistoryItem.vue";

@Component({
  name: "CProgramAgreementHistory",
  components: {
    CProgramAgreementHistoryItem
  }
})
export default class CProgramAgreementHistory extends Vue {
  @Prop({ required: true }) public agreement_id!: string;

  public agreement: IProgramAgreementHistory | null = null;

  protected async created() {
    await this.loadAgreement();
  }

  private async loadAgreement() {
    return this.$api
      .get(programs_routes.agreement_history(this.agreement_id))
      .then(({ data: res }: { data: IProgramAgreementHistory }) => {
        this.agreement = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public closeAgreement() {
    this.$emit("close", true);
  }

  public showProgram() {
    if (this.agreement) {
      this.$emit("onVisited", this.agreement.program_id);
    }
  }
}

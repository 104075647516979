
import { Component, Prop, Vue } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";

import Preloader from "@/components/Preloader.vue";

import { IAktTemplate } from "@/models/akt/template";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly templates: IAktTemplate[];
}

@Component({
  name: "CSearchTemplate",
  components: {
    Preloader
  }
})
export default class CSearchTemplate extends Vue {
  @Prop({ required: false, default: true })
  public readonly searchable!: boolean;
  @Prop({ required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ required: false }) public current_template_id?: string | null;
  @Prop({ required: false }) public templates?: IAktTemplate[] | null;

  public preload: boolean = false;
  public search: string = "";

  public selected_template: IAktTemplate | null = null;

  protected async created() {
    if (!this.templates?.length) {
      this.preload = true;

      await this.loadTemplates();

      this.preload = false;
    } else {
      this.setCurrentTemplate();
    }
  }

  protected async loadTemplates() {
    return this.$api
      .get(akt_routes.templates_actual, {
        params: {
          paginate: false,
          search: this.search,
          include_archived: false
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.templates = res.templates;

        this.setCurrentTemplate();
      });
  }

  protected setCurrentTemplate() {
    if (this.current_template_id && this.templates) {
      this.selected_template = this.templates.find(
        t => t.id === this.current_template_id
      )!;
    }
  }

  public onInput() {
    this.$emit("input", this.selected_template?.id);
  }
}

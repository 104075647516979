
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CUserEdit from "@/components/user/UserEdit.vue";
import CGroupEdit from "@/components/user/GroupEdit.vue";
import CWorkplacePlaceholder from "@/components/WorkplacePlaceholder.vue";

import { sagChildRoutes } from "@/router/staffs_and_groups";

import userRoutes from "@/api/routes/users";

import UserUser from "@/models/user/user";

import { EPermission } from "@/enums/permissions";
import { plainToClass } from "class-transformer";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

@Component({
  components: {
    Preloader,
    Search,
    CUserEdit,
    CWorkplacePlaceholder,
    CGroupEdit
  }
})
export default class AllStaff extends Vue {
  protected preload: boolean = false;
  protected preload_infinite: boolean = false;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected users: UserUser[] = [];

  protected search: string = this.$store.state.search;

  protected links = sagChildRoutes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected editUser = new UserUser();
  protected editUserId: string | null = null;
  protected editUserIndex: number | null = null;
  protected editUserErrors: string | string[] | null = null;
  protected workplaceExist: boolean = true;

  protected showModal: boolean = false;
  protected showGroupModal: boolean = false;

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearUsers();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.WorkplaceExist().then(
      async ({ data: res }: { data: { is_exist: boolean } }) => {
        this.workplaceExist = res.is_exist;

        if (res.is_exist) {
          await this.clearUsers();
        }
      }
    );
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async WorkplaceExist() {
    return this.$api.get(userRoutes.workplace_exists(this.currentUser?.id!));
  }

  protected async clearUsers() {
    this.preload = true;

    this.current_page = 0;
    this.users = [];

    await this.loadUsers();

    this.preload = false;
  }

  protected async loadUsers(page: number = 0) {
    this.preload_infinite = true;

    let only_my = true;

    if (this.currentUser?.is_admin) {
      only_my = false;
    }

    return this.$api
      .get(userRoutes.users, {
        params: {
          page,
          per_page: this.per_page,
          search: this.search,
          paginate: true,
          include_position: true,
          only_my
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.users.push(...res.users);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadUsers(this.current_page + 1);
    }
  }

  protected createUser(data: { user: UserUser }) {
    this.users.push(plainToClass(UserUser, data.user));
  }

  protected updateUser(data: { user: UserUser }) {
    this.$set(
      this.users,
      this.editUserIndex!,
      plainToClass(UserUser, data.user)
    );
  }

  protected closeEditModal() {
    this.editUserIndex = null;
    this.showModal = false;
    this.editUserId = null;
  }

  protected removeUser() {
    this.$delete(this.users, this.editUserIndex!);
    this.showModal = false;
    this.editUserId = null;
  }

  protected beforeCreateUser() {
    this.editUser = new UserUser();
    this.editUserIndex = null;
    this.editUserErrors = null;
    this.showModal = true;
  }

  protected beforeUpdateUser(index: number) {
    this.editUserIndex = index;
    this.editUserErrors = null;
    this.editUserId = this.users[index].id;
    this.showModal = true;
  }

  protected async beforeAddInGroup(index: number) {
    this.editUser = this.users[index];
    this.editUserId = this.editUser.id;
    this.editUserIndex = index;
    this.editUserErrors = null;

    this.showGroupModal = true;
  }

  protected closeGroupModal() {
    this.showGroupModal = false;
    this.editUserIndex = null;
    this.editUserId = null;
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.staffs_and_groups);
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import aktRoutes from "@/api/routes/akt";

import CAktLabel from "@/components/akt/Label.vue";
import Preloader from "@/components/Preloader.vue";
import CPrint from "@/components/Print.vue";
import CAktTestingSubscriptionResult, {
  IAktSub
} from "@/components/akt/SubscriptionResult.vue";

import { IAktTest, IAktTestSub } from "@/models/akt/assignment_subscription";
import { EAktType } from "@/models/akt/question_version";
import { EAktAssignmentUserWorkplaceType } from "@/models/akt/assignment_user_workplace";

@Component({
  components: {
    Preloader,
    CAktTestingSubscriptionResult,
    CPrint,
    CAktLabel
  },
  data: () => {
    return {
      EAktAssignmentUserWorkplaceType
    };
  }
})
export default class VAktTesting extends Vue {
  @Prop({ required: true }) id!: string;

  protected preload: boolean = false;
  protected testInfo: IAktTest | null = null;

  protected allSubs: IAktTestSub[] = [];
  protected preliminarySubs: IAktTestSub[] = [];
  protected mainSubs: IAktTestSub[] = [];
  protected subCount: number = 0;

  protected async created() {
    await this.loadTest();
  }

  protected syncTestInfo(subscription: IAktSub) {
    this.testInfo!.passing_percent = subscription.workplace.passing_percent;
    this.testInfo!.completion_percent =
      subscription.workplace.completion_percent;
    this.testInfo!.status_id = subscription.workplace.status_id;
    this.testInfo!.result_status_id = subscription.workplace.result_status_id;

    const sub = this.allSubs.find(s => s.id === subscription.id);
    sub!.is_correct = subscription.is_correct;
    sub!.is_checked = subscription.is_checked;
  }

  protected async loadTest() {
    this.preload = true;

    return this.$api
      .get(aktRoutes.get_workplace_testing(this.id))
      .then(({ data: res }: { data: IAktTest }) => {
        this.testInfo = res;

        res.subscriptions.forEach(sub => {
          if (sub.akt_type_id === EAktType.PRELIMINARY) {
            this.preliminarySubs.push(sub);
          } else {
            this.mainSubs.push(sub);
          }
        });

        this.allSubs = res.subscriptions;
        this.subCount = this.allSubs.length;
      })
      .finally(() => {
        this.preload = false;
      });
  }
}

import "reflect-metadata";
import { Type } from "class-transformer";

import {
  EAktAssignmentUserWorkplaceResultStatus,
  EAktAssignmentUserWorkplaceStatus,
  EAktAssignmentUserWorkplaceType
} from "./assignment_user_workplace";
import AktQuestionVersion, { EAktType } from "./question_version";
import { EAktQuestionType } from "./question";

export interface IAktTestSub {
  id: string;
  skill_id: string;
  question_id: string;
  question_version_id: string;
  title: string;
  from_template: string;
  position: number;
  akt_type_id: EAktType;
  akt_question_type_id: EAktQuestionType;
  is_current: boolean;
  is_answered: boolean;
  is_correct: boolean;
  is_checked: boolean;
}

export interface IAktTest {
  id: string;
  type_id: EAktAssignmentUserWorkplaceType;
  status_id: EAktAssignmentUserWorkplaceStatus;
  result_status_id: EAktAssignmentUserWorkplaceResultStatus;
  user_full_name: string;
  template_title: string;
  template_mastery_score: number;
  learning_time: number;
  max_learning_time: number;
  current_subscription: number;
  passing_percent: number;
  completion_percent: number;
  real_starts_at: string;
  subscriptions: IAktTestSub[];
}

export class AktAssignmentSubscription {
  public readonly id!: string;
  public readonly question_version_id!: string;
  public readonly from_template!: string;
  public readonly position!: number;

  public readonly is_answered!: boolean;
  public is_correct!: boolean;
  public is_checked!: boolean;

  @Type(() => AktQuestionVersion)
  public question_version!: AktQuestionVersion;
}

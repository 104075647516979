
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "CInfiniteScroll" })
export default class CInfiniteScroll extends Vue {
  @Prop({ default: () => 10 }) public distance!: number;
  @Prop({ default: () => 200 }) public throttleDelay!: number;

  public infiniteHandler() {
    this.$emit("scroll", true);
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import ProgramVersion from "@/models/program/version";

import { EDeviceTypes } from "@/enums/deviceTypes";

import programRoutes from "@/api/routes/programs";

@Component({
  data: () => {
    return {
      EDeviceTypes
    };
  }
})
export default class ConstructorProgramsTitle extends Vue {
  @Prop({ required: true }) public factory_id!: string;
  @Prop({ required: false, default: false }) public disabled!: boolean;

  @Prop({ required: false, default: () => new ProgramVersion() })
  public program_version!: ProgramVersion;

  public title_errors: string | string[] | null = null;
  public description_errors: string | string[] | null = null;

  private update_in_progress: boolean = false;

  public async updateTitle() {
    if (this.update_in_progress || !this.program_version.title?.length) {
      return;
    }

    this.update_in_progress = true;
    this.title_errors = null;

    if (this.factory_id && !this.program_version.id) {
      return this.$api
        .post(programRoutes.edits, {
          title: this.program_version.title,
          factory_id: this.factory_id
        })
        .then(async ({ data: res }: { data: { id: string } }) => {
          await this.changesExist();
          this.$store.dispatch("toggleProgramEditMessage");

          this.$router.push({
            name: "constructor_programs_edit",
            params: { id: res.id }
          });
        })
        .catch(({ response: res }) => {
          this.title_errors = res.data.error;
        })
        .finally(() => {
          this.update_in_progress = false;
        });
    } else {
      return this.$api
        .put(programRoutes.version(this.program_version.id), {
          title: this.program_version.title
        })
        .then(async ({ data: res }: { data: ProgramVersion }) => {
          await this.changesExist();
          this.$store.dispatch("toggleProgramEditMessage");

          this.program_version.title = res.title;
        })
        .catch(({ response: res }) => {
          this.title_errors = res.data.error;
        })
        .finally(() => {
          this.update_in_progress = false;
        });
    }
  }

  public async updateDescription() {
    if (this.update_in_progress || !this.program_version.description?.length) {
      return;
    }

    this.update_in_progress = true;
    this.description_errors = null;

    return this.$api
      .put(programRoutes.version(this.program_version.id), {
        description: this.program_version.description
      })
      .then(async ({ data: res }: { data: ProgramVersion }) => {
        await this.changesExist();
        this.$store.dispatch("toggleProgramEditMessage");

        this.program_version.description = res.description;
      })
      .catch(({ response: res }) => {
        this.description_errors = res.data.error;
      })
      .finally(() => {
        this.update_in_progress = false;
      });
  }

  private async changesExist() {
    if (!this.program_version.id) {
      return;
    }

    return this.$api
      .put(programRoutes.changes_exist(this.program_version?.id!), {})
      .then(() => {
        this.$emit("changesExist", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }
}

export default class KpiIndicator {
  public id?: string;
  public sub_level_id?: string;

  public title?: string;

  public is_archived: boolean = false;

  constructor(sub_level_id: string) {
    this.sub_level_id = sub_level_id;
  }
}


import { Component, Vue } from "vue-property-decorator";

import CFileDownload from "@/components/FileDownload.vue";
import Preloader from "@/components/Preloader.vue";

import importJobsRoutes from "@/api/routes/import_jobs";
import aktRoutes from "@/api/routes/akt";

import { EImportType } from "@/enums/import_type";

import { ImportJob } from "@/models/import/import_job";
import { EImportCompletionCode } from "@/enums/import_completion_code";

export interface IImportsData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly import_jobs: ImportJob[];
}

@Component({
  components: {
    CFileDownload,
    Preloader
  },
  data: () => {
    return {
      EImportCompletionCode
    };
  }
})
export default class VAktImport extends Vue {
  private import_jobs: ImportJob[] = [];

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected preload: boolean = false;

  protected async created() {
    await Promise.all([this.clearImports()]);
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.preload) {
      await this.loadImports(this.current_page + 1);
    }
  }

  protected async clearImports() {
    this.import_jobs = [];
    await this.loadImports();
  }

  protected async downloadTemplate() {
    await this.$api.get(aktRoutes.download_import_template).then(res => {
      const fileLink = document.createElement("a");

      fileLink.href = res.data;
      fileLink.setAttribute("download", `template.csv`);

      fileLink.click();

      fileLink.remove();
    });
  }

  protected async loadImports(page: number = 0) {
    this.preload = true;

    return this.$api
      .get(importJobsRoutes.import_jobs, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          import_type: EImportType.AKT_QUESTIONS
        }
      })
      .then(({ data: res }: { data: IImportsData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.import_jobs.push(...res.import_jobs);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import analyticsRoutes from "@/api/routes/analytics";

import { ESkillCategory } from "@/models/skill/category";
import { IChartData, ISkillData } from "@/models/analytics/chart";
import CWindRoseChart from "@/components/WindRoseChart.vue";
import { IUserWorkplace } from "@/models/user/workplace";
import users from "@/api/routes/users";

@Component({
  data: () => {
    return {
      ESkillCategory
    };
  },
  components: {
    CWindRoseChart
  }
})
export default class CCategoryChart extends Vue {
  @Prop({ required: true }) protected category!: ESkillCategory;

  protected workplaces: IUserWorkplace[] = [];
  protected currentWorkplace: IUserWorkplace | null | undefined = null;

  protected group_avg: IChartData | null = null;
  protected pillar_avg: IChartData | null = null;
  protected workplace_avg: IChartData | null = null;

  protected pillar_label: string = "";
  protected group_label: string = "";
  protected workplace_label: string = "";

  protected current_group_avg: IChartData | null = null;
  protected current_pillar_avg: IChartData | null = null;
  protected current_workplace_avg: IChartData | null = null;

  protected current_table_group_avg: ISkillData | null = null;
  protected current_table_pillar_avg: ISkillData | null = null;
  protected current_table_workplace_avg: ISkillData | null = null;

  @Watch("category")
  protected async selectCategory() {
    switch (this.category) {
      case ESkillCategory.FUNCTIONAL_ID:
        await this.loadWorkplaces();
        break;
      case ESkillCategory.METHODICAL_ID:
        await this.loadSkillGroupAvg();
        break;
      case ESkillCategory.GENERAL_ID:
        await this.loadSkillPillarAvg();
        break;
    }
    this.$forceUpdate();
  }

  protected async loadWorkplaces() {
    return this.$api
      .get(users.workplacesMe)
      .then(async ({ data: res }: { data: IUserWorkplace[] }) => {
        this.workplaces = res;

        this.currentWorkplace =
          this.workplaces.find(w => w.is_main) || this.workplaces[0];

        await this.loadSkillWorkplaceAvg();
      });
  }

  protected async selectGroup(index: number) {
    const id = this.group_avg!.ids![index]!;

    this.group_label = this.group_avg!.labels[index]!;

    await this.loadCurrentSkillGroupAvg(id as string);
  }

  protected async selectPillar(index: number) {
    const id = this.pillar_avg!.ids![index]!;

    this.pillar_label = this.pillar_avg!.labels[index]!;

    await this.loadCurrentSkillPillarAvg(id as string);
  }

  protected async selectWorkplace(index: number) {
    const ids = this.workplace_avg!.ids![index]! as {
      factory_id: string;
      infinity_id: string | null;
    };

    this.workplace_label = this.workplace_avg!.labels[index]!;

    await this.loadCurrentSkillWorkplaceAvg(ids.factory_id, ids.infinity_id);
  }

  protected async loadSkillGroupAvg() {
    return this.$api
      .get(analyticsRoutes.group_avg_level)
      .then(({ data: res }: { data: IChartData }) => {
        this.group_avg = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSkillPillarAvg() {
    return this.$api
      .get(analyticsRoutes.pillar_avg_level)
      .then(({ data: res }: { data: IChartData }) => {
        this.pillar_avg = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSkillWorkplaceAvg() {
    return this.$api
      .get(analyticsRoutes.workplace_avg_level, {
        params: { user_workplace_id: this.currentWorkplace!.id }
      })
      .then(({ data: res }: { data: IChartData }) => {
        this.workplace_avg = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadCurrentSkillWorkplaceAvg(
    factory_id: string,
    infinity_id: string | null
  ) {
    return this.$api
      .get(analyticsRoutes.current_workplace_avg_level, {
        params: { factory_id: factory_id, infinity_id: infinity_id }
      })
      .then(
        ({ data: res }: { data: { chart: IChartData; table: ISkillData } }) => {
          this.current_workplace_avg = res.chart;
          this.current_table_workplace_avg = res.table;
        }
      )
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
  protected async loadCurrentSkillPillarAvg(pillar_id: string) {
    return this.$api
      .get(analyticsRoutes.current_pillar_avg_level, {
        params: { pillar_id: pillar_id }
      })
      .then(
        ({ data: res }: { data: { chart: IChartData; table: ISkillData } }) => {
          this.current_pillar_avg = res.chart;
          this.current_table_pillar_avg = res.table;
        }
      )
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
  protected async loadCurrentSkillGroupAvg(group_id: string) {
    return this.$api
      .get(analyticsRoutes.current_group_avg_level, {
        params: { group_id: group_id }
      })
      .then(
        ({ data: res }: { data: { chart: IChartData; table: ISkillData } }) => {
          this.current_group_avg = res.chart;
          this.current_table_group_avg = res.table;
        }
      )
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

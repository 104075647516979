var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"c-calendar-event"}},[_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preload),expression:"!preload"}]},[_c('div',{staticClass:"flex-none"},[_c('h4',{staticClass:"b-0"},[_vm._v("Событие")]),(!_vm.selectedEvent.confirmed)?_c('p',{staticClass:"t-8 b-0 font-14 font-black"},[_vm._v(" Вы можете изменить дату и время и отправить их сотруднику на согласование. ")]):_vm._e()]),_c('div',{staticClass:"flex-auto it-36 min-height-280 d-flex flex-column"},[_c('div',{staticClass:"flex-auto"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-8"},[_c('div',{staticClass:"font-lightgray font-10"},[_vm._v("Название")]),_c('div',{staticClass:"t-6 font-12 font-black"},[_vm._v(" "+_vm._s(_vm.selectedEvent ? _vm.selectedEvent.name : "")+" ")])]),(_vm.employer)?_c('div',{staticClass:"col-12 col-sm-4 t-xs-24"},[_c('div',{staticClass:"font-lightgray font-10"},[_vm._v("Сотрудник")]),_c('div',{staticClass:"t-6 font-12 font-black"},[_vm._v(" "+_vm._s(_vm.employer.full_name)+" ")])]):_vm._e()]),(_vm.eventInfo)?[_c('div',{staticClass:"font-black font-12 t-24"},[_vm._v(" "+_vm._s(_vm.eventInfo.leader_description)+" ")]),(_vm.eventInfo.leader_url)?[_c('CFileDownload',{staticClass:"t-24",attrs:{"url":_vm.eventInfo.leader_url.replace(
                  _vm.eventInfo.leader_url.split('.').pop(),
                  _vm.eventInfo.leader_file_type
                ),"classLink":'btn btn-micro'}},[_c('inline-svg',{staticClass:"w-14 h-14",attrs:{"src":'/img/icon-download.svg'}}),_c('span',[_vm._v("Скачать")])],1),(_vm.eventInfo.isLeaderImage())?_c('ImageView',{staticClass:"t-24",attrs:{"url":_vm.eventInfo.leader_url,"size":'responsive'}}):(_vm.eventInfo.isLeaderVideo())?_c('VideoView',{staticClass:"t-24",attrs:{"size":'responsive',"url":_vm.eventInfo.leader_url}}):(_vm.eventInfo.isLeaderAudio())?_c('CAudioView',{staticClass:"t-24",attrs:{"url":_vm.eventInfo.leader_url}}):(_vm.eventInfo.isLeaderPdf())?_c('div',{staticClass:"t-24",attrs:{"id":"pdf_view"}},[_c('PdfView',{attrs:{"url":_vm.eventInfo.leader_url,"preview":false}})],1):_vm._e()]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"row it-14 flex-none"},[_c('div',{staticClass:"col-12 col-sm-8 t-24"},[_c('DateTimePicker',{staticClass:"datepicker--top datepicker--left",attrs:{"value":_vm.selectedEvent.starts_at,"disabled":!_vm.editableEvent,"withDisabledDate":false},on:{"input":function($event){return _vm.setNewDate($event)}}})],1),_c('div',{staticClass:"col-12 col-sm-4 t-24"},[_c('div',{staticClass:"select active select--no-arrow"},[_c('v-select',{attrs:{"disabled":true,"options":[_vm.selectedEvent.target_duration]},model:{value:(_vm.selectedEvent.target_duration),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "target_duration", $$v)},expression:"selectedEvent.target_duration"}}),_c('label',[_vm._v("Длительность")])],1)])])]),_c('div',{staticClass:"t-32 flex-none"},[_c('div',{staticClass:"row align-items-center middle"},[_c('div',{staticClass:"col-12 col-sm order-xs-last"},[_c('button',{staticClass:"btn btn-text ilr-0 btn-close-icon full-xs-width",on:{"click":_vm.cancelEdit}},[_vm._v(" Отмена ")])]),(!_vm.selectedEvent.confirmed)?[_c('div',{staticClass:"col-12 col-sm-auto t-xs-8"},[_c('button',{staticClass:"btn full-xs-width",class:[
                _vm.editableEvent
                  ? 'btn-refresh-gray btn-gray'
                  : 'btn-refresh-blue'
              ],attrs:{"disabled":_vm.editableEvent},on:{"click":function($event){_vm.editableEvent = true}}},[_vm._v(" Предложить другой вариант ")])]),_c('div',{staticClass:"col-12 col-sm-auto t-xs-8"},[(_vm.editableEvent)?_c('button',{staticClass:"btn btn-done-icon full-xs-width",on:{"click":_vm.reschedule}},[_vm._v(" "+_vm._s(_vm.editableEvent ? "Предложить" : "Согласовать")+" ")]):_c('button',{staticClass:"btn btn-done-icon full-xs-width",on:{"click":_vm.confirm}},[_vm._v(" Согласовать ")])])]:_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
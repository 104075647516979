import "reflect-metadata";
import { IsDate, IsString, IsUUID, IsOptional } from "class-validator";
import { Type } from "class-transformer";

import ApplicationCommentFile from "@/models/application/comment_file";

export default class ApplicationComment {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public application_id!: string;

  @IsString()
  public text!: string;

  @IsOptional()
  @IsString()
  public sender_name?: string;

  @IsOptional()
  @IsDate()
  public created_at?: Date;

  @Type(() => ApplicationCommentFile)
  public comment_files?: ApplicationCommentFile[];
}

import "reflect-metadata";

import { IsUUID, IsString, IsInt, Min, Max, IsOptional } from "class-validator";
import { Type } from "class-transformer";

import StructureEquipment from "./equipment";
import BlockBlock from "../block/block";

export default class StructureWorkline {
  @IsOptional()
  @IsUUID("4")
  public readonly id?: string;

  @IsString()
  public full_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public workshop_id?: string;

  @IsOptional()
  @IsUUID("4")
  public inventory_id?: string | null;

  @Type(() => StructureEquipment)
  public equipments?: StructureEquipment[];

  public is_show: boolean = false;

  public blocks?: BlockBlock[] = [];

  constructor(data?: any) {
    if (data) {
      this.workshop_id = data.workshop_id || this.workshop_id;
      this.full_name = data.full_name || this.full_name;
      this.id = data.id || this.id;
    }
  }
}

import { IsDateString, IsString, IsUUID } from "class-validator";

export interface IAktWorkplaceGroup {
  id: string;
  name: string;
  template_id?: string;
}

export interface IAktAssignmentStepType {
  id: string;
  title: string;
  position: number;
  default_starts_day: number;
  default_starts_month: number;
  default_ends_day: number;
  default_ends_month: number;
}

export interface IAktAssignmentPosition {
  id: string;
  name: string;
  factory_id: string;
  factory_full_name: string;
}

export interface IAktAssignmentWorkplace {
  full_name: string;
  factory_id: string;
  infinity_id: string | null;
  structure_name: string;
}

export interface IAktAssignment {
  id: string;
  year_id: string;
  full_name: string;
  starts_at: string;
  ends_at: string;
}

export interface IAktAssignmentSettingSteps {
  [assignment_type_id: string]: {
    starts_at: string;
    ends_at: string;
  };
}

export interface IAktAssignmentSetting {
  full_name: string;
  steps: IAktAssignmentSettingSteps;
  position_ids: string[]; // position_local_id
  workplaces: string[]; // `${factory_id}_#{infinity_id}`
}

export class Assignment {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly year_id!: string;

  @IsString()
  public readonly full_name!: string;

  @IsDateString()
  public readonly starts_at!: string;

  @IsDateString()
  public readonly ends_at!: string;
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import position_routes from "@/api/routes/positions";

interface IProgressData {
  readonly level_id: string;
  readonly level_name: string;
  readonly level_position: number;
  readonly position_id: string;
  readonly position_name: string;
  readonly is_current: boolean;
  readonly is_next: boolean;
  readonly is_passed: boolean;
  readonly passed_percent: number;
}

@Component({
  name: "CDashboardProgressLine"
})
export default class CDashboardProgressLine extends Vue {
  @Prop({ required: true }) public workplace_id!: string;

  public levels: IProgressData[] = [];

  protected async created() {
    await this.loadLevels();
  }

  private async loadLevels() {
    return this.$api
      .get(position_routes.my_levels, {
        params: { user_workplace_id: this.workplace_id }
      })
      .then(({ data: res }: { data: IProgressData[] }) => {
        this.levels = res;
      });
  }

  @Watch("workplace_id")
  protected async changeUserWorkplaceId() {
    await this.loadLevels();
  }

  public getTooltipText(level: IProgressData) {
    if (level.is_current) {
      return "Текущий уровень";
    } else if (level.is_next) {
      return "Следующий уровень";
    }
  }
}

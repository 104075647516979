
import { Component, Prop, Vue } from "vue-property-decorator";

import ApplicationApplication from "@/models/application/application";
import UserUser from "@/models/user/user";

@Component
export default class CInformation extends Vue {
  @Prop({ required: true })
  protected currentApplication!: ApplicationApplication;
  @Prop({ required: true })
  protected selectedReconciliations!: UserUser[];
  @Prop({ required: true })
  protected selectedStudents!: UserUser[];
}

import { plainToClass } from "class-transformer";
import { EThreeStateCheckbox } from "../enums/three_state_checkbox";
import BlockBlock from "../models/block/block";

export const defaultBlocks: BlockBlock[] = [{ id: "", full_name: "Все" }];

export function updateSkillCheckboxesState(
  refs: any,
  blocks: BlockBlock[] | undefined,
  selectAllSkillsCheckboxState: EThreeStateCheckbox,
  ref_prefix?: string
): EThreeStateCheckbox {
  if (!blocks || !blocks.length) {
    return (selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK);
  }

  let any_selected = false;
  let any_deselected = false;

  blocks.forEach(block => {
    block.skills?.forEach(skill => {
      const elements =
        refs[
          `${ref_prefix || ""}skill_checkbox_${block.id}_${skill.skill_id ||
            skill.id}`
        ];

      let checkbox = elements;
      if (Array.isArray(elements)) {
        checkbox = checkbox[0];
      }

      if (checkbox) {
        checkbox.checked = skill.selected || false;
      }

      if (skill.selected) {
        any_selected = true;
      } else {
        any_deselected = true;
      }
    });
  });

  if (!any_selected) {
    selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;
  } else if (!any_deselected) {
    selectAllSkillsCheckboxState = EThreeStateCheckbox.CHECK;
  } else {
    selectAllSkillsCheckboxState = EThreeStateCheckbox.INDETERMINATE;
  }

  let select_all_checkbox_element: HTMLInputElement;
  if (Array.isArray(refs[`${ref_prefix || ""}select_all_checkbox`])) {
    select_all_checkbox_element = (refs[
      `${ref_prefix || ""}select_all_checkbox`
    ] as HTMLInputElement[])[0];
  } else {
    select_all_checkbox_element = refs[
      `${ref_prefix || ""}select_all_checkbox`
    ] as HTMLInputElement;
  }

  if (select_all_checkbox_element) {
    if (selectAllSkillsCheckboxState === EThreeStateCheckbox.INDETERMINATE) {
      select_all_checkbox_element.indeterminate = true;
    } else {
      select_all_checkbox_element.checked =
        selectAllSkillsCheckboxState === EThreeStateCheckbox.CHECK;
      select_all_checkbox_element.indeterminate = false;
    }
  }
  return selectAllSkillsCheckboxState;
}

export function onSelectAllClicked(
  refs: any,
  blocks: BlockBlock[] | undefined,
  selectAllSkillsCheckboxState: EThreeStateCheckbox,
  ref_prefix?: string
): EThreeStateCheckbox {
  if (!blocks || !blocks.length) {
    return (selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK);
  }

  if (selectAllSkillsCheckboxState === EThreeStateCheckbox.CHECK) {
    selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;
  } else {
    selectAllSkillsCheckboxState = EThreeStateCheckbox.CHECK;
  }

  (refs[`${ref_prefix || ""}select_all_checkbox`] as HTMLInputElement).checked =
    selectAllSkillsCheckboxState === EThreeStateCheckbox.CHECK;

  blocks.forEach(block => {
    block.skills?.forEach(
      skill =>
        (skill.selected =
          selectAllSkillsCheckboxState === EThreeStateCheckbox.CHECK)
    );
  });

  return selectAllSkillsCheckboxState;
}


import { Component, Vue, Prop } from "vue-property-decorator";

import TestingTestingVersion from "@/models/testing/testing_version";

@Component({
  name: "CTestingsChangeApproval"
})
export default class CTestingsChangeApproval extends Vue {
  @Prop({ required: true }) public testing!: TestingTestingVersion;

  public showCurr() {
    return (
      this.testing.is_approval_or_rejected() &&
      this.testing.current_approvals.length
    );
  }

  public showPrev() {
    return this.testing.actual_id && this.testing.actual_approvals.length;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CWorkplace from "@/components/Workplace.vue";

import userRoutes from "@/api/routes/users";
import kpiRoutes from "@/api/routes/kpi";

import UserUser from "@/models/user/user";
import { EKpiSubLevelType } from "@/models/kpi/sub_level";

import { IKpiData, IData } from "@/views/kpi/All.vue";
import Templates from "@/views/akt/Templates.vue";
import CEducations from "@/components/user/Educations.vue";

interface IKpiTarget {
  target_user_id: string;
  title: string;
  id: string;
  type_id: EKpiSubLevelType;
  target_id: string;
  rating: {
    leader: boolean;
    self?: boolean;
  };
  comment: string;
}

@Component({
  components: {
    Templates,
    Preloader,
    CWorkplace,
    CEducations
  }
})
export default class VUserProfile extends Vue {
  @Prop() protected id?: string | undefined;

  protected preload: boolean = false;

  protected currentDate: Date = new Date();

  protected user: UserUser = new UserUser();
  protected supervisors: UserUser[] = [];

  protected kpis: IKpiData | null = null;

  protected kpi_targets: boolean = false;
  protected kpi_target_personals: IKpiTarget[] = [];
  protected kpi_target_collectives: IKpiTarget[] = [];

  protected error: boolean = false;

  protected async created() {
    this.preload = true;

    await Promise.all([
      this.loadKpis(),
      this.loadUser(),
      this.loadKpiTargets()
    ]);

    this.preload = false;
  }

  protected async loadUser() {
    return this.$api
      .get(this.id ? userRoutes.user(this.id) : userRoutes.userMe)
      .then(async ({ data: res }: { data: UserUser }) => {
        this.user = res;

        await this.loadSupervisors();
      });
  }

  protected async loadSupervisors() {
    return this.$api
      .get(userRoutes.supervisors_all(this.user.id!))
      .then(({ data: res }: { data: UserUser[] }) => {
        this.supervisors = res;
      });
  }

  protected async loadKpis() {
    return this.$api
      .get(kpiRoutes.user_kpis, {
        params: {
          user_id: this.id ? this.id : null
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.kpis = res.kpis;
      });
  }

  protected async loadKpiTargets() {
    return this.$api
      .get(kpiRoutes.get_kpi_target_profile)
      .then(
        ({
          data: res
        }: {
          data: { personals: IKpiTarget[]; collectives: IKpiTarget[] };
        }) => {
          this.kpi_target_personals = res.personals || [];
          this.kpi_target_collectives = res.collectives || [];

          if (
            this.kpi_target_personals.length ||
            this.kpi_target_collectives.length
          ) {
            this.kpi_targets = true;
          }
        }
      );
  }

  protected async changeSelRating(sub_level_index: number) {
    const sub_level = this.kpi_target_personals[sub_level_index];

    return this.$api
      .put(kpiRoutes.put_kpi_target_profile_self, {
        id: sub_level.target_id,
        self_rating: sub_level.rating.self
      })
      .then(({ data: res }: { data: { id: string; self_rating: boolean } }) => {
        this.kpi_target_personals[sub_level_index].rating.self =
          res.self_rating;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

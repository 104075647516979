
import { Component, Vue, Prop } from "vue-property-decorator";

import { AssignmentUserWorkplace } from "@/models/akt/assignment_user_workplace";
import { IAktTest } from "@/models/akt/assignment_subscription";

@Component({
  name: "CTeamAktAssignmentOnlineResult",
  components: {}
})
export default class CTeamAktAssignmentOnlineResult extends Vue {
  @Prop({ required: true }) public readonly test!: IAktTest;
  @Prop({ required: true }) public readonly workplace!: AssignmentUserWorkplace;

  public show: boolean = false;
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"t-32",attrs:{"id":"c-constructor-programs-title"}},[_c('div',{staticClass:"big-input textarea textarea--title",class:{
      active: _vm.program_version.title,
      error: _vm.title_errors
    }},[_c('textarea-autosize',{attrs:{"type":"text","disabled":_vm.disabled,"rows":"1"},nativeOn:{"blur":function($event){return _vm.updateTitle()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateTitle()}},model:{value:(_vm.program_version.title),callback:function ($$v) {_vm.$set(_vm.program_version, "title", $$v)},expression:"program_version.title"}}),_c('label',{attrs:{"for":"title"}},[_vm._v("Введите название программы")])],1),(_vm.title_errors)?_c('p',[_vm._v(_vm._s(_vm.title_errors))]):_vm._e(),(_vm.program_version && _vm.program_version.id)?_c('div',{staticClass:"big-textarea t-24",class:{
      active: _vm.program_version.description,
      error: _vm.description_errors
    }},[_c('textarea-autosize',{attrs:{"type":"text","disabled":_vm.disabled},nativeOn:{"blur":function($event){return _vm.updateDescription()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateDescription()}},model:{value:(_vm.program_version.description),callback:function ($$v) {_vm.$set(_vm.program_version, "description", $$v)},expression:"program_version.description"}}),_c('label',{attrs:{"for":"title"}},[_vm._v("Введите описание программы")])],1):_vm._e(),(_vm.description_errors)?_c('p',[_vm._v(_vm._s(_vm.description_errors))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";
import { Route } from "vue-router";

import CAktLabel from "@/components/akt/Label.vue";
import Preloader from "@/components/Preloader.vue";
import CTestingQuestion from "@/views/user/testing/components/question.vue";
import CTestingQuestionResult from "@/components/testing/QuestionResult.vue";

import testingsRoutes from "@/api/routes/testings";

import TestingAssignment, {
  ETestingAssignmentStatus
} from "@/models/testing/assignment";
import TestingAssignmentQuestion from "@/models/testing/assignment_question";

@Component({
  components: {
    CTestingQuestion,
    Preloader,
    CTestingQuestionResult,
    CAktLabel
  },
  data: () => {
    return {
      ETestingAssignmentStatus
    };
  }
})
export default class VUserTesting extends Vue {
  @Prop({ required: true }) protected id!: string;

  protected preload: boolean = false;

  protected currentAssignmentTesting: TestingAssignment = new TestingAssignment();

  protected currentQuestion: TestingAssignmentQuestion = new TestingAssignmentQuestion();
  protected currentQuestionIndex: number = 0;

  protected timer: number = 0;

  protected currentQuestionTimer: number = 0;

  protected timerInterval: NodeJS.Timeout | null = null;

  protected setToPause: boolean = false;

  protected async created() {
    this.preload = true;

    await Promise.all([this.loadTestingAssignment()]);

    this.preload = false;
  }

  protected async startTimer() {
    this.timerInterval = setInterval(async () => {
      if (this.timer > 0) {
        this.timer--;
        this.currentAssignmentTesting!.learning_time++;

        this.currentQuestionTimer++;
        await this.saveLearningTime();
      } else {
        await this.testing_end();
      }
    }, 1000);
  }

  protected async saveLearningTime() {
    return this.$api.put(testingsRoutes.change_learning_time(this.id), {
      learning_time: 1
    });
  }

  protected async loadTestingAssignment() {
    return this.$api
      .get(testingsRoutes.assignment(this.id))
      .then(async ({ data: res }: { data: TestingAssignment }) => {
        this.currentAssignmentTesting = plainToClass(TestingAssignment, res);
        this.currentQuestionIndex = res.current_question - 1;
        this.currentQuestion = this.currentAssignmentTesting.assignment_questions[
          this.currentQuestionIndex
        ];
        this.timer = res.max_learning_time - res.learning_time;

        if (this.currentAssignmentTesting.show_test) {
          if (this.timer > 0) {
            await this.startTimer();
          } else {
            await this.testing_end();
          }
        }
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected stopTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  }

  protected async nextQuestion(skip: boolean = false) {
    if (!skip) {
      this.currentAssignmentTesting.assignment_questions[
        this.currentQuestionIndex
      ].is_answered = true;
    }

    const not_answered_question = this.currentAssignmentTesting.assignment_questions.filter(
      q => !q.is_answered
    );

    if (!not_answered_question.length) {
      return await this.testing_end();
    }

    if (
      this.currentQuestionIndex ===
      this.currentAssignmentTesting.assignment_questions.length - 1
    ) {
      if (not_answered_question.length) {
        this.currentQuestionIndex = not_answered_question[0].position - 1;
      }
    } else {
      this.currentQuestionIndex++;
    }

    this.currentQuestion = this.currentAssignmentTesting.assignment_questions[
      this.currentQuestionIndex
    ];

    await this.saveCurrentQuestion();

    this.currentQuestionTimer = 0;

    if (this.setToPause) {
      return await this.pauseTest();
    }
  }

  protected async saveCurrentQuestion() {
    return this.$api
      .put(testingsRoutes.change_current_question(this.id), {
        question_id: this.currentQuestion!.id,
        learning_time: this.currentQuestionTimer
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async pauseTest() {
    return this.$api
      .put(testingsRoutes.testing_pause(this.id), {})
      .then(() => {
        this.$router.push({ name: "me_testings" });
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          speed: 500
        });
      });
  }

  protected setQuestion(question: TestingAssignmentQuestion, index: number) {
    if (question.is_answered) {
      return;
    }

    this.currentQuestionIndex = index;

    this.currentQuestion = this.currentAssignmentTesting.assignment_questions[
      this.currentQuestionIndex
    ];
  }

  protected async beforeRouteLeave(
    _to: Route,
    _from: Route,
    next: (vm?: any) => void
  ) {
    if (
      this.currentAssignmentTesting.status ===
        ETestingAssignmentStatus.ON_THE_RUN &&
      !this.setToPause
    ) {
      if (window.confirm("Вы точно хотите завершить тест досрочно?")) {
        await this.testing_end();
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  protected hidePauseModal() {
    this.$modal.hide("pauseModal");
  }

  protected setPauseTest() {
    this.setToPause = true;

    this.$notify({
      group: "notifications",
      type: "success",
      text: "Ответьте на данный вопрос и тестирование будет приостановлено",
      speed: 500
    });

    this.hidePauseModal();
  }

  protected showPauseModal() {
    this.$modal.show("pauseModal");
  }

  protected async testing_end() {
    this.stopTimer();

    return this.$api
      .put(testingsRoutes.testing_end(this.id), {})
      .then(({ data: res }: { data: TestingAssignment }) => {
        this.currentAssignmentTesting = plainToClass(TestingAssignment, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected beforeDestroy() {
    this.stopTimer();
  }
}

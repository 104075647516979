
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { minLength, required } from "vuelidate/lib/validators";

import Preloader from "@/components/Preloader.vue";

import subscriptionRoutes from "@/api/routes/subscriptions";
import CComment from "@/components/Comment.vue";
import CFileDownload from "@/components/FileDownload.vue";
import { ISubscriptionComment } from "@/models/subscriptions/program";

interface IDataConfirmation {
  readonly user_name?: string;
  readonly skill_level?: number;
  readonly skill_name?: string;
  readonly skill_id?: string;
  readonly comment?: string;
  readonly student_comment?: ISubscriptionComment;
}

@Component({
  name: "CConfirmationEducation",
  components: {
    CFileDownload,
    Preloader,
    CComment
  }
})
export default class CConfirmationEducation extends Vue {
  @Prop({ type: String, required: true }) private inspector_id!: string;
  @Prop({ type: Boolean, required: true }) private visited!: boolean;

  public preload: boolean = false;
  public tabs = ["Комментарий сотрудника", "Ваш комментарий"];
  public currentTab: number = 0;

  public confirmationInfo: IDataConfirmation = {};

  public comment: string = "";
  public files: string[] = [];

  public confirmed: boolean = this.visited;

  @Validations()
  protected validations = {
    comment: {
      required,
      minLength: minLength(4)
    }
  };

  protected async created() {
    await this.loadConfirmation();
    this.$v.$reset();
  }

  public uploadFile(file: string) {
    if (this.files.length < 10) {
      this.files.push(file);
    } else {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Не более 10 файлов",
        duration: 500,
        speed: 500
      });
    }
  }

  private async loadConfirmation() {
    this.preload = true;

    return this.$api
      .get(subscriptionRoutes.confirmation_info(this.inspector_id))
      .then(({ data: res }: { data: IDataConfirmation }) => {
        this.confirmationInfo = res;

        if (res.comment) {
          this.comment = res.comment;
          this.confirmed = true;
        }
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          duration: 500,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public confirmProgramResult(is_confirm_program_result: boolean) {
    if (!is_confirm_program_result) {
      this.$v.$touch();

      if (this.$v.comment.$error) {
        this.currentTab = 1;
        return;
      }
    }

    return this.$api
      .put(subscriptionRoutes.confirm_program_result(this.inspector_id), {
        is_confirm: is_confirm_program_result,
        skill_id: this.confirmationInfo.skill_id,
        skill_level: this.confirmationInfo.skill_level,
        comment: this.comment,
        files: this.files
      })
      .then(() => {
        this.$emit("onVisited", true);
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
  }

  public closeConfirmation() {
    this.$emit("close", true);
  }

  public delegateConfirmation() {
    this.$emit("delegate", true);
  }
}

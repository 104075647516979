
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  classToClass,
  plainToClass,
  plainToClassFromExist
} from "class-transformer";

import Preloader from "@/components/Preloader.vue";

import CSkillRadio from "@/components/SkillRadio.vue";

import structureRoutes from "@/api/routes/structures";
import skillRoutes from "@/api/routes/skills";

import StructureWorkline from "@/models/structure/workline";
import StructureEquipment from "@/models/structure/equipment";
import InventoryInventory from "@/models/inventory/inventory";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import { EPermission } from "@/enums/permissions";
import CLevelSelector from "../../components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "../../helpers/update_skill_checkboxes_state";
import { EThreeStateCheckbox } from "../../enums/three_state_checkbox";
import SkillCategory from "../../models/skill/category";
import { onDisplayZeroLevelSkillsChanged } from "../../helpers/matrix_helper";

@Component({
  components: {
    Preloader,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class Workline extends Vue {
  @Prop({ required: true }) protected workline!: StructureWorkline;
  @Prop({ required: true }) protected inventories!: InventoryInventory[];
  @Prop({ required: true }) protected currentUser!: IJWTUser | null;

  protected saveLoader: boolean = false;
  protected toggleSkillBlocks: boolean = false;

  protected equipments: StructureEquipment[] = [];

  protected editEquipment: StructureEquipment = new StructureEquipment();
  protected editEquipmentIndex: number | null = null;
  protected editEquipmentMatrix: BlockBlock[] = [];
  protected editEquipmentErrors: string | string[] | null = null;

  protected editInventory: InventoryInventory | null = null;

  private currentBlockFilter: BlockBlock = defaultBlocks[0];
  protected blocksFilter: BlockBlock[] = [];
  private skillCategoriesFilter: SkillCategory[] = [{ id: 0, value: "Все" }];
  private currentSkillCategoriesFilter: SkillCategory = this
    .skillCategoriesFilter[0];
  protected searchSkills: string = "";
  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills = false;

  $refs!: {
    blocks: HTMLFormElement[];
  };

  protected async created() {
    await this.loadEquipments();
    this.loadCategories();
  }

  protected async loadEquipments() {
    return this.$api
      .get(structureRoutes.equipments, {
        params: { workline_id: this.workline.id }
      })
      .then(({ data: res }: { data: StructureEquipment[] }) => {
        this.equipments = res;
      });
  }

  protected showModal() {
    this.displayZeroLevelSkills = false;
    this.$modal.show(this.workline!.id!);
  }

  protected hideModal() {
    this.$modal.hide(this.workline!.id!);
  }

  protected async loadWorkline() {
    return this.$api
      .get(structureRoutes.workline(this.workline!.id!), {
        params: {
          matrix: true,
          inventory_id: this.workline!.inventory_id
        }
      })
      .then(({ data: res }: { data: StructureWorkline }) => {
        this.workline.blocks = res.blocks;
      });
  }

  protected async loadEquipment(
    id: string,
    inventory_id: string,
    update_full: boolean = true
  ) {
    this.currentBlockFilter = defaultBlocks[0];
    this.currentSkillCategoriesFilter = this.skillCategoriesFilter[0];
    this.searchSkills = "";
    this.selectAllSkillsCheckboxState = EThreeStateCheckbox.UNCHECK;

    return this.$api
      .get(structureRoutes.equipment(id), {
        params: {
          matrix: true,
          workline_id: this.workline.id,
          inventory_id
        }
      })
      .then(({ data: res }: { data: StructureEquipment }) => {
        if (update_full) {
          this.editEquipment = res;
          this.editEquipmentMatrix =
            classToClass(this.editEquipment.blocks) || [];
        } else {
          this.editEquipment.blocks = res.blocks;
        }

        this.blocksFilter = plainToClassFromExist(
          this.blocksFilter,
          res.blocks
        );

        this.editEquipment.blocks = onDisplayZeroLevelSkillsChanged(
          this.editEquipment.blocks || [],
          this.displayZeroLevelSkills
        );
      });
  }

  private loadCategories() {
    this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      });
  }

  protected changeSkillFilter() {
    this.editEquipment.blocks = [];
    this.blocksFilter.forEach(block => {
      if (
        block.id === this.currentBlockFilter.id ||
        !this.currentBlockFilter.id
      ) {
        const block_copy = plainToClass(BlockBlock, block);
        block_copy.skills = block_copy.skills?.filter(
          skill =>
            (skill.category_id === this.currentSkillCategoriesFilter.id ||
              !this.currentSkillCategoriesFilter.id) &&
            (skill.full_name
              ?.toLowerCase()
              .includes(this.searchSkills.trim()?.toLowerCase()) ||
              !this.searchSkills.trim())
        );
        if (block_copy.skills?.length) {
          this.editEquipment.blocks?.push(block_copy);
        }
      }
    });

    this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
      this.$refs,
      this.editEquipment.blocks,
      this.selectAllSkillsCheckboxState
    );

    this.editEquipment.blocks = onDisplayZeroLevelSkillsChanged(
      this.editEquipment.blocks || [],
      this.displayZeroLevelSkills
    );
  }

  protected async changeInventory(obj: InventoryInventory) {
    await this.loadEquipment(this.editEquipment!.id! || "new", obj.id!, false);
  }

  protected async beforeCreateEquipment() {
    this.editEquipmentIndex = null;
    this.editEquipmentErrors = null;
    this.editInventory = null;

    await this.loadWorkline();

    await this.loadEquipment("new", "");

    this.editEquipment.workline_id = this.workline.id;

    this.showModal();
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(
      this.editEquipment!.blocks![blockIndex]!.skills!,
      skillIndex,
      skill
    );
  }

  protected async createEquipment() {
    this.editEquipment.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .post(structureRoutes.equipments, this.editEquipment)
      .then(({ data: res }: { data: StructureEquipment }) => {
        this.equipments.push(res);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editEquipmentErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async beforeUpdateEquipment(index: number) {
    if (!this.canWrite()) {
      return;
    }

    await this.loadWorkline();

    const equipment = this.equipments[index];
    this.editEquipmentIndex = index;
    this.editEquipmentErrors = null;
    this.editInventory = this.inventories.find(
      i => i.id === equipment.inventory_id
    )!;

    await this.loadEquipment(equipment.id!, equipment.inventory_id!);

    this.showModal();
  }

  protected async updateEquipment() {
    this.editEquipment.inventory_id = this.editInventory?.id || null;
    this.saveLoader = true;

    return this.$api
      .put(
        structureRoutes.equipment(this.editEquipment.id!),
        this.editEquipment
      )
      .then(({ data: res }: { data: StructureEquipment }) => {
        const equipment = this.equipments[this.editEquipmentIndex!];

        equipment.full_name = res.full_name;
        equipment.inventory_id = res.inventory_id;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editEquipmentErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected async destroyEquipment() {
    this.saveLoader = true;
    return this.$api
      .destroy(structureRoutes.equipment(this.editEquipment.id!))
      .then(() => {
        this.$delete(this.equipments, this.editEquipmentIndex!);

        this.$modal.hide("destroyEquipmentModal");
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editEquipmentErrors = res.data.error;
      })
      .finally(() => {
        this.saveLoader = false;
      });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.structures);
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import ImageView from "@/components/ImageView.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import SubscriptionUnitLearningTime from "@/components/SubscriptionUnitLearningTime.vue";
import VideoView from "@/components/VideoView.vue";
import CAudioView from "@/components/audioView.vue";
import PdfView from "@/components/PdfView.vue";
import CFileDownload from "@/components/FileDownload.vue";

import SubscriptionUnit from "@/models/subscriptions/unit";
import EventVersion from "@/models/unit/unitable/event/version";

@Component({
  components: {
    ImageView,
    VideoView,
    CAudioView,
    PdfView,
    CFileDownload,
    DateTimePicker,
    SubscriptionUnitLearningTime
  }
})
export default class SubscriptionUnitEvent extends Vue {
  @Prop({ required: true }) subscription_unit!: SubscriptionUnit;
  @Prop({ required: true }) private program_id!: string;
  @Prop({ required: true }) private unit_id!: string;

  private eventAt: Date = new Date();
  private schedule: boolean = false;

  private created() {
    if (
      (this.subscription_unit.unit_version.unitable as EventVersion).event_at
    ) {
      this.eventAt = new Date(
        (this.subscription_unit.unit_version.unitable as EventVersion).event_at!
      );
      this.schedule = true;
    }
  }
}


import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CStructure from "@/components/Structure.vue";

import { sagChildRoutes } from "@/router/staffs_and_groups";
import permissionRoutes from "@/api/routes/permissions";
import groupRoutes from "@/api/routes/groups";

import GroupGroup from "@/models/group/group";
import PermissionPermission from "@/models/permission/permission";
import { EPermission } from "@/enums/permissions";

@Component({
  components: {
    Preloader,
    CStructure
  }
})
export default class AllGroup extends Vue {
  protected groups: GroupGroup[] = [];

  protected preload: boolean = false;

  protected permissions: PermissionPermission[] = [];

  protected editGroup = new GroupGroup({ permissions: [...this.permissions] });
  protected editGroupIndex: number | null = null;
  protected editGroupErrors: string | string[] | null = null;

  protected links = sagChildRoutes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  protected async created() {
    this.preload = true;

    await Promise.all([this.loadGroups(), this.loadPermissions()]);

    this.preload = false;
  }

  protected async loadGroups() {
    return this.$api
      .get(groupRoutes.groups)
      .then(({ data: res }: { data: GroupGroup[] }) => {
        this.groups = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadGroup(id: string) {
    return this.$api
      .get(groupRoutes.group(id))
      .then(({ data: res }: { data: GroupGroup }) => {
        this.editGroup = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadPermissions() {
    return this.$api
      .get(permissionRoutes.permissions)
      .then(({ data: res }: { data: PermissionPermission[] }) => {
        this.permissions = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected showModal() {
    this.$modal.show("editGroup");
  }

  protected hideModal() {
    this.$modal.hide("editGroup");
  }

  protected beforeCreateGroup() {
    this.editGroup = new GroupGroup({ permissions: [...this.permissions] });
    this.editGroupIndex = null;
    this.editGroupErrors = null;

    this.showModal();
  }

  protected async createGroup() {
    this.editGroup.name = this.editGroup.name?.trim();
    await this.$api
      .post(groupRoutes.groups, this.editGroup)
      .then(({ data: res }: { data: GroupGroup }) => {
        this.groups.push(res);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editGroupErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async beforeUpdateGroup(id: string, index: number) {
    this.editGroup = new GroupGroup({ id });

    await this.loadGroup(id);

    this.editGroupIndex = index;
    this.editGroupErrors = null;

    this.showModal();
  }

  protected async updateGroup() {
    this.editGroup.name = this.editGroup.name?.trim();
    this.$api
      .put(groupRoutes.group(this.editGroup.id!), this.editGroup)
      .then(({ data: res }: { data: GroupGroup }) => {
        this.$set(this.groups, this.editGroupIndex!, res);

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editGroupErrors = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected changeRead(index: number) {
    if (!this.editGroup.permissions[index].read) {
      this.editGroup.permissions[index].write = false;
    }
  }

  protected changeWrite(index: number) {
    if (this.editGroup.permissions[index].write) {
      this.editGroup.permissions[index].read = true;
    }
  }

  protected async destroyGroup() {
    if (window.confirm("Вы точно хотите удалить группу?")) {
      this.$api
        .destroy(groupRoutes.group(this.editGroup.id!))
        .then(() => {
          this.$delete(this.groups, this.editGroupIndex!);

          this.hideModal();
        })
        .catch(({ response: res }) => {
          this.editGroupErrors = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.staffs_and_groups);
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CCalendarEvent from "./components/Event.vue";
import CCalendarAkt from "./components/Akt.vue";

import { EDeviceTypes } from "@/enums/deviceTypes";

import userRoutes from "@/api/routes/users";

import {
  weekdays,
  months,
  months_short,
  days,
  days_short,
  ONE_DAY_IN_MILISECONDS
} from "@/helpers/date";

import { CalendarTimestamp } from "vuetify";
import { EEventType, IEvent, getEventColor } from "@/helpers/calendar_helper";

interface ICalendarInstance {
  checkChange: () => void;
  prev: () => void;
  next: () => void;
  timeToY: (
    time: number | string | { hour: number; minute: number }
  ) => number | false;
  scrollToTime: (
    s: number | string | { hour: number; minute: number }
  ) => boolean;
  updateTimes: () => void;
  times: { now: { hour: number; minute: number } };
}

@Component({
  methods: { getEventColor },
  computed: {
    EEventType() {
      return EEventType;
    }
  },
  components: {
    Preloader,
    CCalendarEvent,
    CCalendarAkt
  },
  data: () => {
    return {
      EDeviceTypes
    };
  }
})
export default class CCalendar extends Vue {
  @Prop({ required: true }) user_id!: string;
  @Prop({ required: true }) editable!: boolean;

  @Prop({ default: "Календарь" }) title!: string;

  private preload: boolean = false;
  private showCalendar: boolean = false;

  private weekdays = weekdays;
  private months = months;
  private months_short = months_short;
  private days = days;
  private days_short = days_short;

  private calendarScales = [
    { label: "Месяц", value: "month" },
    { label: "Неделя", value: "week" },
    { label: "День", value: "day" }
  ];

  private currentCalendarScale = { ...this.calendarScales[0] };
  private currentMobileCalendarScale = { ...this.calendarScales[0] };
  private showOnlyUnconfirmed: boolean = false;

  private originalEvents: IEvent[] = [];
  private unconfirmedEvents: IEvent[] = [];
  private calendarEvents: IEvent[] = [];

  private calendarError: string | null = null;
  private calendarCurrentDate: Date = new Date();
  private targetCurrentDate: Date = new Date();

  private updateTimeInterval: NodeJS.Timeout | null = null;

  private selectedEvent: IEvent | null = null;

  private watchers: Function[] = [];

  private created() {
    this.loadEvents();

    this.watchers.push(
      this.$store.watch(
        state => state.addCalendarEvent,
        addCalendarEvent => this.addCalendarEvent(addCalendarEvent)
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changeCalendarEvent,
        changeCalendarEvent => this.changeCalendarEvent(changeCalendarEvent)
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.delCalendarEvent,
        delCalendarEvent => this.delCalendarEvent(delCalendarEvent)
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.reloadCalendarEvents,
        reloadCalendarEvents => this.reloadCalendarEvents(reloadCalendarEvents)
      )
    );
  }

  private addCalendarEvent(event: IEvent | null) {
    if (!event) {
      return;
    }
  }

  private changeCalendarEvent(event: IEvent | null) {
    if (!event) {
      return;
    }
  }

  private delCalendarEvent(event: IEvent | null) {
    if (!event) {
      return;
    }
  }

  private reloadCalendarEvents(flag: boolean) {
    if (!flag) {
      return;
    }

    this.$store.commit("reloadCalendarEvents", false);
    this.loadEvents();
  }

  private mounted() {
    this.showCalendar = true;
  }

  private beforeDestroy() {
    this.stopUpdateTime();

    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private loadEvents() {
    this.preload = true;

    this.$api
      .get(userRoutes.calendar(this.user_id))
      .then(({ data: res }: { data: IEvent[] }) => {
        this.originalEvents = res.map((e, index) => {
          e.starts_at = new Date(e.starts_at);
          e.ends_at = new Date(e.ends_at);

          e.start = new Date(e.starts_at);
          e.end = new Date(e.ends_at);
          e.index = index;
          e.timed = !(
            Math.floor(
              (e.ends_at.getTime() - e.starts_at.getTime()) /
                ONE_DAY_IN_MILISECONDS
            ) > 0
          );
          return e;
        });

        this.unconfirmedEvents = Array.from(this.originalEvents).filter(
          e => !e.confirmed
        );
        this.calendarEvents = Array.from(this.originalEvents);
      })
      .finally(() => {
        this.preload = false;
      });
  }

  @Watch("showOnlyUnconfirmed")
  private changeShowOnlyUnconfirmed() {
    if (this.showOnlyUnconfirmed) {
      this.calendarEvents = Array.from(this.originalEvents).filter(
        e => !e.confirmed
      );
    } else {
      this.calendarEvents = Array.from(this.originalEvents);
    }
  }

  private changeUnconfirmed() {
    this.unconfirmedEvents = Array.from(this.originalEvents).filter(
      e => !e.confirmed
    );
  }

  private get calendarInstance(): ICalendarInstance {
    return this.$refs.calendar as Vue & ICalendarInstance;
  }

  private setToday() {
    this.targetCurrentDate = new Date();
  }

  private prevPage() {
    this.calendarInstance.prev();
  }

  private nextPage() {
    this.calendarInstance.next();
  }

  private currentMonth() {
    return (
      this.months[this.targetCurrentDate.getMonth()] +
      " " +
      this.targetCurrentDate.getFullYear()
    );
  }

  private formatDate(date: Date) {
    const result = new Date(date);

    return `${result.getFullYear()}-${result.getMonth() +
      1}-${result.getDate()} ${result.getHours()}:${result.getMinutes()}`;
  }

  private zeroPad(num: number) {
    return String(num).padStart(2, "0");
  }

  private nowY() {
    return this.showCalendar
      ? this.calendarInstance.timeToY(this.calendarInstance.times.now) + "px"
      : "-10px";
  }

  private getCurrentTime() {
    return this.showCalendar
      ? this.calendarInstance.times.now.hour * 60 +
          this.calendarInstance.times.now.minute
      : 0;
  }

  private scrollToTime() {
    const time = this.getCurrentTime();
    const first = Math.max(0, time - (time % 30) - 30);

    this.calendarInstance.scrollToTime(first);
  }

  private startUpdateTime() {
    this.updateTimeInterval = setInterval(
      () => this.calendarInstance.updateTimes(),
      60
    );
  }

  private stopUpdateTime() {
    if (this.updateTimeInterval) {
      clearInterval(this.updateTimeInterval);
      this.updateTimeInterval = null;
    }
  }

  private showUnconfirmedEvent(event: IEvent) {
    if (!this.editable) {
      return;
    }

    if (
      event.responsible_user_id !== this.$store.state.currentUser.id &&
      !event.student_confirm
    ) {
      return;
    }

    if (this.eventWithAction(event)) {
      this.selectedEvent = event;

      this.$modal.show("eventModal");
    }
  }

  private modalClose() {
    this.$modal.hide("eventModal");

    this.selectedEvent = null;
    this.calendarError = null;
  }

  private setCalendarError(error: string) {
    this.calendarError = error;
  }

  private cancelEdit() {
    this.modalClose();
  }

  private changeEvent(event: IEvent) {
    event.start = event.starts_at;
    event.end = event.ends_at;

    this.$set(this.originalEvents, event.index!, event);

    this.changeShowOnlyUnconfirmed();
    this.changeUnconfirmed();
    this.calendarInstance.checkChange();

    this.modalClose();
  }

  private handleDataClick(data: CalendarTimestamp) {
    this.targetCurrentDate = new Date(data.date);
  }

  private handleDayClick(data: CalendarTimestamp) {
    if (!this.editable) {
      this.$emit("dayClick", data);

      return;
    }
  }

  private handleTimeClick(data: CalendarTimestamp) {
    if (!this.editable) {
      this.$emit("timeClick", data);

      return;
    }
  }

  private handleEventClick({
    nativeEvent,
    event
  }: {
    nativeEvent: MouseEvent;
    event: IEvent;
  }) {
    if (!this.editable) {
      this.$emit("eventClick", event);

      return;
    }

    if (this.eventWithAction(event)) {
      this.selectedEvent = event;

      this.$modal.show("eventModal");
    }
  }

  private eventWithAction(event: IEvent) {
    return [
      EEventType.EVENT_STUDENT,
      EEventType.EVENT_TRAINER,
      EEventType.AKT_STUDENT,
      EEventType.AKT_SUPERVISOR
    ].includes(event.event_type);
  }

  private viewDay(data: CalendarTimestamp) {
    this.targetCurrentDate = new Date(data.date);
    this.currentCalendarScale = {
      ...this.calendarScales.find(s => s.value === "day")!
    };
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import CBaseWorkplace from "./workplace/Base.vue";
import CUecWorkplace from "./workplace/Uec.vue";

import UserWorkplace from "@/models/user/workplace";

@Component({
  components: {
    CBaseWorkplace,
    CUecWorkplace
  }
})
export default class CWorkplace extends Vue {
  @Prop({ required: true }) workplace!: UserWorkplace;
  @Prop({ default: true }) showLevel!: boolean;
}


import { Component, Vue } from "vue-property-decorator";
import { DebounceInstance, debounce } from "vue-debounce";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";

import CPositionLevelStructure from "./components/Structure.vue";
import CPositionLevelFactory from "./components/Factory.vue";
import CPositionLevelFactoryUec from "./components/FactoryUec.vue";
import CPositionLevelEditModal from "./components/LevelEdit.vue";

import PositionLocalLevel from "@/models/position/level";
import StructureFactory from "@/models/structure/factory";
import { EPermission } from "@/enums/permissions";

import positionRoutes from "@/api/routes/positions";
import structureRoutes from "@/api/routes/structures";
import PositionLocalLevelWorkplace from "@/models/position/level_workplace";
import CFileDownload from "@/components/FileDownload.vue";
import CImportModal from "@/components/ImportModal.vue";
import { EImportType } from "@/enums/import_type";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly levels: PositionLocalLevel[];
}

@Component({
  components: {
    Search,
    Preloader,
    CPositionLevelFactoryUec,
    CPositionLevelFactory,
    CPositionLevelStructure,
    CPositionLevelEditModal,
    CImportModal,
    CFileDownload,
    CStructure
  },
  data: () => {
    return {
      EImportType
    };
  }
})
export default class VPositionLevelTree extends Vue {
  protected showEditModal: boolean = false;

  protected preload: boolean = false;
  protected key: number = 0;

  protected search: string = this.$store.state.search;
  protected preload_infinite: boolean = false;
  protected searched_position_levels: PositionLocalLevel[] = [];
  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected editTreeLevel: PositionLocalLevel | null = null;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected factory: StructureFactory | null = null;

  protected factories: StructureFactory[] = [];
  protected exportFactory: StructureFactory | null = null;

  protected reportFactory: StructureFactory | null = null;
  protected reportStructure: IStructureProp = {};
  protected factory_id: string | null = null;
  protected factory_uec: boolean = false;

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.editTreeLevel,
        editTreeLevel => {
          this.editTreeLevel = editTreeLevel;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          if (this.search.length) {
            this.searchLevels();
          }
        }
      )
    );

    this.preload = true;

    this.loadFactories();

    if (!this.currentUser?.is_admin) {
      await this.loadFactory();
    }

    this.preload = false;
  }

  protected async changeReportFactory() {
    this.reportFactory = this.reportFactory ?? this.factories[0];
    this.factory_id = this.reportFactory?.id!;
    this.factory_uec = this.reportFactory?.uec!;

    this.reportStructure = {};
  }

  private async changeReportStructure(structure: IStructureProp) {
    this.reportStructure = structure;
  }

  protected refreshFactories() {
    if (!this.currentUser?.is_admin) {
      this.loadFactory();
    }
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.positions);
  }

  protected async loadFactory() {
    return this.$api
      .get(structureRoutes.me_factory)
      .then(({ data: res }: { data: StructureFactory }) => {
        this.factory = res;
      });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories, {
        params: {
          only_my: !this.currentUser?.is_admin
        }
      })
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories = res;
      });
  }

  protected async searchLevels(page: number = 0) {
    this.preload_infinite = true;

    if (page === 0) {
      this.searched_position_levels = [];
    }

    this.$api
      .get(positionRoutes.levels_tree_search, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          only_my: !this.currentUser?.is_admin,
          factory_id: this.factory?.id,
          search: this.search
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.searched_position_levels.push(...res.levels);
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  protected infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      this.searchLevels(this.current_page + 1);
    }
  }

  protected beforeCreateLevel() {
    if (!this.canWrite()) {
      return;
    }

    this.$store.commit("editTreeLevel", {
      workplace_id: null
    });
  }

  protected beforeUpdateLevel(index: number) {
    if (!this.canWrite()) {
      return;
    }

    const level = this.searched_position_levels[index];

    this.$store.commit("editTreeLevel", {
      workplace_id: level.workplace_id
    });
  }

  protected closeEditModal({
    workplace: editedLevel
  }: {
    workplace: PositionLocalLevelWorkplace;
  }) {
    this.$store.dispatch("editedTreeLevel", editedLevel);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-user-testing"}},[_c('div',{staticClass:"breadcrumbs"},[_c('router-link',{attrs:{"to":{ name: 'root' }}},[_vm._v("Главная")]),_c('i'),_c('router-link',{attrs:{"to":{ name: 'team_all' }}},[_vm._v("Моя Команда")]),_c('i'),_c('router-link',{attrs:{"to":{
        name: 'teammate',
        params: { user_id: _vm.currentAssignmentTesting.user_id }
      }}},[_vm._v("Участник")]),_c('i'),_c('span',[_vm._v(" "+_vm._s(_vm.currentAssignmentTesting.testing_version.name))])],1),_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preload),expression:"!preload"}]},[_c('div',{staticClass:"row align-items-center t-24 flex-none"},[_c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"font-22 font-bold"},[_vm._v(" "+_vm._s(_vm.currentAssignmentTesting.testing_version.name)+" ")])]),_c('div',{staticClass:"col-auto col-sm-auto"},[(_vm.currentAssignmentTesting.show_test)?_c('span',{staticClass:"timer_info"},[_vm._v(_vm._s(_vm._f("normalizedTimer")(_vm.timer)))]):(_vm.currentAssignmentTesting.show_result)?_c('span',{staticClass:"timer_info"},[_vm._v(_vm._s(_vm._f("normalizedTimer")(_vm.currentAssignmentTesting.learning_time)))]):_vm._e()])]),_c('div',{staticClass:"row align-items-center t-24 flex-none d-none"},[_c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"font-22 font-bold"},[_vm._v(" "+_vm._s(_vm.currentAssignmentTesting.testing_version.name)+" ")]),_c('div',[_vm._v(" Статус "+_vm._s(_vm._f("normalizedTestingAssignmentStatus")(_vm.currentAssignmentTesting.status))+" ")]),_c('div',[_vm._v(" время плохождения "+_vm._s(_vm._f("normalizedTimer")(_vm.currentAssignmentTesting.learning_time))+" ")]),_c('div',[_vm._v(" даты прохождения "+_vm._s(_vm.currentAssignmentTesting.real_starts_at)+" - "+_vm._s(_vm.currentAssignmentTesting.real_ends_at)+" ")]),_c('div',[_vm._v(" результат "+_vm._s(_vm.currentAssignmentTesting.completion_percent)+"% ")])])]),(_vm.currentAssignmentTesting.show_result)?[_c('div',{staticClass:"row big flex-none"},[_c('div',{staticClass:"col-12 col-sm-8"},[(
              _vm.currentAssignmentTesting.status ===
                _vm.ETestingAssignmentStatus.SUCCESS
            )?_c('div',{staticClass:"t-24 result-block result-block--success flex-none"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"font-22 font-uppercase font-superbold font-title font-white b-4"},[_vm._v(" Ваш результат - "+_vm._s(_vm.currentAssignmentTesting.completion_percent)+"% ")])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"result-block__icon"},[_c('inline-svg',{staticClass:"w-32 h-32 font-green",attrs:{"src":'/img/icon-check.svg'}})],1)])])]):_vm._e(),(
              _vm.currentAssignmentTesting.status ===
                _vm.ETestingAssignmentStatus.UN_SUCCESS
            )?_c('div',{staticClass:"t-24 result-block result-block--error flex-none"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"result-block__icon"},[_c('inline-svg',{staticClass:"w-32 h-32 font-red",attrs:{"src":'/img/icon-close-inherit.svg'}})],1)])])]):_vm._e(),(
              _vm.currentAssignmentTesting.status ===
                _vm.ETestingAssignmentStatus.ON_APPROVED
            )?_c('div',{staticClass:"t-24 result-block result-block--waiting flex-none"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"result-block__icon"},[_c('inline-svg',{staticClass:"w-32 h-32 font-lightgray",attrs:{"src":'/img/icon-time.svg'}})],1)])])]):_vm._e()])]),_c('div',{staticClass:"row big t-46 t-xs-24 flex-auto"},[_c('div',{staticClass:"col-12 col-sm-8 d-flex flex-column"},_vm._l((_vm.currentAssignmentTesting.assignment_questions),function(question){return _c('div',{key:`question_${question.question_id}`,staticClass:"b-16 answer-result-item"},[_c('p',{staticClass:"font-12 font-lightgray font-bold b-8"},[_vm._v(" Вопрос "+_vm._s(`${question.position} из ${_vm.currentAssignmentTesting.assignment_questions.length}`)+" ")]),(question.question_id)?_c('CTestingQuestionResult',{attrs:{"question_assignment":question}}):_vm._e()],1)}),0)])]:_vm._e()],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col"},[_c('p',{staticClass:"font-22 font-uppercase font-superbold font-title font-white b-4"},[_vm._v(" К сожалению, Вы не прошли тестирование. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col"},[_c('p',{staticClass:"font-22 font-uppercase font-superbold font-title font-black b-4"},[_vm._v(" Ожидает проверки. ")]),_c('p',{staticClass:"font-gray"},[_vm._v(" Дождитесь окончания проверки, чтобы оценить результат ")])])
}]

export { render, staticRenderFns }
export const actionOptions = [
  {
    label: "Установить уровень 0",
    value: 0
  },
  {
    label: "Установить уровень 1",
    value: 1
  },
  {
    label: "Установить уровень 2",
    value: 2
  },
  {
    label: "Установить уровень 3",
    value: 3
  },
  {
    label: "Установить уровень 4",
    value: 4
  },
  {
    label: "Без изменений",
    value: 5
  }
];

export default class AktTemplateAction {
  public readonly id!: string;

  public template_version_id!: string;
  public skill_id!: string;

  public success_to!: number;
  public un_success_to!: number;
}

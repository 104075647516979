
import { Component, Vue, Prop } from "vue-property-decorator";

import structure_routes from "@/api/routes/structures";

import Preloader from "@/components/Preloader.vue";
import CConstructorProgramsIndexContent from "@/views/constructor/programs/index/Content.vue";

import StructureInfinity from "@/models/structure/infinity";

@Component({
  name: "CInfinityConstructor",
  components: {
    Preloader,
    CConstructorProgramsIndexContent
  }
})
export default class CInfinityConstructor extends Vue {
  @Prop({ required: true }) public factory_id!: string;
  @Prop({ required: true }) public parent!: StructureInfinity;

  @Prop({ required: false }) public block_id?: string;
  @Prop({ required: false }) public category_id?: number;
  @Prop({ required: false }) public skill_id?: string;

  public preload: boolean = false;

  public infinities: StructureInfinity[] = [];

  private openTreeProgram: Map<string, boolean> = this.$store.state
    .openTreeProgram;

  protected watchers: Function[] = [];

  protected async created() {
    if (this.openTreeProgram.get(this.parent.id!)) {
      await this.showInfinity();
    }

    this.watchers.push(
      this.$store.watch(
        state => state.openTreeProgram,
        async openTreeProgram => {
          this.openTreeProgram = openTreeProgram;

          if (this.openTreeProgram.get(this.parent.id!)) {
            await this.showInfinity();
          }
        }
      )
    );
  }

  private async loadInfinities() {
    return this.$api
      .get(structure_routes.infinities, {
        params: { factory_id: this.factory_id, parent_id: this.parent?.id }
      })
      .then(({ data: res }: { data: StructureInfinity[] }) => {
        this.infinities = res;
      });
  }

  public async showInfinity() {
    this.$set(this.parent, "is_show", !this.parent.is_show);

    this.preload = true;

    if (this.parent.is_show) {
      this.openTreeProgram.set(this.parent.id!, true);
    } else {
      this.openTreeProgram.delete(this.parent.id!);
    }

    this.$store.commit("openTreeProgram", this.openTreeProgram);

    await Promise.all([this.loadInfinities()]);

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

import "reflect-metadata";

import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";

import TaskFile from "@/models/task/file";
import { Type } from "class-transformer";
import { ETaskTabs } from "@/enums/task_tabs";
import UserUser from "@/models/user/user";
import TaskCommentFile from "@/models/task/commentFile";

export enum ETaskStatus {
  WORK = 1, // В работе
  VERIFICATION = 2, // На проверке
  FAILED = 3, // Не успешно
  DONE = 4 // Успешно
}

export enum ETaskCategory {
  INDIVIDUAL = 1, // Индивидуальное задание
  PRACTICAL = 2, // Практическое задание
  EXTERNAL = 3 // Внешнее обучение
}

export const taskCategories = [
  {
    label: "Индивидуальное задание",
    value: ETaskCategory.INDIVIDUAL
  },
  {
    label: "Практическое задание",
    value: ETaskCategory.PRACTICAL
  },
  {
    label: "Внешнее обучение",
    value: ETaskCategory.EXTERNAL
  }
];

export const taskStatuses = [
  {
    label: "В работе",
    value: ETaskStatus.WORK
  },
  {
    label: "На проверке",
    value: ETaskStatus.VERIFICATION
  },
  {
    label: "Не успешно",
    value: ETaskStatus.FAILED
  },
  {
    label: "Успешно",
    value: ETaskStatus.DONE
  }
];

export const taskIndividualTabs = [
  {
    label: "Настройки задания",
    value: ETaskTabs.SETTINGS
  },
  {
    label: "Материалы к заданию",
    value: ETaskTabs.FILES
  }
];

export const taskGroupTabs = [
  {
    label: "Кому назначить",
    value: ETaskTabs.USERS
  },
  {
    label: "Настройки задания",
    value: ETaskTabs.SETTINGS
  },
  {
    label: "Материалы к заданию",
    value: ETaskTabs.FILES
  }
];

export default class TaskTask {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public user_id!: string;

  @IsOptional()
  @IsUUID("4")
  public parent_id!: string | null;

  @IsString()
  public description!: string;

  @IsOptional()
  @IsString()
  public readonly user_name?: string;

  @IsOptional()
  @IsString()
  public comment!: string | null;

  @IsString()
  public student_comment: string = "";

  @IsString()
  public target: string = "";

  @IsEnum(ETaskCategory)
  public category: ETaskCategory = ETaskCategory.INDIVIDUAL;

  @IsEnum(ETaskStatus)
  public status: ETaskStatus = ETaskStatus.WORK;

  @IsDate()
  public ends_at: Date = new Date();

  @IsBoolean()
  // Назначить программу обучения
  public can_assignment: boolean = false;

  @IsBoolean()
  // Не изменять уровень навыка
  public cant_change_skill_level: boolean = false;

  @IsBoolean()
  public is_supervisor_responsible: boolean = false;

  @IsBoolean()
  public mandatory_file_upload: boolean = false;

  @Type(() => TaskFile)
  public files: TaskFile[] = [];

  @Type(() => TaskCommentFile)
  public comment_files: TaskCommentFile[] = [];

  @Type(() => TaskCommentFile)
  public student_comment_files: TaskCommentFile[] = [];

  @Type(() => UserUser)
  public responsible: UserUser | null = null;

  public skills: {
    [skill_id: string]: {
      id: string;
      full_name?: string;
      negative_level: number;
      positive_level: number;
    };
  } = {};

  // ! Используется только для создания группового назначения
  public user_ids: string[] = [];

  public get inWork() {
    return this.status === ETaskStatus.WORK;
  }

  public get inVerification() {
    return this.status === ETaskStatus.VERIFICATION;
  }

  public get inEnd() {
    return (
      this.status === ETaskStatus.DONE || this.status === ETaskStatus.FAILED
    );
  }

  public get isFailed() {
    return this.status === ETaskStatus.FAILED;
  }
}

import "reflect-metadata";
import { Type } from "class-transformer";

import KpiLevel from "./level";

export default class KpiCompany {
  public id?: string;
  public template_id?: string;

  public title?: string;

  public is_archived: boolean = false;

  public is_show: boolean = false;

  constructor(template_id: string) {
    this.template_id = template_id;
  }

  @Type(() => KpiLevel)
  public levels: KpiLevel[] = [];

  public toggleShow() {
    this.is_show = !this.is_show;
  }
}

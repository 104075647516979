
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";

import positionRoutes from "@/api/routes/positions";

import StructureFactory from "@/models/structure/factory";
import PositionLocal from "@/models/position/local";
import PositionOrigin from "@/models/position/origin";

import { EPermission } from "@/enums/permissions";
import BlockBlock, { IBlockSkill } from "@/models/block/block";
import CSkillRadio from "@/components/SkillRadio.vue";
import skillRoutes from "@/api/routes/skills";
import CLevelSelector from "../../../components/LevelSelector.vue";
import {
  updateSkillCheckboxesState,
  onSelectAllClicked,
  defaultBlocks
} from "../../../helpers/update_skill_checkboxes_state";
import { EThreeStateCheckbox } from "../../../enums/three_state_checkbox";
import { onDisplayZeroLevelSkillsChanged } from "../../../helpers/matrix_helper";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly positions: PositionLocal[];
}

interface IDataSkillCategory {
  id?: string;
  value: string;
}

@Component({
  components: {
    Preloader,
    CSkillRadio,
    CLevelSelector
  },
  methods: {
    updateSkillCheckboxesState,
    onSelectAllClicked,
    onDisplayZeroLevelSkillsChanged
  },
  data: () => {
    return {
      defaultBlocks,
      EThreeStateCheckbox
    };
  }
})
export default class CPosition extends Vue {
  @Prop({ required: true }) protected factory!: StructureFactory;
  @Prop({ required: true }) protected origins!: PositionOrigin[];
  @Prop({ required: true }) protected currentUser!: IJWTUser | null;

  protected currentFactoryId?: string;

  protected preload: boolean = false;
  protected modalLoader: boolean = false;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;
  protected preload_infinite: boolean = false;

  protected positions: PositionLocal[] = [];

  private search: string = this.$store.state.search;

  private watchers: Function[] = [];

  private destroyPositionModal: boolean = false;

  private toggleSkillBlocks: boolean = false;

  protected searchSkills: string = "";

  protected editPosition = new PositionLocal();

  private currentBlockFilter: BlockBlock = defaultBlocks[0];
  protected blocksFilter: BlockBlock[] | null = null;
  protected blocks: BlockBlock[] = [];
  protected editPositionIndex: number | null = null;
  protected editPositionErrors: string | string[] | null = null;
  protected editPositionOrigin:
    | PositionOrigin
    | null
    | undefined = new PositionOrigin();

  protected skillCategoriesFilter: IDataSkillCategory[] = [{ value: "Все" }];
  protected skillCategoryFilter: IDataSkillCategory = this
    .skillCategoriesFilter[0];

  /** Состояние чекбокса выбора всех навыков */
  private selectAllSkillsCheckboxState: EThreeStateCheckbox =
    EThreeStateCheckbox.UNCHECK;
  /** Показать навыки с целевым уровнем "0" */
  private displayZeroLevelSkills = false;

  $refs!: {
    blocks: HTMLFormElement[];
  };

  protected async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadPositions(this.current_page + 1);
    }
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.loadPositions();
        }
      )
    );

    if (this.factory) {
      this.currentFactoryId = this.factory.id!;

      await Promise.all([this.loadPositions(), this.loadSkillCategories()]);
    }
  }

  protected async loadSkillCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: IDataSkillCategory[] }) => {
        this.skillCategoriesFilter.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadPositionSkills(position_id: string) {
    return this.$api
      .get(positionRoutes.position_skills(position_id), {
        params: {
          factory_id: this.currentFactoryId,
          origin_id: this.editPositionOrigin!.id,
          search: this.searchSkills,
          category_id: this.skillCategoryFilter?.id,
          block_id: this.currentBlockFilter.id
        }
      })
      .then(({ data: res }: { data: BlockBlock[] }) => {
        this.blocks = plainToClass(BlockBlock, res);

        if (this.blocksFilter === null) {
          this.blocksFilter = plainToClass(BlockBlock, res);
        } else {
          this.selectAllSkillsCheckboxState = updateSkillCheckboxesState(
            this.$refs,
            this.blocks,
            this.selectAllSkillsCheckboxState
          );
        }

        this.blocks = onDisplayZeroLevelSkillsChanged(
          this.blocks || [],
          this.displayZeroLevelSkills
        );
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  @Watch("factory")
  protected async changeFactory() {
    if (this.currentFactoryId !== this.factory.id) {
      this.currentFactoryId = this.factory!.id!;

      await this.loadPositions();
    }
  }

  protected async changeSkillFilter() {
    await this.loadPositionSkills(this.editPosition.id! || "new");
  }

  protected changeLevel(
    skill: IBlockSkill,
    blockIndex: number,
    skillIndex: number
  ) {
    this.$set(this.blocks![blockIndex]!.skills!, skillIndex, skill);
  }

  protected async loadPositions(page: number = 0) {
    this.preload_infinite = true;

    if (page === 0) {
      this.preload = true;

      this.positions = [];
    }

    return this.$api
      .get(positionRoutes.positions, {
        params: {
          factory_id: this.factory!.id,
          page: page,
          per_page: this.per_page,
          paginate: true,
          search: this.search
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.next_page = res.next_page;
        this.current_page = res.current_page;
        this.positions.push(...res.positions);
      })
      .finally(() => {
        this.preload = false;
        this.preload_infinite = false;
      });
  }

  protected async loadPosition(id: string, fullData: boolean = true) {
    this.currentBlockFilter = defaultBlocks[0];
    this.skillCategoryFilter = this.skillCategoriesFilter[0];

    return this.$api
      .get(positionRoutes.position(id), {
        params: {
          origin_id: this.editPositionOrigin!.id,
          factory_id: this.factory!.id
        }
      })
      .then(({ data: res }: { data: PositionLocal }) => {
        if (fullData) {
          this.editPosition = res;
        }
        this.loadPositionSkills(id);
      });
  }

  protected async changeOrigin() {
    this.editPosition.origin_id = this.editPositionOrigin!.id!;

    await this.loadPosition("new", false);
  }

  protected findOrigin(id: string) {
    return this.origins!.find(o => o.id === id);
  }

  protected showModal() {
    this.displayZeroLevelSkills = false;
    this.$modal.show("editPosition");
  }

  protected hideModal() {
    this.$modal.hide("editPosition");
  }

  protected async beforeCreatePosition() {
    this.editPositionOrigin = this.origins![0];
    this.editPosition = new PositionLocal();

    await this.loadPosition("new");

    this.editPositionIndex = null;
    this.editPositionErrors = null;

    this.showModal();
  }

  protected async createPosition(hide: boolean = true) {
    this.editPosition.origin_id = this.editPositionOrigin!.id!;
    this.editPosition.blocks = this.blocks;
    this.editPosition.name = this.editPosition.name?.trim();
    this.modalLoader = true;

    return this.$api
      .post(positionRoutes.positions, this.editPosition)
      .then(({ data: res }: { data: PositionLocal }) => {
        this.editPosition = plainToClass(PositionLocal, res);

        this.positions.unshift(this.editPosition);

        if (hide) {
          this.hideModal();
        }
      })
      .catch(({ response: res }) => {
        this.editPositionErrors = res.data.error;
      })
      .finally(() => {
        this.modalLoader = false;
      });
  }

  protected async clonePosition(position: PositionLocal) {
    this.editPositionOrigin = position.origin_id
      ? this.findOrigin(position.origin_id)
      : this.origins![0];
    await this.loadPosition(position.id!);
    this.editPosition.id = undefined;

    this.editPositionIndex = null;
    this.editPositionErrors = null;
    this.showModal();
  }

  protected async beforeUpdatePosition(id: string, index: number) {
    if (!this.canWrite()) {
      return;
    }
    this.editPositionOrigin = this.findOrigin(this.positions[index].origin_id!);

    await this.loadPosition(id);

    this.editPositionIndex = index;
    this.editPositionErrors = null;

    this.showModal();
  }

  protected beforeCloseModal() {
    this.searchSkills = "";
  }

  protected async updatePosition(hide: boolean = true) {
    this.editPosition.blocks = this.blocks;
    this.modalLoader = true;
    this.editPosition.name = this.editPosition.name?.trim();

    await this.$api
      .put(positionRoutes.position(this.editPosition.id!), this.editPosition)
      .then(({ data: res }: { data: PositionLocal }) => {
        this.editPosition = plainToClass(PositionLocal, res);

        this.$set(this.positions, this.editPositionIndex!, this.editPosition);

        if (hide) {
          this.hideModal();
        }
      })
      .catch(({ response: res }) => {
        this.editPositionErrors = res.data.error;
      })
      .finally(() => {
        this.modalLoader = false;
      });
  }

  protected async destroyPosition() {
    return this.$api
      .destroy(positionRoutes.position(this.editPosition.id!))
      .then(() => {
        this.$delete(this.positions, this.editPositionIndex!);
        this.$modal.hide("destroyPositionModal");
        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.editPositionErrors = res.data.error;
      });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.positions);
  }
}


import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import testings_routes from "@/api/routes/testings";

import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import { EPermission } from "@/enums/permissions";
import TestingTesting from "@/models/testing/testing";
import TestingTestingVersion from "@/models/testing/testing_version";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface IDataTesting extends IData {
  readonly testings: TestingTesting[];
}

@Component({
  name: "VAllTestingCheck",
  components: {
    Preloader,
    CInfiniteScroll
  }
})
export default class VAllTestingCheck extends Vue {
  public preload: boolean = false;
  private infinite_preload: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  public testings: TestingTesting[] = [];

  protected async created() {
    this.preload = true;

    await this.loadTestingCheck();

    this.preload = false;
  }

  public async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadTestingCheck(this.current_page + 1);
    }
  }

  public showQuestions(version: TestingTestingVersion) {
    this.$set(version, "is_show", !version.is_show);
  }

  private async loadTestingCheck(page: number = 0) {
    this.infinite_preload = true;

    return this.$api
      .get(testings_routes.checks, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataTesting }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.testings.push(...plainToClass(TestingTesting, res.testings));
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testing_checks);
  }
}

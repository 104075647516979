
import { Component, Prop, Vue } from "vue-property-decorator";
import UserUser from "@/models/user/user";

import userRoutes from "@/api/routes/users";

import Preloader from "@/components/Preloader.vue";
import CUserEdit from "@/components/user/UserEdit.vue";
import CCalendar from "@/components/calendar/Calendar.vue";

import { EDeviceTypes } from "@/enums/deviceTypes";

import CTeamPriority from "./components/Priority.vue";
import CTeamAkt from "./components/Akt.vue";
import CTeamStudentApps from "./components/StudentApplications.vue";
import CTeamLearningPrograms from "./components/LearningPrograms.vue";
import CTeamTesting from "@/views/team/components/testing/Testing.vue";
import CTasks from "@/views/team/components/Tasks.vue";
import CEducations from "@/components/user/Educations.vue";
import { plainToClass } from "class-transformer";

@Component({
  components: {
    Preloader,
    CUserEdit,
    CTeamPriority,
    CTeamAkt,
    CCalendar,
    CTeamStudentApps,
    CTeamLearningPrograms,
    CTasks,
    CTeamTesting,
    CEducations
  },
  data: () => {
    return {
      EDeviceTypes
    };
  }
})
export default class Teammate extends Vue {
  @Prop({ required: true }) protected user_id!: string;

  protected preload: boolean = false;
  protected showModal: boolean = false;

  protected teammate: UserUser | null = null;

  public tabs: { id: string; value: string; badge: number }[] = [
    { id: "programs", value: "Программы обучения", badge: 0 },
    { id: "task", value: "Практические/Индивидуальные задания", badge: 0 },
    { id: "education", value: "Внешнее обучение", badge: 0 },
    { id: "priority", value: "Приоритеты", badge: 0 },
    { id: "calendar", value: "Календарь обучения", badge: 0 },
    { id: "skills", value: "Навыки", badge: 0 },
    { id: "act", value: "Назначение ЕПЗ", badge: 0 },
    { id: "testing", value: "Тесты", badge: 0 }
  ];
  public currentTab = this.tabs[0];

  $refs!: {
    tabs: HTMLDivElement;
  };

  protected async created() {
    this.preload = true;

    await Promise.all([this.loadUnconfirmedEvents(), this.loadUser()]);
  }

  protected async loadUser() {
    return this.$api
      .get(userRoutes.user(this.user_id))
      .then(({ data: res }: { data: UserUser }) => {
        this.teammate = plainToClass(UserUser, res);

        this.preload = false;
      });
  }

  protected async loadUnconfirmedEvents() {
    return this.$api
      .get(userRoutes.unconfirmedEvents(this.user_id))
      .then(({ data: res }: { data: { count: number } }) => {
        this.tabs.find(i => i.id === "calendar")!.badge = res.count;
      });
  }

  protected closeModal(data: { user: UserUser }) {
    this.teammate = data.user;
    this.showModal = false;
  }

  public changeTab(newTab: { id: string; value: string }) {
    const category = this.tabs.find(c => c.id === newTab.id);

    if (category) this.currentTab = category;
  }

  public tabScrollRight() {
    this.$refs.tabs.scrollLeft += 100;
  }

  public tabScrollLeft() {
    this.$refs.tabs.scrollLeft -= 100;
  }

  protected openModal() {
    this.showModal = true;
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import structure_routes from "@/api/routes/structures";

import Preloader from "@/components/Preloader.vue";
import CEquipmentConstructor from "@/views/constructor/programs/index/Equipment.vue";
import CConstructorProgramsIndexContent from "@/views/constructor/programs/index/Content.vue";

import StructureWorkline from "@/models/structure/workline";
import StructureEquipment from "@/models/structure/equipment";

@Component({
  components: {
    Preloader,
    CEquipmentConstructor,
    CConstructorProgramsIndexContent
  }
})
export default class CWorklineConstructor extends Vue {
  @Prop({ required: true }) public factory_id!: string;
  @Prop({ required: true }) public workshop_id!: string;
  @Prop({ required: true }) public workline!: StructureWorkline;

  public preload: boolean = false;

  public equipments: StructureEquipment[] = [];

  private openTreeProgram: Map<string, boolean> = this.$store.state
    .openTreeProgram;

  protected watchers: Function[] = [];

  protected async created() {
    if (this.openTreeProgram.get(this.workline.id!)) {
      await this.showWorkline();
    }

    this.watchers.push(
      this.$store.watch(
        state => state.openTreeProgram,
        async openTreeProgram => {
          this.openTreeProgram = openTreeProgram;

          if (this.openTreeProgram.get(this.workline.id!)) {
            await this.showWorkline();
          }
        }
      )
    );
  }

  private async loadEquipments() {
    return this.$api
      .get(structure_routes.equipments, {
        params: { workline_id: this.workline.id }
      })
      .then(({ data: res }: { data: StructureEquipment[] }) => {
        this.equipments = res;
      });
  }

  public async showWorkline() {
    this.$set(this.workline, "is_show", !this.workline.is_show);
    this.preload = true;

    if (this.workline.is_show) {
      this.openTreeProgram.set(this.workline.id!, true);
    } else {
      this.openTreeProgram.delete(this.workline.id!);
    }

    this.$store.commit("openTreeProgram", this.openTreeProgram);

    await Promise.all([this.loadEquipments()]);

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

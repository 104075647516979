import { IsOptional, IsString, IsUUID } from "class-validator";

import { ILevel } from "./level";

export default class PositionLocalLevelWorkplace {
  @IsUUID("4")
  public id!: string;

  @IsUUID("4")
  public factory_id!: string;

  @IsOptional()
  @IsString()
  public factory_id_error?: string | null;

  @IsString()
  public structure_name!: string;

  @IsOptional()
  @IsUUID("4")
  public workshop_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public workline_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public equipment_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public infinity_id?: string | null;

  @IsOptional()
  @IsString()
  public levels_error?: string | null;

  public levels: ILevel[] = [];
  public deletedLevels: string[] = [];

  public get structure() {
    return {
      factory_id: this.factory_id,
      workshop_id: this.workshop_id,
      workline_id: this.workline_id,
      equipment_id: this.equipment_id,
      infinity_id: this.infinity_id
    };
  }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { plainToClass } from "class-transformer";
import { isUUID } from "class-validator";

import notifications_routes from "@/api/routes/notifications";

import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import CTestingsApproval from "@/views/testings/components/TestingApproval.vue";
import CMentorSelect from "@/views/subscriptions/components/MentorSelect.vue";
import CConfirmationEducation from "@/views/constructor/programs/components/CConfirmationEducation.vue";
import CDelegateConfirmationEducation from "@/views/constructor/programs/components/DelegateConfirmationEducation.vue";
import CSummaryContent from "./components/Summary.vue";
import CQuestionApproval from "./components/QuestionApproval.vue";
import CProgramApproval from "./components/ProgramApproval.vue";
import CProgramApprovalDelegation from "./components/ProgramApprovalDelegation.vue";
import CProgramAgreementHistory from "./components/ProgramAgreementHistory.vue";

import NotificationNotification, {
  ENotificationReactionType,
  ENotificationCode
} from "@/models/notification/notification";

import CNotificationsFilters, { IFilters } from "./components/Filters.vue";

interface IData {
  readonly current_page: number;
  readonly notifications: NotificationNotification[];
  readonly next_page: boolean;
}

@Component({
  name: "VNotifications",
  components: {
    Preloader,
    CInfiniteScroll,
    CNotificationsFilters,
    CTestingsApproval,
    CMentorSelect,
    CSummaryContent,
    CQuestionApproval,
    CConfirmationEducation,
    CDelegateConfirmationEducation,
    CProgramApproval,
    CProgramApprovalDelegation,
    CProgramAgreementHistory
  }
})
export default class VNotifications extends Vue {
  @Prop({ required: false, default: null }) private notification_id!:
    | string
    | null;
  public target_notification_id: string | null = this.notification_id;

  public ENotificationCode = ENotificationCode;

  private watchers: Function[] = [];

  public tabs: string[] = ["Новые", "Все уведомления"];
  public current_tab_idx: number = 0;
  public only_not_visited: boolean = true;

  public preload: boolean = false;
  private infinite_preload: boolean = false;

  public notifications: NotificationNotification[] = [];

  public edit_notification: NotificationNotification | null = null;
  public edit_notification_index: number | null = null;

  private per_page: number = 25;
  private current_page: number = 0;
  private next_page: boolean = false;

  public filters: IFilters = {
    group_id: null,
    range_date: []
  };

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    if (
      !this.target_notification_id &&
      this.$route.query.notification_id &&
      isUUID(this.$route.query.notification_id)
    ) {
      this.target_notification_id = this.$route.query.notification_id as string;
    }

    await Promise.all([this.loadNotification(), this.clearNotifications()]);
  }

  public async applyFilter(filters: IFilters) {
    this.filters = filters;

    await this.clearNotifications();
  }

  public async changeTab(tab_idx: number) {
    if (this.current_tab_idx !== tab_idx) {
      this.current_tab_idx = tab_idx;

      this.only_not_visited = this.current_tab_idx === 0;

      await this.clearNotifications();
    }
  }

  private async clearNotifications() {
    this.preload = true;

    this.notifications = [];
    await this.loadNotifications();

    this.preload = false;
  }

  private async loadNotifications(page: number = 0) {
    this.infinite_preload = true;

    const start_date: Date | null =
      this.filters.range_date[0] != null
        ? new Date(this.filters.range_date[0])
        : new Date("2020-01-01");

    const end_date: Date =
      this.filters.range_date[1] != null
        ? new Date(this.filters.range_date[1])
        : new Date();

    end_date.setHours(23);
    end_date.setMinutes(59);
    end_date.setSeconds(59);
    end_date.setMilliseconds(999);

    return this.$api
      .get(notifications_routes.notifications, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          only_not_visited: this.only_not_visited,
          start_date,
          end_date,
          group_id: this.filters.group_id
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.notifications.push(
          ...plainToClass(NotificationNotification, res.notifications)
        );

        this.current_page = res.current_page;
        this.next_page = res.next_page;
      })
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  public async loadNotification(index: number | null = null) {
    this.edit_notification = null;
    this.edit_notification_index = null;

    let target_id: string | null = null;

    if (index != null) {
      const notification = this.notifications[index];

      target_id = notification.id;
    } else if (this.target_notification_id) {
      target_id = this.target_notification_id;
    }

    if (!target_id) {
      return;
    }

    return this.$api
      .get(notifications_routes.notification(target_id))
      .then(({ data: res }: { data: NotificationNotification }) => {
        this.edit_notification = plainToClass(NotificationNotification, res);
        this.edit_notification_index = index;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });

        if (index != null) {
          this.$delete(this.notifications, index);
        }
      });
  }

  @Watch("edit_notification")
  protected async processingEditNotification() {
    if (!this.edit_notification) {
      return;
    }

    if (
      this.edit_notification.reaction_type === ENotificationReactionType.PASSIVE
    ) {
      return await this.setVisited();
    }

    switch (this.edit_notification.code) {
      case ENotificationCode.SUBSCRIPTION_FOR_LEADER:
      case ENotificationCode.TESTING_SEND_TO_APPROVAL:
      case ENotificationCode.PROGRAM_SEND_TO_APPROVAL:
      case ENotificationCode.PROGRAM_APPROVAL_DELEGATED:
      case ENotificationCode.PROGRAM_APPROVED:
      case ENotificationCode.PROGRAM_REJECTED:
      case ENotificationCode.SUMMARY:
        return this.showModal();
      case ENotificationCode.AKT_REMIND_REVIEWER:
        await this.setVisited();

        return this.$router.push({ name: "akt_passed_testing" });
      case ENotificationCode.ASSIGNED_TESTING:
      case ENotificationCode.ASSIGNED_TESTING_ON_TIME:
        await this.setVisited();

        return this.$router.push({ name: "me_testings" });
      case ENotificationCode.TESTING_REVIEW_CHECK_RESULT:
        await this.setVisited();

        return this.$router.push({
          name: "user_pass_testing",
          params: { id: this.edit_notification.targetable_id }
        });
      case ENotificationCode.EMPLOYEE_PASSED_TESTING_CHECK_RESULT:
        await this.setVisited();

        return this.$router.push({
          name: "testing_result",
          params: {
            id: this.edit_notification.targetable_id,
            user_id: this.edit_notification.target_user_id!
          }
        });
      case ENotificationCode.QUESTION_SEND_TO_REVIEW:
        await this.setVisited();

        return this.$router.push({
          name: "question",
          params: { question_id: this.edit_notification.targetable_id }
        });
      case ENotificationCode.EMPLOYEE_OFFERS_TRAINING_TIME:
      case ENotificationCode.RESCHEDULE_OF_TRAINING:
        await this.setVisited();

        return this.$router.push({ name: "calendar" });
      case ENotificationCode.TO_VERIFICATION_TASK:
        await this.setVisited();

        return this.$router.push({
          name: "verify_task",
          params: {
            task_id: this.edit_notification.targetable_id
          }
        });
      default:
        break;
    }

    switch (this.edit_notification.targetable_type) {
      case "Akt::QuestionVersion":
      case "Subscription::Inspector":
        return this.showModal();
      case "Akt::Assignment":
        await this.setVisited();

        return this.$router.push({ name: "team_all" });
      case "Akt::AssignmentUser":
        await this.setVisited();

        return this.$router.push({ name: "me_akt" });
      case "Subscription::Program":
        await this.setVisited();

        return this.$router.push({
          name: "subscription_program",
          params: { id: this.edit_notification.targetable_id }
        });
      case "Application::Application":
        await this.setVisited();

        return this.$router.push({
          name: "training_applications",
          params: { id: this.edit_notification.targetable_id }
        });
      case "Task::Task":
        await this.setVisited();

        return this.$router.push({
          name: "examination_task",
          params: { task_id: this.edit_notification.targetable_id }
        });
      default:
        break;
    }
  }

  public showDelegateModal() {
    this.$modal.show("delegate_notification_modal");
  }

  public hideDelegateModal() {
    this.$modal.hide("delegate_notification_modal");

    this.hideModal();
  }

  public showModal() {
    this.$modal.show("notification_modal");
  }

  public hideModal() {
    this.$modal.hide("notification_modal");

    this.edit_notification = null;
    this.edit_notification_index = null;
  }

  private async setVisited() {
    if (!this.edit_notification || this.edit_notification.visited) {
      return;
    }

    return this.$api
      .post(notifications_routes.set_visited(this.edit_notification.id), {})
      .then(({ data: res }: { data: { count: number } }) => {
        if (this.edit_notification_index != null) {
          if (this.current_tab_idx === 0) {
            this.$delete(this.notifications, this.edit_notification_index);
          } else {
            this.$set(
              this.notifications[this.edit_notification_index],
              "visited",
              true
            );
          }
        }

        this.$store.commit("countNotification", res.count);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async confirmationEducation() {
    await this.setVisited();

    this.hideModal();
  }

  public async delegateConfirmationEducation() {
    await this.setVisited();

    this.hideDelegateModal();
  }

  public async delegateProgramApproval() {
    if (this.edit_notification_index != null) {
      this.$delete(this.notifications, this.edit_notification_index);
    }

    this.hideDelegateModal();
  }

  public async testingsApproval(set_visited: boolean) {
    if (set_visited) {
      await this.setVisited();
    } else {
      if (this.edit_notification_index != null) {
        this.$delete(this.notifications, this.edit_notification_index);
      }
    }

    this.hideModal();
  }

  public async questionApproval() {
    await this.setVisited();

    this.hideModal();
  }

  public async mentorSelect() {
    await this.setVisited();

    this.hideModal();
  }

  public async programApproval(program_id: string) {
    await this.setVisited();

    this.$router.push({
      name: "constructor_programs_edit",
      params: { id: program_id }
    });
  }

  public async summaryContent() {
    await this.setVisited();

    this.$router.push({ name: "team" });
  }

  public async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadNotifications(this.current_page + 1);
    }
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { Route } from "vue-router";

import aktRoutes from "@/api/routes/akt";

import Preloader from "@/components/Preloader.vue";
import CPrint from "@/components/Print.vue";

import CUserAktTestingSubscription from "../../user/akt_testing/components/Subscription.vue";

import { IAktTest, IAktTestSub } from "@/models/akt/assignment_subscription";
import { EAktType } from "@/models/akt/question_version";
import { EPermission } from "@/enums/permissions";

@Component({
  components: {
    Preloader,
    CUserAktTestingSubscription,
    CPrint
  }
})
export default class VAktTestingTask extends Vue {
  @Prop({ required: true }) id!: string;

  private preload: boolean = false;
  private testInfo: IAktTest | null = null;

  private allSubs: IAktTestSub[] = [];
  private preliminarySubs: IAktTestSub[] = [];
  private mainSubs: IAktTestSub[] = [];
  private subCount: number = 0;

  private created() {
    this.loadTest();
  }

  private loadTest() {
    this.preload = true;

    return this.$api
      .get(aktRoutes.get_workplace_testing(this.id))
      .then(({ data: res }: { data: IAktTest }) => {
        this.testInfo = res;

        res.subscriptions.forEach(sub => {
          if (sub.akt_type_id === EAktType.PRELIMINARY) {
            this.preliminarySubs.push(sub);
          } else {
            this.mainSubs.push(sub);
          }
        });

        this.allSubs = res.subscriptions;
        this.subCount = this.allSubs.length;
      })
      .finally(() => {
        this.preload = false;
      });
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { getEventColor, IEvent } from "@/helpers/calendar_helper";

@Component({
  methods: { getEventColor }
})
export default class CDashboardCalendarItem extends Vue {
  @Prop({ required: true }) public events!: IEvent[];
  public week_days: {
    name: string;
    day: number;
    active: boolean;
    today: boolean;
  }[] = [];

  protected created() {
    this.setWeekDays();
  }

  public setWeekDays() {
    const today = new Date();
    const week_day = (today.getDay() + 6) % 7;
    const week_start = new Date(today);
    week_start.setDate(today.getDate() - week_day);

    this.week_days = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].reduce<
      Array<{
        name: string;
        day: number;
        active: boolean;
        today: boolean;
      }>
    >((acc, name, index) => {
      const current_date = new Date(week_start);
      current_date.setDate(week_start.getDate() + index);
      const active = current_date >= today;
      const is_today = today.getDay() === current_date.getDay();

      acc.push({ name, day: current_date.getDate(), today: is_today, active });

      return acc;
    }, []);
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import { phases } from "@/consts";

import Upload from "@/components/Upload.vue";
import ImageView from "@/components/ImageView.vue";

import RelationVersion from "@/models/relation/version";
import UnitVersion, { EUnitableType } from "@/models/unit/unit_version";
import TestVersion from "@/models/unit/unitable/test/version";
import { questionTypes } from "@/models/unit/unitable/test/question";
import TestQuestionVersion from "@/models/unit/unitable/test/question_version";
import TestAnswerVersion from "@/models/unit/unitable/test/answer_version";

import unitRoutes from "@/api/routes/units";

interface IQuestionType {
  key: string;
  value: number;
}

interface IReanswer {
  label: string;
  value: number | null;
}

@Component({
  components: {
    Upload,
    ImageView
  },
  data: () => {
    return {
      questionTypes
    };
  }
})
export default class ConstructorProgramsMaterialsTest extends Vue {
  @Prop({ required: true }) program_id!: string;
  @Prop({ required: true }) relation!: RelationVersion;

  @Prop({ required: false, default: false }) public disabled!: boolean;

  protected phases = phases.slice(1, 5);

  private acceptFiles: string[] = ["image/png", "image/jpeg"];

  private unit_version: UnitVersion | null = null;
  private editErrors: string | null = null;

  private newQuestion: TestQuestionVersion = new TestQuestionVersion();

  private question_text: string[] = [];

  private created() {
    if (this.relation.id) {
      this.$api
        .get(unitRoutes.version(this.relation.unit_version_id))
        .then(({ data: res }: { data: UnitVersion }) => {
          this.unit_version = res;
        });
    } else {
      this.unit_version = new UnitVersion({
        relation_version: this.relation,
        unitable: new TestVersion(),
        unitable_type: EUnitableType["Test::Version"]
      });

      this.unit_version!.title = "Тест";
    }
  }

  private addQuestion(event: any) {
    if (event.target.value.length) {
      (this.unit_version!.unitable as TestVersion).question_versions.push(
        new TestQuestionVersion({ title: event.target.value })
      );
      event.target.blur();
      event.target.composing = true;
      event.target.value = "";
    }
  }

  private addAnswer(q_text: string, qv: TestQuestionVersion, qv_index: number) {
    if (q_text !== "") {
      qv.answer_versions.push(new TestAnswerVersion({ value: q_text }));

      this.question_text[qv_index] = "";
    }
  }

  private removeAnswer(event: any, qv: TestQuestionVersion, av_index: number) {
    if (!event.target.value.length) {
      this.$delete(qv.answer_versions, av_index);
    }
  }

  private setImage(url: string, qv: TestQuestionVersion) {
    if (url.length) {
      qv.image_url = url;
    }
  }

  private changeQuestionType(qv: TestQuestionVersion) {
    qv.answer_versions.forEach(av => {
      av.answer.is_correct = false;
    });
  }

  private selectCorrectAnswer(qv: TestQuestionVersion, av_index: number) {
    if (qv.question.type === "SINGLE") {
      qv.answer_versions.forEach((av, index) => {
        if (index !== av_index) {
          av.answer.is_correct = false;
        }
      });
    }
  }

  private createTest() {
    this.$api
      .post(unitRoutes.versions, {
        ...this.unit_version,
        ...{ relation: { program_id: this.program_id } }
      })
      .then(({ data: res }: { data: RelationVersion }) => {
        this.$emit("relationCreated", res);
      })
      .catch(({ response: res }) => {
        this.editErrors = res.data.error;
      });
  }

  private updateTest() {
    if (
      !window.confirm(
        "Вы уверены, что хотите сохранить новую редакцию материала?"
      )
    ) {
      return;
    }

    this.$api
      .put(unitRoutes.version(this.unit_version!.id), {
        ...this.unit_version,
        ...{
          relation_version: { id: this.relation.id, level: this.relation.level }
        }
      })
      .then(({ data: res }: { data: RelationVersion }) => {
        this.$emit("relationUpdated", res);
      })
      .catch(({ response: res }) => {
        this.editErrors = res.data.error;
      });
  }

  private deleteQuestion(qv_index: number) {
    this.$delete(
      (this.unit_version!.unitable as TestVersion).question_versions,
      qv_index
    );
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-period-date-picker"},[_c('div',{staticClass:"input input--fake input-datepicker"},[_c('span',{staticClass:"font-pointer",on:{"click":_vm.togglePicker}},[_vm._v(" "+_vm._s(_vm.string_date)+" ")]),(_vm.header)?_c('label',[_vm._v(_vm._s(_vm.header))]):_vm._e()]),_c('div',{staticClass:"datepicker-period",class:{
      open: _vm.show_date_picker,
      left: _vm.position === 'left',
      right: _vm.position === 'right',
      top: _vm.position_y === 'top',
      bottom: _vm.position_y === 'bottom'
    }},[_c('div',{staticClass:"datepicker-period__overlay",on:{"click":function($event){_vm.show_date_picker = !_vm.show_date_picker}}}),_c('div',{staticClass:"datepicker-period__wrap"},[_c('div',[_c('span',{staticClass:"d-block font-10 font-bold font-lightgray"},[_vm._v(" Дата начала ")]),_c('v-datepicker',{staticClass:"select",attrs:{"language":_vm.ru,"full-month-name":true,"inline":true},on:{"input":_vm.setStartsAt},model:{value:(_vm.curr_starts_at),callback:function ($$v) {_vm.curr_starts_at=$$v},expression:"curr_starts_at"}})],1),_c('div',[_c('span',{staticClass:"d-block font-10 font-bold font-lightgray"},[_vm._v(" Дата окончания ")]),_c('v-datepicker',{staticClass:"select",attrs:{"language":_vm.ru,"full-month-name":true,"inline":true},on:{"input":_vm.setEndsAt},model:{value:(_vm.curr_ends_at),callback:function ($$v) {_vm.curr_ends_at=$$v},expression:"curr_ends_at"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
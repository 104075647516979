
import { Component, Vue, Prop } from "vue-property-decorator";

import structureRoutes from "@/api/routes/structures";

import UserWorkplace from "@/models/user/workplace";
import StructureInfinity from "@/models/structure/infinity";

@Component({ name: "CBaseWorkplace" })
export default class CBaseWorkplace extends Vue {
  @Prop({ required: true }) workplace!: UserWorkplace;
  @Prop({ default: () => [] }) parent_ids!: string[];

  private infinity: StructureInfinity | null = null;
  private infinity_ids: string[] = [];

  private created() {
    this.loadInfinity();
  }

  private loadInfinity() {
    if (!this.parent_ids.length && this.workplace.infinity_id) {
      return this.$api
        .get(structureRoutes.infinity(this.workplace.infinity_id), {
          params: {
            parents: true
          }
        })
        .then(({ data: res }: { data: StructureInfinity }) => {
          this.infinity_ids = (res.parent_ids || []).reverse();

          this.loadCurrentInfinity();
        });
    } else {
      this.infinity_ids = Array.from(this.parent_ids);

      this.loadCurrentInfinity();
    }
  }

  private loadCurrentInfinity() {
    const current_infinity_id = this.infinity_ids.shift();

    if (current_infinity_id) {
      return this.$api
        .get(structureRoutes.infinity(current_infinity_id))
        .then(({ data: res }: { data: StructureInfinity }) => {
          this.infinity = res;
        });
    }
  }
}

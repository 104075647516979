
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Search extends Vue {
  @Prop({ default: () => false }) disableSearch!: boolean;

  private search: string = this.$store.state.search;
  private showSearch: boolean = false;

  protected onSearch() {
    this.$store.commit("search", this.search);
  }

  protected openSearch() {
    if (!this.disableSearch) {
      this.showSearch = true;
    }
  }

  protected closeSearch() {
    this.showSearch = false;
    this.search = "";

    this.$store.commit("search", "");
  }

  protected beforeDestroy() {
    this.showSearch = false;

    this.$store.commit("search", "");
  }
}

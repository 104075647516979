
import { Component, Vue, Prop } from "vue-property-decorator";

import { IProgramAgreementHistory } from "@/models/program/agreement";

import CProgramAgreementHistoryItem from "./AgreementHistoryItem.vue";

@Component({
  name: "CProgramAgreementHistory",
  components: {
    CProgramAgreementHistoryItem
  }
})
export default class CProgramAgreementHistory extends Vue {
  @Prop({ required: true })
  public agreement_histories!: IProgramAgreementHistory[] | null;

  public showHistoryModal() {
    this.$modal.show("agreement_history_program_modal");
  }

  public hideHistoryModal() {
    this.$modal.hide("agreement_history_program_modal");
  }
}

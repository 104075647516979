
import { Component, Vue, Prop } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";
import skills_routes from "@/api/routes/skills";

import CStructure, { IStructureProp } from "@/components/Structure.vue";

import SkillCategory, { ESkillCategory } from "@/models/skill/category";
import SkillLocal from "@/models/skill/local";
import UserUser from "@/models/user/user";
import AktQuestionVersion, {
  aktTypes,
  EAktType
} from "@/models/akt/question_version";
import StructureFactory from "@/models/structure/factory";
import AktQuestionComment from "@/models/akt/question_comment";

@Component({
  name: "CAktQuestionChangeSettings",
  components: {
    CStructure
  }
})
export default class CAktQuestionChangeSettings extends Vue {
  @Prop({ required: true }) public question_version!: AktQuestionVersion;

  @Prop({ required: true }) public skill_categories!: SkillCategory[];
  public skill_category_id: number | null = null;

  @Prop({ required: true }) public responsibles_list!: UserUser[];
  public responsibles: UserUser[] = [];

  @Prop({ required: true }) public factories!: StructureFactory[];
  public factory_id: string | null = null;
  public structure: IStructureProp = {};
  public clear_structure: boolean = false;

  public skills: SkillLocal[] = [];
  public skill_id: string | null = null;

  public akt_types = aktTypes;
  public akt_types_first_two = aktTypes.slice(0, 2);
  public EAktType = EAktType;

  public disabled() {
    return this.question_version.isSendToApprove;
  }

  public disabledAlways() {
    return (
      this.question_version.version > 1 || this.question_version.isSendToApprove
    );
  }

  protected async created() {
    if (this.question_version.question) {
      this.structure = {
        factory_id: this.question_version.question.factory_id,
        workshop_id: this.question_version.question.workshop_id,
        workline_id: this.question_version.question.workline_id,
        equipment_id: this.question_version.question.equipment_id,
        infinity_id: this.question_version.question.infinity_id
      };
    }

    if (this.question_version.responsibles) {
      this.responsibles = this.question_version.responsibles;
    }

    if (this.question_version.question) {
      if (this.question_version.question.skill_category_id) {
        this.skill_category_id = this.question_version.question.skill_category_id;
      }

      if (this.question_version.question.skill_id) {
        this.skill_id = this.question_version.question.skill_id;
      }

      if (this.skill_category_id && this.skill_id) {
        await this.loadSkills();
      }
    }
  }

  public isMethodical() {
    return (
      !this.skill_category_id ||
      this.skill_category_id === ESkillCategory.METHODICAL_ID
    );
  }

  public async changeStructure(obj: IStructureProp) {
    this.question_version.question!.factory_id = obj.factory_id || null;
    this.question_version.question!.infinity_id = obj.infinity_id || null;
    this.question_version.question!.workshop_id = null;
    this.question_version.question!.workline_id = null;
    this.question_version.question!.equipment_id = null;

    await this.loadSkills();
  }

  public async changeFactory() {
    await this.changeStructure({ factory_id: this.factory_id });
  }

  public async changeSkillCategory() {
    this.clear_structure = false;

    if (this.isMethodical()) {
      this.clear_structure = true;
      this.factory_id = null;

      await this.changeStructure({});
    } else {
      await this.loadSkills();
    }
  }

  public changeSkill() {
    this.question_version.question!.skill_id = this.skill_id;
  }

  public changeResponsibles() {
    this.question_version.responsibles = this.responsibles;
  }

  public async saveComment() {
    if (this.question_version.question?.id) {
      return this.$api
        .post(akt_routes.question_comment_add, {
          question_id: this.question_version.question.id,
          comment: this.question_version.comment
        })
        .then(({ data: res }: { data: AktQuestionComment }) => {
          this.question_version!.comments!.unshift(res);

          this.question_version.comment = "";
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  public async destroyComment(id: string, index: number) {
    return this.$api
      .destroy(akt_routes.question_comment_del(id))
      .then(() => {
        this.$delete(this.question_version!.comments!, index);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadSkills() {
    this.question_version.question!.skill_id = null;
    this.skill_id = null;

    return this.$api
      .get(skills_routes.skills, {
        params: {
          paginate: false,
          includes: "all",
          category_id: this.skill_category_id,
          factory_id: this.question_version?.question?.factory_id,
          infinity_id: this.question_version?.question?.infinity_id
        }
      })
      .then(({ data: res }: { data: { skills: SkillLocal[] } }) => {
        this.skills = res.skills;
      });
  }
}

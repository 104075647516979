import UserUser from "../user/user";
import AktQuestion from "./question";
import AktAgreement from "./agreement";

export default class AktResponsible {
  public readonly id!: string;

  public user_id?: string;
  public user?: UserUser;

  public question_id?: string;
  public question?: AktQuestion;

  public agreements?: AktAgreement[];
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import ConstructorProgramsMaterialsAdd from "./materials/Add.vue";
import ConstructorProgramsMaterialsDocument from "./materials/Document.vue";
import ConstructorProgramsMaterialsTest from "./materials/Test.vue";
import ConstructorProgramsMaterialsPractical from "./materials/Practical.vue";
import ConstructorProgramsMaterialsEvent from "./materials/Event.vue";
import ConstructorProgramsAddInspectors from "./materials/Inspector.vue";
import ConstructorProgramsInspectors from "@/views/constructor/programs/components/Inspectors.vue";

import RelationVersion from "@/models/relation/version";
import programRoutes from "@/api/routes/programs";

import unitRoutes from "@/api/routes/units";

import { EUnitableType } from "@/models/unit/unit_version";
import ProgramInspector from "@/models/program/inspector";

@Component({
  name: "ConstructorProgramsRelations",
  components: {
    ConstructorProgramsMaterialsAdd,
    ConstructorProgramsMaterialsDocument,
    ConstructorProgramsMaterialsTest,
    ConstructorProgramsMaterialsPractical,
    ConstructorProgramsMaterialsEvent,
    ConstructorProgramsAddInspectors,
    ConstructorProgramsInspectors
  }
})
export default class ConstructorProgramsRelations extends Vue {
  @Prop({ required: true }) program_id!: string;
  @Prop({ required: true }) level!: number;
  @Prop({ required: true }) program_version_id!: string;
  @Prop({ required: true }) confirmation_levels!: number[];
  @Prop({ required: true }) mentor_levels!: number[];

  @Prop({ required: false, default: false }) public disabled!: boolean;

  public preload: boolean = false;
  public EUnitableType = EUnitableType;

  public can_update_confirmation: boolean = false;
  public can_update_mentor: boolean = false;

  public selected_type: EUnitableType | null = null;
  public edit_relation: RelationVersion | null = null;
  public edit_relation_index: number | null = null;

  public changed_inspectors: ProgramInspector[] = [];

  public relation_versions: RelationVersion[] = [];

  protected async created() {
    await this.loadRelations();
  }

  @Watch("level")
  protected async loadRelations() {
    this.can_update_confirmation = false;
    this.preload = true;

    return this.$api
      .get(programRoutes.relations(this.program_version_id!), {
        params: {
          level: this.level
        }
      })
      .then(({ data: res }: { data: RelationVersion[] }) => {
        this.relation_versions = res;
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public showChooseMaterialModal() {
    if (this.disabled) {
      return;
    }

    this.$modal.show("chooseMaterial");
  }

  public hideChooseMaterialModal() {
    this.$modal.hide("chooseMaterial");
  }

  protected showEditMaterialModal() {
    this.$modal.show("editMaterial");
  }

  public hideEditMaterialModal() {
    this.$modal.hide("editMaterial");
  }

  public changeInspectors(inspectors: ProgramInspector[]) {
    this.$emit("changesExist", true);

    this.changed_inspectors = inspectors;
  }

  protected beforeCloseModal(event: Event & { stop: () => void }) {
    if (!window.confirm("Вы точно хотите закрыть без сохранения изменений?")) {
      event.stop();
    }
  }

  public addMaterial(type: EUnitableType) {
    if (this.disabled) {
      return;
    }

    this.selected_type = type;
    this.edit_relation = new RelationVersion({
      program_version_id: this.program_version_id,
      level: this.level
    });
    this.edit_relation_index = null;

    this.showEditMaterialModal();
  }

  public editMaterial(index: number) {
    this.edit_relation_index = index;
    this.edit_relation = this.relation_versions[index];
    this.selected_type = this.edit_relation.unit_version_unitable_type!;

    this.showEditMaterialModal();
  }

  public async relationCreated(relation: RelationVersion) {
    if (this.level === relation.level) {
      this.relation_versions.push(relation);

      await this.changesExist();
    }

    this.hideEditMaterialModal();
  }

  public async relationUpdated(relation: RelationVersion) {
    if (this.level !== relation.level) {
      this.$delete(this.relation_versions, this.edit_relation_index!);
    } else {
      this.edit_relation!.unit_version_title = relation.unit_version_title;

      await this.changesExist();
    }

    this.hideEditMaterialModal();
  }

  public async deleteMaterial(index: number) {
    this.edit_relation_index = index;
    this.edit_relation = this.relation_versions[index];

    await this.$api
      .destroy(unitRoutes.relation(this.edit_relation.id))
      .then(async () => {
        await this.changesExist();

        this.$delete(this.relation_versions, this.edit_relation_index!);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async changesExist() {
    this.can_update_confirmation = false;
    this.can_update_mentor = false;

    return this.$api
      .put(programRoutes.changes_exist(this.program_version_id!), {})
      .then(() => {
        this.$emit("changesExist", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  public selectSupervisor() {
    this.can_update_confirmation = true;

    this.hideEditMaterialModal();
  }

  public selectMentor() {
    this.can_update_mentor = true;

    this.hideEditMaterialModal();
  }
}

export const phases: number[] = [0, 1, 2, 3, 4];

export const phasesObj = phases.map(el => {
  return { name: `T${el}`, value: el };
});

export const phasesFactory = [...phases, 5].map(el => {
  return { name: `Фаза ${el}`, value: el };
});

export default {
  phases,
  phasesObj,
  phasesFactory
};

export enum EPermission {
  staffs_and_groups = "staffs_and_groups",
  positions = "positions",
  constructor_permission = "constructor",
  training_applications = "training_applications",
  training_planning = "training_planning",
  akt_questions = "akt_questions",
  akt_templates = "akt_templates",
  akt_assignments = "akt_assignments",
  akt_passed_testing = "akt_passed_testing",
  structures = "structures",
  skills = "skills",
  inventories = "inventories",
  team = "team",
  local_block = "local_block",
  appoint_responsible = "appoint_responsible",
  priorities = "priorities",
  skill_signs = "skill_signs",
  user_signs = "user_signs",
  testings = "testings",
  testing_checks = "testing_checks",
  kpi_all = "kpi_all",
  kpi_catalog = "kpi_catalog",
  kpi_settings = "kpi_settings",
  kpi_assignments = "kpi_assignments",
  kpi_archive = "kpi_archive"
}

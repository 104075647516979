
import { Component, Vue, Prop } from "vue-property-decorator";

import programs_routes from "@/api/routes/programs";

import { IProgramAgreementBase } from "@/models/program/agreement";

@Component({
  name: "CProgramAgreement"
})
export default class CProgramAgreement extends Vue {
  @Prop({ required: true }) public program_id!: string;

  @Prop({ required: true }) public agreement!: IProgramAgreementBase | null;

  public comment: string = "";
  public comment_errors: string | null = null;

  public disabled: boolean = false;

  public options = [
    { label: "Согласовать", value: true },
    { label: "Отклонить", value: false }
  ];
  public result = this.options[0];

  public changeResult() {
    if (this.result == null) {
      this.result = this.options[0];
    }
  }

  public showModal() {
    this.$modal.show("agreement_program_modal");
  }

  public hideModal() {
    this.$modal.hide("agreement_program_modal");
  }

  public async sendApproval() {
    this.comment_errors = null;

    if (!this.result.value && !this.comment.length) {
      this.comment_errors = "Добавьте комментарий";

      return;
    }

    return this.$api
      .post(programs_routes.confirm_approval(this.program_id), {
        agreement_id: this.agreement!.id,
        approve: this.result.value,
        comment: this.comment
      })
      .then(({ data: res }: { data: { message: string } }) => {
        this.disabled = true;

        this.hideModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: res.message,
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

import { IsBoolean, IsString, IsUUID } from "class-validator";
import AktAnswer from "@/models/akt/answer";

export default class TestingQuestionAnswerVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public title!: string;

  @IsBoolean()
  public is_right: boolean = false;

  @IsUUID("4")
  public readonly answer_id!: string;

  constructor(data: any) {
    if (data) {
      this.title = data.title || this.title;
    }
  }
}

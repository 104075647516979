
import { Component, Vue } from "vue-property-decorator";

import CCalendar from "@/components/calendar/Calendar.vue";

@Component({
  components: {
    CCalendar
  }
})
export default class VNewCalendar extends Vue {
  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected watchers: Function[] = [];

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

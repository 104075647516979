var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-auto it-0 ib-16 t-32",attrs:{"id":"c-testings-change-approval"}},[(_vm.showCurr())?[_c('h5',{staticClass:"t-0"},[_vm._v("Текущая версия")]),_c('div',{staticClass:"b-40"},_vm._l((_vm.testing.current_approvals),function(approval){return _c('div',{key:approval.id,staticClass:"row t-26 align-items-start font-12"},[_c('div',{staticClass:"col"},[_c('div',[_vm._v(_vm._s(approval.conciliator_name))]),(approval.last_comment)?_c('div',{staticClass:"font-gray t-4"},[_vm._v(" "+_vm._s(approval.last_comment)+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-3 col-auto",class:[
            approval.status == 3
              ? 'font-red'
              : approval.status == 4
              ? 'font-blue'
              : 'font-gray'
          ]},[_vm._v(" "+_vm._s(_vm._f("normalizedTestingStatus")(approval.status))+" ")]),_c('div',{staticClass:"col-2 d-xs-none"},[_vm._v(" "+_vm._s(_vm._f("toDate")(approval.updated_at))+" ")])])}),0)]:_vm._e(),(_vm.showPrev())?[_c('h5',{staticClass:"t-0"},[_vm._v("Предыдущая версия")]),_vm._l((_vm.testing.actual_approvals),function(approval){return _c('div',{key:approval.id,staticClass:"row t-26 align-items-start font-12"},[_c('div',{staticClass:"col"},[_c('div',[_vm._v(_vm._s(approval.conciliator_name))]),(approval.last_comment)?_c('div',{staticClass:"font-gray t-4"},[_vm._v(" "+_vm._s(approval.last_comment)+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-3 col-auto",class:[
          approval.status == 3
            ? 'font-red'
            : approval.status == 4
            ? 'font-blue'
            : 'font-gray'
        ]},[_vm._v(" "+_vm._s(_vm._f("normalizedTestingStatus")(approval.status))+" ")]),_c('div',{staticClass:"col-2 d-xs-none"},[_vm._v(" "+_vm._s(_vm._f("toDate")(approval.updated_at))+" ")])])})]:_vm._e(),(!_vm.showCurr() && !_vm.showPrev())?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"font-12 font-gray"},[(_vm.testing.id)?[_vm._v(" Информация о согласовании недоступна ")]:[_vm._v(" Согласование может быть только у созданного тестирования ")]],2)])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import {
  IsUUID,
  IsInt,
  IsPositive,
  IsBoolean,
  IsString,
  IsOptional,
  ValidateIf
} from "class-validator";

import TestQuestion from "./question";
import TestAnswerVersion from "./answer_version";

export default class TestQuestionVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsString()
  public title: string = "";

  @IsInt()
  @IsPositive()
  public version: number = 1;

  @IsInt()
  @IsPositive()
  public position: number = 1;

  @IsBoolean()
  public use_image: boolean = false;

  @IsOptional()
  @IsString()
  @ValidateIf(o => o.use_image)
  public image_url?: string | null;

  public question: TestQuestion = new TestQuestion();

  public answer_versions: TestAnswerVersion[] = [];

  public is_correct?: boolean;
  public is_answered?: boolean;

  constructor(data?: any) {
    if (data) {
      this.title = data.title;
    }
  }
}

export default {
  edits: `/v1/programs/edit`,
  edit: (id: string) => `/v1/programs/edit/${id}`,
  shows: `/v1/programs/show`,
  show: (id: string) => `/v1/programs/show/${id}`,
  version: (id: string) => `/v1/programs/versions/${id}`,
  relations: (id: string) => `/v1/programs/edit/${id}/relations`,
  changes_exist: (id: string) => `/v1/programs/versions/${id}/changes_exist`,
  copy: (id: string) => `/v1/programs/edit/${id}/copy`,
  archived: (id: string) => `/v1/programs/edit/${id}/archived`,
  change_author: (id: string) => `/v1/programs/edit/${id}/change_author`,
  available_skills: (factory_id: string) =>
    `/v1/programs/edit/${factory_id}/available_skills`,
  inspectors: `/v1/programs/inspectors`,
  inspector: (id: string) => `/v1/programs/inspectors/${id}`,
  send_to_approval: (id: string) => `/v1/programs/edit/${id}/approval`,
  confirm_approval: (id: string) => `/v1/programs/edit/${id}/confirm`,
  agreement: (id: string) => `/v1/programs/agreement/${id}`,
  agreement_history: (id: string) => `/v1/programs/agreement_history/${id}`,
  agreement_delegation: (id: string) =>
    `/v1/programs/agreement/${id}/delegation`
};

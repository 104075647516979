
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { plainToClass } from "class-transformer";

import PdfView from "@/components/PdfView.vue";
import CUploadDocument from "@/components/application/UploadDocument.vue";

import UserUser from "@/models/user/user";
import applicationRoutes from "@/api/routes/applications";
import SkillLocal from "@/models/skill/local";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import UserDocument from "@/models/user/document";
import ApplicationApplication from "@/models/application/application";
import CFileDownload from "@/components/FileDownload.vue";

interface IPlanData {
  id: string;
  is_planned: boolean;
}

@Component({
  components: { CInfiniteScroll, CUploadDocument, PdfView, CFileDownload }
})
export default class CPlanningUsers extends Vue {
  @Prop({ required: true }) planningUsers!: UserUser[];
  @Prop({ required: true }) hidePosition!: boolean;
  @Prop({ required: true }) canWrite!: boolean;
  @Prop({ required: true }) filteredSkills!: SkillLocal[];

  protected unlockUserCount: number = 0;

  protected userDocument: UserDocument = new UserDocument();

  protected currentApplication: ApplicationApplication = new ApplicationApplication();
  protected currentStudents: UserUser[] = [];
  protected currentStudentIndex!: number;
  protected currentSkillIndex!: number;

  @Watch("planningUsers")
  protected updateUnlock() {
    this.unlockUserCount = 0;
    this.$emit("isAllLockChange", true);
  }

  protected unlockUser(index: number) {
    if (!this.canWrite) {
      return;
    }
    const user = this.planningUsers[index];

    this.$set(user, "unlock", !user.unlock);

    if (user.unlock) {
      this.unlockUserCount++;
    } else {
      this.unlockUserCount--;
    }

    this.$emit("isAllLockChange", this.unlockUserCount === 0);
  }

  protected async updatePlans(skill_index: number, user_index: number) {
    const user = this.planningUsers[user_index];

    if (!user.unlock) {
      return;
    }

    await this.$api
      .put(applicationRoutes.plan_choice, {
        plan_choice_id: user.all_skills?.[skill_index].plan_choices_id,
        user_id: user.id,
        skill_id: user.all_skills?.[skill_index].skill_id,
        plan_id: user.plan_id
      })
      .then(({ data: res }: { data: IPlanData }) => {
        this.$set(
          user.all_skills?.[skill_index]!,
          "is_planned",
          res.is_planned
        );

        this.$set(user.all_skills?.[skill_index]!, "plan_choices_id", res.id);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected showUserDocs(user_index: number, skill_index: number) {
    this.userDocument = this.planningUsers?.[user_index].all_skills?.[
      skill_index
    ].educations[0]!;
    this.$modal.show("showUserDocsFileModal");
  }

  protected showUploadUserDocs(user_index: number, skill_index: number) {
    const user = this.planningUsers?.[user_index];
    const user_skill = user.all_skills?.[skill_index];

    this.currentStudentIndex = user_index;
    this.currentSkillIndex = skill_index;

    this.currentApplication = user_skill!.applications[0]!;

    this.currentStudents = [
      plainToClass(UserUser, {
        id: user!.id!,
        certificate_id: this.currentApplication.certificate_id,
        protocol_id: this.currentApplication.protocol_id
      })
    ];

    this.$modal.show("addUserDocumentFileModal");
  }

  protected updateCertificateId(id: string) {
    this.planningUsers![this.currentStudentIndex].all_skills![
      this.currentSkillIndex
    ].applications[0].certificate_id = id;
  }

  protected updateProtocolId(id: string) {
    this.planningUsers![this.currentStudentIndex].all_skills![
      this.currentSkillIndex
    ].applications[0].protocol_id = id;
  }
}

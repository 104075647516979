
import { Component, Prop, Vue } from "vue-property-decorator";
import Upload from "@/components/Upload.vue";
import CFileDownload from "@/components/FileDownload.vue";

import applications from "@/api/routes/applications";
import applicationRoutes from "@/api/routes/applications";

import Preloader from "@/components/Preloader.vue";
import ApplicationCertificate from "@/models/application/certificate";
import ApplicationProtocol from "@/models/application/protocol";
import ApplicationCertificateFile from "@/models/application/sertificate_file";
import ApplicationProtocolFile from "@/models/application/protocol_file";

interface IDataStudentApplications {
  readonly id: string;
  readonly skill_id: string;
  readonly status: number;
  readonly skill_full_name: string;
  readonly block_full_names: string;
  readonly education_place: string;
  readonly start_date: string;
}

interface IDataStudentApplication {
  readonly id: string;
  readonly skill_full_name: string;
  readonly certificate_id: string;
  readonly protocol_id: string;
  readonly block_full_names: string;
  readonly education_place: string;
  readonly start_date: string;
}

@Component({
  components: {
    Preloader,
    CFileDownload,
    Upload
  }
})
export default class CTeamStudentApps extends Vue {
  @Prop({ required: true }) private user_id!: string;

  private preload: boolean = false;

  private acceptFiles: string[] = ["application/pdf"];
  private docsError: string = "";

  private tabs = ["Данные", "Загрузка документов"];
  private currentTab: number = 0;

  private studentApplications: IDataStudentApplications[] = [];
  private showedSkill: IDataStudentApplications | null = null;
  private currentApplication: IDataStudentApplication | null = null;
  private currentCertificate = new ApplicationCertificate();
  private currentProtocol = new ApplicationProtocol();

  private created() {
    this.preload = true;

    this.loadStudentApplications();
  }

  private loadStudentApplications() {
    this.$api
      .get(applications.student(this.user_id))
      .then(({ data: res }: { data: IDataStudentApplications[] }) => {
        this.studentApplications = res;
      })
      .finally(() => {
        this.preload = false;
      });
  }

  private changeTab(index: number) {
    this.currentTab = index;
  }

  private showModal() {
    this.$modal.show("showSkill");
  }

  private clearShowApplication() {
    this.currentTab = 0;
    this.currentProtocol = new ApplicationProtocol();
    this.currentCertificate = new ApplicationCertificate();
  }

  private hideModal() {
    this.clearShowApplication();
    this.$modal.hide("showSkill");
  }

  private onClickSkill(skill: IDataStudentApplications) {
    this.showedSkill = skill;
    this.loadStudentApplication().then(() => {
      this.showModal();
    });
  }

  private loadStudentApplication() {
    return this.$api
      .get(applications.student_application(this.user_id, this.showedSkill!.id))
      .then(async ({ data: res }: { data: IDataStudentApplication }) => {
        this.currentApplication = res;
        await this.loadCurrentStudentDocs();
      });
  }

  private async loadCurrentStudentDocs() {
    if (this.currentApplication!.certificate_id) {
      this.$api
        .get(
          applicationRoutes.certificate(this.currentApplication!.certificate_id)
        )
        .then(({ data: res }: { data: ApplicationCertificate }) => {
          this.currentCertificate = res;
        });

      if (this.currentApplication!.protocol_id) {
        this.$api
          .get(applicationRoutes.protocol(this.currentApplication!.protocol_id))
          .then(({ data: res }: { data: ApplicationProtocol }) => {
            this.currentProtocol = res;
          });
      }
    }
  }

  private uploadCertificate(url: string) {
    this.$api
      .post(applicationRoutes.certificate_files, {
        certificate_id: this.currentCertificate.id,
        url: url
      })
      .then(({ data: res }: { data: ApplicationCertificateFile }) => {
        this.currentCertificate.certificate_files?.push(res);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;
      });
  }

  private uploadProtocol(url: string) {
    if (this.currentApplication) {
      if (this.currentProtocol.id) {
        this.sendProtocolFile(url);
        return;
      }

      this.$api
        .post(applicationRoutes.protocols, {
          application_id: this.currentApplication.id!
        })
        .then(({ data: res }: { data: ApplicationProtocol }) => {
          this.currentProtocol = res;
          this.sendProtocolFile(url);
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;
        });
    }
  }

  private sendProtocolFile(url: string) {
    this.$api
      .post(applicationRoutes.protocol_files, {
        protocol_id: this.currentProtocol.id,
        url: url
      })
      .then(({ data: res }: { data: ApplicationProtocolFile }) => {
        this.currentProtocol.protocol_files?.push(res);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;
      });
  }
}

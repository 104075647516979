
import { Ref, ref } from "vue";
import { Component, Vue, Prop } from "vue-property-decorator";

import admission_routes from "@/api/routes/admission";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import { AdmissionRole } from "@/models/admission/role";
import { IAdmissionUser } from "@/models/admission/user";
import StructureFactory from "@/models/structure/factory";

interface IAdmissionUserOption extends IAdmissionUser {
  is_check: boolean;
}

@Component({
  name: "CAdmissionRoleChangeUsers",
  components: {
    CInfiniteScroll
  }
})
export default class CAdmissionRoleChangeUsers extends Vue {
  @Prop({ required: true }) public role!: AdmissionRole;
  @Prop({ required: true }) public factories!: StructureFactory[];

  public users_key: number = 0; // для ререндера
  public users_key_available: number = 0; // для ререндера
  public users_key_exists: number = 0; // для ререндера
  public search: string = "";

  public show_filter: boolean = false;
  public filters_count: number = 0;
  public filters: Map<string, boolean> = new Map();

  public factory_options: StructureFactory[] = [
    { id: null, full_name: "Все", skills: [] },
    ...this.factories
  ];
  public selected_factory: StructureFactory = this.factory_options[0];

  private available_preload_infinite: boolean = false;
  private available_per_page: number = 100;
  private available_current_page: number = 0;
  private available_next_page: boolean = false;
  public available_users: Ref<Map<string, IAdmissionUserOption>> = ref(
    new Map()
  );
  public available_users_key: number = 0; // для ререндера
  public available_users_select_all: boolean = false;
  public available_users_checked_count: number = 0;

  private exists_preload_infinite: boolean = false;
  private exists_per_page: number = 100;
  private exists_current_page: number = 0;
  private exists_next_page: boolean = false;
  public exists_users: Ref<Map<string, IAdmissionUserOption>> = ref(new Map());
  public exists_users_key: number = 0; // для ререндера
  public exists_users_select_all: boolean = false;
  public exists_users_checked_count: number = 0;

  private ids_to_add: Ref<Set<string>> = ref(new Set<string>());
  private ids_to_del: Ref<Set<string>> = ref(new Set<string>());

  protected async created() {
    this.available_users.value = new Map();
    this.exists_users.value = new Map();

    this.ids_to_add.value = new Set();
    this.ids_to_del.value = new Set();

    const promises = [this.loadAvailableUsers()];

    if (this.role.id) {
      promises.push(this.loadExistsUsers());
    }

    await Promise.all(promises);

    this.users_key++;
  }

  public async changeFactoryFilter() {
    if (!this.selected_factory) {
      this.selected_factory = this.factory_options[0];
    }

    if (this.selected_factory.id) {
      this.filters.set("factory", true);
    } else {
      this.filters.delete("factory");
    }

    this.filters_count = this.filters.size;

    await this.apply();
  }

  public async clearFilters() {
    this.filters.clear();

    this.selected_factory = this.factory_options[0];

    this.filters_count = this.filters.size;

    await this.apply();
  }

  public async searchUsers() {
    await this.apply();
  }

  private async apply() {
    this.available_current_page = 0;
    this.available_next_page = false;
    this.available_users_select_all = false;
    this.available_users.value = new Map();

    await this.loadAvailableUsers();

    this.users_key_available++;
  }

  public async availableInfiniteHandler() {
    if (this.available_next_page && !this.available_preload_infinite) {
      await this.loadAvailableUsers(this.available_current_page + 1);
    }
  }

  public async existsInfiniteHandler() {
    if (this.exists_next_page && !this.exists_preload_infinite) {
      await this.loadExistsUsers(this.exists_current_page + 1);
    }
  }

  private async loadAvailableUsers(page: number = 0) {
    this.available_preload_infinite = true;

    return this.$api
      .get(admission_routes.users_available(this.role.id), {
        params: {
          page,
          per_page: this.available_per_page,
          search: this.search,
          factory_id: this.selected_factory?.id || ""
        }
      })
      .then(({ data: res }: { data: IAdmissionUser[] }) => {
        this.available_current_page = page;
        this.available_next_page = res.length === this.available_per_page;

        res.forEach(user => {
          if (!this.exists_users.value.has(user.id)) {
            this.available_users.value.set(user.id, {
              ...user,
              is_check: this.available_users_select_all
            });
          }
        });
      })
      .finally(() => {
        this.available_preload_infinite = false;
        this.available_users_key++;
      });
  }

  private async loadExistsUsers(page: number = 0) {
    this.exists_preload_infinite = true;

    return this.$api
      .get(admission_routes.users_exists(this.role.id), {
        params: {
          page,
          per_page: this.exists_per_page
        }
      })
      .then(({ data: res }: { data: IAdmissionUser[] }) => {
        this.exists_current_page = page;
        this.exists_next_page = res.length === this.exists_per_page;

        res.forEach(user => {
          this.exists_users.value.set(user.id, {
            ...user,
            is_check: this.exists_users_select_all
          });
        });
      })
      .finally(() => {
        this.exists_preload_infinite = false;
        this.exists_users_key++;
      });
  }

  public checkAllAvailableUsers() {
    if (this.available_users_select_all) {
      this.available_users.value.forEach(user => {
        user.is_check = false;
      });

      this.available_users_checked_count = 0;
      this.available_users_select_all = false;
    } else {
      this.available_users.value.forEach(user => {
        user.is_check = true;
      });

      this.available_users_checked_count = this.available_users.value.size;
      this.available_users_select_all = true;
    }
  }

  public checkAllIconAvailableUsers() {
    return this.available_users_checked_count === 0
      ? null
      : this.available_users_select_all
      ? "/img/icon-plus.svg"
      : "/img/icon-minus.svg";
  }

  public async addAllCheckedAvailableUsers() {
    this.available_users.value.forEach(user => {
      if (user.is_check) {
        user.is_check = false;
        this.available_users.value.delete(user.id);
        this.exists_users.value.set(user.id, user);

        this.ids_to_del.value.delete(user.id);
        this.ids_to_add.value.add(user.id);
      }
    });

    this.available_users_checked_count = 0;
    this.available_users_select_all = false;
    this.exists_users_select_all = false;

    if (!this.available_users.value.size && this.available_next_page) {
      await this.loadAvailableUsers(this.available_current_page + 1);

      this.users_key_available++;
    }

    this.$emit("change", {
      add: this.ids_to_add.value,
      del: this.ids_to_del.value
    });
  }

  public checkAvailableUser(user: IAdmissionUserOption) {
    this.$set(user, "is_check", !user.is_check);

    this.available_users_checked_count += user.is_check ? 1 : -1;
    this.available_users_select_all =
      this.available_users_checked_count === this.available_users.value.size;
  }

  public checkAllExistsUsers() {
    if (this.exists_users_select_all) {
      this.exists_users.value.forEach(user => {
        user.is_check = false;
      });

      this.exists_users_checked_count = 0;
      this.exists_users_select_all = false;
    } else {
      this.exists_users.value.forEach(user => {
        user.is_check = true;
      });

      this.exists_users_checked_count = this.exists_users.value.size;
      this.exists_users_select_all = true;
    }
  }

  public checkAllIconExistsUsers() {
    return this.exists_users_checked_count === 0
      ? null
      : this.exists_users_select_all
      ? "/img/icon-plus.svg"
      : "/img/icon-minus.svg";
  }

  public async removeAllCheckedExistsUsers() {
    this.exists_users.value.forEach(user => {
      if (user.is_check) {
        user.is_check = false;
        this.exists_users.value.delete(user.id);
        this.available_users.value.set(user.id, user);

        this.ids_to_add.value.delete(user.id);
        this.ids_to_del.value.add(user.id);
      }
    });

    this.exists_users_checked_count = 0;
    this.available_users_select_all = false;
    this.exists_users_select_all = false;

    if (!this.exists_users.value.size && this.exists_next_page) {
      await this.loadExistsUsers(this.exists_current_page + 1);

      this.users_key_exists++;
    }

    this.$emit("change", {
      add: this.ids_to_add.value,
      del: this.ids_to_del.value
    });
  }

  public checkExistsUser(user: IAdmissionUserOption) {
    this.$set(user, "is_check", !user.is_check);

    this.exists_users_checked_count += user.is_check ? 1 : -1;
    this.exists_users_select_all =
      this.exists_users_checked_count === this.exists_users.value.size;
  }
}

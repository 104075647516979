
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import userRoutes from "@/api/routes/users";
import { IDataBlock } from "@/views/user/Skill.vue";

@Component({
  name: "CDashboardSkillsItem"
})
export default class CDashboardSkillsItem extends Vue {
  @Prop({ required: true }) private workplace_id!: string;

  public preload = false;

  public difference = 0;

  // Разница в процентах между таргет левелом и текущим для метологических навыков
  public difference_m = 0;
  // Разница в процентах между таргет левелом и текущим для функциональных навыков
  public difference_f = 0;
  // Разница в процентах между таргет левелом и текущим для общих навыков
  public difference_c = 0;

  protected async created() {
    await this.loadSkills();
  }

  @Watch("workplace_id")
  protected async changeUserWorkplaceId() {
    await this.loadSkills();
  }

  private async loadSkills() {
    if (!this.workplace_id) {
      return;
    }

    this.preload = true;

    return this.$api
      .get(userRoutes.skillsMe, {
        params: {
          category_id: 0,
          user_workplace_id: this.workplace_id
        }
      })
      .then(({ data: res }: { data: IDataBlock }) => {
        this.difference = res.difference;
        this.difference_m = res.difference_m!;
        this.difference_f = res.difference_f!;
        this.difference_c = res.difference_c!;
      })
      .finally(() => {
        this.preload = false;
      });
  }
}

export default {
  tasks: `/v1/tasks/tasks`,
  index_responsibles: `/v1/tasks/index_responsibles`,
  group_create: `/v1/tasks/group_create`,
  global_skills: `/v1/tasks/global_skills`,
  user_skills: `/v1/tasks/user_skills`,
  task: (id: string) => `/v1/tasks/tasks/${id}`,
  to_verification: (id: string) => `/v1/tasks/tasks/${id}/to_verification`,
  verify: (id: string) => `/v1/tasks/tasks/${id}/verify`,
  reassign: (id: string) => `/v1/tasks/tasks/${id}/reassign`
};

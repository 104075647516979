var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-subscriptions-unit-event"}},[_c('h3',{staticClass:"d-xs-none"},[_vm._v(_vm._s(_vm.subscription_unit.unit_version_title))]),_c('div',{staticClass:"event_description"},[(_vm.subscription_unit.unit_version.unitable.subordinate_url)?_c('div',{staticClass:"media_content full-xs-width"},[_c('div',[_vm._v(_vm._s(_vm.subscription_unit.unit_version.unitable.file_type))]),_c('div',{staticClass:"t-24"},[_c('CFileDownload',{staticClass:"download-document",attrs:{"url":_vm.subscription_unit.unit_version.unitable.subordinate_url.replace(
              _vm.subscription_unit.unit_version.unitable.subordinate_url
                .split('.')
                .pop(),
              _vm.subscription_unit.unit_version.unitable.file_type
            ),"classLink":'btn btn-min'}},[_c('inline-svg',{staticClass:"w-18 h-18",attrs:{"src":'/img/icon-import.svg'}}),_c('span',[_vm._v("Скачать")])],1)],1),(_vm.subscription_unit.unit_version.unitable.isSubordinateImage())?_c('ImageView',{attrs:{"url":_vm.subscription_unit.unit_version.unitable.subordinate_url,"size":'responsive'}}):(
          _vm.subscription_unit.unit_version.unitable.isSubordinateVideo()
        )?_c('VideoView',{attrs:{"size":'responsive',"url":_vm.subscription_unit.unit_version.unitable.subordinate_url}}):(
          _vm.subscription_unit.unit_version.unitable.isSubordinateAudio()
        )?_c('CAudioView',{attrs:{"url":_vm.subscription_unit.unit_version.unitable.subordinate_url}}):(_vm.subscription_unit.unit_version.unitable.isSubordinatePdf())?_c('div',{attrs:{"id":"pdf_view"}},[_c('PdfView',{attrs:{"url":_vm.subscription_unit.unit_version.unitable.subordinate_url,"preview":false}})],1):_vm._e()],1):_vm._e(),_c('p',{staticClass:"font-12 t-24"},[_vm._v(" "+_vm._s(_vm.subscription_unit.unit_version.unitable.subordinate_description)+" ")])]),_c('div',{staticClass:"row t-24 align-items-center"},[_c('div',{staticClass:"col-12 col-sm-auto"},[_c('router-link',{staticClass:"btn btn-calendar",attrs:{"to":{
          name: 'trainer_calendar',
          params: {
            id: _vm.program_id,
            unit_id: _vm.unit_id
          }
        }}},[_vm._v("Перейти к календарю тренера ")])],1),(_vm.schedule)?_c('div',{staticClass:"col-12 col-sm t-xs-16"},[_vm._m(0)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row null align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('img',{staticClass:"d-block br-8",attrs:{"src":"/img/icon-warning-red.svg","alt":""}})]),_c('div',{staticClass:"col font-12"},[_vm._v(" Встреча уже назначена! Вы можете перейти к календарю "),_c('br',{staticClass:"d-xs-none"}),_vm._v("и изменить ее параметры ")])])
}]

export { render, staticRenderFns }

import { Component, Vue } from "vue-property-decorator";
import { classToClass, plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";

import kpiRoutes from "@/api/routes/kpi";

import KpiTemplate from "@/models/kpi/template";
import KpiCompany from "@/models/kpi/company";
import KpiLevel from "@/models/kpi/level";
import KpiSubLevel, { EKpiSubLevelType } from "@/models/kpi/sub_level";
import KpiIndicator from "@/models/kpi/indicator";
import { EPermission } from "@/enums/permissions";

interface ISearchResult {
  id: string;
  type:
    | "TEMPLATE"
    | "COMPANY"
    | "LEVEL"
    | "SUB_LEVEL_COLLECTIVE"
    | "SUB_LEVEL_PERSONAL"
    | "INDICATOR";
  path: string;
}

@Component({
  components: {
    Preloader,
    Search
  }
})
export default class VKpiCatalog extends Vue {
  protected preload: boolean = false;
  protected searched: boolean = false;
  protected search: string = this.$store.state.search;
  protected watchers: Function[] = [];

  protected templates: KpiTemplate[] = [];
  protected searchResults: ISearchResult[] = [];

  protected editTemplate: KpiTemplate | null = null;
  protected editTemplateIndex: number | null = null;

  protected editCompany: KpiCompany | null = null;
  protected editCompanyIndex: number | null = null;

  protected editLevel: KpiLevel | null = null;
  protected editLevelIndex: number | null = null;

  protected editSubLevel: KpiSubLevel | null = null;
  protected editSubLevelIndex: number | null = null;

  protected editIndicator: KpiIndicator | null = null;
  protected editIndicatorIndex: number | null = null;

  protected editId: string | null = null;
  protected editTitle: string | null = null;

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.changeSearch();
        }
      )
    );

    await this.loadCatalog();
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadCatalog() {
    this.preload = true;

    return this.$api
      .get(kpiRoutes.catalog)
      .then(({ data: res }: { data: KpiTemplate[] }) => {
        this.templates = plainToClass(KpiTemplate, res);
      })
      .finally(() => {
        this.preload = false;
      });
  }

  protected async changeSearch() {
    if (this.search?.length) {
      this.searched = true;
      await this.searchCatalog();
    } else {
      this.searched = false;
      this.searchResults = [];
    }
  }

  protected async searchCatalog() {
    this.preload = true;

    return this.$api
      .get(kpiRoutes.catalog_search, {
        params: {
          search: this.search
        }
      })
      .then(({ data: res }: { data: ISearchResult[] }) => {
        this.searchResults = res;
      })
      .finally(() => {
        this.preload = false;
      });
  }

  private showModal() {
    this.$modal.show("editKpi");
  }

  private hideModal() {
    this.$modal.hide("editKpi");

    this.closeModal();
  }

  private closeModal() {
    this.editTemplate = null;
    this.editTemplateIndex = null;

    this.editCompany = null;
    this.editCompanyIndex = null;

    this.editLevel = null;
    this.editLevelIndex = null;

    this.editSubLevel = null;
    this.editSubLevelIndex = null;

    this.editIndicator = null;
    this.editIndicatorIndex = null;

    this.editId = null;
    this.editTitle = null;
  }

  protected titleModal() {
    if (this.editTemplate) {
      return this.editId ? "Редактировать шаблон" : "Создать шаблон";
    } else if (this.editCompany) {
      return this.editId ? "Редактировать компанию" : "Создать компанию";
    } else if (this.editLevel) {
      return this.editId ? "Редактировать уровень" : "Создать уровень";
    } else if (this.editSubLevel) {
      return this.editId ? "Редактировать подуровень" : "Создать подуровень";
    } else if (this.editIndicator) {
      return this.editId ? "Редактировать индикатор" : "Создать индикатор";
    }
  }

  protected async changeModal() {
    if (this.editTemplate) {
      this.editId ? await this.updateTemplate() : await this.createTemplate();
    } else if (this.editCompany) {
      this.editId ? await this.updateCompany() : await this.createCompany();
    } else if (this.editLevel) {
      this.editId ? await this.updateLevel() : await this.createLevel();
    } else if (this.editSubLevel) {
      this.editId ? await this.updateSubLevel() : await this.createSubLevel();
    } else if (this.editIndicator) {
      this.editId ? await this.updateIndicator() : await this.createIndicator();
    }
  }

  /**
   * CREATE
   */

  protected beforeCreateTemplate() {
    this.editTemplate = new KpiTemplate();
    this.editTemplateIndex = null;

    this.showModal();
  }

  protected beforeCreateCompany(template_index: number, template_id: string) {
    this.editCompany = new KpiCompany(template_id);
    this.editCompanyIndex = null;

    this.editTitle = "";
    this.editId = null;

    this.editTemplateIndex = template_index;

    this.showModal();
  }

  protected beforeCreateLevel(
    template_index: number,
    company_index: number,
    company_id: string
  ) {
    this.editLevel = new KpiLevel(company_id);
    this.editLevelIndex = null;

    this.editTitle = "";
    this.editId = null;

    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;

    this.showModal();
  }

  protected beforeCreateCollective(
    template_index: number,
    company_index: number,
    level_index: number,
    level_id: string
  ) {
    this.editSubLevel = new KpiSubLevel(level_id, EKpiSubLevelType.COLLECTIVE);
    this.editSubLevelIndex = null;

    this.editTitle = "";
    this.editId = null;

    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;
    this.editLevelIndex = level_index;

    this.showModal();
  }

  protected beforeCreatePersonal(
    template_index: number,
    company_index: number,
    level_index: number,
    level_id: string
  ) {
    this.editSubLevel = new KpiSubLevel(level_id, EKpiSubLevelType.PERSONAL);
    this.editSubLevelIndex = null;

    this.editTitle = "";
    this.editId = null;

    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;
    this.editLevelIndex = level_index;

    this.showModal();
  }

  protected beforeCreateIndicator(
    template_index: number,
    company_index: number,
    level_index: number,
    sub_level_index: number,
    sub_level_id: string
  ) {
    this.editIndicator = new KpiIndicator(sub_level_id);
    this.editIndicatorIndex = null;

    this.editTitle = "";
    this.editId = null;

    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;
    this.editLevelIndex = level_index;
    this.editSubLevelIndex = sub_level_index;

    this.showModal();
  }

  protected async createTemplate() {
    if (!this.canWrite()) {
      return;
    }

    this.editTemplate!.title = this.editTitle!;

    return this.$api
      .post(kpiRoutes.templates, this.editTemplate)
      .then(({ data: res }: { data: KpiTemplate }) => {
        this.templates.push(plainToClass(KpiTemplate, res));

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async createCompany() {
    if (!this.canWrite()) {
      return;
    }

    this.editCompany!.title = this.editTitle!;

    return this.$api
      .post(kpiRoutes.companies, this.editCompany)
      .then(({ data: res }: { data: KpiCompany }) => {
        this.templates[this.editTemplateIndex!].companies.push(
          plainToClass(KpiCompany, res)
        );

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async createLevel() {
    if (!this.canWrite()) {
      return;
    }

    this.editLevel!.title = this.editTitle!;

    return this.$api
      .post(kpiRoutes.levels, this.editLevel)
      .then(({ data: res }: { data: KpiLevel }) => {
        this.templates[this.editTemplateIndex!].companies[
          this.editCompanyIndex!
        ].levels.push(plainToClass(KpiLevel, res));

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async createSubLevel() {
    if (!this.canWrite()) {
      return;
    }

    this.editSubLevel!.title = this.editTitle!;

    return this.$api
      .post(kpiRoutes.sub_levels, this.editSubLevel)
      .then(({ data: res }: { data: KpiSubLevel }) => {
        this.templates[this.editTemplateIndex!].companies[
          this.editCompanyIndex!
        ].levels[this.editLevelIndex!].sub_levels.push(
          plainToClass(KpiSubLevel, res)
        );

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async createIndicator() {
    if (!this.canWrite()) {
      return;
    }

    this.editIndicator!.title = this.editTitle!;

    return this.$api
      .post(kpiRoutes.indicators, this.editIndicator)
      .then(({ data: res }: { data: KpiIndicator }) => {
        this.templates[this.editTemplateIndex!].companies[
          this.editCompanyIndex!
        ].levels[this.editLevelIndex!].sub_levels[
          this.editSubLevelIndex!
        ].indicators.push(plainToClass(KpiIndicator, res));

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  /**
   * UPDATE
   */

  protected beforeUpdateTemplate(template_index: number) {
    this.editTemplateIndex = template_index;

    this.editTemplate = classToClass(this.templates[template_index], {
      excludePrefixes: ["companies", "is_show", "setting"]
    });
    this.editId = this.editTemplate.id!;
    this.editTitle = this.editTemplate.title!;

    this.showModal();
  }

  protected beforeUpdateCompany(template_index: number, company_index: number) {
    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;

    this.editCompany = classToClass(
      this.templates[template_index].companies[company_index],
      { excludePrefixes: ["levels", "is_show"] }
    );
    this.editId = this.editCompany.id!;
    this.editTitle = this.editCompany.title!;

    this.showModal();
  }

  protected beforeUpdateLevel(
    template_index: number,
    company_index: number,
    level_index: number
  ) {
    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;
    this.editLevelIndex = level_index;

    this.editLevel = classToClass(
      this.templates[template_index].companies[company_index].levels[
        level_index
      ],
      { excludePrefixes: ["sub_levels", "is_show"] }
    );
    this.editId = this.editLevel.id!;
    this.editTitle = this.editLevel.title!;

    this.showModal();
  }

  protected beforeUpdateSubLevel(
    template_index: number,
    company_index: number,
    level_index: number,
    sub_level_index: number
  ) {
    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;
    this.editLevelIndex = level_index;
    this.editSubLevelIndex = sub_level_index;

    this.editSubLevel = classToClass(
      this.templates[template_index].companies[company_index].levels[
        level_index
      ].sub_levels[sub_level_index],
      { excludePrefixes: ["indicators", "is_show"] }
    );
    this.editId = this.editSubLevel.id!;
    this.editTitle = this.editSubLevel.title!;

    this.showModal();
  }

  protected beforeUpdateIndicator(
    template_index: number,
    company_index: number,
    level_index: number,
    sub_level_index: number,
    indicator_index: number
  ) {
    this.editTemplateIndex = template_index;
    this.editCompanyIndex = company_index;
    this.editLevelIndex = level_index;
    this.editSubLevelIndex = sub_level_index;
    this.editIndicatorIndex = indicator_index;

    this.editIndicator = classToClass(
      this.templates[template_index].companies[company_index].levels[
        level_index
      ].sub_levels[sub_level_index].indicators[indicator_index]
    );
    this.editId = this.editIndicator.id!;
    this.editTitle = this.editIndicator.title!;

    this.showModal();
  }

  protected async updateTemplate() {
    if (!this.canWrite()) {
      return;
    }

    this.editTemplate!.title = this.editTitle!;

    return this.$api
      .put(kpiRoutes.template(this.editTemplate!.id!), this.editTemplate)
      .then(() => {
        this.templates[this.editTemplateIndex!].title = this.editTitle!;
        this.templates[
          this.editTemplateIndex!
        ].collective_bonus = this.editTemplate!.collective_bonus;
        this.templates[
          this.editTemplateIndex!
        ].personal_bonus = this.editTemplate!.personal_bonus;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateCompany() {
    if (!this.canWrite()) {
      return;
    }

    this.editCompany!.title = this.editTitle!;

    return this.$api
      .put(kpiRoutes.company(this.editCompany!.id!), this.editCompany)
      .then(() => {
        this.templates[this.editTemplateIndex!].companies[
          this.editCompanyIndex!
        ].title = this.editTitle!;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateLevel() {
    if (!this.canWrite()) {
      return;
    }

    this.editLevel!.title = this.editTitle!;

    return this.$api
      .put(kpiRoutes.level(this.editLevel!.id!), this.editLevel)
      .then(() => {
        this.templates[this.editTemplateIndex!].companies[
          this.editCompanyIndex!
        ].levels[this.editLevelIndex!].title = this.editTitle!;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateSubLevel() {
    if (!this.canWrite()) {
      return;
    }

    this.editSubLevel!.title = this.editTitle!;

    return this.$api
      .put(kpiRoutes.sub_level(this.editSubLevel!.id!), this.editSubLevel)
      .then(() => {
        this.templates[this.editTemplateIndex!].companies[
          this.editCompanyIndex!
        ].levels[this.editLevelIndex!].sub_levels[
          this.editSubLevelIndex!
        ].title = this.editTitle!;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateIndicator() {
    if (!this.canWrite()) {
      return;
    }

    this.editIndicator!.title = this.editTitle!;

    return this.$api
      .put(kpiRoutes.indicator(this.editIndicator!.id!), this.editIndicator)
      .then(() => {
        this.templates[this.editTemplateIndex!].companies[
          this.editCompanyIndex!
        ].levels[this.editLevelIndex!].sub_levels[
          this.editSubLevelIndex!
        ].indicators[this.editIndicatorIndex!].title = this.editTitle!;

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  /**
   * DELETE
   */

  protected async deleteTemplate(template_index: number) {
    if (!this.canWrite()) {
      return;
    }

    if (
      window.confirm(
        "Вы точно хотите удалить шаблон? Дочерние элементы будут также удалены"
      )
    ) {
      return this.$api
        .destroy(kpiRoutes.template(this.templates[template_index].id!))
        .then(() => {
          this.$delete(this.templates, template_index);
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected async deleteCompany(template_index: number, company_index: number) {
    if (!this.canWrite()) {
      return;
    }

    if (
      window.confirm(
        "Вы точно хотите удалить компанию? Дочерние элементы будут также удалены"
      )
    ) {
      return this.$api
        .destroy(
          kpiRoutes.company(
            this.templates[template_index].companies[company_index].id!
          )
        )
        .then(() => {
          this.$delete(this.templates[template_index].companies, company_index);
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected async deleteLevel(
    template_index: number,
    company_index: number,
    level_index: number
  ) {
    if (!this.canWrite()) {
      return;
    }

    if (
      window.confirm(
        "Вы точно хотите удалить уровень? Дочерние элементы будут также удалены"
      )
    ) {
      return this.$api
        .destroy(
          kpiRoutes.level(
            this.templates[template_index].companies[company_index].levels[
              level_index
            ].id!
          )
        )
        .then(() => {
          this.$delete(
            this.templates[template_index].companies[company_index].levels,
            level_index
          );
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected async deleteSubLevel(
    template_index: number,
    company_index: number,
    level_index: number,
    sub_level_index: number
  ) {
    if (!this.canWrite()) {
      return;
    }

    if (
      window.confirm(
        "Вы точно хотите удалить подуровень? Дочерние элементы будут также удалены"
      )
    ) {
      return this.$api
        .destroy(
          kpiRoutes.sub_level(
            this.templates[template_index].companies[company_index].levels[
              level_index
            ].sub_levels[sub_level_index].id!
          )
        )
        .then(() => {
          this.$delete(
            this.templates[template_index].companies[company_index].levels[
              level_index
            ].sub_levels,
            sub_level_index
          );
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected async deleteIndicator(
    template_index: number,
    company_index: number,
    level_index: number,
    sub_level_index: number,
    indicator_index: number
  ) {
    if (!this.canWrite()) {
      return;
    }

    if (window.confirm("Вы точно хотите удалить индикатор?")) {
      return this.$api
        .destroy(
          kpiRoutes.indicator(
            this.templates[template_index].companies[company_index].levels[
              level_index
            ].sub_levels[sub_level_index].indicators[indicator_index].id!
          )
        )
        .then(() => {
          this.$delete(
            this.templates[template_index].companies[company_index].levels[
              level_index
            ].sub_levels[sub_level_index].indicators,
            indicator_index
          );
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.kpi_catalog);
  }
}

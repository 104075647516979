
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import programRoutes from "@/api/routes/programs";

import ProgramInspector from "@/models/program/inspector";

@Component
export default class ConstructorProgramsInspectors extends Vue {
  @Prop({ required: true }) level!: number;
  @Prop({ required: true }) program_id!: string;
  @Prop({ required: true }) program_version_id!: string;
  @Prop({ required: true }) can_update_confirmation!: boolean;
  @Prop({ required: true }) can_update_mentor!: boolean;
  @Prop({ required: false }) changed_inspectors?: ProgramInspector[];
  @Prop({ required: true }) confirmation_levels!: number[];
  @Prop({ required: true }) mentor_levels!: number[];

  @Prop({ required: false, default: false }) public disabled!: boolean;

  public preload: boolean = false;

  private levels: number[] = this.confirmation_levels;
  private mentors_levels: number[] = this.mentor_levels;

  public confirmation_level_exist: boolean = false;
  public mentor_level_exist: boolean = false;

  public inspectors: ProgramInspector[] = [];

  protected async created() {
    await this.loadInspectors();
  }

  @Watch("level")
  protected async changeLevel() {
    await this.loadInspectors();
  }

  @Watch("can_update_mentor")
  protected async updateMentor() {
    if (
      !this.can_update_mentor ||
      this.inspectors.length ||
      this.can_update_confirmation
    ) {
      return;
    }

    this.mentor_level_exist =
      this.confirmation_levels.filter(i => i === this.level).length !== 0;

    if (!this.mentor_level_exist) {
      this.mentors_levels.push(this.level);

      return this.$api
        .put(programRoutes.version(this.program_version_id), {
          mentor_levels: this.mentors_levels
        })
        .then(() => {
          this.mentor_level_exist = true;
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            duration: 500,
            speed: 500
          });
        });
    }
  }

  public async deleteMentor() {
    this.mentors_levels.splice(this.mentors_levels.indexOf(this.level), 1);

    return this.$api
      .put(programRoutes.version(this.program_version_id), {
        mentor_levels: this.mentors_levels
      })
      .then(async () => {
        await this.changesExist();
        this.$emit("mentor_levels", this.mentors_levels);

        this.mentor_level_exist = false;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  @Watch("can_update_confirmation")
  protected async updateConfirmation() {
    if (
      !this.can_update_confirmation ||
      this.inspectors.length ||
      this.mentor_level_exist
    ) {
      return;
    }

    this.confirmation_level_exist =
      this.confirmation_levels.filter(i => i === this.level).length !== 0;

    if (!this.confirmation_level_exist) {
      this.levels.push(this.level);

      return this.$api
        .put(programRoutes.version(this.program_version_id), {
          confirmation_levels: this.levels
        })
        .then(() => {
          this.confirmation_level_exist = true;
        })
        .catch(({ response: res }) => {
          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            duration: 500,
            speed: 500
          });
        });
    }
  }

  public async deleteConfirmation() {
    this.levels.splice(this.levels.indexOf(this.level), 1);

    return this.$api
      .put(programRoutes.version(this.program_version_id), {
        confirmation_levels: this.levels
      })
      .then(async () => {
        await this.changesExist();
        this.$emit("confirmation_levels", this.levels);

        this.confirmation_level_exist = false;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  @Watch("changed_inspectors")
  protected changeInspectors() {
    this.inspectors = this.changed_inspectors!;
  }

  private async loadInspectors() {
    this.preload = true;

    this.confirmation_level_exist =
      this.confirmation_levels.filter(i => i === this.level).length !== 0;
    this.mentor_level_exist =
      this.mentors_levels.filter(i => i === this.level).length !== 0;

    return this.$api
      .get(programRoutes.inspectors, {
        params: {
          level: this.level,
          program_id: this.program_id
        }
      })
      .then(({ data: res }: { data: ProgramInspector[] }) => {
        this.inspectors = res;
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public async deleteInspector(index: number) {
    const inspector = this.inspectors[index];

    return this.$api
      .destroy(programRoutes.inspector(inspector.id))
      .then(async () => {
        await this.changesExist();

        this.$delete(this.inspectors, index);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  private async changesExist() {
    return this.$api
      .put(programRoutes.changes_exist(this.program_version_id!), {})
      .then(() => {
        this.$emit("changesExist", true);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          duration: 500,
          speed: 500
        });
      });
  }
}

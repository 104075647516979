export default {
  roles: `/v1/admission/roles`,
  role: (id: string) => `/v1/admission/roles/${id}`,
  positions_available: (role_id: string | null) =>
    `/v1/admission/positions/available?role_id=${role_id || ""}`,
  positions_exists: (role_id: string | null) =>
    `/v1/admission/positions/exists?role_id=${role_id || ""}`,
  users_available: (role_id: string | null) =>
    `/v1/admission/users/available?role_id=${role_id || ""}`,
  users_exists: (role_id: string | null) =>
    `/v1/admission/users/exists?role_id=${role_id || ""}`
};

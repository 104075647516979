import {
  IsUUID,
  IsInt,
  IsPositive,
  Min,
  Max,
  IsBoolean,
  IsOptional
} from "class-validator";

import TestTest from "./test";
import TestQuestionVersion from "./question_version";

export default class TestVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsInt()
  @IsPositive()
  public version: number = 1;

  @IsInt()
  @Min(0)
  @Max(100)
  public score: number = 80;

  @IsBoolean()
  public can_reanswer: boolean = false;

  @IsOptional()
  @IsInt()
  @IsPositive()
  @Min(1)
  @Max(3)
  public reanswer_count: null | number = null;

  public test?: TestTest;

  public question_versions: TestQuestionVersion[] = [];
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import task_routes from "@/api/routes/tasks";

import Preloader from "@/components/Preloader.vue";
import CComment from "@/components/Comment.vue";
import CFileDownload from "@/components/FileDownload.vue";

import TaskTask from "@/models/task/task";

@Component({
  name: "VExaminationTask",
  components: {
    Preloader,
    CComment,
    CFileDownload
  }
})
export default class VExaminationTask extends Vue {
  @Prop({ required: true }) private task_id!: string;

  public preload: boolean = false;

  public task: TaskTask | null = null;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await this.loadTask();
  }

  private async loadTask() {
    this.preload = true;

    return this.$api
      .get(task_routes.task(this.task_id))
      .then(({ data: res }: { data: TaskTask }) => {
        this.task = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public async toVerification() {
    return this.$api
      .put(task_routes.to_verification(this.task_id), {
        student_comment: this.task!.student_comment,
        student_comment_files: this.task!.student_comment_files
      })
      .then(({ data: res }: { data: TaskTask }) => {
        this.preload = true;

        this.task = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public uploadFile(file: string) {
    if (this.task!.student_comment_files.length < 10) {
      this.task!.student_comment_files.push({ url: file });
    } else {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Не более 10 файлов",
        duration: 500,
        speed: 500
      });
    }
  }

  public removeCommentFile(index: number) {
    this.task!.student_comment_files.splice(index, 1);
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class CAudioView extends Vue {
  @Prop() private url!: string;

  private audioUrl = this.$api.staticUploaderUrl + this.url;

  protected created() {
    this.changeUrl();
  }

  @Watch("url")
  protected changeUrl() {
    this.audioUrl = RegExp(this.$api.regexUrl).test(this.url)
      ? this.url
      : this.$api.staticUploaderUrl + this.url;
  }
}

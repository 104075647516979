
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import IProgram, { IData } from "./interfaces";

import program_routes from "@/api/routes/programs";
import { EPermission } from "@/enums/permissions";

@Component({
  name: "CConstructorProgramsIndexContent",
  components: {
    Preloader
  }
})
export default class CConstructorProgramsIndexContent extends Vue {
  @Prop({ required: false }) public factory_id?: string;
  @Prop({ required: false }) public workshop_id?: string;
  @Prop({ required: false }) public workline_id?: string;
  @Prop({ required: false }) public equipment_id?: string;
  @Prop({ required: false }) public infinity_id?: string;

  @Prop({ required: false }) private block_id?: string;
  @Prop({ required: false }) private exclude_category_id?: number;
  @Prop({ required: false }) private include_category_id?: number;
  @Prop({ required: false }) private skill_id?: string;

  public preload: boolean = false;
  public programs: IProgram[] = [];
  private search: string = this.$store.state.search;

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.loadPrograms();
        }
      )
    );

    await this.loadPrograms();
  }

  @Watch("factory_id")
  @Watch("block_id")
  @Watch("include_category_id")
  @Watch("skill_id")
  protected async changeFactory() {
    await this.loadPrograms();
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  private async loadPrograms() {
    this.preload = true;

    return this.$api
      .get(program_routes.edits, {
        params: {
          factory_id: this.factory_id,
          workshop_id: this.workshop_id,
          workline_id: this.workline_id,
          equipment_id: this.equipment_id,
          infinity_id: this.infinity_id,
          block_id: this.block_id,
          include_category_id: this.include_category_id,
          exclude_category_id: this.exclude_category_id,
          skill_id: this.skill_id,
          search: this.search,
          paginate: false
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.programs = res.programs;
        this.preload = false;
      });
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.constructor_permission);
  }
}

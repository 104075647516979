export interface IEvent {
  event_type: EEventType;
  target_id: string;
  name: string;
  description: string;
  confirmed: boolean;
  student_confirm: boolean;
  paused: boolean;
  starts_at: Date;
  ends_at: Date;
  start?: Date;
  end?: Date;
  color?: string;
  responsible_user_id?: string;
  target_reschedule_id?: string;
  target_detail_id?: string;
  target_version?: number;
  target_duration?: number;
  index?: number;
  timed?: boolean;
  other_information?: string;
}

export enum EEventType {
  EVENT_STUDENT = "EVENT_STUDENT",
  EVENT_TRAINER = "EVENT_TRAINER",
  AKT_STUDENT = "AKT_STUDENT",
  AKT_SUPERVISOR = "AKT_SUPERVISOR",
  APPLICATION = "APPLICATION"
}

export function getEventColor(event: IEvent) {
  switch (event.event_type) {
    case EEventType.EVENT_STUDENT:
    case EEventType.EVENT_TRAINER:
      if (event.confirmed) {
        return "green";
      }

      return "red";
    case EEventType.AKT_STUDENT:
    case EEventType.AKT_SUPERVISOR:
      return "blue";
    case EEventType.APPLICATION:
      return "orange";
    default:
      return event.color;
  }
}

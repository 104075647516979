
import { Component, Vue, Prop } from "vue-property-decorator";

import aktRoutes from "@/api/routes/akt";

@Component
export default class CAktTemplateHeader extends Vue {
  @Prop({ required: false }) protected disabled: boolean | undefined;
  @Prop({ required: false }) protected version: number | undefined;
  @Prop({ required: false }) protected template_id: string | undefined;
  @Prop({ required: false, default: false }) protected is_archive:
    | boolean
    | undefined;

  protected is_archive_comp: boolean = false;

  protected publishErrors: string | null | undefined = null;
  protected archivedErrors: string | null | undefined = null;

  protected programEditMessage: string = this.$store.state.programEditMessage;

  protected watchers: Function[] = [];

  protected notyError: boolean = false;
  protected notyText: string = "";

  protected created() {
    this.is_archive_comp = !!this.is_archive;

    this.watchers.push(
      this.$store.watch(
        state => state.programEditMessage,
        programEditMessage => {
          this.programEditMessage = programEditMessage;
        }
      )
    );
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected saveTemplate() {
    if (this.template_id) {
      this.$store.dispatch("toggleProgramEditMessage");

      this.notyError = false;
      this.notyText = "Изменения сохранены";

      this.$modal.show("modal_notify_header");
    }
  }

  protected async publish() {
    if (this.template_id) {
      return this.$api
        .post(aktRoutes.publish(this.template_id!), {})
        .then(() => {
          this.publishErrors = null;
          this.notyError = false;

          this.$router.push({ name: "akt_templates" });
        })
        .catch(({ response: res }) => {
          this.publishErrors = res.data.error;

          this.notyError = true;
          this.notyText = this.publishErrors!;
          this.$modal.show("modal_notify_header");
        });
    }
  }

  protected async archived() {
    if (this.template_id) {
      return this.$api
        .post(aktRoutes.archived(this.template_id!), {})
        .then(({ data: res }: { data: { is_archive: boolean } }) => {
          this.archivedErrors = null;
          this.notyError = false;

          this.is_archive_comp = res.is_archive;
        })
        .catch(({ response: res }) => {
          this.archivedErrors = res.data.error;

          this.notyError = true;
          this.notyText = this.archivedErrors!;
          this.$modal.show("modal_notify_header");
        });
    }
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import structureRoutes from "@/api/routes/structures";

import StructureInfinity from "@/models/structure/infinity";

export interface IBaseProp {
  infinity_id?: string;
  parent_infinity_ids?: string[];
  infinity_names?: string;
}

@Component({
  name: "CBaseStructure",
  components: {
    Preloader
  }
})
export default class CBaseStructure extends Vue {
  @Prop({ required: true }) public factory_id!: string;
  @Prop({ default: null }) public parent_id!: string | null;
  @Prop({ required: false, default: false }) public include_archived!: boolean;

  @Prop({ required: true }) public structure!: IBaseProp;

  @Prop({ required: false, default: false }) public disabled!: boolean;
  @Prop({ required: false, default: false }) public clear!: boolean;
  @Prop({ required: false, default: true }) public appendToBody!: boolean;

  @Prop({ required: false, default: () => "" }) line!: string;

  public current_line = this.line;

  public infinities: StructureInfinity[] = [];
  public infinity: StructureInfinity | null = null;
  public targetInfinity: StructureInfinity | null = null;

  public new_parent_id: string | null = null;
  public new_structure: IBaseProp = {};

  public preload: boolean = false;

  protected async created() {
    this.preload = true;

    if (this.factory_id) {
      await this.loadInfinities();
      await this.loadInfinity();
    }

    this.preload = false;
  }

  protected async loadInfinities() {
    return this.$api
      .get(
        `${structureRoutes.infinities}?include_archived=${this.include_archived}`,
        {
          params: {
            factory_id: this.factory_id,
            parent_id: this.parent_id
          }
        }
      )
      .then(({ data: res }: { data: StructureInfinity[] }) => {
        this.infinities = res;
      });
  }

  protected async loadInfinity() {
    if (!this.parent_id && this.structure?.infinity_id) {
      return this.$api
        .get(structureRoutes.infinity(this.structure!.infinity_id), {
          params: {
            parents: true
          }
        })
        .then(({ data: res }: { data: StructureInfinity }) => {
          this.targetInfinity = res;

          this.targetInfinity.reverse_parent_ids = this.targetInfinity.parent_ids!.reverse();

          this.setCurrentInfinity();
        });
    } else {
      this.setCurrentInfinity();
    }
  }

  private setCurrentInfinity() {
    const parent_infinity_ids = Array.from(
      this.targetInfinity?.reverse_parent_ids ||
        this.structure?.parent_infinity_ids ||
        []
    );

    const current_infinity_id = parent_infinity_ids.shift();

    if (current_infinity_id) {
      const infinity = this.infinities.find(i => i.id === current_infinity_id);
      if (infinity) {
        this.infinity = infinity;
      }

      this.new_parent_id = this.infinity?.id!;
    } else {
      this.new_parent_id = null;
    }

    this.new_structure = {
      infinity_id: this.structure?.infinity_id,
      parent_infinity_ids
    };
  }

  @Watch("factory_id")
  @Watch("parent_id")
  protected async changeFactoryId() {
    this.infinities = [];
    this.infinity = null;
    this.new_parent_id = null;

    if (this.factory_id) {
      this.preload = true;

      await this.loadInfinities();

      this.preload = false;
    }
  }

  @Watch("clear")
  protected changeClear() {
    if (this.clear) {
      this.infinity = null;
      this.new_parent_id = null;

      if (this.parent_id) {
        this.infinities = [];
      }

      this.emitStructure(null);
    }
  }

  public changeInfinity(obj: StructureInfinity) {
    this.new_parent_id = obj?.id || null;

    this.current_line = `${this.line} > ${this.infinity?.full_name}`;
    this.emitStructure(this.new_parent_id);
  }

  public changeBaseStructure(obj: IBaseProp) {
    this.current_line = obj.infinity_names!;
    this.emitStructure(obj.infinity_id || this.new_parent_id);
  }

  protected emitStructure(infinity_id: string | null) {
    this.$emit("changeBaseStructure", {
      infinity_id,
      infinity_names: this.current_line
    });
  }
}

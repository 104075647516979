import { render, staticRenderFns } from "./RoleDelete.vue?vue&type=template&id=d49bb134&scoped=true"
import script from "./RoleDelete.vue?vue&type=script&lang=ts"
export * from "./RoleDelete.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d49bb134",
  null
  
)

export default component.exports

import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import structureRoutes from "@/api/routes/structures";

import StructureFactory from "@/models/structure/factory";
import StructureWorkshop from "@/models/structure/workshop";
import StructureWorkline from "@/models/structure/workline";
import StructureEquipment from "@/models/structure/equipment";

export interface IUecProp {
  workshop_id?: string;
  workline_id?: string;
  equipment_id?: string;
  workshop_name?: string;
  workline_name?: string;
  equipment_name?: string;
}

@Component({
  name: "CUecStructure",
  components: {
    Preloader
  }
})
export default class CUecStructure extends Vue {
  @Prop({ required: true }) protected factory_id!: string;
  @Prop({ required: false, default: false })
  protected include_archived!: boolean;

  @Prop({ required: true }) protected structure!: IUecProp;

  @Prop({ required: false, default: false }) protected disabled!: boolean;
  @Prop({ required: false, default: true }) protected appendToBody!: boolean;
  @Prop({ required: false, default: false }) protected clear!: boolean;

  protected workshops: StructureWorkshop[] = [];
  protected workshop: StructureWorkshop | null = null;

  protected worklines: StructureWorkline[] = [];
  protected workline: StructureWorkline | null = null;

  protected equipments: StructureEquipment[] = [];
  protected equipment: StructureEquipment | null = null;

  protected preload: boolean = false;

  protected async created() {
    this.preload = true;

    if (this.factory_id) {
      await this.loadWorkshops();

      if (this.structure?.workshop_id) {
        this.workshop = this.workshops.find(
          w => w.id === this.structure!.workshop_id
        )!;

        await this.loadWorklines();

        if (this.structure?.workline_id) {
          this.workline = this.worklines.find(
            w => w.id === this.structure!.workline_id
          )!;

          await this.loadEquipments();

          if (this.structure?.equipment_id) {
            this.equipment = this.equipments.find(
              w => w.id === this.structure!.equipment_id
            )!;
          }
        }
      }
    }

    this.preload = false;
  }

  @Watch("factory_id")
  protected async changeFactoryId() {
    if (this.factory_id) {
      this.workshops = [];
      this.workshop = null;

      this.worklines = [];
      this.workline = null;

      this.equipments = [];
      this.equipment = null;

      this.changeStructure();

      await this.loadWorkshops();
    }
  }

  @Watch("clear")
  protected changeClear() {
    if (this.clear) {
      this.workshop = null;

      this.worklines = [];
      this.workline = null;

      this.equipments = [];
      this.equipment = null;

      this.changeStructure();
    }
  }

  protected async loadWorkshops() {
    return this.$api
      .get(structureRoutes.workshops, {
        params: {
          factory_id: this.factory_id
        }
      })
      .then(({ data: res }: { data: StructureWorkshop[] }) => {
        this.workshops = res;
      });
  }

  protected async loadWorklines() {
    return this.$api
      .get(structureRoutes.worklines, {
        params: {
          workshop_id: this.workshop!.id
        }
      })
      .then(({ data: res }: { data: StructureWorkline[] }) => {
        this.worklines = res;
      });
  }

  protected async loadEquipments() {
    return this.$api
      .get(structureRoutes.equipments, {
        params: {
          workline_id: this.workline!.id
        }
      })
      .then(({ data: res }: { data: StructureEquipment[] }) => {
        this.equipments = res;
      });
  }

  protected async changeWorkshop(obj: StructureWorkshop) {
    this.worklines = [];
    this.workline = null;

    this.equipments = [];
    this.equipment = null;

    this.changeStructure();

    if (obj) {
      await this.loadWorklines();
    }
  }

  protected async changeWorkline(obj: StructureWorkline) {
    this.equipments = [];
    this.equipment = null;

    this.changeStructure();

    if (obj) {
      await this.loadEquipments();
    }
  }

  protected changeEquipment(obj: StructureEquipment) {
    this.changeStructure();
  }

  protected changeStructure() {
    this.$emit("changeUecStructure", {
      workshop_id: this.workshop?.id || null,
      workline_id: this.workline?.id || null,
      equipment_id: this.equipment?.id || null,
      workshop_name: this.workshop?.full_name || null,
      workline_name: this.workline?.full_name || null,
      equipment_name: this.equipment?.full_name || null
    });
  }
}

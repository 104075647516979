import { IsUUID, IsString, IsOptional } from "class-validator";

import PermissionPermission from "../permission/permission";

export default class GroupGroup {
  @IsOptional()
  @IsUUID("4")
  public readonly id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public factory_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public workshop_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public workline_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public equipment_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public infinity_id?: string | null;

  @IsString()
  public name: string = "";

  public permissions: PermissionPermission[] = [];

  constructor(data?: any) {
    if (data) {
      this.name = data.name || this.name;
      this.id = this.id || data.id;
      this.factory_id = this.id || data.id;
      this.workshop_id = this.workshop_id || data.workshop_id;
      this.workline_id = this.workline_id || data.workline_id;
      this.equipment_id = this.equipment_id || data.equipment_id;
      this.equipment_id = this.infinity_id || data.infinity_id;

      if (typeof data.permissions != "undefined" && data.permissions.length) {
        this.permissions = data.permissions.map(
          (p: any) => new PermissionPermission(p)
        );
      }
    }
  }
}

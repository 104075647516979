import "reflect-metadata";

import { IsUUID, IsString, IsInt, Min, Max, IsEnum } from "class-validator";
import { Type } from "class-transformer";

import SubscriptionUnit from "./unit";
import ProgramInspector from "@/models/program/inspector";
import { EInspectorStatus } from "@/enums/inspector_status";

export enum ESubscriptionStatus {
  NOT_STARTED = 1, // Не начат
  IN_PROGRESS = 2, // В процессе выполнения
  DONE = 3, // Выполнен корректно
  FAILED = 4, // Выполнен некорректно
  AWAITING_CONFIRMATION = 5, // Ожидает подтверждения
  AWAITING_REPEAT_PASS = 6, // Ожидает перепрохождения
  AWAITING_CONTINUE = 7, // Ожидает продолжения
  AWAITING_COMPLETION = 8, // Ожидает завершения
  ARCHIVED = 9 // Архивировано
}

export enum ESubscriptionProgressStatus {
  NOT_STARTED = 1, // Не начат
  SLIGHTLY_BEHIND = 2, // Немного отстает
  FAR_BEHIND = 3, // Сильно отстает
  MAKE_PROGRESS = 4, // Успевает
  DONE = 5 // Выполнен
}

export interface ISubscriptionInspector {
  readonly id?: string;
  readonly comment?: string;
  readonly level?: number;
  readonly status?: EInspectorStatus;
  readonly updated_at?: string;
  readonly inspector_name?: string;
  readonly inspector_files?: { id: string; url: string }[];
}

export interface ISubscriptionComment {
  readonly id?: string;
  readonly text?: string;
  readonly comment_files?: { id: string; url: string }[];
}

export default class SubscriptionProgram {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly program_version_id!: string;

  @IsUUID("4")
  public readonly user_id!: string;

  @IsUUID("4")
  public readonly mentor_id!: string;

  @IsString()
  public readonly program_version_title!: string;

  @IsString()
  public readonly program_version_description!: string;

  @IsString()
  public readonly pillar_full_name!: string;

  @IsString()
  public readonly pillar_short_name!: string;

  @IsString()
  public readonly skill_full_name!: string;

  @IsString()
  public readonly workplace_name!: string;

  @IsUUID("4")
  public readonly skill_id!: string;

  @IsInt()
  @Min(0)
  @Max(4)
  public readonly skill_level!: string;

  @IsEnum(ESubscriptionStatus)
  public readonly status?: ESubscriptionStatus;

  @IsEnum(ESubscriptionProgressStatus)
  public readonly progress_status?: ESubscriptionProgressStatus;

  @IsInt()
  @Min(0)
  @Max(4)
  public readonly current_skill_level?: number;

  @IsInt()
  public readonly confirmation_levels: number[] = [];

  public readonly current_comment?: ISubscriptionComment;

  @Type(() => SubscriptionUnit)
  public subscription_units!: SubscriptionUnit[];

  @Type(() => ProgramInspector)
  public subscription_inspectors!: ProgramInspector[];

  public failed_inspector?: ISubscriptionInspector | null;

  public get awaitingContinue() {
    return this.status === ESubscriptionStatus.AWAITING_CONTINUE;
  }

  public get awaitingConfirmation() {
    return this.status === ESubscriptionStatus.AWAITING_CONFIRMATION;
  }

  public get Failed() {
    return this.status === ESubscriptionStatus.FAILED;
  }

  public get awaitingCompletion() {
    return this.status === ESubscriptionStatus.AWAITING_COMPLETION;
  }

  public get awaitingNext() {
    return this.awaitingCompletion || this.awaitingContinue;
  }
}

export interface IStudentProgramsData {
  readonly skill_name: string;
  readonly skill_id: string;
  readonly id: string;
  readonly color: string;
  readonly program_status: ESubscriptionStatus;
  readonly program_percent: number;
  readonly pillar_name: string;
  readonly pillar_short_name: string;
  readonly program_version_title: string;
  readonly progress_status: ESubscriptionProgressStatus;
  readonly priority?: number | null;
  readonly priority_programs_count?: number | null;
  readonly starts_at: string;
  readonly days_to_study: number;
  readonly deadline_at: string;
  readonly is_deadline_missed: boolean;
  readonly is_skill_assignment: boolean;
  readonly in_horizon: boolean;
}

export interface IDataPrograms {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly programs: IStudentProgramsData[];
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"c-testing-question"}},[_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preload),expression:"!preload"}],staticClass:"d-flex flex-column flex-auto"},[(_vm.currentQuestion)?[_c('div',{staticClass:"flex-auto"},[_c('p',{staticClass:"font-14 font-medium"},[_vm._v(" "+_vm._s(_vm.currentQuestion.title)+" ")]),(_vm.currentQuestion.file_type === _vm.EFileType.IMAGE)?[(_vm.currentQuestion.file_url)?[_c('div',{staticClass:"t-24"},[(_vm.currentQuestion.file_url.endsWith('.pdf'))?_c('PdfView',{key:_vm.currentQuestion.file_url,attrs:{"url":_vm.currentQuestion.file_url,"preview":true}}):_c('ImageView',{key:_vm.currentQuestion.file_url,attrs:{"url":_vm.currentQuestion.file_url,"size":'responsive',"id":'image_preview'}})],1)]:_vm._e()]:(_vm.currentQuestion.file_type === _vm.EFileType.VIDEO)?[(_vm.currentQuestion.file_url)?[_c('div',{staticClass:"t-24"},[_c('VideoView',{attrs:{"size":'responsive',"url":_vm.currentQuestion.file_url}})],1)]:_vm._e()]:(_vm.currentQuestion.file_type === _vm.EFileType.AUDIO)?[(_vm.currentQuestion.file_url)?[_c('div',{staticClass:"t-24"},[_c('CAudioView',{attrs:{"url":_vm.currentQuestion.file_url}})],1)]:_vm._e()]:_vm._e(),(_vm.currentQuestion.isText)?[_c('div',{staticClass:"textarea t-34",class:{ active: _vm.textResult }},[_c('textarea-autosize',{key:_vm.currentQuestion.id,attrs:{"min-height":154,"disabled":true},model:{value:(_vm.textResult),callback:function ($$v) {_vm.textResult=$$v},expression:"textResult"}}),_c('label',{attrs:{"for":"textAnswer"}},[_vm._v("Ваш ответ")])],1)]:[_c('div',{staticClass:"t-24"},_vm._l((_vm.currentQuestion
                .questionable.answer_versions),function(answer_version,av_index){return _c('label',{key:answer_version.id,staticClass:"answer_item",class:{
                'answer_item--checked':
                  answer_version.id === _vm.singleChecked ||
                  _vm.multipleChecked.includes(answer_version.id)
              },attrs:{"for":'answer_' + av_index}},[(_vm.currentQuestion.isSingle)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.singleChecked),expression:"singleChecked"}],staticClass:"radio",attrs:{"type":"radio","id":'answer_' + av_index,"name":"answer_element","disabled":true},domProps:{"value":answer_version.id,"checked":_vm._q(_vm.singleChecked,answer_version.id)},on:{"change":function($event){_vm.singleChecked=answer_version.id}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.multipleChecked),expression:"multipleChecked"}],attrs:{"type":"checkbox","id":'answer_' + av_index,"disabled":true},domProps:{"value":answer_version.id,"checked":Array.isArray(_vm.multipleChecked)?_vm._i(_vm.multipleChecked,answer_version.id)>-1:(_vm.multipleChecked)},on:{"change":function($event){var $$a=_vm.multipleChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=answer_version.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.multipleChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.multipleChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.multipleChecked=$$c}}}}),_c('div',{staticClass:"answer_item_icon",class:[
                  answer_version.is_right
                    ? 'answer_item_icon--green'
                    : 'answer_item_icon--red'
                ],domProps:{"textContent":_vm._s((av_index + 1 + 9).toString(36).toUpperCase())}}),_c('div',{staticClass:"answer_item_label"},[_vm._v(_vm._s(answer_version.title))]),_c('div',{staticClass:"answer_item__choise-icon",class:[answer_version.is_right ? 'font-green' : 'font-red']},[(
                    answer_version.id === _vm.singleChecked ||
                      _vm.multipleChecked.includes(answer_version.id)
                  )?[(answer_version.is_right)?_c('inline-svg',{staticClass:"w-30 h-30",attrs:{"src":'/img/icon-check.svg'}}):_c('inline-svg',{staticClass:"w-30 h-30",attrs:{"src":'/img/icon-close-current.svg'}})]:_vm._e()],2)])}),0)]],2)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  testings: `/v1/testings/testings/`,
  testing: (id: string) => `/v1/testings/testings/${id}`,
  testing_archived: (id: string) => `/v1/testings/testings/${id}/archived`,
  folders: `/v1/testings/folders`,
  migration_list: `/v1/testings/folders/migrations/migration_list`,
  folder: (id: string) => `/v1/testings/folders/${id}`,
  migrate_testings: `/v1/testings/testings/migrations/migrate`,
  assign_testings: `/v1/testings/subjects/subjects/assign_subjects`,
  remove_subjects: `/v1/testings/subjects/subjects/remove_subjects`,
  not_subject: `/v1/testings/subjects/subjects/not_subject`,
  subjects: `/v1/testings/subjects/`,
  assignment: (id: string) => `/v1/testings/assignments/${id}`,
  question: (id: string) => `/v1/testings/questions/${id}`,
  show_question: (id: string) =>
    `/v1/testings/testings/testings/question/${id}`,
  save_assignment_text_result: (id: string) =>
    `/v1/testings/assignments/assignments/save_assignment_text_result/${id}`,
  save_assignment_answer_result: (id: string) =>
    `/v1/testings/assignments/assignments/save_assignment_answer_result/${id}`,
  testing_end: (id: string) =>
    `/v1/testings/assignments/assignments/testing_end/${id}`,
  testing_pause: (id: string) =>
    `/v1/testings/assignments/assignments/testing_pause/${id}`,
  change_current_question: (id: string) =>
    `/v1/testings/assignments/assignments/change_current_question/${id}`,
  change_learning_time: (id: string) =>
    `/v1/testings/assignments/assignments/change_learning_time/${id}`,
  result: (id: string) => `/v1/testings/assignments/assignments/result/${id}`,
  comments: "/v1/testings/comments",
  send_to_approval: (id: string) =>
    `/v1/testings/testings/approvals/send_to_approval/${id}`,
  assignment_result: (id: string) =>
    `/v1/testings/assignments/assignments/assignment_result/${id}`,
  confirm: (id: string, conciliator_id: string) =>
    `/v1/testings/${id}/reconciliations/${conciliator_id}/confirm`,
  cancel: (id: string, conciliator_id: string) =>
    `/v1/testings/${id}/reconciliations/${conciliator_id}/cancel`,
  checks: `/v1/testings/checks`,
  check: (id: string) => `/v1/testings/checks/${id}`
};

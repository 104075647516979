import "reflect-metadata";

import { IsUUID, IsString, IsInt, Min, Max, IsOptional } from "class-validator";
import { Type } from "class-transformer";

import StructureWorkline from "./workline";
import BlockBlock from "../block/block";

export default class StructureWorkshop {
  @IsOptional()
  @IsUUID("4")
  public readonly id?: string;

  @IsString()
  public full_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public factory_id?: string;

  @IsOptional()
  @IsUUID("4")
  public inventory_id?: string | null;

  @Type(() => StructureWorkline)
  public worklines?: StructureWorkline[];

  public is_show: boolean = false;

  public blocks?: BlockBlock[] = [];

  constructor(data?: any) {
    if (data) {
      this.factory_id = data.factory_id || this.factory_id;
      this.full_name = data.full_name || this.full_name;
      this.id = data.id || this.id;
    }
  }
}

export default {
  origins: `/v1/positions/origins`,
  origin: (id: string) => `/v1/positions/origins/${id}`,
  positions: `/v1/positions/positions`,
  positions_factory: (factory_id: string) =>
    `/v1/positions/factory/${factory_id}`,
  positions_index: `/v1/positions/positions_index`,
  position_skills: (id: string) => `/v1/positions/${id}/position_skills`,
  position: (id: string) => `/v1/positions/positions/${id}`,
  levels: `/v1/positions/levels`,
  levels_origin: `/v1/positions/level_origin`,
  levels_filter: `/v1/positions/level_filter`,
  levels_tree: `/v1/positions/levels/tree`,
  levels_tree_search: `/v1/positions/levels/tree_search`,
  level: (id: string) => `/v1/positions/levels/${id}`,
  level_blocks: (id: string) => `/v1/positions/levels/${id}/blocks`,
  my_levels: `/v1/positions/levels/my`
};

import "reflect-metadata";
import {
  IsDate,
  IsOptional,
  IsString,
  IsUUID,
  IsEnum,
  IsBoolean
} from "class-validator";
import { Type } from "class-transformer";

export enum ENotificationCode {
  REQUEST_FOR_LEARNING = 1, // Заявка на обучение по программе
  CONFIRMATION_OF_TRAINING = 2, // Утверждение времени обучения по программе
  RESCHEDULE_OF_TRAINING = 3, // Изменение времени обучения по программе
  QUESTION_ON_APPROVE = 4, // Вопрос направлен на согласование
  QUESTION_RE_APPROVE = 5, // Вопрос ожидает согласование
  AKT_ASSIGNMENT = 6, // Подчиненным назначено ЕПЗ
  APPLICATION_RE_APPROVE = 7, // заявка ожидает согласование
  APPLICATION_REJECTED = 8, // заявка отклонена
  APPLICATION_FOR_EXECUTION = 9, // заявка перешла к исполнению
  APPLICATION_UPDATE = 10, // заявка обновлена
  APPLICATION_ASSIGNED = 11, // заявка запланирована
  AKT_ASSIGNMENT_USER = 12, // Вам назначено ЕПЗ
  AKT_REMIND_REVIEWER = 13, // У вас есть ЕПЗ на проверку
  ASSIGNED_TESTING_ON_TIME = 14, // Вам назначен тест, Срок прохождения
  TESTING_SENT_FOR_REVIEW = 15, // Тест отправлен на проверку
  TESTING_REVIEW_CHECK_RESULT = 16, // Тест проверен, посмотрите результаты
  EMPLOYEE_PASSED_TESTING_CHECK_RESULT = 17, // Тест сотрудника проверен, посмотрите результаты
  TESTING_SEND_TO_APPROVAL = 18, // Вам направлен на проверку тест
  QUESTION_SEND_TO_REVIEW = 19, // Вам направлен вопрос на проверку
  ASSIGNED_TESTING = 20, // Вам назначен тест
  EDUCATION_END_CONFIRM_SKILL = 21, // обучение закончено, подтвердите навык
  EMPLOYEE_REJECTED_TESTING = 22, // Сотрудник отклонил тестирование
  EMPLOYEE_APPROVED_TESTING = 23, // Сотрудник согласовал тестирование
  EMPLOYEE_OFFERS_TRAINING_TIME = 24, // Сотрудник предлагает время обучения по программе
  CONFIRMED_THE_TRAINING_TIME_YOU_OFFERED = 25, // Сотрудник подтвердил предложенное вами время обучения
  SKILL_LEVEL_CHANGED_TO = 26, // Уровень навыка изменен
  SUBSCRIPTION_FOR_LEADER = 27, // Руководителю: Сотруднику назначение обучение по программе
  SUBSCRIPTION_FOR_LEADER_DEADLINE = 28, // Руководителю: Сотрудник просрочил обучение
  SUBSCRIPTION_FOR_SUBORDINATE = 29, // Сотруднику: Вам назначение обучение по программе
  SUBSCRIPTION_FOR_LEADER_CHANGED = 30, // Руководителю: Сотруднику изменили сроки обучения по программе
  SUBSCRIPTION_FOR_SUBORDINATE_CHANGED = 31, // Сотруднику: Вам изменили сроки обучение по программе
  PERMISSION_DELEGATED = 32, // Вам делегированы права
  DELEGATION_REVOKED = 33, // Делегирование отозвано
  TASK_ASSIGNED = 34, // Вам назначено задание
  CONFIRMATION_PROGRAM_DELEGATED = 35, // Вам делегированы права на подтверждение
  TO_VERIFICATION_TASK = 36, // индивидуальное задание отправлено на проверку
  VERIFY_TASK = 37, // индивидуальное задание проверено
  SUMMARY = 38, // итоги недели
  ALL_AKT_ASSIGNMENT_TESTS_PASSED = 40, // Все ваши подчиненные успешно завершили прохождение ЕПЗ
  PROGRAM_SEND_TO_APPROVAL = 41, // Программа отправлена на согласование
  PROGRAM_REJECTED = 42, // Согласование программы отклонено
  PROGRAM_APPROVED = 43, // Программа согласована
  PROGRAM_APPROVAL_DELEGATED = 44 // Согласование программы делегировано
}

export enum ENotificationReactionType {
  PASSIVE = 0,
  ACTIVE = 1
}

interface ISummary {
  laggards: number;
  not_end_count: number;
  success_count: number;
  un_success_count: number;
  user_id: string;
  task_verification_count: number;
  verification_count: number;
  waiting_verify_count: number;
  work_count: number;
}

export default class NotificationNotification {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsUUID("4")
  public target_user_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public sender_user_id?: string | null;

  public data?: ISummary;

  @IsUUID("4")
  public targetable_id!: string;

  @IsString()
  public targetable_type!: string;

  @IsEnum(ENotificationCode)
  public code!: ENotificationCode;

  @IsEnum(ENotificationReactionType)
  public reaction_type!: ENotificationReactionType;

  @IsString()
  public content!: string;

  @IsBoolean()
  public visited!: boolean;

  @IsDate()
  public created_at!: Date;
}

import "reflect-metadata";

import { IsString, IsUUID } from "class-validator";

export default class ProgramInspector {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public inspector_id!: string;

  @IsString()
  public readonly inspector_name!: string;

  @IsString()
  public readonly position_name!: string;

  @IsString()
  public readonly comment!: string;
}


import { Component, Vue, Prop } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";

export interface IFolder {
  id: string | null;
  name: string;
  children_exist: boolean;
  is_show?: boolean;
}

@Component({
  name: "CTestingsMovingFolderTree"
})
export default class CTestingsMovingFolderTree extends Vue {
  @Prop({ required: true }) public excluded_folder_id!: string | null;
  @Prop({ required: false, default: null }) public parent_id!: string | null;

  @Prop({ required: false, default: true }) public add_top_folder!: boolean;

  public folders: IFolder[] = [];

  protected async created() {
    if (this.parent_id == null && this.add_top_folder) {
      this.folders.push({
        id: null,
        name: "Верхний уровень",
        children_exist: false,
        is_show: false
      });
    }

    await this.loadFolders();
  }

  public toggleFolder(folder: IFolder) {
    this.$set(folder, "is_show", !folder.is_show);
  }

  public selectFolder(folder: IFolder) {
    this.$emit("input", folder);
  }

  private async loadFolders() {
    this.$api
      .get(testings_routes.migration_list, {
        params: {
          parent_folder_id: this.parent_id,
          excluded_folder_id: this.excluded_folder_id
        }
      })
      .then(({ data: res }: { data: IFolder[] }) => {
        this.folders.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

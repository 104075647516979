
import { Component, Prop, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import CTestingQuestionResult from "@/components/testing/QuestionResult.vue";

import testingsRoutes from "@/api/routes/testings";

import TestingAssignment, {
  ETestingAssignmentStatus
} from "@/models/testing/assignment";

@Component({
  components: {
    Preloader,
    CTestingQuestionResult
  },
  data: () => {
    return {
      ETestingAssignmentStatus
    };
  }
})
export default class VTeamResultTesting extends Vue {
  @Prop({ required: true }) private id!: string;

  private preload: boolean = false;

  private currentAssignmentTesting: TestingAssignment = new TestingAssignment();

  public created() {
    this.loadTestingAssignment();
  }

  private loadTestingAssignment() {
    this.preload = true;
    this.$api
      .get(testingsRoutes.assignment_result(this.id))
      .then(({ data: res }: { data: TestingAssignment }) => {
        this.currentAssignmentTesting = plainToClass(TestingAssignment, res);
        this.preload = false;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}


import { Component, Vue } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";

import { EPermission } from "@/enums/permissions";

import TestingFolder from "@/models/testing/folder";

import CTestingsMovingFolderTree, { IFolder } from "./MovingFolderTree.vue";

@Component({
  name: "CTestingsMovingFolder",
  components: {
    CTestingsMovingFolderTree
  }
})
export default class CTestingsMovingFolder extends Vue {
  private watchers: Function[] = [];

  public show_folder_list: boolean = false;

  public moving_folder_id: string | null = null;
  public target_folder: IFolder | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.movingFolderId,
        (movingFolderId: string | null) => {
          this.show_folder_list = false;

          this.moving_folder_id = movingFolderId;
          this.target_folder = null;

          if (this.moving_folder_id) {
            this.showModal();
          }
        }
      )
    );
  }

  public async moveFolder() {
    if (!this.moving_folder_id) {
      return;
    }

    return this.$api
      .put(testings_routes.folder(this.moving_folder_id), {
        parent_folder_id: this.target_folder?.id || null
      })
      .then(({ data: res }: { data: TestingFolder }) => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: `Папка ${res.name} перемещена`,
          speed: 500
        });

        this.$store.commit("movedFolder", {
          id: this.moving_folder_id,
          parent_folder_id: this.target_folder?.id || null
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public changeTargetFolder(folder: IFolder) {
    this.show_folder_list = false;

    this.target_folder = folder;
  }

  public showModal() {
    this.$modal.show("moving_folder_modal");
  }

  public hideModal() {
    this.$modal.hide("moving_folder_modal");

    this.$store.commit("movingFolderId", null);
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testings);
  }
}

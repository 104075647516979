import "reflect-metadata";
import {
  IsUUID,
  IsInt,
  IsPositive,
  IsOptional,
  IsBoolean,
  IsEnum,
  IsString
} from "class-validator";
import { Type } from "class-transformer";

import DocumentVersion from "./unitable/document/version";
import RelationVersion from "../relation/version";
import UnitUnit from "./unit";
import TestVersion from "./unitable/test/version";
import PracticalVersion from "./unitable/practical/version";
import EventVersion from "@/models/unit/unitable/event/version";

export enum EUnitableType {
  "Document::Version" = "Document::Version",
  "Test::Version" = "Test::Version",
  "Practical::Version" = "Practical::Version",
  "Event::Version" = "Event::Version",
  "Program::Inspector" = "Program::Inspector"
}

export default class UnitVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsString()
  public title?: string;

  @IsBoolean()
  public is_draft: boolean = true;

  @IsInt()
  @IsPositive()
  public version: number = 0;

  @IsInt()
  @IsPositive()
  public skill_level: number = 0;

  @IsOptional()
  @IsEnum(EUnitableType)
  public unitable_type?: EUnitableType;

  public unit?: UnitUnit;

  @Type(() => Object, {
    discriminator: {
      property: "unitable_type",
      subTypes: [
        { value: DocumentVersion, name: "Document::Version" },
        { value: TestVersion, name: "Test::Version" },
        { value: PracticalVersion, name: "Practical::Version" },
        { value: EventVersion, name: "Event::Version" }
      ]
    }
  })
  public unitable?:
    | DocumentVersion
    | TestVersion
    | PracticalVersion
    | EventVersion;

  public relation_version?: RelationVersion;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.is_draft = data.is_draft;
      this.version = data.version;
      this.skill_level = data.skill_level;
      this.unit = data.unit || new UnitUnit();
      this.relation_version = data.relation_version;
      this.unitable = data.unitable;
      this.unitable_type = data.unitable_type;
    }
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import Upload from "@/components/Upload.vue";

@Component({
  name: "CComment",
  components: { Upload }
})
export default class CComment extends Vue {
  @Prop({ type: String, required: false }) protected value?: string;

  @Prop({ type: Number, required: false, default: () => 120 })
  protected min_height!: number;

  @Prop({ type: Boolean, required: false, default: () => false })
  protected disabled?: boolean;

  @Prop({ type: Boolean, required: false, default: () => false })
  protected has_error?: boolean;

  protected acceptFiles: string[] = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
    "application/vnd.ms-excel.sheet.macroenabled.12", // xlsm MacOS|Linux
    "application/vnd.ms-excel.sheet.macroEnabled.12", //xlsm Win
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
    "application/msword", // doc
    "application/vnd.ms-excel", // xlx
    "application/vnd.ms-powerpoint", // ppt
    "application/vnd.oasis.opendocument.text", // odt
    "application/vnd.oasis.opendocument.spreadsheet", // ods
    "application/vnd.oasis.opendocument.presentation", // odp
    "text/plain" // txt
  ];
}

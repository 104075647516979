import "reflect-metadata";
import { Type } from "class-transformer";

import KpiSubLevel from "./sub_level";

export default class KpiLevel {
  public id?: string;
  public company_id?: string;

  public title?: string;

  public is_archived: boolean = false;

  public is_show: boolean = false;

  constructor(company_id: string) {
    this.company_id = company_id;
  }

  @Type(() => KpiSubLevel)
  public sub_levels: KpiSubLevel[] = [];

  public toggleShow() {
    this.is_show = !this.is_show;
  }
}

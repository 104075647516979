import { render, staticRenderFns } from "./SubscriptionUnitLearningTime.vue?vue&type=template&id=79cd51cb&scoped=true"
import script from "./SubscriptionUnitLearningTime.vue?vue&type=script&lang=ts"
export * from "./SubscriptionUnitLearningTime.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79cd51cb",
  null
  
)

export default component.exports
import { IsBoolean, IsUUID } from "class-validator";

import TestingQuestionVersion from "@/models/testing/question_version";

export default class TestingAssignmentQuestion {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public question_id!: string;

  @IsBoolean()
  public is_answered: boolean = false;

  @IsUUID("4")
  public assignment_id?: string;

  public position!: number;

  public question: TestingQuestionVersion = new TestingQuestionVersion();
}

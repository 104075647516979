import {
  IsOptional,
  IsUUID,
  IsString,
  IsInt,
  IsBoolean
} from "class-validator";

export interface IBlockSkill {
  readonly id?: string | null;
  readonly skill_id?: string | null;
  readonly origin_id?: string | null;
  readonly category_id?: number | null;
  readonly pillar_id?: string | null;
  readonly is_archived?: boolean | null;
  readonly block_id?: string | null;
  readonly full_name?: string | null;
  readonly workplace_id?: string | null;
  readonly skill_color?: string | null;
  readonly workplace_full_name?: string | null;
  level?: number | null;
  current_level?: number | null;
  target_level?: number | null;
  another_level?: number | null;
  min_level?: number | null;
  selected?: boolean;
  is_manual?: boolean;
  workplace_level?: boolean;
  display?: boolean;
}

export default class BlockBlock {
  @IsOptional()
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public full_name?: string;

  @IsOptional()
  @IsInt()
  public skill_local_count?: number;

  @IsBoolean()
  public is_local?: boolean = true;

  @IsBoolean()
  public is_compulsory_education?: boolean = false;

  public show_skills?: boolean = false;

  public skills?: IBlockSkill[] | null = [];

  public skill_count?: number = 0;
}

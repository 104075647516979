import { IsUUID, IsString, IsOptional } from "class-validator";

import BlockBlock from "../block/block";

export default class StructureEquipment {
  @IsOptional()
  @IsUUID("4")
  public readonly id?: string;

  @IsString()
  public full_name: string = "";

  @IsOptional()
  @IsUUID("4")
  public workline_id?: string;

  @IsOptional()
  @IsUUID("4")
  public inventory_id?: string | null;

  public blocks?: BlockBlock[] = [];

  public is_show?: boolean;

  constructor(data?: any) {
    if (data) {
      this.workline_id = data.workline_id || this.workline_id;
      this.full_name = data.full_name || this.full_name;
      this.id = data.id || this.id;
    }
  }
}

import {
  IsString,
  IsOptional,
  IsBoolean,
  IsPositive,
  IsInt,
  IsEnum
} from "class-validator";
import { EPermission } from "@/enums/permissions";

export default class PermissionPermission {
  @IsInt()
  @IsPositive()
  public readonly id!: number;

  @IsString()
  public value: string = "";

  @IsEnum(EPermission)
  public name?: EPermission;

  @IsString()
  public description: string = "";

  @IsOptional()
  @IsBoolean()
  public is_hide: boolean = false;

  @IsOptional()
  @IsBoolean()
  public read: boolean = false;

  @IsOptional()
  @IsBoolean()
  public write: boolean = false;

  @IsOptional()
  @IsBoolean()
  public lock_read: boolean = false;

  @IsOptional()
  @IsBoolean()
  public lock_write: boolean = false;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.value = data.value;
      this.name = data.name;
      this.description = data.description;

      this.read = typeof data.read === "undefined" ? this.read : data.read;
      this.write = typeof data.write === "undefined" ? this.read : data.read;
    }
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CPrint from "@/components/Print.vue";

import { EDeviceTypes } from "@/enums/deviceTypes";

import kpiRoutes from "@/api/routes/kpi";

interface IKpiReport {
  user_id: string;
  full_name: string;
  email: string;
  identification_number: string;
  setting_user_id: string;
  target_user_id: string;
  sub_levels_count: number;
  passed_sub_levels_count: number;
  sum_bonus: number;
}

@Component({
  components: {
    Preloader,
    CPrint
  },
  data: () => {
    return {
      EDeviceTypes
    };
  }
})
export default class VKpiReport extends Vue {
  @Prop({ default: false }) isArchive?: boolean;

  protected preload: boolean = false;
  protected currentDate: Date = new Date();
  protected reportDate: Date = new Date();

  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected watchers: Function[] = [];

  protected target_id: string | null = null;
  protected results: IKpiReport[] = [];
  protected description: string = "";

  protected agreed: string = "";
  protected manager: string = "";

  protected created() {
    if (this.$route.query.date) {
      this.reportDate = new Date(this.$route.query.date as string);
    }

    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.loadReport().finally(() => {
      this.preload = false;
    });
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadReport() {
    return this.$api
      .get(kpiRoutes.get_kpi_target_report, {
        params: { date: this.reportDate }
      })
      .then(({ data: res }: { data: { id: string; info: IKpiReport[] } }) => {
        this.results = res.info;
        this.target_id = res.id;
      });
  }

  protected async loadXlsxReport() {
    return this.$api
      .post(kpiRoutes.post_kpi_target_report_xlsx, {
        id: this.target_id,
        description: this.description,
        agreed: this.agreed,
        manager: this.manager,
        prepared: this.currentUser?.full_name
      })
      .then(({ data: res }: { data: { base64: string } }) => {
        const link = document.createElement("a");
        link.download = "Распоряжение.xlsx";
        link.href = res.base64;
        link.click();
        link.remove();
      });
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";
import { ru } from "vuejs-datepicker/dist/locale";

import Upload from "@/components/Upload.vue";
import CFileDownload from "@/components/FileDownload.vue";
import PdfView from "@/components/PdfView.vue";

import applicationRoutes from "@/api/routes/applications";

import ApplicationApplication from "@/models/application/application";
import ApplicationCertificate from "@/models/application/certificate";
import ApplicationProtocol from "@/models/application/protocol";
import UserUser from "@/models/user/user";
import ApplicationCertificateFile from "@/models/application/sertificate_file";
import ApplicationProtocolFile from "@/models/application/protocol_file";
import { ESkillDocumentTypes } from "@/models/skill/local";

@Component({
  components: { Upload, CFileDownload, PdfView },
  data: () => {
    return { ESkillDocumentTypes, ru };
  }
})
export default class CUploadDocument extends Vue {
  @Prop({ required: true })
  protected currentApplication!: ApplicationApplication;
  @Prop({ required: true }) protected selectedStudents!: UserUser[];
  @Prop({ required: false, default: false }) protected isClose!: boolean;

  protected currentCertificate: ApplicationCertificate = new ApplicationCertificate();
  protected currentProtocol: ApplicationProtocol = new ApplicationProtocol();

  protected acceptFiles: string[] = ["application/pdf"];

  protected docsError: string = "";

  protected async created() {
    await this.loadDocuments();
  }

  protected async loadDocuments() {
    if (
      this.currentApplication?.certificates?.length ||
      this.currentApplication.certificate_id
    ) {
      await this.$api
        .get(
          applicationRoutes.certificate(
            this.currentApplication.certificates?.[0]?.id! ||
              this.currentApplication.certificate_id!
          )
        )
        .then(({ data: res }: { data: ApplicationCertificate }) => {
          this.currentCertificate = plainToClass(ApplicationCertificate, res);
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    } else {
      this.currentCertificate = new ApplicationCertificate();
      this.currentCertificate.start_date = new Date();
      this.currentCertificate.end_date = new Date(
        new Date().setMonth(
          new Date().getMonth() + this.currentApplication.deadline_certificate!
        )
      );
    }

    if (
      this.currentApplication?.protocols?.length ||
      this.currentApplication.protocol_id
    ) {
      await this.$api
        .get(
          applicationRoutes.protocol(
            this.currentApplication.protocols?.[0]?.id! ||
              this.currentApplication.protocol_id!
          )
        )
        .then(({ data: res }: { data: ApplicationProtocol }) => {
          this.currentProtocol = res;
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    } else {
      this.currentProtocol = new ApplicationProtocol();
    }
  }

  protected async showUserDocs(index: number) {
    const student = this.selectedStudents[index];

    if (!student.show) {
      await this.loadCurrentStudentDocs(student);
    }

    this.$set(student, "show", !student.show);
  }

  protected async changeCertificate() {
    if (this.isClose) {
      return;
    }

    if (
      this.currentCertificate.start_date! >= this.currentCertificate.end_date!
    ) {
      this.docsError = "дата начала позже даты окончания";
      return;
    }

    if (this.currentCertificate.isEndDate()) {
      this.docsError = "Сертификат закончил действие";
      return;
    }

    if (this.currentCertificate.id) {
      await this.updateCertificate(this.currentCertificate)
        .then(({ data: res }: { data: ApplicationCertificate }) => {
          this.currentCertificate = plainToClass(ApplicationCertificate, res);
          this.docsError = "";
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    } else {
      await this.createCertificate(this.currentCertificate, null)
        .then(({ data: res }: { data: ApplicationCertificate }) => {
          this.currentCertificate = plainToClass(ApplicationCertificate, res);
          this.docsError = "";
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }

    this.$emit("updateCertificate", this.currentCertificate.id);
  }

  protected async updateCertificate(certificate: ApplicationCertificate) {
    return this.$api.put(applicationRoutes.certificate(certificate.id!), {
      certificate: certificate
    });
  }

  protected async createCertificate(
    certificate: ApplicationCertificate,
    student_id: string | null
  ) {
    return this.$api.post(applicationRoutes.certificates, {
      application_id: this.currentApplication.id,
      student_id: student_id,
      certificate: certificate
    });
  }

  protected async uploadCertificate(url: string) {
    if (this.isClose) {
      return;
    }

    await this.$api
      .post(applicationRoutes.certificate_files, {
        certificate_id: this.currentCertificate.id,
        url: url
      })
      .then(({ data: res }: { data: ApplicationCertificateFile }) => {
        this.currentCertificate.certificate_files?.push(res);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async removeCertificateFile(index: number) {
    if (this.isClose) {
      return;
    }

    await this.$api
      .destroy(
        applicationRoutes.certificate_file(
          this.currentCertificate!.certificate_files![index].id!
        )
      )
      .then(() => {
        this.$delete(this.currentCertificate!.certificate_files!, index);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async uploadProtocol(url: string) {
    if (this.isClose) {
      return;
    }

    if (this.currentProtocol.id) {
      await this.sendProtocolFile(url);
      return;
    }

    await this.$api
      .post(applicationRoutes.protocols, {
        application_id: this.currentApplication.id
      })
      .then(({ data: res }: { data: ApplicationProtocol }) => {
        this.currentProtocol = res;
        this.sendProtocolFile(url);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });

    this.$emit("updateProtocol", this.currentProtocol.id);
  }

  protected async sendProtocolFile(url: string) {
    if (this.isClose) {
      return;
    }

    await this.$api
      .post(applicationRoutes.protocol_files, {
        protocol_id: this.currentProtocol.id,
        url: url
      })
      .then(({ data: res }: { data: ApplicationProtocolFile }) => {
        this.currentProtocol.protocol_files?.push(res);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async removeProtocolFile(index: number) {
    if (this.isClose) {
      return;
    }

    await this.$api
      .destroy(
        applicationRoutes.protocol_file(
          this.currentProtocol!.protocol_files![index].id!
        )
      )
      .then(() => {
        this.$delete(this.currentProtocol!.protocol_files!, index);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadCurrentStudentDocs(student: UserUser) {
    if (student.certificate_id) {
      await this.$api
        .get(applicationRoutes.certificate(student.certificate_id))
        .then(({ data: res }: { data: ApplicationCertificate }) => {
          this.$set(
            student,
            "certificate",
            plainToClass(ApplicationCertificate, res)
          );
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    } else {
      student.certificate = new ApplicationCertificate();
      student.certificate.start_date = new Date();
      student.certificate.end_date = new Date(
        new Date().setMonth(
          new Date().getMonth() + this.currentApplication.deadline_certificate!
        )
      );
    }

    if (student.protocol_id) {
      await this.$api
        .get(applicationRoutes.protocol(student.protocol_id))
        .then(({ data: res }: { data: ApplicationProtocol }) => {
          this.$set(student, "protocol", res);
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    } else {
      student.protocol = new ApplicationProtocol();
    }
  }

  protected async uploadStudentCertificate(url: string, index: number) {
    if (this.isClose) {
      return;
    }

    const student = this.selectedStudents[index];
    await this.$api
      .post(applicationRoutes.certificate_files, {
        certificate_id: student.certificate!.id,
        url: url
      })
      .then(({ data: res }: { data: ApplicationCertificate }) => {
        student.certificate!.certificate_files?.push(res);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async removeStudentProtocolFile(file_index: number, index: number) {
    if (this.isClose) {
      return;
    }

    const student = this.selectedStudents[index];
    await this.$api
      .destroy(
        applicationRoutes.protocol_file(
          student.protocol!.protocol_files![file_index].id!
        )
      )
      .then(() => {
        this.$delete(student.protocol!.protocol_files!, file_index);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async changeStudentCertificate(index: number) {
    if (this.isClose) {
      return;
    }
    const student = this.selectedStudents[index];

    if (student.certificate!.start_date! >= student.certificate!.end_date!) {
      this.docsError = "дата начала позже даты окончания";
      return;
    }

    if (student!.certificate!.isEndDate()) {
      this.docsError = "Сертификат закончил действие";
      return;
    }

    if (student.certificate!.id) {
      await this.updateCertificate(student.certificate!)
        .then(({ data: res }: { data: ApplicationCertificate }) => {
          this.$set(
            student,
            "certificate",
            plainToClass(ApplicationCertificate, res)
          );
          this.docsError = "";
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    } else {
      await this.createCertificate(student.certificate!, student.id!)
        .then(({ data: res }: { data: ApplicationCertificate }) => {
          this.$set(
            student,
            "certificate",
            plainToClass(ApplicationCertificate, res)
          );
          this.$set(student, "certificate_id", res.id);
          this.docsError = "";
        })
        .catch(({ response: res }) => {
          this.docsError = res.data.error;

          this.$notify({
            group: "notifications",
            type: "error",
            text: res.data.error,
            speed: 500
          });
        });
    }

    this.$emit("updateCertificate", student.certificate_id);
  }

  protected async removeStudentCertificateFile(
    file_index: number,
    index: number
  ) {
    if (this.isClose) {
      return;
    }

    const student = this.selectedStudents[index];
    await this.$api
      .destroy(
        applicationRoutes.certificate_file(
          student.certificate!.certificate_files![file_index].id!
        )
      )
      .then(() => {
        this.$delete(student.certificate!.certificate_files!, file_index);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async uploadStudentProtocol(url: string, index: number) {
    if (this.isClose) {
      return;
    }

    const student = this.selectedStudents[index];
    if (student.protocol_id) {
      await this.sendStudentProtocolFile(url, student);
      return;
    }

    await this.$api
      .post(applicationRoutes.protocols, {
        application_id: this.currentApplication.id,
        student_id: student.id
      })
      .then(({ data: res }: { data: ApplicationProtocol }) => {
        this.$set(student, "protocol", res);
        this.$set(student, "protocol_id", res.id);
        this.sendStudentProtocolFile(url, student);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });

    this.$emit("updateProtocol", student.protocol_id);
  }

  protected async sendStudentProtocolFile(url: string, student: UserUser) {
    if (this.isClose) {
      return;
    }

    return this.$api
      .post(applicationRoutes.protocol_files, {
        protocol_id: student.protocol_id,
        url: url
      })
      .then(({ data: res }: { data: ApplicationProtocolFile }) => {
        student.protocol!.protocol_files?.push(res);
      })
      .catch(({ response: res }) => {
        this.docsError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

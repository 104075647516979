export default {
  factories: `/v1/structures/factories`,
  factory: (id: string) => `/v1/structures/factories/${id}`,
  factory_archived: (id: string) => `/v1/structures/factories/${id}/archived`,
  infinities: `/v1/structures/infinities`,
  infinity: (id: string) => `/v1/structures/infinities/${id}`,
  infinity_archived: (id: string) => `/v1/structures/infinities/${id}/archived`,
  workshops: `/v1/structures/workshops`,
  workshop: (id: string) => `/v1/structures/workshops/${id}`,
  worklines: `/v1/structures/worklines`,
  workline: (id: string) => `/v1/structures/worklines/${id}`,
  equipments: `/v1/structures/equipments`,
  equipment: (id: string) => `/v1/structures/equipments/${id}`,
  show_dashboard: `/v1/structures/factories/dashboard_items`,
  me_factory: `/v1/users/users/me_factory`
};


import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import analytics_routes from "@/api/routes/analytics";

import Preloader from "@/components/Preloader.vue";
import CAnalyticsDownloadReport from "./components/DownloadReport.vue";
import CAnalyticsDisplayOptions from "./components/DisplayOptions.vue";
import CAnalyticsFilters from "./components/Filters.vue";
import CAnalyticsTableHeader from "./components/TableHeader.vue";
import CAnalyticsTableBody from "./components/TableBody.vue";

import AnalyticsFilter from "@/models/analytics/filter";

@Component({
  name: "VAnalytics",
  components: {
    Preloader,
    CAnalyticsDownloadReport,
    CAnalyticsDisplayOptions,
    CAnalyticsFilters,
    CAnalyticsTableHeader,
    CAnalyticsTableBody
  }
})
export default class VAnalytics extends Vue {
  public filter: AnalyticsFilter = new AnalyticsFilter();

  public preload: boolean = false;
  private watchers: Function[] = [];

  $refs!: {
    table: HTMLFormElement;
  };

  private show_nav_menu = this.$store.state.showNavMenu;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.showNavMenu,
        showNavMenu => {
          this.show_nav_menu = showNavMenu;
        }
      )
    );

    await this.loadFilter().finally(() => {
      this.preload = false;
    });
  }

  private async loadFilter() {
    return this.$api
      .get(analytics_routes.filter)
      .then(async ({ data: res }: { data: AnalyticsFilter }) => {
        this.filter = plainToClass(AnalyticsFilter, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async applyTemplate(template: AnalyticsFilter) {
    const filter_id = this.filter.id;
    const filter_factory_id = this.filter.factory_id;
    const filter_infinity_id = this.filter.infinity_id;

    const new_filter = plainToClass(AnalyticsFilter, template);
    new_filter.id = filter_id;
    new_filter.factory_id = filter_factory_id;
    new_filter.infinity_id = filter_infinity_id;
    new_filter.template_id = template.id;

    if (new_filter.factory_id) {
      new_filter.show_workplace = true;
    }

    await this.applyFilter(new_filter);
  }

  public async applyFilter(new_filter: AnalyticsFilter | null) {
    return this.$api
      .put(analytics_routes.assign, { filters: new_filter ?? this.filter })
      .then(async ({ data: res }: { data: AnalyticsFilter }) => {
        this.filter = plainToClass(AnalyticsFilter, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async clearFilter() {
    const filter_id = this.filter.id;
    const filter_factory_id = this.filter.factory_id;
    const filter_infinity_id = this.filter.infinity_id;

    const new_filter = new AnalyticsFilter();
    new_filter.id = filter_id;
    new_filter.factory_id = filter_factory_id;
    new_filter.infinity_id = filter_infinity_id;

    if (new_filter.factory_id) {
      new_filter.show_workplace = true;
    }

    await this.applyFilter(new_filter);
  }

  public scrollLeft() {
    this.$refs.table.scrollLeft -= 200;
  }

  public scrollRight() {
    this.$refs.table.scrollLeft += 200;
  }

  public scrollLeftBtn() {
    if (this.show_nav_menu) {
      return "320px"; // 14 + 272 + 34
    } else {
      return "88px"; // 14 + 40 + 34
    }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"c-notifications-summary-content"}},[_c('h1',[_vm._v("Итоги недели")]),(_vm.notification.data)?[(_vm.notification.data.laggards)?_c('div',{staticClass:"summary__block"},[_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"header"},[_vm._v("Участники, отстающие по программе")]),_c('div',{staticClass:"circle circle__red"},[_vm._v(" "+_vm._s(_vm.notification.data.laggards)+" ")])])]):_vm._e(),(_vm.notification.data.verification_count)?_c('div',{staticClass:"summary__block"},[_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"header"},[_vm._v("Программы обучения ожидают подтверждения")]),_c('div',{staticClass:"circle circle__yellow"},[_vm._v(" "+_vm._s(_vm.notification.data.verification_count)+" ")])])]):_vm._e(),(
        _vm.notification.data.work_count +
          _vm.notification.data.task_verification_count
      )?_c('div',{staticClass:"summary__block"},[_vm._m(0),_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"text"},[_vm._v("В работе")]),_c('div',{staticClass:"circle circle__blue"},[_vm._v(" "+_vm._s(_vm.notification.data.work_count)+" ")])]),_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"text"},[_vm._v("Ожидают оценки")]),_c('div',{staticClass:"circle circle__yellow"},[_vm._v(" "+_vm._s(_vm.notification.data.task_verification_count)+" ")])])]):_vm._e(),(
        _vm.notification.data.success_count +
          _vm.notification.data.waiting_verify_count +
          _vm.notification.data.un_success_count
      )?_c('div',{staticClass:"summary__block"},[_vm._m(1),_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"text"},[_vm._v("Успешно")]),_c('div',{staticClass:"circle circle__green"},[_vm._v(" "+_vm._s(_vm.notification.data.success_count)+" ")])]),_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"text"},[_vm._v("Не успешно")]),_c('div',{staticClass:"circle circle__red"},[_vm._v(" "+_vm._s(_vm.notification.data.un_success_count)+" ")])]),_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"text"},[_vm._v("Ожидает проверки")]),_c('div',{staticClass:"circle circle__yellow"},[_vm._v(" "+_vm._s(_vm.notification.data.waiting_verify_count)+" ")])])]):_vm._e(),(_vm.notification.data.not_end_count)?_c('div',{staticClass:"summary__block"},[_c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"header"},[_vm._v("Назначенные тесты")]),_c('div',{staticClass:"circle circle__blue"},[_vm._v(" "+_vm._s(_vm.notification.data.not_end_count)+" ")])])]):_vm._e()]:_vm._e(),_c('div',{staticClass:"t-32 flex-none"},[_c('div',{staticClass:"row align-items-center middle"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-text ilr-0",on:{"click":_vm.closeSummary}},[_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-close.svg'}})],1)]),_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-blue",on:{"click":_vm.showSummary}},[_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-menu-akt_team.svg'}}),_c('span',[_vm._v("Моя команда")])],1)])])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"header"},[_vm._v("Индивидуальные задания")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"summary__block_item"},[_c('p',{staticClass:"header"},[_vm._v("ЕПЗ")])])
}]

export { render, staticRenderFns }
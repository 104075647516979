var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-subscriptions-unit-schedule-status"}},[_c('div',{staticClass:"statuses_list"},_vm._l(([
        'Новичок',
        'Специалист',
        'Профессионал',
        'Гуру'
      ]),function(level,index){return _c('div',{key:index,staticClass:"status_item",class:{
        current: _vm.current_skill_level === index,
        finished: _vm.current_skill_level > index
      }},[_vm._v(" "+_vm._s(level)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import AktResponsible from "./responsible";

export default class AktAgreement {
  public readonly id!: string;

  public approve?: boolean;
  public comment?: string;

  public responsible_id!: string;
  public responsible!: AktResponsible;
}

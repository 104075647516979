
import { Component, Prop, Vue } from "vue-property-decorator";
import subscriptionRoutes from "@/api/routes/subscriptions";
import SubscriptionProgram from "@/models/subscriptions/program";
import skillRoutes from "@/api/routes/skills";
import subscriptionsRoutes from "@/api/routes/subscriptions";

interface IMentorData {
  id: string | null;
  full_name: string;
}

@Component({})
export default class CMentorSelect extends Vue {
  @Prop({ required: true }) protected subscription_program_id!: string;

  protected potentialMentors: boolean = false;
  protected subscription?: SubscriptionProgram = new SubscriptionProgram();

  protected mentors: IMentorData[] = [];

  protected async created() {
    await this.loadProgram();
  }

  protected async loadProgram() {
    return this.$api
      .get(
        subscriptionRoutes.subscription_program(this.subscription_program_id),
        {
          params: {
            units: false
          }
        }
      )
      .then(({ data: res }: { data: SubscriptionProgram }) => {
        this.subscription = res;

        this.loadMentors(res.skill_id);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadMentors(skill_id: string) {
    return this.$api
      .get(skillRoutes.mentors, {
        params: {
          skill_id
        }
      })
      .then(({ data: res }: { data: IMentorData[] }) => {
        if (res.length === 0) {
          this.loadPotentialMentors(skill_id);

          return;
        }
        this.mentors = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadPotentialMentors(skill_id: string) {
    return this.$api
      .get(skillRoutes.potential_mentors, {
        params: {
          skill_id
        }
      })
      .then(({ data: res }: { data: IMentorData[] }) => {
        this.potentialMentors = true;

        this.mentors = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected setMentor() {
    return this.$api
      .put(
        subscriptionsRoutes.subscription_program(this.subscription_program_id),
        {
          mentor_id: this.subscription?.mentor_id
        }
      )
      .then(() => {
        this.$emit("onVisited", true);

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

import "reflect-metadata";
import { Type } from "class-transformer";

import KpiIndicator from "./indicator";

export enum EKpiSubLevelType {
  COLLECTIVE = 1,
  PERSONAL = 2
}

export const kpiSubLevelTypes = [
  {
    value: 1,
    label: "Коллективные цели"
  },
  {
    value: 2,
    label: "Личные цели"
  }
];
export default class KpiSubLevel {
  public id?: string;
  public level_id?: string;

  public title?: string;

  public type_id?: EKpiSubLevelType;

  public is_archived: boolean = false;

  public is_show: boolean = false;

  constructor(level_id: string, type_id: EKpiSubLevelType) {
    this.level_id = level_id;
    this.type_id = type_id;
  }

  @Type(() => KpiIndicator)
  public indicators: KpiIndicator[] = [];

  public toggleShow() {
    this.is_show = !this.is_show;
  }
}

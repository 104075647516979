export default {
  tags: `/v1/akts/tags`,
  questions: `/v1/akts/questions`,
  questions_index_post: `/v1/akts/questions/index`,
  question: (id: string) => `/v1/akts/questions/${id}`,
  question_comment_add: `/v1/akts/questions/comments`,
  question_comment_del: (id: string) => `/v1/akts/questions/comments/${id}`,
  approve_multi: `/v1/akts/questions/approve_multi`,
  question_to_approve: (id: string) => `/v1/akts/questions/${id}/approve`,
  question_re_approve: (id: string) => `/v1/akts/questions/${id}/reapprove`,
  questions_archived: (id: string) => `/v1/akts/questions/${id}/archived`,
  templates: `/v1/akts/templates`,
  templates_actual: `/v1/akts/templates_actual`,
  post_put_template_skill_action: `/v1/akts/templates/skills`,
  delete_template_skill_action: (
    template_version_id: string,
    skill_id: string
  ) => `/v1/akts/templates/${template_version_id}/skills/${skill_id}`,
  template: (id: string) => `/v1/akts/templates/${id}`,
  publish: (id: string) => `/v1/akts/templates/${id}/publish`,
  archived: (id: string) => `/v1/akts/templates/${id}/archived`,
  sync_responsibles: (id: string) =>
    `/v1/akts/templates/${id}/sync_responsibles`,
  sync_tags: (id: string) => `/v1/akts/templates/${id}/sync_tags`,
  create_relation: (id: string) => `/v1/akts/templates/${id}/create_relation`,
  destroy_relation: (relation_id: string) =>
    `/v1/akts/templates/destroy_relation/${relation_id}`,
  assignment_available_years: `/v1/akts/assignments/available_years`,
  assignment_available_steps: `/v1/akts/assignments/available_steps`,
  assignment_available_positions: `/v1/akts/assignments/available_positions`,
  assignment_available_workplaces: `/v1/akts/assignments/available_workplaces`,
  assignment_modules: (starts_year: string) =>
    `/v1/akts/assignments/modules?starts_year=${starts_year}`,
  assignment_settings: (assignment_id: string) =>
    `/v1/akts/assignments/${assignment_id}/settings`,
  assignment_create: `/v1/akts/assignments`,
  assignment_update: (assignment_id: string) =>
    `/v1/akts/assignments/${assignment_id}`,
  assignment_delete: (assignment_id: string) =>
    `/v1/akts/assignments/${assignment_id}`,
  assignments: (id: string) => `/v1/akts/users/${id}/assignments`,
  assignment_user: (id: string) => `/v1/akts/users/${id}`,
  get_assignment_user_passed_testings: (id: string) =>
    `/v1/akts/users/${id}/passed_testings`,
  put_assignment_user_workplaces: `/v1/akts/users/workplaces`,
  put_assignment_user_comment: (id: string) => `/v1/akts/users/${id}/comment`,
  put_assignment_user_workplace: (id: string) =>
    `/v1/akts/users/workplaces/${id}`,
  put_workplace_start: (id: string) => `/v1/akts/users/workplaces/${id}/start`,
  put_workplace_end: (id: string) => `/v1/akts/users/workplaces/${id}/end`,
  put_workplace_pause: (id: string) => `/v1/akts/users/workplaces/${id}/pause`,
  get_workplace_testing: (id: string) =>
    `/v1/akts/users/workplaces/${id}/testing`,
  get_assignment_user_for_calendar: (workplace_id: string) =>
    `/v1/akts/users/workplaces/${workplace_id}/calendar`,
  post_workplace_current_subscription: (id: string) =>
    `/v1/akts/users/workplaces/${id}/current_subscription`,
  post_workplace_learning_time: (id: string) =>
    `/v1/akts/users/workplaces/${id}/learning_time`,
  get_subscription_testing: (id: string) =>
    `/v1/akts/users/subscriptions/${id}`,
  post_subscription_text: (id: string) =>
    `/v1/akts/users/subscriptions/${id}/text`,
  post_subscription_survey: (id: string) =>
    `/v1/akts/users/subscriptions/${id}/survey`,
  put_subscription_correct: (id: string) =>
    `/v1/akts/testings/subscriptions/${id}/correct`,
  put_workplace_offline: (id: string) => `/v1/akts/testings/workplaces/${id}`,
  get_responsible_testings: `/v1/akts/testings`,
  groups: `/v1/akts/groups`,
  not_started_akts: (id: string) => `/v1/akts/users/${id}/not_started_akts`,
  imported_questions: (id: string) =>
    `/v1/akts/questions/${id}/imported_questions`,
  download_import_template: "/v1/akts/questions/download_import_template",
  get_group_results: "/v1/akts/testings/group_results",
  post_group_results_xlsx: "/v1/akts/testings/group_results_xlsx"
};


import { Component, Vue, Prop } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";

import Upload from "@/components/Upload.vue";
import ImageView from "@/components/ImageView.vue";
import PdfView from "@/components/PdfView.vue";

import AktQuestionVersion from "@/models/akt/question_version";
import { aktQuestionTypes, EAktQuestionType } from "@/models/akt/question";
import AktSurveyVersion from "@/models/akt/survey_version";
import AktTextVersion from "@/models/akt/text_version";
import AktAnswerVersion from "@/models/akt/answer_version";
import { IAktTag } from "@/models/akt/tag";

@Component({
  name: "CAktQuestionChangeQuestionable",
  components: {
    Upload,
    ImageView,
    PdfView
  }
})
export default class CAktQuestionChangeQuestionable extends Vue {
  @Prop({ required: true }) public question_version!: AktQuestionVersion;

  @Prop({ required: true }) public tags!: IAktTag[];
  public selected_tags: IAktTag[] = [];

  public akt_question_types = aktQuestionTypes;
  public EAktQuestionType = EAktQuestionType;

  public accept_files: string[] = [
    "image/png",
    "image/jpeg",
    "application/pdf"
  ];

  public question_version_questionable:
    | AktSurveyVersion
    | AktTextVersion
    | null = null;

  protected async created() {
    this.question_version_questionable = this.question_version.questionable!;
    this.selected_tags = this.question_version.tags;
  }

  public tagCreateOption(new_tag: string) {
    return { value: new_tag };
  }

  public disabled() {
    return this.question_version.isSendToApprove;
  }

  public disabledAlways() {
    return (
      this.question_version.version > 1 || this.question_version.isSendToApprove
    );
  }

  public changeQuestionType() {
    this.question_version.changeQuestionable();
    this.question_version_questionable = this.question_version.questionable!;
  }

  public selectCorrectAnswer(av_index: number) {
    if (this.question_version.isSingle) {
      (this
        .question_version_questionable as AktSurveyVersion).answer_versions.forEach(
        (av, index) => {
          if (index !== av_index) {
            av.answer!.is_correct = false;
          }
        }
      );
    }
  }

  public changeTags() {
    const res = this.selected_tags.reduce<{
      tags: IAktTag[];
      values: Set<string>;
    }>(
      (acc, curr) => {
        if (!acc.values.has(curr.value)) {
          acc.tags.push(curr);
          acc.values.add(curr.value);
        }

        return acc;
      },
      { tags: [], values: new Set([]) }
    );

    this.selected_tags = res.tags;
    this.question_version.tags = this.selected_tags;
  }

  public addAnswer(event: any) {
    if (event.target.value.length) {
      (this
        .question_version_questionable as AktSurveyVersion).answer_versions.push(
        new AktAnswerVersion({ title: event.target.value })
      );

      event.target.value = "";
    }
  }
}

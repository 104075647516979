
import { Component, Vue } from "vue-property-decorator";

import task_routes from "@/api/routes/tasks";
import skill_routes from "@/api/routes/skills";

import { EPermission } from "@/enums/permissions";
import TaskTask, { taskIndividualTabs } from "@/models/task/task";

import CTaskChangeSettings from "./TaskChangeSettings.vue";
import CTaskChangeMaterials from "./TaskChangeMaterials.vue";
import TaskSkillBlock from "@/models/task/skillBlock";
import { plainToClass } from "class-transformer";
import SkillCategory, { ESkillCategory } from "@/models/skill/category";

export interface ITaskUpdate {
  user_id: string;
  task_id: string;
  task_idx: number;
}

@Component({
  name: "CTaskUpdateIndividual",
  components: {
    CTaskChangeSettings,
    CTaskChangeMaterials
  }
})
export default class CTaskUpdateIndividual extends Vue {
  public task: ITaskUpdate | null = null;

  public new_task: TaskTask | null = null;
  public skills: TaskSkillBlock[] = [];
  public skill_categories: SkillCategory[] = [];

  public task_tabs = taskIndividualTabs;
  public current_tab_idx: number = 0;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    await this.loadSkillCategories();

    this.watchers.push(
      this.$store.watch(
        state => state.updatingIndividualTask,
        async (updatingIndividualTask: ITaskUpdate | null) => {
          if (updatingIndividualTask) {
            await Promise.all([
              this.loadSkills(updatingIndividualTask.user_id),
              this.loadTask(updatingIndividualTask.task_id)
            ]);
          }

          this.task = updatingIndividualTask;

          if (this.task) {
            this.showModal();
          }
        }
      )
    );
  }

  private async loadTask(task_id: string) {
    return this.$api
      .get(task_routes.task(task_id))
      .then(({ data: res }: { data: TaskTask }) => {
        this.new_task = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadSkills(user_id: string) {
    return this.$api
      .get(task_routes.user_skills, { params: { user_id } })
      .then(({ data: res }: { data: TaskSkillBlock[] }) => {
        this.skills = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private async loadSkillCategories() {
    return this.$api
      .get(skill_routes.categories, {
        params: { exclude_id: ESkillCategory.LEADERSHIP_ID }
      })
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skill_categories = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async updateTask() {
    return await this.$api
      .put(task_routes.task(this.new_task!.id), this.new_task)
      .then(({ data: res }: { data: TaskTask }) => {
        this.$store.commit("updatedIndividualTask", {
          info: this.task,
          task: res
        });

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("update_task_modal");
  }

  public hideModal() {
    this.$modal.hide("update_task_modal");
    this.$store.commit("updatingIndividualTask", null);
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.staffs_and_groups);
  }
}

import { IsUUID, IsString, IsOptional } from "class-validator";

import BlockBlock from "../block/block";

export default class PositionLocal {
  @IsOptional()
  @IsUUID("4")
  public id?: string;

  @IsString()
  public name: string = "";

  @IsOptional()
  @IsUUID("4")
  public readonly factory_id?: string;

  @IsOptional()
  @IsString()
  public readonly factory_full_name?: string;

  @IsOptional()
  @IsUUID("4")
  public origin_id?: string;

  @IsOptional()
  @IsUUID("4")
  public akt_position_id?: string;

  public blocks?: BlockBlock[];

  public structure_infinity_name?: string;

  constructor(data?: any) {
    if (data) {
      this.id = this.id || data.id;
      this.name == data.name || this.name;
      this.factory_id = this.factory_id || data.factory_id;
      this.origin_id = this.origin_id || data.origin_id;
    }
  }
}

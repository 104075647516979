var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',{staticClass:"v--modal-middle has_scroll",attrs:{"name":"importModal","height":"auto","scrollable":true}},[(!_vm.isLoaded)?_c('Preloader'):_vm._e(),[_c('h4',{staticClass:"flex-none",domProps:{"textContent":_vm._s('Импорт ' + _vm.getImportTypeText())}}),_c('div',{staticClass:"t-24 flex-none"},[_c('Upload',{attrs:{"accept":[
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ],"outline":true,"newForm":true},on:{"fileChanged":function($event){return _vm.createImportJob($event)}},model:{value:(_vm.file_url),callback:function ($$v) {_vm.file_url=$$v},expression:"file_url"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.import_jobs.length > 0),expression:"import_jobs.length > 0"}],staticClass:"skill_list flex-auto"},[_c('CInfiniteScroll',{attrs:{"distance":300},on:{"scroll":_vm.onLoadMore}},_vm._l((_vm.import_jobs),function(import_job){return _c('div',{key:import_job.id,staticClass:"b-24"},[_c('div',{staticClass:"row min align-items-center",on:{"click":function($event){return _vm.toggleImportJob(import_job)}}},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"font-gray line-0 font-pointer",class:{ rotate: import_job.toggle }},[_c('inline-svg',{staticClass:"w-16 h-16",attrs:{"src":'/img/icon-chevron-right.svg'}})],1)]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"progress-circular"},[(
                    _vm.getImportJobStatus(import_job) ===
                      _vm.EImportJobStatus.AWAITING
                  )?_c('div',[_c('progress-circle',{staticClass:"d-flex align-items-center justify-content-center font-0",attrs:{"diameter":40,"completed-steps":0,"circle-color":'rgba(30, 100, 170, 0.16)',"start-color":'#1E64AA',"stop-color":'#1E64AA',"circle-width":2,"total-steps":100}},[_c('inline-svg',{staticClass:"w-20 h-20 font-blue",attrs:{"src":'/img/icon-close-current.svg'},on:{"click":function($event){$event.stopPropagation();return _vm.cancelJob(import_job)}}})],1)],1):_vm._e(),(
                    _vm.getImportJobStatus(import_job) ===
                      _vm.EImportJobStatus.IN_PROGRESS
                  )?_c('div',[_c('progress-circle',{staticClass:"d-flex align-items-center justify-content-center font-0",attrs:{"diameter":40,"completed-steps":import_job.progress,"circle-color":'rgba(30, 100, 170, 0.16)',"start-color":'#1E64AA',"stop-color":'#1E64AA',"circle-width":2,"total-steps":100}},[_c('inline-svg',{staticClass:"w-20 h-20 font-orange font-pointer",attrs:{"src":'/img/icon-close-current.svg'},on:{"click":function($event){$event.stopPropagation();return _vm.cancelJob(import_job)}}})],1)],1):_vm._e(),(
                    _vm.getImportJobStatus(import_job) ===
                      _vm.EImportJobStatus.COMPLETE
                  )?_c('div',[_c('progress-circle',{staticClass:"d-flex align-items-center justify-content-center font-0",attrs:{"diameter":40,"completed-steps":100,"circle-width":0,"total-steps":100,"inner-color":'rgba(30, 100, 170, 0.16)'}},[_c('inline-svg',{staticClass:"w-20 h-20 font-blue",attrs:{"src":'/img/icon-done-inherit.svg'}})],1)],1):_vm._e(),(
                    _vm.getImportJobStatus(import_job) ===
                      _vm.EImportJobStatus.CANCELED
                  )?_c('div',[_c('progress-circle',{staticClass:"d-flex align-items-center justify-content-center font-0",attrs:{"diameter":40,"completed-steps":0,"circle-color":'rgba(250, 71, 71, 0.16)',"start-color":'#fa4747',"stop-color":'#fa4747',"circle-width":2,"total-steps":100}},[_c('div',{staticClass:"cancel-red-icon__icon"}),_c('inline-svg',{staticClass:"w-20 h-20 font-red",attrs:{"src":'/img/icon-close-current.svg'}})],1)],1):_vm._e(),(
                    _vm.getImportJobStatus(import_job) === _vm.EImportJobStatus.ERROR
                  )?_c('div',[_c('progress-circle',{staticClass:"d-flex align-items-center justify-content-center font-0",attrs:{"diameter":40,"completed-steps":100,"circle-width":0,"total-steps":100,"inner-color":'rgba(30, 100, 170, 0.16)'}},[_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-alert-red.svg'}})],1)],1):_vm._e()])]),_c('div',{staticClass:"col font-12"},[_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(import_job.creator.full_name)+" ")]),_c('span',{staticClass:"d-block t-4 font-gray"},[_vm._v(" "+_vm._s(new Date(import_job.created_at).getDate())+" "+_vm._s(_vm._f("month_text")(new Date(import_job.created_at).getMonth()))+" ")])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-20 font-0"},[(
                    _vm.getImportJobStatus(import_job) ===
                      _vm.EImportJobStatus.COMPLETE ||
                      _vm.getImportJobStatus(import_job) ===
                        _vm.EImportJobStatus.ERROR
                  )?_c('a',{staticClass:"font-blue line-0 font-pointer",attrs:{"href":_vm.static_uploader_url + import_job.file_url},on:{"click":function($event){$event.stopPropagation();}}},[_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-download.svg'}})],1):_vm._e(),(
                    _vm.getImportJobStatus(import_job) ===
                      _vm.EImportJobStatus.CANCELED
                  )?_c('a',{staticClass:"font-blue line-0 font-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.repeatJob(import_job)}}},[_c('inline-svg',{staticClass:"w-20 h-20",attrs:{"src":'/img/icon-refresh-blue.svg'}})],1):_vm._e()])])]),(import_job.toggle)?[_c('div',{staticClass:"row min"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-66"})]),_c('div',{staticClass:"col"},[(
                    typeof import_job.exit_code === 'number' &&
                      import_job.exit_code !== _vm.EImportCompletionCode.CANCELED
                  )?_c('div',{staticClass:"upload-warnings t-16"},[(
                      import_job.exit_code ===
                        _vm.EImportCompletionCode.INVALID_FILE_STRUCTURE
                    )?_c('div',{staticClass:"font-12 font-red"},_vm._l((import_job.error.sheets),function(sheet){return _c('div',{key:sheet.name},[_vm._v(" "+_vm._s(sheet.message)+" ")])}),0):_vm._e(),(import_job.success_count > 0)?_c('h6',{staticClass:"font-bold font-12 b-12"},[[_vm._v(" "+_vm._s(_vm._f("rows_count_success_skills")(import_job.success_count))+" ")]],2):_vm._e(),(import_job.errors_count > 0)?_c('h6',{staticClass:"font-bold font-12 b-12"},[[_vm._v(" "+_vm._s(_vm._f("rows_count_failure_skills")(import_job.errors_count))+" ")]],2):_vm._e(),_c('RecycleScroller',{staticClass:"scroller",attrs:{"items":import_job.import_errors || [],"item-size":20,"key-field":"id"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"row min font-12 it-2 ib-2"},[_c('div',{staticClass:"col-auto align-right font-blue"},[_c('div',[_c('span',[_vm._v(_vm._s(item.row_number)+".")])])]),_c('div',{staticClass:"col font-ellipsis",attrs:{"title":item.data}},[_vm._v(" "+_vm._s(item.data)+" ")])])]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-20"})])])]:_vm._e()],2)}),0)],1),_c('div',{staticClass:"d-flex justify-content-end t-32 flex-none"},[_c('button',{staticClass:"btn btn-text btn-close-icon",on:{"click":function($event){return _vm.$modal.hide('importModal')}}},[_vm._v(" Закрыть ")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ib-32",attrs:{"id":"v-skill-category-chart"}},[(_vm.category_avg)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12 d-flex"},[_c('CWindRoseChart',{attrs:{"labels":_vm.category_avg.labels,"datasets":[
            {
              label: 'Целевые',
              data: _vm.category_avg.target_array,
              fill: false,
              borderColor: ['#826AF9'],
              backgroundColor: ['#826AF9']
            },
            {
              label: 'Фактические',
              data: _vm.category_avg.current_array,
              fill: false,
              borderColor: ['#FFAB00'],
              backgroundColor: ['#FFAB00']
            }
          ],"title":'Тип навыков'},on:{"click":_vm.SetCategoryChart}})],1)]),_c('CCategoryChart',{attrs:{"category":_vm.category}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
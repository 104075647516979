
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import SubscriptionUnitTestQuestion from "./test/Question.vue";
import SubscriptionUnitResult from "./test/Result.vue";

import unitRoutes from "@/api/routes/units";

import SubscriptionUnitLearningTime from "@/components/SubscriptionUnitLearningTime.vue";

import SubscriptionUnit from "@/models/subscriptions/unit";
import TestQuestionVersion from "@/models/unit/unitable/test/question_version";
import TestVersion from "@/models/unit/unitable/test/version";

@Component({
  components: {
    SubscriptionUnitTestQuestion,
    SubscriptionUnitResult,
    SubscriptionUnitLearningTime
  }
})
export default class SubscriptionUnitTest extends Vue {
  @Prop({ required: true }) subscription_unit!: SubscriptionUnit;

  protected showResults = false;
  protected currentQuestion: TestQuestionVersion | null = null;
  protected currentQuestionIndex: number = 0;

  protected questionsCount: number | null = null;

  protected created() {
    this.loadTest();
  }

  @Watch("subscription_unit")
  protected loadTest() {
    if (this.subscription_unit.isDone) {
      this.showResults = true;
      return;
    }

    this.currentQuestionIndex = (this.subscription_unit.unit_version
      .unitable as TestVersion).question_versions.findIndex(
      qv => qv.is_answered === false
    );

    this.questionsCount = (this.subscription_unit.unit_version
      .unitable as TestVersion).question_versions.length;

    if (this.currentQuestionIndex !== -1) {
      this.showResults = false;

      this.currentQuestion = (this.subscription_unit.unit_version
        .unitable as TestVersion).question_versions[this.currentQuestionIndex];
    } else {
      this.showResults = true;
    }
  }

  public async nextQuestion(ids: string[]) {
    const corrects: boolean[] = [];

    const answer_ids: string[] = [];

    this.currentQuestion!.answer_versions.forEach(av => {
      const is_selected = ids.includes(av.id);

      if (is_selected) {
        answer_ids.push(av.answer.id);
      }

      av.is_selected = is_selected;

      if (av.answer.is_correct && is_selected) {
        corrects.push(true);
      } else if (av.answer.is_correct && !is_selected) {
        corrects.push(false);
      } else if (!av.answer.is_correct && is_selected) {
        corrects.push(false);
      }
    });

    if (!answer_ids.length) {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Выберите вариант ответа",
        speed: 500
      });

      return;
    }

    await this.$api
      .post(unitRoutes.test_answer_results, {
        subscription_unit_id: this.subscription_unit.id,
        question_version_id: this.currentQuestion!.id,
        answer_ids
      })
      .then(() => {
        if (corrects.length && corrects.every(Boolean)) {
          this.currentQuestion!.is_correct = true;
        } else {
          this.currentQuestion!.is_correct = false;
        }

        if (this.currentQuestionIndex < this.questionsCount! - 1) {
          this.currentQuestionIndex++;

          this.currentQuestion = (this.subscription_unit.unit_version
            .unitable as TestVersion).question_versions[
            this.currentQuestionIndex
          ];
        } else {
          this.showResults = true;
          this.$emit("testEnd", true);
        }
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

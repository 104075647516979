
import { Component, Vue, Prop } from "vue-property-decorator";

import { IProgramAgreementHistory } from "@/models/program/agreement";

@Component({
  name: "CProgramAgreementHistoryItem"
})
export default class CProgramAgreementHistoryItem extends Vue {
  @Prop({ required: true }) public agreement!: IProgramAgreementHistory;
}

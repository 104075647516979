export default {
  analytics: "/v1/analytics/analytics",
  assign: "/v1/analytics/analytics/assign",
  filter: "/v1/analytics/analytics/filter",
  templates: "/v1/analytics/analytics/templates",
  send_report: "/v1/analytics/analytics/send_report",
  categories_avg: "/v1/analytics/dashboards/categories_avg",
  group_avg_level: "/v1/analytics/dashboards/group_avg_level",
  pillar_avg_level: "/v1/analytics/dashboards/pillar_avg_level",
  workplace_avg_level: "/v1/analytics/dashboards/workplace_avg_level",
  skill_level_percent: "/v1/analytics/dashboards/skill_level_percent",
  current_group_avg_level: "/v1/analytics/dashboards/current_group_avg_level",
  current_pillar_avg_level: "/v1/analytics/dashboards/current_pillar_avg_level",
  current_position_level_skills:
    "/v1/analytics/dashboards/current_position_level_skills",
  current_workplace_avg_level:
    "/v1/analytics/dashboards/current_workplace_avg_level"
};


import { Component, Vue } from "vue-property-decorator";

import subscriptionsRoutes from "@/api/routes/subscriptions";

import Preloader from "@/components/Preloader.vue";
import {
  ESubscriptionProgressStatus,
  ESubscriptionStatus,
  IDataPrograms,
  IStudentProgramsData
} from "@/models/subscriptions/program";

export interface IDataProgress {
  id: number;
  name: string;
  clazz: string;
}

@Component({
  data: () => {
    return { ESubscriptionProgressStatus, ESubscriptionStatus };
  },
  components: {
    Preloader
  }
})
export default class VUserCatalog extends Vue {
  protected currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected preload: boolean = false;
  protected infinite_preload: boolean = false;

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected programs: IStudentProgramsData[] = [];

  protected progress: IDataProgress[] = [
    { id: 2, name: "Немного отстает", clazz: "font-orange" },
    { id: 4, name: "Успевает", clazz: "font-blue" },
    { id: 3, name: "Сильно отстает по активным программам", clazz: "font-red" }
  ];
  protected currentProgress: IDataProgress = this.progress[1];

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        async currentUser => {
          this.currentUser = currentUser;

          await this.loadAll();
        }
      )
    );

    await this.loadAll();
  }

  protected async loadAll() {
    if (!this.preload && this.currentUser?.id) {
      this.preload = true;

      await Promise.all([this.getProgress(), this.loadPrograms()]).finally(
        () => {
          this.preload = false;
        }
      );
    }
  }

  protected async getProgress() {
    return this.$api
      .get(subscriptionsRoutes.progress, {
        params: {
          id: this.currentUser!.id
        }
      })
      .then(({ data: res }: { data: { progress_status: number } }) => {
        this.currentProgress = this.progress.find(
          i => i.id === res.progress_status
        )!;
      });
  }

  protected async loadPrograms(page: number = 0) {
    this.infinite_preload = true;

    return this.$api
      .get(subscriptionsRoutes.assignment_programs(this.currentUser!.id), {
        params: {
          page,
          per_page: this.per_page,
          paginate: true
        }
      })
      .then(({ data: res }: { data: IDataPrograms }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.programs.push(...res.programs);
      })
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadPrograms(this.current_page + 1);
    }
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

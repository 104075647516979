import UserUser from "../user/user";
import AktTemplateVersion from "./template_version";

export default class AktTemplateResponsible {
  public readonly id!: string;

  public user_id?: string;
  public user?: UserUser;

  public template_version_id?: string;
  public template_version?: AktTemplateVersion;
}

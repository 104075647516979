import "reflect-metadata";
import { IsDate, IsOptional, IsString, IsUUID } from "class-validator";
import { Type } from "class-transformer";

import ApplicationCertificateFile from "@/models/application/sertificate_file";

export default class ApplicationCertificate {
  @IsUUID("4")
  public id!: string;

  @IsOptional()
  @IsUUID("4")
  public application_id?: string;

  @IsOptional()
  @IsString()
  public number?: string;

  @IsDate()
  public start_date: Date = new Date();

  @IsDate()
  public end_date: Date = new Date();

  @Type(() => ApplicationCertificateFile)
  public certificate_files?: ApplicationCertificateFile[];

  public isEndDate() {
    return new Date(this.end_date ?? new Date()) < new Date();
  }
}

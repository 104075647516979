
import { Component, Prop, Vue } from "vue-property-decorator";

import CStructure, { IStructureProp } from "@/components/Structure.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import userRoutes from "@/api/routes/users";
import subscriptionRoutes from "@/api/routes/subscriptions";
import structureRoutes from "@/api/routes/structures";

import UserUser from "@/models/user/user";
import StructureFactory from "@/models/structure/factory";
import PositionLocal from "@/models/position/local";
import positionRoutes from "@/api/routes/positions";

interface IDataUsers {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

interface IDataConfirmation {
  readonly user_name?: string;
  readonly skill_level?: number;
  readonly skill_name?: string;
  readonly skill_id?: string;
  readonly comment?: string;
}

@Component({
  components: {
    CInfiniteScroll,
    CStructure
  }
})
export default class CDelegateConfirmationEducation extends Vue {
  @Prop({ required: true }) private inspector_id!: string;

  private skill_level: number | null = null;

  public search: string = "";

  public currentUserId: string = "";

  private infinite_preload: boolean = false;
  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  public users: UserUser[] = [];

  public filters: Map<string, boolean> = new Map();
  public showFilter: boolean = false;

  public positions: PositionLocal[] = [{ name: "Все" }];
  public positionFilter: PositionLocal = this.positions[0];

  public factories: StructureFactory[] = [
    { id: "", full_name: "Все", skills: [] }
  ];
  public factoryFilter: StructureFactory = this.factories[0];

  public factory_id: string | null = null;
  public factory_uec: boolean = false;
  public structure: IStructureProp = {};

  public delegateError: string = "";

  protected async created() {
    await Promise.all([
      this.clearUsers(),
      this.loadConfirmation(),
      this.loadFactories()
    ]);
  }

  public async clearUsers() {
    this.current_page = 0;
    this.users = [];

    return await this.loadUsers();
  }

  public async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadUsers(this.current_page + 1);
    }
  }

  private async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async changePosition() {
    this.positionFilter = this.positionFilter ?? this.positions[0];

    if (this.positionFilter!.id !== this.positions[0].id) {
      this.filters.set("position", true);
    } else {
      this.filters.delete("position");
    }

    await this.clearUsers();
  }

  public async changeFactory() {
    this.structure = {};

    this.factoryFilter = this.factoryFilter ?? this.factories[0];
    this.factory_id = this.factoryFilter?.id!;
    this.factory_uec = this.factoryFilter?.uec!;

    if (this.factoryFilter!.id !== this.factories[0].id) {
      this.filters.set("factory", true);
    } else {
      this.filters.delete("factory");
    }

    await Promise.all([this.loadPositions(), this.clearUsers()]);
  }

  public async changeStructureFilter(structure: IStructureProp) {
    this.structure = structure;

    await this.clearUsers();
  }

  public async clearFilters() {
    this.factoryFilter = this.factories[0];
    this.positionFilter = this.positions[0];

    this.factory_id = null;
    this.structure = {};

    this.filters.clear();

    await this.clearUsers();
  }

  private async loadConfirmation() {
    return this.$api
      .get(subscriptionRoutes.confirmation_info(this.inspector_id))
      .then(({ data: res }: { data: IDataConfirmation }) => {
        this.skill_level = res.skill_level!;
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  private async loadUsers(page: number = 0) {
    this.infinite_preload = true;

    return this.$api
      .get(userRoutes.users_tree_search, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          factory_id: this.factoryFilter.id,
          infinity_id: this.structure.infinity_id,
          position_id: this.positionFilter.id,
          search: this.search,
          any_infinity: !this.structure.infinity_id
        }
      })
      .then(({ data: res }: { data: IDataUsers }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.users.push(...res.users);
      })
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  private async loadPositions() {
    return this.$api
      .get(positionRoutes.positions, {
        params: {
          factory_id: this.factory_id,
          paginate: false,
          matrices: true
        }
      })
      .then(({ data: res }: { data: { positions: PositionLocal[] } }) => {
        this.positions = [{ name: "Все" }];
        this.positions.push(...res.positions);

        this.positionFilter = this.positions[0];
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          duration: 500,
          speed: 500
        });
      });
  }

  public cancelDelegate() {
    this.$emit("close", true);
  }

  public async submitDelegate() {
    if (!this.currentUserId) {
      this.delegateError = "Необходимо выбрать пользователя";

      return;
    }

    return this.$api
      .put(
        subscriptionRoutes.delegate_confirmation_program(this.inspector_id),
        {
          skill_level: this.skill_level,
          user_id: this.currentUserId
        }
      )
      .then(() => {
        this.$emit("onVisited", true);
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          duration: 500,
          speed: 500
        });
      });
  }
}

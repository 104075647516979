import { IsBoolean, IsOptional, IsString, IsUUID } from "class-validator";
import { ETestingStatus } from "./testing";

export interface ITestingData {
  readonly id: string;
  name: string;
  is_select: boolean;
  readonly status: ETestingStatus;
}

export interface ITestingFolder {
  id: string | null;
  name: string;
  parent_folder_id: string | null;
}

export default class TestingFolder {
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public name!: string;

  @IsOptional()
  @IsUUID("4")
  public parent_folder_id?: string | null;

  @IsOptional()
  @IsBoolean()
  public children_exist?: boolean;

  public testings?: ITestingData[];

  public is_show: boolean = false;
}

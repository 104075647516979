var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-skill-category-chart"}},[_c('div',{staticClass:"tabs t-xs-0"},_vm._l((_vm.links),function(link){return _c('router-link',{key:link.title,attrs:{"to":link.to,"id":link.id,"tag":"div"}},[_vm._v(_vm._s(link.title))])}),1),(_vm.position_skill_level_percent)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12 d-flex"},[_c('CWindRoseChart',{attrs:{"labels":_vm.position_skill_level_percent.labels,"max":100,"step":10,"datasets":[
            {
              label: 'Процент',
              data: _vm.position_skill_level_percent.percent_array,
              fill: true,
              borderColor: ['orange'],
              backgroundColor: ['rgba(255,165,0,0.2)']
            }
          ],"title":'Уровень Т0-Т4'},on:{"click":_vm.setPositionChart}})],1),(_vm.current_position_skill_level)?[_c('div',{staticClass:"col-md-6 col-sm-12 d-flex"},[_c('CWindRoseChart',{attrs:{"labels":_vm.current_position_skill_level.labels,"datasets":[
              {
                label: 'Целевые',
                data: _vm.current_position_skill_level.target_array,
                fill: true,
                borderColor: ['blue'],
                backgroundColor: ['rgb(0,0,255,0.2)']
              },
              {
                label: 'Фактические',
                data: _vm.current_position_skill_level.current_array,
                fill: true,
                borderColor: ['orange'],
                backgroundColor: ['rgba(255,165,0,0.2)']
              }
            ],"title":_vm.position_skill_level_label}})],1),(_vm.current_position_skill_level_table)?[_c('div',{staticClass:"col-12 t-40 b-32"},[_vm._m(0),_vm._l((_vm.current_position_skill_level_table),function(skill){return _c('div',{key:`user_name=${skill.user_name}_skill_name=${skill.skill_name}`,staticClass:"row alig-items-center t-8 font-12 it-10 ib-10"},[_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(skill.user_name))]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(skill.skill_name))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.target))]),_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(skill.current))])])})],2)]:_vm._e()]:_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row middle alig-items-center h-36 font-10 font-gray"},[_c('div',{staticClass:"col-3"},[_vm._v("Сотрудник")]),_c('div',{staticClass:"col"},[_vm._v("Навык")]),_c('div',{staticClass:"col-2"},[_vm._v("Цел. ур.")]),_c('div',{staticClass:"col-2"},[_vm._v("Факт. ур.")])])
}]

export { render, staticRenderFns }

import { Component, Vue } from "vue-property-decorator";

import testings_routes from "@/api/routes/testings";

import { EPermission } from "@/enums/permissions";
import { ITestingFolder } from "@/models/testing/folder";

@Component({
  name: "CTestingsFolderDelete"
})
export default class CTestingsFolderDelete extends Vue {
  private watchers: Function[] = [];

  public folder: ITestingFolder | null = null;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.deletingTestingFolder,
        (deletingTestingFolder: ITestingFolder | null) => {
          this.folder = deletingTestingFolder;

          if (this.folder) {
            this.showModal();
          }
        }
      )
    );
  }

  public async deleteFolder() {
    return this.$api
      .destroy(testings_routes.folder(this.folder!.id!))
      .then(() => {
        this.$store.commit("deletedTestingFolder", this.folder);

        this.hideModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Папка удалена",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showModal() {
    this.$modal.show("delete_folder_modal");
  }

  public hideModal() {
    this.$modal.hide("delete_folder_modal");

    this.$store.commit("deletingTestingFolder", null);
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.testings);
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

import ApplicationApplication, {
  EApplicationStatuses
} from "@/models/application/application";
import { normalizedApplicationStatusColor } from "@/filters/application";

@Component({
  data: () => {
    return {
      EApplicationStatuses,
      normalizedApplicationStatusColor
    };
  }
})
export default class CLiveLine extends Vue {
  @Prop({ required: true })
  protected currentApplication!: ApplicationApplication;
}

import { Type } from "class-transformer";

import AktSurvey from "./survey";
import UserUser from "../user/user";
import AktAnswerVersion from "./answer_version";
import AktQuestionVersion from "./question_version";

export default class AktSurveyVersion {
  public readonly id!: string;

  public version?: number;

  public survey_id?: string;
  @Type(() => AktSurvey)
  public survey?: AktSurvey;

  public creator_id?: string;
  @Type(() => UserUser)
  public creator?: UserUser;

  public editor_id?: string;
  @Type(() => UserUser)
  public editor?: UserUser;

  @Type(() => AktAnswerVersion)
  public answer_versions: AktAnswerVersion[] = [];
  public question_version?: AktQuestionVersion;

  constructor() {
    this.version = 1;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

import task_routes from "@/api/routes/tasks";

import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import UserUser from "@/models/user/user";
import CTaskCreateIndividual from "@/views/tasks/components/TaskCreateIndividual.vue";
import CTaskDeleteIndividual, {
  ITaskDelete
} from "@/views/tasks/components/TaskDeleteIndividual.vue";
import CTaskUpdateIndividual, {
  ITaskUpdate
} from "@/views/tasks/components/TaskUpdateIndividual.vue";
import TaskTask from "@/models/task/task";
import { EPermission } from "@/enums/permissions";

interface ITaskData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly tasks: TaskTask[];
}

@Component({
  name: "CTeamTasks",
  components: {
    Preloader,
    CInfiniteScroll,
    CTaskCreateIndividual,
    CTaskUpdateIndividual,
    CTaskDeleteIndividual
  }
})
export default class CTeamTasks extends Vue {
  @Prop({ required: true }) public user!: UserUser;

  public preload: boolean = false;
  private preload_infinite: boolean = false;

  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  public tasks: TaskTask[] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.deletedIndividualTask,
        (deletedIndividualTask: ITaskDelete) => {
          if (deletedIndividualTask) {
            this.$delete(this.tasks, deletedIndividualTask.task_idx);
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.updatedIndividualTask,
        (updatedIndividualTask: { info: ITaskUpdate; task: TaskTask }) => {
          this.$set(
            this.tasks,
            updatedIndividualTask.info.task_idx,
            updatedIndividualTask.task
          );
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.createdIndividualTask,
        (createdIndividualTask: TaskTask) => {
          this.tasks.unshift(createdIndividualTask);
        }
      )
    );

    this.preload = true;

    await this.getUserTasks();

    this.preload = false;
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.getUserTasks(this.current_page + 1);
    }
  }

  private async getUserTasks(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(task_routes.tasks, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          user_id: this.user.id
        }
      })
      .then(({ data: res }: { data: ITaskData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.tasks.push(...res.tasks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  public createTask() {
    this.$store.commit("creatingIndividualTask", {
      user_id: this.user.id,
      task_id: null
    });
  }

  public updateTask(index: number) {
    const task = this.tasks[index];

    this.$store.commit("updatingIndividualTask", {
      user_id: task.user_id,
      task_id: task.id,
      task_idx: index
    });
  }

  public deleteTask(index: number) {
    const task = this.tasks[index];

    this.$store.commit("deletingIndividualTask", {
      user_id: task.user_id,
      task_id: task.id,
      task_idx: index
    });
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.staffs_and_groups);
  }
}

import BlockBlock from "./block/block";
import PillarPillar from "./pillar/pillar";

export interface ICompulsoryTabs {
  readonly label: string;
  readonly value?: boolean;
}

export const compulsoryTabs: ICompulsoryTabs[] = [
  { label: "Все" },
  { label: "Обязательное", value: true },
  { label: "Необязательное", value: false }
];

export const blocksTabs: BlockBlock[] = [
  { id: "", full_name: "Все" },
  { id: "null", full_name: "Без группы" }
];

export const pillarsTabs: PillarPillar[] = [
  { id: "", full_name: "Все" },
  { id: "null", full_name: "Без колонны" }
];

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"c-live-line"}},[(_vm.currentApplication)?_c('div',{staticClass:"col-12 t-xs-16 col-sm-auto d-flex align-items-center justify-content-end justify-content-xs-start app-labels"},[_c('div',{staticClass:"app-labels__item order-4",class:[
        _vm.currentApplication.close ? 'active' : '',
        _vm.normalizedApplicationStatusColor(_vm.EApplicationStatuses.CLOSE)
      ]},[_c('inline-svg',{staticClass:"w-14 h-14 d-xs-none",attrs:{"src":'/img/icon-status-4.svg'}}),_c('div',{staticClass:"bl-4 font-normal"},[_vm._v(" "+_vm._s(_vm._f("normalizedApplicationStatus")(_vm.EApplicationStatuses.CLOSE))+" ")])],1),_c('div',{staticClass:"app-labels__item order-3",class:[
        _vm.currentApplication.work ? 'active' : '',
        _vm.normalizedApplicationStatusColor(_vm.EApplicationStatuses.WORK)
      ]},[_c('inline-svg',{staticClass:"w-14 h-14 d-xs-none",attrs:{"src":'/img/icon-status-3.svg'}}),_c('div',{staticClass:"bl-4 font-normal"},[_vm._v(" "+_vm._s(_vm._f("normalizedApplicationStatus")(_vm.EApplicationStatuses.WORK))+" ")])],1),(_vm.currentApplication.rejected)?_c('div',{staticClass:"app-labels__item order-1 active",class:_vm._f("normalizedApplicationStatusColor")(_vm.EApplicationStatuses.REJECTED)},[_c('inline-svg',{staticClass:"w-14 h-14 d-xs-none",attrs:{"src":'/img/icon-status-5.svg'}}),_c('div',{staticClass:"bl-4 font-normal"},[_vm._v(" "+_vm._s(_vm._f("normalizedApplicationStatus")(_vm.EApplicationStatuses.REJECTED))+" ")])],1):_vm._e(),(_vm.currentApplication.withdrawn)?_c('div',{staticClass:"app-labels__item order-1 active",class:[
        _vm.normalizedApplicationStatusColor(_vm.EApplicationStatuses.WITHDRAWN)
      ]},[_c('inline-svg',{staticClass:"w-14 h-14 d-xs-none",attrs:{"src":'/img/icon-status-1.svg'}}),_c('div',{staticClass:"bl-4 font-normal"},[_vm._v(" "+_vm._s(_vm._f("normalizedApplicationStatus")(_vm.EApplicationStatuses.WITHDRAWN))+" ")])],1):_vm._e(),_c('div',{staticClass:"app-labels__item order-2",class:[
        _vm.currentApplication.approval ? 'active' : '',
        _vm.normalizedApplicationStatusColor(_vm.EApplicationStatuses.APPROVAL)
      ]},[_c('inline-svg',{staticClass:"w-14 h-14 d-xs-none",attrs:{"src":'/img/icon-status-2.svg'}}),_c('div',{staticClass:"bl-4 font-normal"},[_vm._v(" "+_vm._s(_vm._f("normalizedApplicationStatus")(_vm.EApplicationStatuses.APPROVAL))+" ")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
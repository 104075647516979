
import { Component, Prop, Vue } from "vue-property-decorator";
import UserUser from "@/models/user/user";
import CFileDownload from "@/components/FileDownload.vue";

@Component({
  components: { CFileDownload }
})
export default class CEducations extends Vue {
  @Prop({ required: true }) protected user!: UserUser;
}

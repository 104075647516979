
import { Component, Prop, Vue } from "vue-property-decorator";

import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import ImageView from "@/components/ImageView.vue";
import CAudioView from "@/components/audioView.vue";
import VideoView from "@/components/VideoView.vue";

import testingsRoutes from "@/api/routes/testings";

import TestingQuestionVersion, {
  EFileType
} from "@/models/testing/question_version";
import TestingAssignmentQuestion from "@/models/testing/assignment_question";

@Component({
  name: "CTestingQuestionResult",
  components: {
    Preloader,
    ImageView,
    CAudioView,
    VideoView
  },
  data: () => {
    return { EFileType };
  }
})
export default class CTestingQuestionResult extends Vue {
  @Prop({ required: true }) question_assignment!: TestingAssignmentQuestion;

  private preload: boolean = false;
  private currentQuestion: TestingQuestionVersion = new TestingQuestionVersion();

  private textResult: string = "";

  private singleChecked: string = "";
  private multipleChecked: string[] = [];

  public created() {
    this.loadQuestion();
  }

  private loadQuestion() {
    this.preload = true;
    this.$api
      .get(testingsRoutes.result(this.question_assignment.id))
      .then(({ data: res }: { data: TestingQuestionVersion }) => {
        this.currentQuestion = plainToClass(TestingQuestionVersion, res);

        this.textResult = "";
        this.singleChecked = "";
        this.multipleChecked = [];

        if (this.currentQuestion.isText) {
          this.textResult = this.currentQuestion?.text_result?.value || "";
        } else if (this.currentQuestion.isSingle) {
          this.singleChecked = this.currentQuestion?.answer_results?.[0].answer_version_id!;
        } else if (this.currentQuestion.isMultiple) {
          this.multipleChecked.push(
            ...(this.currentQuestion?.answer_results || []).map(
              a => a.answer_version_id
            )
          );
        }
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload = false;
      });
  }
}

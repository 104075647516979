
import { Component, Vue, Prop } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

import akt_routes from "@/api/routes/akt";

import { AssignmentUserWorkplace } from "@/models/akt/assignment_user_workplace";
import { IAktTest } from "@/models/akt/assignment_subscription";
import {
  aktWorkplaceResultStatusesFull,
  EAktAssignmentUserWorkplaceResultStatus
} from "@/models/akt/assignment_user_workplace";

@Component({
  name: "CTeamAktAssignmentOfflineResult",
  components: {}
})
export default class CTeamAktAssignmentOfflineResult extends Vue {
  @Prop({ required: true }) public readonly test!: IAktTest;
  @Prop({ required: true }) public readonly workplace!: AssignmentUserWorkplace;

  public show: boolean = false;
  public ru = ru;
  public aktWorkplaceResultStatusesFull = aktWorkplaceResultStatusesFull;

  public result_status_id: EAktAssignmentUserWorkplaceResultStatus =
    this.test.result_status_id > 1
      ? this.test.result_status_id
      : EAktAssignmentUserWorkplaceResultStatus.SUCCESS;

  public save_error: string | null = null;

  public real_starts_at: string = this.test.real_starts_at;
  public real_starts_at_error: string | null = null;

  public learning_time: { mm?: string; ss?: string } = {};
  public learning_time_error: string | null = null;

  protected created() {
    const minutes = Math.floor(this.test.learning_time / 60);
    const seconds = this.test.learning_time % 60;

    this.learning_time["mm"] = minutes > 9 ? `${minutes}` : `0${minutes}`;
    this.learning_time["ss"] = seconds > 9 ? `${seconds}` : `0${seconds}`;
  }

  public updateResultStatusId() {
    this.test.result_status_id = this.result_status_id;
  }

  public updateRealStartsAt() {
    this.real_starts_at_error = null;
    this.test.real_starts_at = this.real_starts_at;
  }

  public updateLearningTime() {
    this.learning_time_error = null;

    const parsed_learning_time =
      parseInt(this.learning_time["mm"]!, 10) * 60 +
      parseInt(this.learning_time["ss"]!, 10);

    if (isNaN(parsed_learning_time)) {
      this.test.learning_time = 0;
    } else {
      this.test.learning_time = parsed_learning_time;
    }
  }

  public async saveOffline() {
    this.save_error = null;

    if (this.test.learning_time < 1) {
      this.learning_time_error = "Введите время прохождения";

      return;
    }

    if (!this.test.real_starts_at) {
      this.real_starts_at_error = "Введите дату прохождения";

      return;
    }

    return this.$api
      .put(akt_routes.put_workplace_offline(this.test.id), {
        real_starts_at: this.test.real_starts_at,
        learning_time: this.test.learning_time,
        completion_percent: this.test.completion_percent,
        result_status_id: this.result_status_id
      })
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.save_error = res.data.error;
      });
  }
}


import { Component, Vue } from "vue-property-decorator";

import admission_routes from "@/api/routes/admission";

export interface IDeletingAdmissionRole {
  id: string;
  value: string;
  role_index: number;
}

export interface IDeletedAdmissionRole {
  role_index: number;
}

@Component({
  name: "CAdmissionRoleDelete"
})
export default class CAdmissionRoleDelete extends Vue {
  private modal_opened: boolean = false;

  public role_id: string | null = null;
  public role_value: string | null = null;
  private role_index: number | null = null;

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.deletingAdmissionRole,
        (changingAdmissionRole: IDeletingAdmissionRole | null) => {
          this.initHandler(changingAdmissionRole);
        }
      )
    );

    if (this.$store.state.deletingAdmissionRole && !this.modal_opened) {
      this.initHandler(this.$store.state.changingAdmissionRole);
    }
  }

  private initHandler(deleting_admission_role: IDeletingAdmissionRole | null) {
    if (deleting_admission_role != null) {
      this.role_id = deleting_admission_role.id;
      this.role_value = deleting_admission_role.value;
      this.role_index = deleting_admission_role.role_index;

      this.showModal();
    } else {
      this.role_id = null;
      this.role_value = null;
      this.role_index = null;
    }
  }

  public showModal() {
    this.$modal.show("delete_admission_role_modal");
    this.modal_opened = true;
  }

  public hideModal() {
    this.$modal.hide("delete_admission_role_modal");
    this.modal_opened = false;

    this.$store.commit("deletingAdmissionRole", null);
  }

  public async deleteRole() {
    if (!this.role_id) {
      return;
    }

    return this.$api
      .destroy(admission_routes.role(this.role_id))
      .then(() => {
        this.$store.commit("deletedAdmissionRole", {
          role_index: this.role_index
        });

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Роль отправлена в архив",
          speed: 500
        });

        this.hideModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}

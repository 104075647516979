
import { Component, Vue, Prop } from "vue-property-decorator";

import UserUser from "@/models/user/user";
import TestingTestingVersion from "@/models/testing/testing_version";
import TestingQuestionVersion, {
  questionType,
  fileType,
  EFileType
} from "@/models/testing/question_version";
import TestingQuestionTextVersion from "@/models/testing/question_text_version";
import TestingQuestionSurveyVersion from "@/models/testing/question_survey_version";
import TestingQuestionAnswerVersion from "@/models/testing/question_answer_version";

import CSkillFilter from "@/components/SkillFilter.vue";
import CUpload from "@/components/Upload.vue";
import CImageView from "@/components/ImageView.vue";
import CAudioView from "@/components/audioView.vue";
import CVideoView from "@/components/VideoView.vue";

interface ISkillEvent {
  readonly label: string;
  readonly value: boolean;
}

@Component({
  name: "CTestingsChangeQuestions",
  components: {
    CSkillFilter,
    CUpload,
    CImageView,
    CAudioView,
    CVideoView
  }
})
export default class CTestingsChangeQuestions extends Vue {
  @Prop({ required: true }) public testing!: TestingTestingVersion;
  @Prop({ required: true }) public questions!: TestingQuestionVersion[];
  @Prop({ required: true }) public responsibles!: UserUser[];
  @Prop({ required: false, default: false }) public disabled!: boolean;

  public question_types = questionType;
  public file_types = fileType;
  public EFileType = EFileType;

  public accepted_image_types: string[] = [
    "image/png",
    "image/jpeg",
    "application/pdf"
  ];

  public accepted_video_types: string[] = [
    "video/mp4",
    "video/mov",
    "video/avi"
  ];

  public accepted_audio_types: string[] = ["audio/mpeg", "audio/wav"];

  public skill_events: ISkillEvent[] = [
    { label: "Оставить без изменений текущий уровень навыка", value: false },
    { label: "Изменить уровень навыка", value: true }
  ];

  public changeQuestionType(index: number) {
    const question = this.questions[index];

    if (question.isText) {
      question.questionable = new TestingQuestionTextVersion();
      question.questionable_version_type = "Testing::QuestionTextVersion";
    } else {
      if (
        question.questionable_version_type === "Testing::QuestionTextVersion"
      ) {
        question.questionable = new TestingQuestionSurveyVersion();
        question.questionable_version_type = "Testing::QuestionSurveyVersion";
      }
    }
  }

  public addQuestion() {
    this.questions.push(new TestingQuestionVersion());
  }

  public removeQuestion(index: number) {
    this.questions.splice(index, 1);
  }

  public selectCorrectAnswer(av_index: number, qv_index: number) {
    const question = this.questions[qv_index];

    if (question.isSingle) {
      (question!
        .questionable! as TestingQuestionSurveyVersion).answer_versions.forEach(
        (av, index) => {
          if (index !== av_index) {
            av.is_right = false;
          }
        }
      );
    }
  }

  public addAnswer(event: any, qv_index: number) {
    const question = this.questions[qv_index];

    if (event.target.value.length) {
      (question.questionable as TestingQuestionSurveyVersion).answer_versions.push(
        new TestingQuestionAnswerVersion({ title: event.target.value })
      );

      event.target.value = "";
    }
  }

  public decPositiveLevel(question: TestingQuestionVersion) {
    if (this.disabled) {
      return;
    }

    question.positive_level <= 0 ? 0 : question.positive_level--;
  }

  public incPositiveLevel(question: TestingQuestionVersion) {
    if (this.disabled) {
      return;
    }

    question.positive_level >= 4 ? 4 : question.positive_level++;
  }

  public decNegativeLevel(question: TestingQuestionVersion) {
    if (this.disabled) {
      return;
    }

    question.negative_level <= 0 ? 0 : question.negative_level--;
  }

  public incNegativeLevel(question: TestingQuestionVersion) {
    if (this.disabled) {
      return;
    }

    question.negative_level >= 4 ? 4 : question.negative_level++;
  }

  public removeAnswer(av_index: number, qv_index: number) {
    const question = this.questions[qv_index];

    (question.questionable as TestingQuestionSurveyVersion).answer_versions.splice(
      av_index,
      1
    );
  }
}

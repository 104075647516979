
import { Component, Prop, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CStaffsAndGroupStaffsWorkline from "./Workline.vue";

import structureRoutes from "@/api/routes/structures";
import StructureWorkline from "@/models/structure/workline";
import userRoutes from "@/api/routes/users";
import UserUser from "@/models/user/user";

import { EPermission } from "@/enums/permissions";

@Component({
  name: "CStaffsAndGroupStaffsWorkshop",
  components: {
    Preloader,
    CStaffsAndGroupStaffsWorkline
  }
})
export default class CStaffsAndGroupStaffsWorkshop extends Vue {
  @Prop({ required: true }) protected workshop_id!: string;

  protected preload: boolean = false;
  protected worklines: StructureWorkline[] = [];
  protected users: UserUser[] = [];

  protected watchers: Function[] = [];

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.editedTreeUser,
        (editedTreeUser: UserUser) => {
          const userIndex = this.users.findIndex(
            u => u.id === editedTreeUser.id
          );

          if (userIndex !== -1) {
            if (!editedTreeUser.workplaces?.length) {
              this.$delete(this.users, userIndex);
            } else if (
              editedTreeUser.workplaces.findIndex(
                w =>
                  w.factory_id &&
                  w.workshop_id === this.workshop_id &&
                  !w.workline_id &&
                  !w.equipment_id &&
                  !w.infinity_id
              ) === -1
            ) {
              this.$delete(this.users, userIndex);
            } else {
              this.users[userIndex].full_name = editedTreeUser.full_name;
              this.users[userIndex].email = editedTreeUser.email;
              this.users[userIndex].identification_number =
                editedTreeUser.identification_number;
              this.users[userIndex].position_name =
                editedTreeUser.position_name;
              this.users[userIndex].signs = editedTreeUser.signs;
            }
          } else {
            if (
              editedTreeUser.workplaces?.length &&
              editedTreeUser.workplaces.findIndex(
                w =>
                  w.factory_id &&
                  w.workshop_id === this.workshop_id &&
                  !w.workline_id &&
                  !w.equipment_id &&
                  !w.infinity_id
              ) !== -1
            ) {
              this.users.push({
                id: editedTreeUser.id,
                full_name: editedTreeUser.full_name,
                email: editedTreeUser.email,
                identification_number: editedTreeUser.identification_number,
                position_name: editedTreeUser.position_name,
                signs: editedTreeUser.signs
              } as UserUser);
            }
          }
        }
      )
    );

    await Promise.all([this.loadUsers(), this.loadWorklines()]);

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadWorklines() {
    return this.$api
      .get(structureRoutes.worklines, {
        params: { workshop_id: this.workshop_id }
      })
      .then(({ data: res }: { data: StructureWorkline[] }) => {
        res.forEach(r => (r.is_show = false));

        this.worklines = res;
      });
  }

  protected async toggleWorkline(workline_index: number) {
    const workline = this.worklines[workline_index];

    workline.is_show = !workline.is_show;
  }

  protected async loadUsers() {
    return this.$api
      .get(userRoutes.users_tree, {
        params: {
          workshop_id: this.workshop_id
        }
      })
      .then(({ data: res }: { data: UserUser[] }) => {
        this.users = res;
      });
  }

  protected async beforeUpdateUser(user_index: number) {
    const user = this.users[user_index];

    this.$store.commit("editTreeUser", {
      id: user.id,
      full_name: user.full_name,
      action: "user"
    });
  }

  protected async beforeAddInGroup(user_index: number) {
    const user = this.users[user_index];

    this.$store.commit("editTreeUser", {
      id: user.id,
      full_name: user.full_name,
      action: "group"
    });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.staffs_and_groups);
  }
}

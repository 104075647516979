import UserUser from "../user/user";
import AktTextVersion from "./text_version";
import AktQuestion from "./question";

export default class AktText {
  public readonly id!: string;

  public creator_id?: string;
  public creator?: UserUser;

  public versions?: AktTextVersion[];
  public question?: AktQuestion;
}

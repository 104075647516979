import { PriorityTypes } from "@/enums/priority_types";

export interface IPrioritySkill {
  id?: string;
  priority: number;
  skill_id: string;
  full_name: string;
  is_archived: boolean;
  factory_id?: string;
  workshop_id?: string;
  workline_id?: string;
  equipment_id?: string;
  infinity_id?: string;
  user_id?: string;
  category_id: number;
  pillar_id?: string;
  origin_id?: string;
  workplace_full_name?: string;
  programs?: Array<{ id: string; title: string }>;
  block_ids?: Record<string, string>;
  sign_ids?: Record<string, string>;
  show?: boolean;
  checked?: boolean;
}

export interface IPriorityPosition {
  id: string;
  name: string;
}

export interface IPriority {
  id?: string | null;
  programs_count: number;
  priority_type: PriorityTypes;
  factory_id?: string | null;
  workshop_id?: string | null;
  workline_id?: string | null;
  equipment_id?: string | null;
  infinity_id?: string | null;
  user_id?: string | null;
  skills?: IPrioritySkill[];
  positions?: IPriorityPosition[];
}

export interface IPriorityShort {
  id: string;
  programs_count: number;
  priority_type: PriorityTypes;
  factory_id?: string | null;
  workshop_id?: string | null;
  workline_id?: string | null;
  equipment_id?: string | null;
  infinity_id?: string | null;
  user_id?: string | null;
  structure_uec?: boolean | null;
  structure_name?: string | null;
}

/** sort by priority ${asc} nulls last, full_name asc */
export function sort_fn(asc: boolean = true) {
  return function(a: IPrioritySkill, b: IPrioritySkill) {
    if (typeof a.priority === "string" && a.priority === "") {
      a.priority = 1000;
    }

    if (typeof b.priority === "string" && b.priority === "") {
      b.priority = 1000;
    }

    if (a.priority === 1000) {
      return 1;
    }

    if (b.priority === 1000) {
      return -1;
    }

    const a_priority =
      typeof a.priority === "number" ? a.priority : parseInt(a.priority, 10);

    const b_priority =
      typeof b.priority === "number" ? b.priority : parseInt(b.priority, 10);

    if (a_priority === b_priority) {
      // return 0;

      /** sort by full_name asc */
      if (a.full_name < b.full_name) {
        return -1;
      } else {
        return 1;
      }
    }

    if ((asc && a_priority > b_priority) || (!asc && a_priority < b_priority)) {
      return 1;
    }

    return -1;
  };
}

import { IsUUID, IsString, IsInt, IsOptional, IsArray } from "class-validator";

export interface ISignUserData {
  readonly full_name: string;
  readonly id?: string;
  readonly structure_name: string;
}

export default class SignUser {
  @IsUUID("4")
  public readonly id!: string;

  @IsString()
  public full_name: string = "";

  @IsArray()
  public users: ISignUserData[] | null = [];

  @IsOptional()
  @IsInt()
  public readonly users_count?: number;

  public show_users: boolean = false;
}

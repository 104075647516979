
import { Component, Vue } from "vue-property-decorator";

import analytics_routes from "@/api/routes/analytics";

@Component({
  name: "CAnalyticsDownloadReport"
})
export default class CAnalyticsDownloadReport extends Vue {
  private watchers: Function[] = [];

  private current_user: IJWTUser | null = this.$store.state.currentUser;

  public email: string = this.current_user?.email || "";

  public email_regex: RegExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );
  }

  public showDownloadReportModal() {
    this.$modal.show("download_report_modal");
  }

  public hideDownloadReportModal() {
    this.$modal.hide("download_report_modal");
  }

  public async downloadReport() {
    return this.$api
      .post(analytics_routes.send_report, {
        email: this.email
      })
      .then(() => {
        this.hideDownloadReportModal();

        this.$notify({
          group: "notifications",
          type: "success",
          text:
            "Спасибо! Отчет будет отправлен на email в течение 1 часа. В некоторых случаях генерация может занять длительное время",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CPrint extends Vue {
  @Prop({ default: () => false }) disabled!: boolean;

  protected print() {
    window.focus();
    window.print();
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import subscriptionRoutes from "@/api/routes/subscriptions";

interface IBody {
  is_started?: boolean;
  learning_time?: number;
}

@Component
export default class SubscriptionUnitLearningTime extends Vue {
  @Prop() private subscription_unit_id!: string;

  private counter: NodeJS.Timeout | null = null;

  private created() {
    this.updateSubscription({ is_started: true });
  }

  private mounted() {
    this.counter = setInterval(() => {
      this.updateSubscription({ learning_time: 5 });
    }, 5000);
  }

  private updateSubscription(body: IBody) {
    this.$api.put(
      subscriptionRoutes.subscription_unit(this.subscription_unit_id),
      body
    );
  }

  private beforeDestroy() {
    if (this.counter) {
      clearInterval(this.counter);
      this.counter = null;
    }
  }
}


import { Component, Vue } from "vue-property-decorator";

import { sagChildRoutes } from "@/router/staffs_and_groups";

@Component
export default class StaffsAndGroups extends Vue {
  private links = sagChildRoutes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });
}

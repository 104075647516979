
import { Component, Vue, Prop } from "vue-property-decorator";
import { ProgressCircle } from "vue-progress-circle";
import {
  IStudentProgramsData,
  ESubscriptionStatus
} from "@/models/subscriptions/program";

@Component({ name: "CDashboardPlanItem", components: { ProgressCircle } })
export default class CDashboardPlanItem extends Vue {
  @Prop({ required: true }) protected program!: IStudentProgramsData;

  public getColorByStatus(program_status: ESubscriptionStatus) {
    return program_status === ESubscriptionStatus.AWAITING_COMPLETION ||
      program_status === ESubscriptionStatus.AWAITING_CONFIRMATION
      ? "font-orange"
      : program_status === ESubscriptionStatus.FAILED
      ? "font-red"
      : "font-blue";
  }
}

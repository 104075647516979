
import { Component, Vue, Prop } from "vue-property-decorator";
import { Route } from "vue-router";

import aktRoutes from "@/api/routes/akt";

import CAktLabel from "@/components/akt/Label.vue";
import Preloader from "@/components/Preloader.vue";
import CUserAktTestingSubscription, {
  IResponseResult
} from "./components/Subscription.vue";
import CAktTestingSubscriptionResult from "@/components/akt/SubscriptionResult.vue";

import { IAktTest, IAktTestSub } from "@/models/akt/assignment_subscription";
import { EAktType } from "@/models/akt/question_version";
import {
  EAktAssignmentUserWorkplaceResultStatus,
  EAktAssignmentUserWorkplaceStatus
} from "@/models/akt/assignment_user_workplace";
import { EAktQuestionType } from "@/models/akt/question";

@Component({
  components: {
    Preloader,
    CUserAktTestingSubscription,
    CAktTestingSubscriptionResult,
    CAktLabel
  },
  data: () => {
    return {
      EAktAssignmentUserWorkplaceResultStatus
    };
  }
})
export default class VUserAktTesting extends Vue {
  @Prop({ required: true }) protected id!: string;

  protected preload: boolean = false;
  protected showResult: boolean = false;

  protected testInfo: IAktTest | null = null;
  protected currentSub: IAktTestSub | null = null;

  protected allSubs: IAktTestSub[] = [];
  protected resultSubs: IAktTestSub[] = [];
  protected preliminarySubs: IAktTestSub[] = [];
  protected mainSubs: IAktTestSub[] = [];
  protected subCount: number = 0;
  protected EAktQuestionType = EAktQuestionType;

  protected maxMissCount: number = 0;
  protected timer: number = 0;
  protected timerInterval: NodeJS.Timeout | null = null;

  protected setToPause: boolean = false;

  protected async created() {
    await this.loadTest();
  }

  protected async loadTest() {
    this.preload = true;

    return this.$api
      .get(aktRoutes.get_workplace_testing(this.id))
      .then(async ({ data: res }: { data: IAktTest }) => {
        this.testInfo = res;

        res.subscriptions.forEach(sub => {
          if (sub.akt_type_id === EAktType.PRELIMINARY) {
            this.preliminarySubs.push(sub);
          } else {
            this.mainSubs.push(sub);
          }
        });

        this.allSubs = res.subscriptions;

        if (
          this.testInfo.status_id === EAktAssignmentUserWorkplaceStatus.PASSED
        ) {
          if (!this.allNotOpenPrelimaryCorrect) {
            this.resultSubs = Array.from(this.preliminarySubs);
          } else {
            this.resultSubs = Array.from(this.allSubs);
          }

          this.subCount = this.resultSubs.length;

          this.showResult = true;
        } else {
          this.subCount = this.allSubs.length;

          this.currentSub = res.subscriptions[res.current_subscription - 1];

          this.timer = res.max_learning_time - res.learning_time;

          if (this.timer > 0) {
            this.startTimer();
          } else {
            await this.endTest();
          }

          this.maxMissCount =
            this.mainSubs.length -
            Math.ceil(
              (this.mainSubs.length * res.template_mastery_score) / 100
            );
        }
      })
      .finally(() => {
        this.preload = false;
      });
  }

  protected startTimer() {
    this.timerInterval = setInterval(async () => {
      if (this.timer > 0) {
        this.timer--;
        this.testInfo!.learning_time++;

        await this.saveLearningTime();
      } else {
        await this.endTest();
      }
    }, 1000);
  }

  protected stopTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  }

  public beforeRouteLeave(_to: Route, _from: Route, next: (vm?: any) => void) {
    if (
      this.testInfo!.status_id !== EAktAssignmentUserWorkplaceStatus.PASSED &&
      !this.setToPause
    ) {
      if (window.confirm("Вы точно хотите завершить тест досрочно?")) {
        this.endTest();
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  protected beforeDestroy() {
    this.stopTimer();
  }

  protected async saveLearningTime() {
    return this.$api.post(aktRoutes.post_workplace_learning_time(this.id), {
      learning_time: 1
    });
  }

  protected get notAnsweredPrelimary() {
    return this.preliminarySubs.filter(s => !s.is_answered);
  }

  protected get allNotOpenPrelimaryCorrect() {
    return (
      this.preliminarySubs.filter(
        s => s.akt_question_type_id != EAktQuestionType.OPEN
      ).length ===
      this.preliminarySubs.filter(
        s => s.akt_question_type_id != EAktQuestionType.OPEN && s.is_correct
      ).length
    );
  }

  protected get allResultSubChecked() {
    return (
      this.resultSubs.length ===
      this.resultSubs.filter(s => s.is_checked).length
    );
  }

  public async setNextSub(result: IResponseResult | null) {
    if (this.setToPause) {
      return await this.pauseTest();
    }

    const prevSub = this.allSubs.find(
      s => s.position === this.currentSub!.position
    );

    let nextSub = this.allSubs.find(
      s => s.position > prevSub!.position && !s.is_answered
    );

    if (result) {
      prevSub!.is_answered = result.is_answered;
      prevSub!.is_correct = result.is_correct;
      prevSub!.is_checked = result.is_checked;
    }

    const prelimaryNotAnswered = this.notAnsweredPrelimary;

    const prelimaryNotAnsweredCount = prelimaryNotAnswered.length;

    if (prelimaryNotAnsweredCount) {
      if (nextSub && nextSub.akt_type_id !== EAktType.PRELIMINARY) {
        nextSub = prelimaryNotAnswered[0];
      }
    }

    if (!prelimaryNotAnsweredCount && !this.allNotOpenPrelimaryCorrect) {
      return await this.endTest();
    }

    if (!nextSub) {
      nextSub = this.allSubs.find(s => !s.is_answered);
    }

    if (prevSub !== nextSub) {
      prevSub!.is_current = false;
    }

    if (nextSub) {
      nextSub.is_current = true;
      this.currentSub = nextSub;

      return await this.saveCurrentSubscription();
    }

    return await this.endTest();
  }

  protected async setSub(position: number) {
    this.setToPause = false;

    const prevSub = this.allSubs.find(
      s => s.position === this.currentSub!.position
    );
    const nextSub = this.allSubs.find(s => s.position === position);

    const prelimaryNotAnsweredCount = this.preliminarySubs.filter(
      s => !s.is_answered
    ).length;

    if (prelimaryNotAnsweredCount) {
      if (nextSub!.akt_type_id !== EAktType.PRELIMINARY) {
        return;
      }
    }

    if (prevSub !== nextSub) {
      prevSub!.is_current = false;
    }

    nextSub!.is_current = true;
    this.currentSub = nextSub!;

    await this.saveCurrentSubscription();
  }

  protected async saveCurrentSubscription() {
    return this.$api
      .post(aktRoutes.post_workplace_current_subscription(this.id), {
        subscription_id: this.currentSub!.id
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected hidePauseModal() {
    this.$modal.hide("pauseModal");
  }

  protected showPauseModal() {
    this.$modal.show("pauseModal");
  }

  protected setPauseTest() {
    this.setToPause = true;

    this.$notify({
      group: "notifications",
      type: "success",
      text: "Ответьте на данный вопрос и тестирование будет приостановлено",
      speed: 500
    });

    this.hidePauseModal();
  }

  protected async pauseTest() {
    return this.$api
      .put(aktRoutes.put_workplace_pause(this.id), {})
      .then(() => {
        this.$router.push({ name: "me_akt" });
      })
      .catch(({ response }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: response.data.error,
          speed: 500
        });
      });
  }

  protected async endTest() {
    this.stopTimer();

    this.showResult = true;

    return this.$api
      .put(aktRoutes.put_workplace_end(this.id), {
        not_open_prelimary_correct: this.allNotOpenPrelimaryCorrect
      })
      .then(({ data: res }: { data: IAktTest }) => {
        this.testInfo!.status_id = res.status_id;
        this.testInfo!.result_status_id = res.result_status_id;
        this.testInfo!.passing_percent = res.passing_percent;
        this.testInfo!.completion_percent = res.completion_percent;

        if (!this.allNotOpenPrelimaryCorrect) {
          this.resultSubs = Array.from(this.preliminarySubs);
        } else {
          this.resultSubs = Array.from(this.allSubs);
        }

        this.subCount = this.resultSubs.length;

        this.showResult = true;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected formatName(fio: string) {
    return fio
      ? fio
          .split(/\s+/)
          .map((w, i) => (i ? w.substring(0, 1).toUpperCase() + "." : w))
          .join(" ")
      : "";
  }

  protected getLastpreliminarySubs() {
    return this.preliminarySubs?.[this.preliminarySubs.length - 1]?.position;
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import CStructure, { IStructureProp } from "@/components/Structure.vue";

import StructureFactory from "@/models/structure/factory";
import UserWorkplace from "@/models/user/workplace";

@Component({
  components: {
    CStructure
  }
})
export default class CWorkplace extends Vue {
  @Prop({ required: true }) factories!: StructureFactory[];
  private factory: StructureFactory | null = null;
  private factory_id: string | null = null;
  private factory_uec: boolean = false;

  @Prop({ required: true }) workplace_index!: number;
  @Prop({ required: true }) workplace!: UserWorkplace;

  @Prop({ default: true }) showBottomRow!: boolean;

  private created() {
    if (this.workplace.factory_id) {
      this.factory_id = this.workplace.factory_id;

      this.factory = this.factories.find(
        f => f.id === this.workplace.factory_id
      )!;

      this.factory_uec = !!this.factory?.uec;
    }
  }

  private changeFactory(obj: StructureFactory) {
    this.factory_id = obj.id!;
    this.factory_uec = !!obj.uec;

    this.changeWorkplace();
  }

  private changeStructure(obj: IStructureProp) {
    this.workplace.workshop_id = obj.workshop_id;
    this.workplace.workline_id = obj.workline_id;
    this.workplace.equipment_id = obj.equipment_id;
    this.workplace.infinity_id = obj.infinity_id;

    this.setStructure();
  }

  private setStructure() {
    this.workplace.factory_id = this.factory?.id || null;
  }

  private changeWorkplace() {
    this.setStructure();

    this.$emit("changeWorkplace", {
      index: this.workplace_index,
      worplace: this.workplace
    });
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";

import userRoutes from "@/api/routes/users";
import aktRoutes from "@/api/routes/akt";

import UserUser from "@/models/user/user";
import { EEventType, IEvent } from "@/helpers/calendar_helper";

interface IAssignmetnUser {
  id: string;
  comment?: string;
}

@Component({
  data: () => {
    return {
      EEventType
    };
  }
})
export default class CCalendarAkt extends Vue {
  @Prop({ required: true }) selectedEvent!: IEvent;

  private editableEvent: boolean = false;
  private employer: UserUser | null = null;
  private assignmentUser: IAssignmetnUser | null = null;
  private showComment: boolean = false;

  private created() {
    Promise.all([this.loadEmployer(), this.loadAssignmentUser()]);
  }

  private loadEmployer() {
    this.$api
      .get(userRoutes.user(this.selectedEvent.responsible_user_id!))
      .then(({ data: res }: { data: UserUser }) => {
        this.employer = res;
      });
  }

  private loadAssignmentUser() {
    this.$api
      .get(
        aktRoutes.get_assignment_user_for_calendar(this.selectedEvent.target_id)
      )
      .then(({ data: res }: { data: IAssignmetnUser }) => {
        this.assignmentUser = res;
      });
  }

  private cancelEdit() {
    this.$emit("cancelEdit");
  }

  private startAkt() {
    this.$api
      .put(aktRoutes.put_workplace_start(this.selectedEvent.target_id), {})
      .then(() => {
        this.$router.push({
          name: "akt_user_testing",
          params: { id: this.selectedEvent.target_id }
        });
      })
      .catch(({ response: res }) => {
        this.$emit("calendarError", res.data.error);
      });
  }

  private saveComment() {
    this.$api
      .put(aktRoutes.put_assignment_user_comment(this.assignmentUser!.id), {
        comment: this.assignmentUser!.comment
      })
      .then(({ data: res }: { data: IAssignmetnUser }) => {
        this.assignmentUser = res;

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$emit("calendarError", res.data.error);
      });
  }
}


import { Component, Vue } from "vue-property-decorator";

import { plainToClass } from "class-transformer";
import { ru } from "vuejs-datepicker/dist/locale";

import Preloader from "@/components/Preloader.vue";
import PdfView from "@/components/PdfView.vue";
import Upload from "@/components/Upload.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import Search from "@/components/Search.vue";
import CApplication from "@/views/training_planning/components/Application.vue";
import CNewApplication from "@/views/training_planning/components/NewApplication.vue";
import CIntervalDate from "@/views/training_planning/components/IntervalDate.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";
import CFileDownload from "@/components/FileDownload.vue";

import skillsRoutes from "@/api/routes/skills";
import applicationRoutes from "@/api/routes/applications";
import blockRoutes from "@/api/routes/blocks";

import BlockBlock from "@/models/block/block";
import ApplicationApplication, {
  EApplicationStatuses,
  EApplicationTypes,
  EEducationTypes
} from "@/models/application/application";
import SkillLocal, {
  ESkillDocumentTypes,
  skillDocumentTypes
} from "@/models/skill/local";
import UserUser from "@/models/user/user";
import ApplicationCertificate from "@/models/application/certificate";
import ApplicationProtocol from "@/models/application/protocol";
import ApplicationStudent from "@/models/application/student";
import ApplicationCertificateFile from "@/models/application/sertificate_file";
import ApplicationProtocolFile from "@/models/application/protocol_file";
import StructureFactory from "@/models/structure/factory";
import structureRoutes from "@/api/routes/structures";
import UserDocument from "@/models/user/document";
import { EPermission } from "@/enums/permissions";
import CPlanningUsers from "@/views/training_planning/components/PlanningUsers.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
}

interface ISkillData extends IData {
  readonly skills: SkillLocal[];
}

interface IPlanPaginationData extends IData {
  readonly plan: UserUser[];
}

interface IPlanAppData {
  value?: boolean;
  label: string;
}

interface IDataBlock extends IData {
  readonly blocks: BlockBlock[];
}

@Component({
  components: {
    PdfView,
    Preloader,
    CInfiniteScroll,
    Upload,
    CIntervalDate,
    CApplication,
    CNewApplication,
    CPlanningUsers,
    Search,
    CFileDownload,
    CStructure
  },
  data: () => {
    return {
      skillDocumentTypes,
      EApplicationTypes,
      EEducationTypes,
      EApplicationStatuses,
      ESkillDocumentTypes,
      ru
    };
  }
})
export default class TrainingPlanning extends Vue {
  protected filters: Map<string, boolean> = new Map();

  protected planningError: string = "";

  protected isAllUserLock = true;

  protected per_page: number = 30;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected showFilter: boolean = false;
  protected showSendedRequests: boolean = false;

  protected structure: IStructureProp = {};

  protected isNewApplicationShow: boolean = false;

  protected factories: StructureFactory[] = [
    { full_name: "Все", level_name: "Уровень", skills: [] }
  ];
  protected factoryFilter: StructureFactory = this.factories[0];
  protected factory_id: string | null = null;
  protected factory_uec: boolean = false;

  protected workshop_id: string | null = null;
  protected workline_id: string | null = null;
  protected equipment_id: string | null = null;
  protected infinity_id: string | null = null;

  protected preload: boolean = false;
  protected preload_infinite: boolean = false;

  protected search: string = this.$store.state.search;

  protected applications: ApplicationApplication[] = [];

  protected planAppFilter: IPlanAppData[] = [
    { label: "Все" },
    { value: true, label: "Сформированные" },
    { value: false, label: "Несформированные" }
  ];
  protected currentAppFilter: IPlanAppData = this.planAppFilter[0];

  protected blocksFilter: BlockBlock[] = [
    { id: "-1", full_name: "Даты обучения" },
    { id: "", full_name: "Все" }
  ];
  protected currentBlockFilter: BlockBlock = this.blocksFilter[1];
  protected currentBlockFilterIndex: number = 1;

  protected skills: SkillLocal[] = [];
  protected filteredSkills: SkillLocal[] = [];
  protected currentSkillFilter: SkillLocal = new SkillLocal();

  protected users: UserUser[] = [];

  protected hidePosition: boolean = false;

  $refs!: {
    tabs: HTMLFormElement;
  };

  protected watchers: Function[] = [];

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.clearPlans();
        }
      )
    );

    await Promise.all([
      this.clearPlans(),
      this.loadSkills(),
      this.loadBlocksFilter(),
      this.loadFactories()
    ]).then(() => {
      this.preload = false;
    });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadPlans(this.current_page + 1);
    }
  }

  protected async changeShowSendedRequests() {
    this.showSendedRequests = !this.showSendedRequests;

    if (this.showSendedRequests) {
      await this.loadApplication();
    }
  }

  protected async changeSkillFilter() {
    if (this.currentSkillFilter.id !== this.skills[0].id) {
      this.filters.set("skill", true);
      this.filteredSkills = [this.currentSkillFilter!];
    } else {
      this.filters.delete("skill");
      this.filteredSkills = this.skills.splice(1, this.skills.length);
    }

    await this.clearPlans();
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories.push(...res);
      })
      .catch(({ response: res }) => {
        this.planningError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async addApplication(application: ApplicationApplication) {
    this.applications.unshift(application);

    await this.clearFilters();
    this.hideNewApplication();
  }

  protected async downloadXlsx() {
    await this.$api
      .get(applicationRoutes.export, { params: { search: this.search } })
      .then(res => {
        const fileLink = document.createElement("a");

        fileLink.href = res.data;
        fileLink.setAttribute(
          "download",
          `plannings_${new Date().toLocaleString()}.xlsx`
        );

        fileLink.click();

        fileLink.remove();
      });
  }

  protected showNewApplication() {
    this.isNewApplicationShow = true;
    this.$modal.show("newApplicationModal");
  }

  protected hideNewApplication() {
    this.isNewApplicationShow = false;
    this.$modal.hide("newApplicationModal");
  }

  protected async clearFilters() {
    this.factoryFilter = this.factories[0];
    this.hidePosition = false;
    this.currentAppFilter = this.planAppFilter[0];
    this.currentSkillFilter = this.skills[0];
    this.filters.clear();
    return this.changeFactory();
  }

  protected async changeFactory() {
    this.factoryFilter = this.factoryFilter ?? this.factories[0];
    this.factory_id = this.factoryFilter.id!;
    this.factory_uec = !!this.factoryFilter.uec;
    if (this.factoryFilter.id !== this.factories[0].id) {
      this.filters.set("factory", true);
    } else {
      this.filters.delete("factory");
    }
    return this.changeStructure({});
  }

  protected async changeAppFilter() {
    this.currentAppFilter = this.currentAppFilter ?? this.planAppFilter[0];

    if (this.currentAppFilter !== this.planAppFilter[0]) {
      this.filters.set("app", true);
    } else {
      this.filters.delete("app");
    }
    await this.clearPlans();
  }

  protected async changeStructure(obj: IStructureProp) {
    this.workshop_id = obj.workshop_id!;
    this.workline_id = obj.workline_id!;
    this.equipment_id = obj.equipment_id!;
    this.infinity_id = obj.infinity_id!;
    return this.clearPlans();
  }

  protected async clearPlans() {
    if (this.currentBlockFilter.id === "-1") {
      return;
    }

    this.current_page = 0;
    this.users = [];
    await this.loadPlans();
  }

  protected async loadPlans(page: number = 0) {
    this.preload = true;
    this.preload_infinite = true;

    return this.$api
      .get(applicationRoutes.plannings, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          block_id: this.currentBlockFilter.id,
          skill_id: this.currentSkillFilter?.id,
          factory_id: this.factory_id,
          workshop_id: this.workshop_id,
          workline_id: this.workline_id,
          equipment_id: this.equipment_id,
          infinity_id: this.infinity_id,
          search: this.search,
          plan_app: this.currentAppFilter.value
        }
      })
      .then(({ data: res }: { data: IPlanPaginationData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.users.push(...plainToClass(UserUser, res.plan));
      })
      .catch(({ response: res }) => {
        this.planningError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
        this.preload = false;
      });
  }

  protected async loadApplication() {
    return this.$api
      .get(applicationRoutes.applications, {
        params: {
          paginate: false,
          show_close: false,
          hide_withdraw: true
        }
      })
      .then(
        ({
          data: res
        }: {
          data: { application: ApplicationApplication[] };
        }) => {
          this.applications = plainToClass(
            ApplicationApplication,
            res.application
          );
        }
      )
      .catch(({ response: res }) => {
        this.planningError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSkills() {
    return this.$api
      .get(skillsRoutes.skills, {
        params: {
          paginate: false,
          is_compulsory_education: true,
          block_id: this.currentBlockFilter.id
        }
      })
      .then(({ data: res }: { data: ISkillData }) => {
        this.skills = [
          plainToClass(SkillLocal, {
            id: "",
            full_name: "Все",
            category_id: 1,
            is_archived: false,
            is_compulsory_education: true,
            documentary_evidence: false,
            first_notification: 3,
            next_notification: 1,
            deadline: 10
          }),
          ...res.skills
        ];
        this.filteredSkills = plainToClass(SkillLocal, res.skills);
        this.currentSkillFilter = this.skills[0];
      })
      .catch(({ response: res }) => {
        this.planningError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async setTab(index: number) {
    this.currentBlockFilterIndex = index;
    this.currentBlockFilter = this.blocksFilter[index];
    if (index !== 0) {
      await this.loadSkills();
      await this.clearPlans();
    }
  }

  protected async loadBlocksFilter() {
    return this.$api
      .get(blockRoutes.blocks, {
        params: {
          is_compulsory_education: true
        }
      })
      .then(({ data: res }: { data: IDataBlock }) => {
        this.blocksFilter.push(...res.blocks);
      })
      .catch(({ response: res }) => {
        this.planningError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected tabScrollRight() {
    this.$refs.tabs.scrollLeft += 100;
  }

  protected tabScrollLeft() {
    this.$refs.tabs.scrollLeft -= 100;
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.training_planning);
  }

  protected canWriteInApplication() {
    return this.$api.canWrite(EPermission.training_applications);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

import "reflect-metadata";
import { Type } from "class-transformer";

import KpiCompany from "./company";
import KpiSetting from "./setting";

export default class KpiTemplate {
  public id?: string;

  public title?: string;

  public is_archived: boolean = false;

  public is_show: boolean = false;

  public personal_bonus: number = 20;
  public collective_bonus: number = 15;

  @Type(() => KpiCompany)
  public companies: KpiCompany[] = [];

  @Type(() => KpiSetting)
  public setting?: KpiSetting;

  public toggleShow() {
    this.is_show = !this.is_show;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { isUUID } from "class-validator";

import akt_routes from "@/api/routes/akt";

import Search from "@/components/Search.vue";
import Preloader from "@/components/Preloader.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";

import { EPermission } from "@/enums/permissions";
import { IQuestion } from "@/models/akt/question";
import { EAktStatus } from "@/models/akt/question_version";
import { IAktTag } from "@/models/akt/tag";

import CAktQuestionFilter, { IFilter } from "./components/QuestionFilter.vue";
import CAktQuestionChange, {
  IChangedAktQuestion
} from "./components/QuestionChange.vue";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly questions: IQuestion[];
}

interface IQuestionWithSelected extends IQuestion {
  is_selected: boolean;
}

@Component({
  name: "VAktQuestions",
  components: {
    CAktQuestionFilter,
    CAktQuestionChange,
    CInfiniteScroll,
    Search,
    Preloader
  }
})
export default class VAktQuestions extends Vue {
  @Prop({ required: false, default: null }) private id!: string | null;
  private target_question_version_id: string | null = this.id;

  public EAktStatus = EAktStatus;

  public preload: boolean = false;
  public preload_infinite: boolean = false;
  private per_page: number = 100;
  private current_page: number = 0;
  private next_page: boolean = false;

  private filters: IFilter = {
    akt_status_id: null,
    akt_type_id: null,
    skill_category_id: null,
    skill_id: null,
    tag_ids: null
  };
  private search: string = this.$store.state.search;
  public tags: IAktTag[] = [];

  private watchers: Function[] = [];

  public questions: IQuestionWithSelected[] = [];
  public selected_question_idx: Set<string> = new Set([]);
  public selected_question_idx_count: number = 0;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    if (
      !this.target_question_version_id &&
      this.$route.query.id &&
      isUUID(this.$route.query.id)
    ) {
      this.target_question_version_id = this.$route.query.id as string;
    }

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        async search => {
          this.search = search;

          await this.initHandler();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.changedAktQuestion,
        (changedAktQuestion: IChangedAktQuestion) => {
          if (changedAktQuestion.question_index != -1) {
            if (changedAktQuestion.question_index != null) {
              this.$set(this.questions, changedAktQuestion.question_index, {
                ...changedAktQuestion.question,
                is_selected: false
              });
            } else {
              this.questions.unshift({
                ...changedAktQuestion.question,
                is_selected: false
              });
            }
          }
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.removedAktQuestion,
        (removedAktQuestion: Pick<IChangedAktQuestion, "question_index">) => {
          if (removedAktQuestion.question_index != null) {
            this.$delete(this.questions, removedAktQuestion.question_index);
          }
        }
      )
    );

    const promises = [this.loadQuestions(), this.loadTags()];

    this.preload = true;

    await Promise.all(promises).finally(() => {
      this.preload = false;

      if (this.target_question_version_id) {
        this.updateQuestion(this.target_question_version_id, -1);
      }
    });
  }

  public async initHandler() {
    this.questions = [];

    await this.loadQuestions();
  }

  public async infiniteHandler() {
    if (this.next_page && !this.preload_infinite) {
      await this.loadQuestions(this.current_page + 1);
    }
  }

  private async loadTags() {
    return this.$api
      .get(akt_routes.tags)
      .then(({ data: res }: { data: IAktTag[] }) => {
        this.tags = res;
      });
  }

  private async loadQuestions(page: number = 0) {
    this.preload_infinite = true;

    return this.$api
      .get(akt_routes.questions, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: this.search,
          ...this.filters
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        res.questions.forEach(q => {
          this.questions.push(({
            ...q,
            is_select: this.selected_question_idx.has(q.id)
          } as unknown) as IQuestionWithSelected);
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      })
      .finally(() => {
        this.preload_infinite = false;
      });
  }

  public async applyFilters(filters: IFilter) {
    this.filters = filters;

    await this.initHandler();
  }

  public async sendToMultiApprove() {
    if (!this.selected_question_idx.size) {
      this.selected_question_idx_count = 0;

      return;
    }

    return this.$api
      .post(akt_routes.approve_multi, {
        question_version_ids: Array.from(this.selected_question_idx)
      })
      .then(() => {
        this.questions.forEach(question => {
          if (this.selected_question_idx.has(question.question_version_id)) {
            question.is_selected = false;
            question.question_version_akt_status_id =
              EAktStatus.SEND_FOR_APPROVAL;
          }
        });

        this.selected_question_idx.clear();
        this.selected_question_idx_count = 0;

        this.$notify({
          group: "notifications",
          type: "error",
          text: "Выбранные вопросы были направлены на согласование",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async resendToApprove(id: string, akt_status_id: EAktStatus) {
    if (akt_status_id !== EAktStatus.SEND_FOR_APPROVAL) {
      return;
    }

    return this.$api
      .post(akt_routes.question_re_approve(id), {})
      .then(() => {
        this.$notify({
          group: "notifications",
          type: "success",
          text: "Уведомления отправлены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public showReSendBtn(question: IQuestionWithSelected) {
    this.$set(
      question,
      "show_agreement",
      question.question_version_akt_status_id === EAktStatus.SEND_FOR_APPROVAL
    );
  }

  public hideReSendBtn(question: IQuestionWithSelected) {
    this.$set(question, "show_agreement", false);
  }

  public toggleSelected(question: IQuestionWithSelected) {
    question.is_selected = !question.is_selected;

    if (question.is_selected) {
      this.selected_question_idx.add(question.question_version_id);
      this.selected_question_idx_count++;
    } else {
      this.selected_question_idx.delete(question.question_version_id);
      this.selected_question_idx_count--;
    }
  }

  public showCheckbox(akt_status_id: EAktStatus) {
    return [EAktStatus.CREATED, EAktStatus.NOT_APPROVED].includes(
      akt_status_id
    );
  }

  public questionAktStatusClass(question: IQuestionWithSelected) {
    switch (question.question_version_akt_status_id) {
      case EAktStatus.SEND_FOR_APPROVAL:
        return "font-green";
      case EAktStatus.APPROVED:
        return "font-blue";
      case EAktStatus.NOT_APPROVED:
        return "font-red";
      default:
        return "font-gray";
    }
  }

  public createQuestion() {
    this.$store.commit("changingAktQuestion", {
      id: null,
      question_index: null
    });
  }

  public updateQuestion(id: string, question_index: number) {
    this.$store.commit("changingAktQuestion", { id, question_index });
  }

  public canWrite() {
    return this.$api.canWrite(EPermission.akt_questions);
  }
}

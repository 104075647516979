
import { Component, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

import Preloader from "@/components/Preloader.vue";
import Search from "@/components/Search.vue";

import kpiRoutes from "@/api/routes/kpi";

import { EKpiSubLevelType } from "@/models/kpi/sub_level";

interface IKpiArchiveDataSubLevel {
  id: string;
  target_user_id: string;
  title: string;
  type_id: EKpiSubLevelType;
  rating: boolean;
  comment: string;
  bonus?: string;
}

interface IKpiArchiveData {
  year: number;
  month: number;
  user_id: string;
  user_full_name: string;
  user_email: string;
  user_identification_number: string;
  user_position_name?: string;
  sub_levels: Array<IKpiArchiveDataSubLevel>;
}

interface IKpiArchiveDataModify {
  year: number;
  month: number;
  is_show: boolean;
  users: Array<{
    id: string;
    full_name: string;
    email: string;
    identification_number: string;
    position_name?: string;
    sub_levels: Array<IKpiArchiveDataSubLevel>;
    is_show: boolean;
  }>;
}

@Component({
  components: {
    Preloader,
    Search
  },
  data: () => {
    return {
      EKpiSubLevelType,
      ru
    };
  }
})
export default class VKpiArchive extends Vue {
  protected preload: boolean = false;

  protected showFilter: boolean = false;
  protected filters: Map<string, boolean> = new Map();

  protected search: string = this.$store.state.search;

  protected watchers: Function[] = [];

  protected originArchiveData: IKpiArchiveData[] = [];
  protected modifyArchiveData: IKpiArchiveDataModify[] = [];

  protected monthFilter: Date | null = null;

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.loadArchive();
        }
      )
    );

    await this.loadArchive();

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected changeModifyData() {
    this.modifyArchiveData = [];

    let current_year: null | number = null;
    let current_month: null | number = null;

    this.originArchiveData.forEach(r => {
      let skip = false;

      if (
        this.search?.length &&
        r.user_full_name.toLowerCase().indexOf(this.search.toLowerCase()) === -1
      ) {
        skip = true;
      }

      if (!skip) {
        let new_el = false;

        if (!current_year || current_year !== r.year) {
          current_year = r.year;

          new_el = true;
        }

        if (!current_month || current_month !== r.month) {
          current_month = r.month;

          new_el = true;
        }

        const user_data = {
          id: r.user_id,
          full_name: r.user_full_name,
          email: r.user_email,
          identification_number: r.user_identification_number,
          position_name: r.user_position_name,
          sub_levels: r.sub_levels,
          is_show: false
        };

        if (new_el) {
          this.modifyArchiveData.push({
            year: r.year,
            month: r.month,
            is_show: false,
            users: [user_data]
          });
        } else {
          const index = this.modifyArchiveData.length - 1;

          this.modifyArchiveData[index].users.push(user_data);
        }
      }
    });
  }

  protected async loadArchive() {
    let year = "";
    let month = "";

    if (this.monthFilter) {
      year = this.monthFilter.getFullYear().toString();
      month = (this.monthFilter.getMonth() + 1).toString();
    }

    return this.$api
      .get(
        `${kpiRoutes.get_kpi_target_archive}?year=${year}&month=${month}&search=${this.search}`
      )
      .then(({ data: res }: { data: IKpiArchiveData[] }) => {
        this.originArchiveData = res;

        this.changeModifyData();
      });
  }

  protected async clearFilters() {
    this.monthFilter = null;

    this.filters.clear();

    await this.loadArchive();
  }

  protected async changeMonthFilter() {
    if (this.monthFilter) {
      this.filters.set("month", true);

      await this.loadArchive();
    } else {
      this.filters.delete("month");
    }
  }
}

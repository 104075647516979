
import { Component, Vue, Prop } from "vue-property-decorator";

import CConstructorProgramsHeader from "./components/Header.vue";
import CConstructorProgramsTitle from "./components/Title.vue";

@Component({
  name: "VConstructorProgramsNew",
  components: {
    CConstructorProgramsHeader,
    CConstructorProgramsTitle
  }
})
export default class VConstructorProgramsNew extends Vue {
  @Prop({ required: true }) public id!: string;
}

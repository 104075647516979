import "reflect-metadata";
import { IsOptional, IsUUID } from "class-validator";
import { Type } from "class-transformer";

import ApplicationProtocolFile from "@/models/application/protocol_file";

export default class ApplicationProtocol {
  @IsUUID("4")
  public id!: string;

  @IsOptional()
  @IsUUID("4")
  public application_id?: string;

  @Type(() => ApplicationProtocolFile)
  public protocol_files?: ApplicationProtocolFile[];
}

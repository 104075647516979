export default {
  kpis: `/v1/users/kpis`,
  kpi_file: `/v1/users/kpis`,
  user_kpis: `/v1/users/user_kpis`,
  catalog: "/v1/kpi/templates/catalog",
  catalog_search: "/v1/kpi/templates/catalog_search",
  templates: "/v1/kpi/templates",
  template: (id: string) => `/v1/kpi/templates/${id}`,
  companies: "/v1/kpi/companies",
  company: (id: string) => `/v1/kpi/companies/${id}`,
  levels: "/v1/kpi/levels",
  level: (id: string) => `/v1/kpi/levels/${id}`,
  sub_levels: "/v1/kpi/sub_levels",
  sub_level: (id: string) => `/v1/kpi/sub_levels/${id}`,
  indicators: "/v1/kpi/indicators",
  indicator: (id: string) => `/v1/kpi/indicators/${id}`,
  settings: "/v1/kpi/settings",
  setting: (id: string) => `/v1/kpi/settings/${id}`,
  setting_users: `/v1/kpi/setting_users`,
  get_setting_users: `/v1/kpi/setting_users/users`,
  post_setting_users_delete: `/v1/kpi/setting_users/delete`,
  get_kpi_targets: "/v1/kpi/targets",
  get_kpi_target_templates: "/v1/kpi/targets/templates",
  get_kpi_target_assignments: (id: string) =>
    `/v1/kpi/targets/${id}/assignments`,
  get_kpi_target_assignment_collectives: (id: string, template_id?: string) =>
    `/v1/kpi/targets/${id}/assignment_collectives?template_id=${template_id}`,
  get_kpi_target_assignment_personals: (id: string, template_id?: string) =>
    `/v1/kpi/targets/${id}/assignment_personals?template_id=${template_id}`,
  post_kpi_target_user_leader_rating: (id: string) =>
    `/v1/kpi/targets/users/${id}/leader_rating`,
  get_kpi_target_profile: "/v1/kpi/targets/me",
  put_kpi_target_profile_self: "/v1/kpi/targets/me/self",
  get_kpi_target_archive: "/v1/kpi/targets/me/archive",
  get_kpi_target_report: "/v1/kpi/targets/me/report",
  post_kpi_target_report_xlsx: "/v1/kpi/targets/me/report_xlsx"
};

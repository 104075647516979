import UserUser from "../user/user";
import AktSurveyVersion from "./survey_version";
import AktAnswer from "./answer";
import AktQuestion from "./question";

export default class AktSurvey {
  public readonly id!: string;

  public creator_id?: string;
  public creator?: UserUser;

  public versions?: AktSurveyVersion[];
  public answers?: AktAnswer[];

  public question?: AktQuestion;
}

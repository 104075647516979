import {
  IsUUID,
  IsString,
  IsOptional,
  IsNumber,
  IsDate
} from "class-validator";
import EventEvent from "./event";
import { EFormatFile } from "@/enums/fileTypes";

export default class EventVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsUUID("4")
  public readonly event_id?: string;

  @IsOptional()
  @IsString()
  public leader_url?: string | null;

  @IsString()
  public leader_description?: string | null;

  @IsOptional()
  @IsString()
  public subordinate_url?: string | null;

  @IsString()
  public subordinate_description?: string | null;

  @IsString()
  public trainer_id?: string;

  @IsString()
  public event_where?: string;

  @IsNumber()
  public duration: number = 0;

  public event?: EventEvent;

  @IsString()
  public leader_file_type: string = "";

  @IsString()
  public subordinate_file_type: string = "";

  @IsOptional()
  @IsDate()
  public event_at?: Date | null = null;

  public isLeaderPdf() {
    return this.leader_file_type === EFormatFile.pdf;
  }

  public isLeaderVideo() {
    return this.leader_file_type === EFormatFile.mp4;
  }

  public isLeaderAudio() {
    return (
      this.leader_file_type === EFormatFile.mpeg ||
      this.leader_file_type === EFormatFile.wav
    );
  }

  public isLeaderImage() {
    return (
      this.leader_file_type === EFormatFile.jpeg ||
      this.leader_file_type === EFormatFile.jpg ||
      this.leader_file_type === EFormatFile.bmp ||
      this.leader_file_type === EFormatFile.png
    );
  }

  public isSubordinatePdf() {
    return this.subordinate_file_type === EFormatFile.pdf;
  }

  public isSubordinateVideo() {
    return this.subordinate_file_type === EFormatFile.mp4;
  }

  public isSubordinateAudio() {
    return (
      this.subordinate_file_type === EFormatFile.mpeg ||
      this.subordinate_file_type === EFormatFile.wav
    );
  }

  public isSubordinateImage() {
    return (
      this.subordinate_file_type === EFormatFile.jpeg ||
      this.subordinate_file_type === EFormatFile.jpg ||
      this.subordinate_file_type === EFormatFile.bmp ||
      this.subordinate_file_type === EFormatFile.png
    );
  }

  constructor(data?: any) {
    if (data) {
      this.id = data.id;

      this.leader_url = data.leader_url;
      this.leader_description = data.leader_description;

      this.subordinate_url = data.subordinate_url;
      this.subordinate_description = data.subordinate_description;

      this.trainer_id = data.trainer_id;
      this.event_where = data.event_where;

      this.event_at = data.event_at;
    }
  }
}

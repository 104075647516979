
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import ImageView from "@/components/ImageView.vue";
import PdfView from "@/components/PdfView.vue";

import aktRoutes from "@/api/routes/akt";

import { AktAssignmentSubscription } from "@/models/akt/assignment_subscription";
import AktTextVersion from "@/models/akt/text_version";
import AktSurveyVersion from "@/models/akt/survey_version";

export interface IResponseResult {
  readonly id: string;
  readonly is_correct: boolean;
  readonly is_answered: boolean;
  readonly is_checked: boolean;
}

@Component({
  name: "CUserAktTestingSubscription",
  components: {
    Preloader,
    PdfView,
    ImageView
  }
})
export default class CUserAktTestingSubscription extends Vue {
  @Prop({ required: true }) protected sub_id!: string;
  @Prop({ required: false, default: true }) protected showActions!: boolean;

  protected preload: boolean = false;
  protected subscription: AktAssignmentSubscription | null = null;

  protected textResult: string = "";

  protected singleChecked: string = "";
  protected multipleChecked: string[] = [];

  protected responseResult: IResponseResult | null = null;

  @Watch("sub_id")
  protected async changeSub() {
    await this.loadSubscription();
  }

  protected async created() {
    await this.loadSubscription();
  }

  protected async loadSubscription() {
    if (this.sub_id) {
      this.preload = true;
      this.subscription = null;
      this.responseResult = null;

      return this.$api
        .get(aktRoutes.get_subscription_testing(this.sub_id))
        .then(({ data: res }: { data: AktAssignmentSubscription }) => {
          this.subscription = plainToClass(AktAssignmentSubscription, res);

          this.textResult = "";
          this.singleChecked = "";
          this.multipleChecked = [];

          if (this.subscription.question_version.isOpen) {
            this.textResult =
              ((this.subscription.question_version
                .questionable as AktTextVersion).results || [])[0]?.value || "";
          } else if (this.subscription.question_version.isSingle) {
            (this.subscription.question_version
              .questionable as AktSurveyVersion).answer_versions.forEach(av => {
              if ((av.results || []).length) {
                this.singleChecked = av.results![0].answer_version_id;
              }
            });
          } else if (this.subscription.question_version.isMultiple) {
            (this.subscription.question_version
              .questionable as AktSurveyVersion).answer_versions.forEach(av => {
              if ((av.results || []).length) {
                this.multipleChecked.push(av.results![0].answer_version_id);
              }
            });
          }
        })
        .finally(() => {
          this.preload = false;
        });
    }
  }

  protected async sendResult() {
    if (this.subscription) {
      let req;

      if (this.subscription.question_version.isOpen) {
        req = this.$api.post(
          aktRoutes.post_subscription_text(this.subscription.id),
          {
            value: this.textResult
          }
        );
      } else if (this.subscription.question_version.isSingle) {
        req = this.$api.post(
          aktRoutes.post_subscription_survey(this.subscription.id),
          {
            answer_version_ids: [this.singleChecked]
          }
        );
      } else if (this.subscription.question_version.isMultiple) {
        req = this.$api.post(
          aktRoutes.post_subscription_survey(this.subscription.id),
          {
            answer_version_ids: this.multipleChecked
          }
        );
      }

      if (req) {
        return req
          .then(({ data: res }: { data: IResponseResult }) => {
            this.responseResult = res;

            this.nextQuestion();
          })
          .catch(({ response: res }) => {
            this.$notify({
              group: "notifications",
              type: "error",
              text: res.data.error,
              speed: 500
            });
          });
      }
    }
  }

  protected nextQuestion() {
    this.$emit("next", this.responseResult);
  }
}

import "reflect-metadata";

import { IsString, IsUUID } from "class-validator";

export default class TaskFile {
  @IsUUID("4")
  public readonly file_id?: string;

  @IsString()
  public url!: string;
}

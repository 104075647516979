import { render, staticRenderFns } from "./SearchTemplate.vue?vue&type=template&id=3bad77f0&scoped=true"
import script from "./SearchTemplate.vue?vue&type=script&lang=ts"
export * from "./SearchTemplate.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bad77f0",
  null
  
)

export default component.exports
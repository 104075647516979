
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Chart, { ChartDataset } from "chart.js/auto";

@Component({
  components: {}
})
export default class CWindRoseChart extends Vue {
  @Prop({ default: [] }) readonly labels!: string[];
  @Prop({ default: [] }) readonly datasets: any;
  @Prop({ default: 0 }) readonly min!: number;
  @Prop({ default: 4 }) readonly max!: number;
  @Prop({ default: 1 }) readonly step!: number;
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: false }) readonly showSlot!: boolean;

  $refs!: {
    wind_rose_chart: HTMLCanvasElement;
    legend: HTMLElement;
    ticks: HTMLElement;
    pointLabel: HTMLElement[];
  };

  protected chart: any;

  protected mounted() {
    this.chart = new Chart(this.$refs.wind_rose_chart, {
      type: "radar",
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        scales: {
          r: {
            suggestedMin: this.min,
            suggestedMax: this.max,
            beginAtZero: true,
            ticks: {
              stepSize: this.step,
              backdropColor: "rgba(0, 0, 0, 0.87)",
              color: "#fff",
              backdropPadding: 3,
              display: false,
              font: {
                size: 10,
                family: "Roboto",
                weight: "bold",
                lineHeight: 0
              },
              z: 10
            },
            pointLabels: {
              display: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              padding: 12,
              color: "rgba(0, 0, 0, 0)",
              font: {
                size: 10,
                family: "Roboto",
                weight: "medium",
                lineHeight: 1
              },
              callback: (label: string) => {
                return label.length > 20 ? label.substr(0, 20) + "..." : label;
              }
            }
          }
        },
        elements: {
          point: {
            radius: 0,
            backgroundColor: "rgba(0,0,0,0)"
          },
          line: {
            tension: 0
          }
        },

        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false,
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              color: "rgba(0, 0, 0, 0.87)",
              font: {
                size: 14,
                family: "Roboto",
                weight: "normal",
                lineHeight: 1
              }
            }
          }
        }
      },
      plugins: [
        {
          id: "labelsPositions",
          afterLayout: (chart: any) => {
            const ul = document.createElement("ul");
            for (let i = 0; i < chart!.legend!.legendItems!.length; i++) {
              ul.innerHTML += `
                <li>
                  <span style="background-color: ${
                    chart!.legend!.legendItems![i].fillStyle
                  }"></span>
                  ${chart!.legend!.legendItems![i].text}
                </li>
              `;
            }

            this.$refs.legend.innerHTML = ul.outerHTML;

            const div = document.createElement("div");
            for (let i = 0; i < chart!.scales!.r!.ticks!.length; i++) {
              const { ctx } = chart;
              const top =
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                chart!.scales!.r!.yCenter -
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                (chart!.scales!.r!.drawingArea / chart!.scales!.r!.max) *
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  chart!.scales!.r!.ticks![i].value -
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                ctx.measureText(chart!.scales!.r!.ticks![i].value)
                  .actualBoundingBoxAscent /
                  2;
              const left =
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                chart!.scales!.r!.xCenter -
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                ctx.measureText(chart!.scales!.r!.ticks![i].value).width / 2;

              div.innerHTML += `
                  <span style="top: ${top}px; left: ${left}px;">${
                chart!.scales!.r!.ticks![i].label
              }</span>
                `;
            }
            this.$refs.ticks.innerHTML = div.outerHTML;

            setTimeout(() => {
              for (
                let i = 0;
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                i < chart!.scales!.r!._pointLabelItems!.length;
                i++
              ) {
                if (this.$refs.pointLabel![i]) {
                  const el = this.$refs.pointLabel[i];
                  if (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    chart!.scales!.r!._pointLabelItems!.length > 40 &&
                    i % 3
                  ) {
                    el.style.display = "none";
                  } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    const item = chart!.scales!.r!._pointLabelItems![i];
                    el!.setAttribute(
                      "style",
                      `margin-left: ${
                        item.left < 16 ? 16 : item.left
                      }px; margin-right: ${
                        item.right < 16 ? 16 : item.right
                      }px; top: ${item.top}px; opacity: 1;`
                    );
                  }
                }
              }
            }, 100);

            return true;
          }
        }
      ]
    });
  }

  protected OnClick(contextIndex: number) {
    this.$emit("click", contextIndex);
  }

  @Watch("labels")
  @Watch("datasets")
  protected updateChart() {
    this.chart.data.labels = this.labels;
    this.chart.data.datasets = this.datasets;
    this.chart.update();
  }
}

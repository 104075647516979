import {
  IsUUID,
  IsOptional,
  IsString,
  IsPositive,
  IsInt
} from "class-validator";

import TestAnswer from "./answer";

export default class TestAnswerVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsString()
  public value: string = "";

  @IsInt()
  @IsPositive()
  public version: number = 1;

  @IsInt()
  @IsPositive()
  public position: number = 1;

  public answer: TestAnswer = new TestAnswer();

  public is_selected?: boolean;

  constructor(data?: any) {
    if (data) {
      this.value = data.value;
    }
  }
}

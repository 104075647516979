
import { Component, Prop, Vue } from "vue-property-decorator";

import akt_routes from "@/api/routes/akt";

import Preloader from "@/components/Preloader.vue";
import Print from "@/components/Print.vue";
import Search from "@/components/Search.vue";
import CAktPassedTestingsEmptyResult from "./components/EmptyTestings.vue";

import {
  aktWorkplaceResultStatuses,
  EAktAssignmentUserWorkplaceResultStatus
} from "@/models/akt/assignment_user_workplace";

interface IPositionCountsData {
  success: {
    [position_id: string]: {
      position_name: string;
      count: number;
    };
  };
  failed: {
    [position_id: string]: {
      position_name: string;
      count: number;
    };
  };
}

interface IAttemptsCountsData {
  positions: {
    [position_id: string]: {
      position_name: string;
      count: number;
    };
  };
  users: {
    [user_id: string]: {
      full_name: string;
      count: number;
    };
  };
}

interface IResultsCountsData {
  all: number;
  success: number;
  failed: number;
}

interface IUserData {
  id: string; // user_id
  full_name: string;
  position_id: string;
  position_name: string;
  structure_name: string;
  user_workplace_id: string;
  assignment_user_id: string;
  assignment_user_workplace_id: string;
  attempt_number: number;
  result_status_id: EAktAssignmentUserWorkplaceResultStatus;
  current_status?: EAktAssignmentUserWorkplaceResultStatus;
  selected?: boolean;
}

interface IData {
  users: IUserData[];
  counts: IPositionCountsData;
  attempts: IAttemptsCountsData;
  results: IResultsCountsData;
}

@Component({
  name: "VAktProtocol",
  components: {
    Preloader,
    Print,
    Search,
    CAktPassedTestingsEmptyResult
  }
})
export default class VAktProtocol extends Vue {
  @Prop({ required: false, default: new Date().getFullYear() })
  private year!: number;
  @Prop({ required: false, default: false }) private only_my_team!: boolean;

  public preload: boolean = false;
  protected watchers: Function[] = [];

  public current_user: IJWTUser | null = this.$store.state.currentUser;

  public search: string = "";

  public show_filter: boolean = false;
  public filters_count: number = 0;
  public filters: Map<string, boolean> = new Map();

  public current_date: Date = new Date();
  public target_year: number = this.year;
  public target_only_my_team: boolean = this.only_my_team;
  public available_years: number[] = [this.target_year];

  public results: { [assignment_user_workplace_id: string]: IUserData } = {};
  public searched_results: IUserData[] = [];
  public positions_with_counts: IPositionCountsData = {
    success: {},
    failed: {}
  };

  public attempts_with_counts: IAttemptsCountsData = {
    positions: {},
    users: {}
  };

  public results_with_counts: IResultsCountsData = {
    all: 0,
    success: 0,
    failed: 0
  };

  public positions: Set<string> = new Set();
  public users: Set<string> = new Set();
  public all_selected: boolean = false;
  public all_selected_count: number = 0;

  public success_statuses = [
    {
      color: "green",
      icon: "check",
      value: EAktAssignmentUserWorkplaceResultStatus.SUCCESS,
      label: "Пройден"
    },
    {
      color: "gray",
      icon: "minus",
      value: EAktAssignmentUserWorkplaceResultStatus.UN_SUCCESS,
      label: "Не пройден"
    },
    {
      color: "gray",
      icon: "question",
      value: EAktAssignmentUserWorkplaceResultStatus.WAITING_VERIFY,
      label: "Ожидает проверки"
    }
  ];

  public unsuccess_statuses = [
    {
      color: "gray",
      icon: "minus",
      value: EAktAssignmentUserWorkplaceResultStatus.SUCCESS,
      label: "Пройден"
    },
    {
      color: "red",
      icon: "close-current",
      value: EAktAssignmentUserWorkplaceResultStatus.UN_SUCCESS,
      label: "Не пройден"
    },
    {
      color: "gray",
      icon: "question",
      value: EAktAssignmentUserWorkplaceResultStatus.WAITING_VERIFY,
      label: "Ожидает проверки"
    }
  ];

  protected async created() {
    this.filters.set("year", true);

    if (this.target_only_my_team) {
      this.filters.set("team", true);
    } else {
      this.filters.delete("team");
    }

    this.filters_count = this.filters.size;

    this.watchers.push(
      this.$store.watch(
        state => state.search,
        search => {
          this.search = search;
          this.searchUser();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;
        }
      )
    );

    this.preload = true;

    await Promise.all([this.loadYears(), this.loadGroupResults()]).then(
      () => (this.preload = false)
    );
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected searchUser() {
    if (this.search.length) {
      this.searched_results = Object.values(this.results).filter(r =>
        r.full_name.toLowerCase().includes(this.search.toLowerCase())
      );
    } else {
      this.searched_results = Object.values(this.results);
    }
  }

  protected async loadYears() {
    return this.$api
      .get(akt_routes.assignment_available_years)
      .then(({ data: res }: { data: number[] }) => {
        this.available_years = res;
      });
  }

  protected async loadGroupResults() {
    this.preload = true;

    this.positions = new Set();
    this.users = new Set();
    this.results = {};
    this.searched_results = [];

    return this.$api
      .get(akt_routes.get_group_results, {
        params: {
          year: this.target_year,
          only_my_team: this.target_only_my_team
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.searched_results = res.users.map(r => {
          r.current_status = r.result_status_id;
          r.selected = true;

          this.positions.add(r.position_id);
          this.users.add(r.id);
          this.results[r.assignment_user_workplace_id] = r;

          return r;
        });

        this.positions_with_counts = res.counts;
        this.attempts_with_counts = res.attempts;
        this.results_with_counts = res.results;

        this.all_selected = true;
        this.all_selected_count = res.users.length;
      })
      .finally(() => {
        this.preload = false;
      });
  }

  public async loadXlsxReport() {
    return this.$api
      .post(akt_routes.post_group_results_xlsx, {
        results: Object.values(this.results),
        positions: Array.from(this.positions),
        users: Array.from(this.users),
        positions_with_counts: this.positions_with_counts,
        results_with_counts: this.results_with_counts,
        attempts_with_counts: this.attempts_with_counts,
        year: this.target_year
      })
      .then(({ data: res }: { data: { base64: string } }) => {
        const link = document.createElement("a");
        link.download = "Протокол.xlsx";
        link.href = res.base64;
        link.click();
        link.remove();
      });
  }

  public async clearFilters() {
    this.target_year = new Date().getFullYear();
    this.target_only_my_team = this.only_my_team;

    this.filters.set("year", true);

    if (this.target_only_my_team) {
      this.filters.set("team", true);
    } else {
      this.filters.delete("team");
    }

    this.filters_count = this.filters.size;

    await this.loadGroupResults();
  }

  public async changeOnlyMyTeam() {
    if (this.target_only_my_team) {
      this.filters.set("team", true);
    } else {
      this.filters.delete("team");
    }

    this.filters_count = this.filters.size;

    await this.loadGroupResults();
  }

  public async changeYear() {
    if (!this.target_year) {
      this.target_year = this.year;
    }

    await this.loadGroupResults();
  }

  public changeAllSelected() {
    const keys = Object.keys(this.results);

    keys.forEach(r => {
      this.$set(this.results[r], "selected", this.all_selected);
    });

    if (this.all_selected) {
      this.all_selected_count = keys.length;
    } else {
      this.all_selected_count = 0;
    }

    this.searchUser();
  }

  public changeSuccessStatus(result: IUserData) {
    this.$set(
      this.results[result.assignment_user_workplace_id],
      "current_status",
      result.current_status
    );
  }

  public changeUnSuccessStatus(result: IUserData) {
    this.$set(
      this.results[result.assignment_user_workplace_id],
      "current_status",
      result.current_status
    );
  }

  public changeSelected(result: IUserData) {
    this.$set(
      this.results[result.assignment_user_workplace_id],
      "selected",
      result.selected
    );

    if (result.selected) {
      this.all_selected_count++;
    } else {
      this.all_selected_count--;
    }

    if (this.all_selected_count > 0) {
      this.all_selected = true;
    } else {
      this.all_selected = false;
    }
  }

  public namePassedPositions(position_id: string) {
    return (
      this.positions_with_counts?.success?.[position_id]?.position_name || ""
    );
  }

  public countPassedPositions(position_id: string) {
    return this.positions_with_counts?.success?.[position_id]?.count || "";
  }

  public nameFailedPositions(position_id: string) {
    return (
      this.positions_with_counts?.failed?.[position_id]?.position_name || ""
    );
  }

  public countFailedPositions(position_id: string) {
    return this.positions_with_counts?.failed?.[position_id]?.count || "";
  }

  public nameAttemptPositions(position_id: string) {
    return (
      this.attempts_with_counts?.positions?.[position_id]?.position_name || ""
    );
  }

  public countAttemptPositions(position_id: string) {
    return this.attempts_with_counts?.positions?.[position_id]?.count || "";
  }

  public nameAttemptUsers(user_id: string) {
    return this.attempts_with_counts?.users?.[user_id]?.full_name || "";
  }

  public countAttemptUsers(user_id: string) {
    return this.attempts_with_counts?.users?.[user_id]?.count || "";
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CRangeDatePicker"
})
export default class CRangeDatePicker extends Vue {
  @Prop({ required: false }) public header?: string;
  @Prop({ required: false, default: true }) public show_close_button!: boolean;
  @Prop({ required: false, default: null }) public min_date!: string | null;
  @Prop({ required: false, default: null }) public max_date!: string | null;

  public range_date: string[] = this.$store.state.rangeDate;
  public string_date: string = this.range_date.join(" - ");

  public show_date_picker: boolean = false;

  private watchers: Function[] = [];

  protected created() {
    this.watchers.push(
      this.$store.watch(
        state => state.rangeDate,
        rangeDate => {
          this.range_date = rangeDate;
          this.string_date = this.range_date.join(" - ");
        }
      )
    );
  }

  protected beforeDestroy() {
    this.show_date_picker = false;
    this.clearRange();

    this.watchers.forEach(unwatch => unwatch());
  }

  public setRange() {
    if (this.range_date.length > 1) {
      if (new Date(this.range_date[0]) > new Date(this.range_date[1])) {
        this.range_date = this.range_date.reverse();
      }
    }

    this.$store.commit("rangeDate", this.range_date);
  }

  public clearRange() {
    this.$store.commit("rangeDate", []);
  }
}

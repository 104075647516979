import { Type } from "class-transformer";

import UserUser from "../user/user";
import AktQuestionVersion from "./question_version";
import AktText from "./text";
import AktTextResult from "./text_result";

export default class AktTextVersion {
  public readonly id!: string;

  public version?: number;

  public creator_id?: string;
  @Type(() => UserUser)
  public creator?: UserUser;

  public editor_id?: string;
  @Type(() => UserUser)
  public editor?: UserUser;

  public value?: string;

  @Type(() => AktText)
  public text?: AktText;

  @Type(() => AktTextResult)
  public results?: AktTextResult[];

  public question_version?: AktQuestionVersion;

  constructor() {
    this.version = 1;
  }
}

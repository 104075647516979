
import { Component, Vue, Prop } from "vue-property-decorator";

import programs_routes from "@/api/routes/programs";
import users_routes from "@/api/routes/users";

import UserUser from "@/models/user/user";
import { EPermission } from "@/enums/permissions";

@Component({
  name: "CProgramApprovalDelegation"
})
export default class CProgramApprovalDelegation extends Vue {
  @Prop({ required: true }) private agreement_id!: string;

  public responsibles: UserUser[] = [];
  public selected_responsible_id: string | null = null;
  public selected_responsible_errors: string | null = null;

  protected async created() {
    await this.loadResponsibles();
  }

  private async loadResponsibles() {
    return this.$api
      .get(users_routes.users, {
        params: {
          only_my: false,
          paginate: false,
          exclude_current_user: true,
          permission: EPermission.constructor_permission
        }
      })
      .then(({ data: res }: { data: { users: UserUser[] } }) => {
        this.responsibles = res.users;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async delegateApproval() {
    this.selected_responsible_errors = null;

    if (!this.selected_responsible_id) {
      this.selected_responsible_errors =
        "Выберите сотрудников для согласования программы";

      return;
    }

    return this.$api
      .post(programs_routes.agreement_delegation(this.agreement_id), {
        responsible_id: this.selected_responsible_id
      })
      .then(() => {
        this.$emit("onVisited", false);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public hideDelegationModal() {
    this.$emit("close", true);
  }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import ApplicationApplication, {
  EApplicationStatuses,
  EApplicationTypes,
  EEducationTypes
} from "@/models/application/application";
import UserUser from "@/models/user/user";
import userRoutes from "@/api/routes/users";
import applicationRoutes from "@/api/routes/applications";
import SkillLocal, { ESkillDocumentTypes } from "@/models/skill/local";
import { plainToClass } from "class-transformer";
import CSkillFilter from "@/components/SkillFilter.vue";

@Component({ components: { CSkillFilter } })
export default class CNewApplication extends Vue {
  @Prop({ required: true }) protected users!: UserUser[];

  protected newApplicationError: string = "";
  protected currentApplication: ApplicationApplication = new ApplicationApplication();

  protected me: UserUser | null = null;

  protected currentApplicationType: EApplicationTypes =
    EApplicationTypes.COLLECTIVE;
  protected currentDocumentType?: ESkillDocumentTypes | null;
  protected currentEducationType?: EEducationTypes | null;

  protected currentResponsible: UserUser = new UserUser();
  protected selectedReconciliations: UserUser[] = [];
  protected selectedStudents: UserUser[] = [];

  protected appointmentUsers: Map<string, UserUser> = new Map();

  protected allUsersId: string[] = [];

  protected currentSkill?: SkillLocal = this.$store.state.currentSkill;

  protected watchers: Function[] = [];

  protected async created() {
    this.$store.commit("currentSkill", null);

    this.watchers.push(
      this.$store.watch(
        state => state.currentSkill,
        currentSkill => {
          this.currentSkill = currentSkill;
          if (currentSkill) {
            this.changeSkill();
          }
        }
      )
    );

    await Promise.all([this.loadMe(), this.loadUsers()]);
  }

  protected async loadMe() {
    return this.$api
      .get(userRoutes.userMe)
      .then(({ data: res }: { data: UserUser }) => {
        this.me = res;
      });
  }

  protected async loadUsers() {
    return this.$api
      .get(userRoutes.users_tree, {
        params: {
          factory_id: this.me?.first_workplace_factory_id
        }
      })
      .then(({ data: res }: { data: UserUser[] }) => {
        res.forEach(u => {
          this.appointmentUsers.set(u.id, plainToClass(UserUser, u));
        });
      });
  }

  protected changeSkill() {
    this.$api
      .get(applicationRoutes.current_skill_choice, {
        params: {
          skill_id: this.currentSkill!.id
        }
      })
      .then(({ data: res }: { data: UserUser[] }) => {
        this.selectedStudents = res;
        this.currentApplicationType = this.currentSkill?.application_type
          ? this.currentSkill.application_type
          : this.currentApplicationType;
        this.currentResponsible = this.currentSkill?.responsible_id
          ? this.appointmentUsers.get(this.currentSkill?.responsible_id)!
          : new UserUser();
        this.currentDocumentType = this.currentSkill?.document_type
          ? this.currentSkill.document_type
          : ESkillDocumentTypes.GROUP;
        this.currentEducationType = this.currentSkill?.education_type
          ? this.currentSkill.education_type
          : EEducationTypes.EXTERNAL;
      })
      .catch(({ response: res }) => {
        this.newApplicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async createNewApplication() {
    if (!this.$store.state.currentSkill) {
      this.newApplicationError = "Не выбран навык";
      return;
    }

    if (!this.currentResponsible.id) {
      this.newApplicationError = "Не выбран ответсвенный";
      return;
    }

    if (this.selectedReconciliations.length < 1) {
      this.newApplicationError = "Минимум один согласующий";
      return;
    }

    if (this.selectedStudents.length < 1) {
      this.newApplicationError = "Минимум один обучающийся";
      return;
    }

    if (!this.me?.workplaces?.length) {
      this.newApplicationError = "Вам не назначено рабочее место";
      return;
    }

    this.allUsersId = [];
    this.allUsersId.push(...this.selectedStudents.map(i => i.id!));
    this.allUsersId.push(...this.selectedReconciliations.map(i => i.id!));
    this.allUsersId.push(this.me!.id!);
    this.allUsersId.push(this.currentResponsible!.id!);

    return this.$api
      .post(applicationRoutes.applications, {
        skill_id: this.$store.state.currentSkill?.id,
        workplace_id: this.me!.workplaces?.[0].id,
        author_id: this.me!.id,
        application_type: this.currentApplicationType,
        education_type: this.currentEducationType,
        documentary_evidence: this.$store.state.currentSkill
          ?.documentary_evidence,
        document_type: this.currentDocumentType,
        responsible_id: this.currentResponsible!.id,
        reconciliations: this.selectedReconciliations,
        students: this.selectedStudents,
        all_users: this.allUsersId,
        comments: [],
        status: EApplicationStatuses.APPROVAL
      })
      .then(({ data: res }: { data: ApplicationApplication }) => {
        this.$emit("add", plainToClass(ApplicationApplication, res));
        this.hideNewApplication();
      })
      .catch(({ response: res }) => {
        this.newApplicationError = res.data.error;

        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected hideNewApplication() {
    this.$emit("hide", true);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}

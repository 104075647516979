
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CPositionLevelFactory from "./Factory.vue";
import CPositionLevelFactoryUec from "./FactoryUec.vue";

import StructureFactory from "@/models/structure/factory";

import structureRoutes from "@/api/routes/structures";

@Component({
  name: "CPositionLevelStructure",
  components: {
    Preloader,
    CPositionLevelFactory,
    CPositionLevelFactoryUec
  }
})
export default class CPositionLevelStructure extends Vue {
  protected preload: boolean = false;
  protected factories: StructureFactory[] = [];

  protected async created() {
    this.preload = true;

    await this.loadFactories();

    this.preload = false;
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        res.forEach(r => (r.is_show = false));

        this.factories = res;
      });
  }

  protected async toggleFactory(factory_index: number) {
    const factory = this.factories[factory_index];

    factory.is_show = !factory.is_show;
  }
}

import { IsUUID, IsString, IsOptional } from "class-validator";
import PracticalPractical from "./practical";
import { EFormatFile } from "@/enums/fileTypes";

export default class PracticalVersion {
  @IsUUID("4")
  public readonly id!: string;

  @IsOptional()
  @IsString()
  public url?: string;

  @IsString()
  public description?: string;

  public practical?: PracticalPractical;

  @IsString()
  public file_type: string = "";

  public isPdf() {
    return this.file_type === EFormatFile.pdf;
  }

  public isVideo() {
    return this.file_type === EFormatFile.mp4;
  }

  public isAudio() {
    return (
      this.file_type === EFormatFile.mpeg || this.file_type === EFormatFile.wav
    );
  }

  public isImage() {
    return (
      this.file_type === EFormatFile.jpeg ||
      this.file_type === EFormatFile.jpg ||
      this.file_type === EFormatFile.bmp ||
      this.file_type === EFormatFile.png
    );
  }

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.url = data.url;
      this.description = data.description;
    }
  }
}

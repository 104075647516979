
import { Component, Vue } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";
import CStaffsAndGroupStaffsFactory from "./Factory.vue";
import CStaffsAndGroupStaffsFactoryUec from "./FactoryUec.vue";

import StructureFactory from "@/models/structure/factory";
import structureRoutes from "@/api/routes/structures";
import userRoutes from "@/api/routes/users";
import UserUser from "@/models/user/user";

import { EPermission } from "@/enums/permissions";

@Component({
  name: "CStaffsAndGroupStaffsStructure",
  components: {
    Preloader,
    CStaffsAndGroupStaffsFactory,
    CStaffsAndGroupStaffsFactoryUec
  }
})
export default class CStaffsAndGroupStaffsStructure extends Vue {
  protected preload: boolean = false;
  protected factories: StructureFactory[] = [];
  protected users: UserUser[] = [];

  protected watchers: Function[] = [];

  protected async created() {
    this.preload = true;

    this.watchers.push(
      this.$store.watch(
        state => state.editedTreeUser,
        (editedTreeUser: UserUser) => {
          const userIndex = this.users.findIndex(
            u => u.id === editedTreeUser.id
          );

          if (userIndex !== -1) {
            if (editedTreeUser.workplaces?.length) {
              this.$delete(this.users, userIndex);
            } else {
              this.users[userIndex].full_name = editedTreeUser.full_name;
              this.users[userIndex].position_name =
                editedTreeUser.position_name;
              this.users[userIndex].signs = editedTreeUser.signs;
            }
          } else {
            if (!editedTreeUser.workplaces?.length) {
              this.users.push({
                id: editedTreeUser.id,
                full_name: editedTreeUser.full_name,
                email: editedTreeUser.email,
                identification_number: editedTreeUser.identification_number,
                position_name: editedTreeUser.position_name,
                signs: editedTreeUser.signs
              } as UserUser);
            }
          }
        }
      )
    );

    await Promise.all([this.loadUsers(), this.loadFactories()]);

    this.preload = false;
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        res.forEach(r => (r.is_show = false));

        this.factories = res;
      });
  }

  protected async toggleFactory(factory_index: number) {
    const factory = this.factories[factory_index];

    factory.is_show = !factory.is_show;
  }

  protected async loadUsers() {
    return this.$api
      .get(userRoutes.users_tree)
      .then(({ data: res }: { data: UserUser[] }) => {
        this.users = res;
      });
  }

  protected async beforeUpdateUser(user_index: number) {
    const user = this.users[user_index];

    this.$store.commit("editTreeUser", {
      id: user.id,
      full_name: user.full_name,
      action: "user"
    });
  }

  protected async beforeAddInGroup(user_index: number) {
    const user = this.users[user_index];

    this.$store.commit("editTreeUser", {
      id: user.id,
      full_name: user.full_name,
      action: "group"
    });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.staffs_and_groups);
  }
}

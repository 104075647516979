
import { Component, Vue, Prop } from "vue-property-decorator";

import Preloader from "@/components/Preloader.vue";

import groupRoutes from "@/api/routes/groups";
import GroupGroup from "@/models/group/group";

@Component({
  components: {
    Preloader
  }
})
export default class CGroupEdit extends Vue {
  @Prop({ required: true }) protected user_id!: string;
  @Prop({ required: true }) protected full_name!: string;

  protected preload: boolean = false;

  protected groups: GroupGroup[] = [];
  protected selectedGroup: GroupGroup = new GroupGroup();
  protected editUserErrors: string | null = null;

  protected async created() {
    this.preload = true;

    await this.loadGroups();

    this.preload = false;
  }

  protected mounted() {
    this.showGroupModal();
  }

  protected async loadGroups() {
    return this.$api
      .get(groupRoutes.groups, {
        params: {
          user_id: this.user_id
        }
      })
      .then(({ data: res }: { data: GroupGroup[] }) => {
        this.groups = res;
      });
  }

  public async addInGroup() {
    return this.$api
      .post(groupRoutes.users, {
        user_id: this.user_id,
        group_id: this.selectedGroup.id
      })
      .then(() => {
        this.hideGroupModal();
      })
      .catch(({ response: res }) => {
        this.editUserErrors = res.data.error;
      });
  }

  protected showGroupModal() {
    this.$modal.show("editGroup");
  }

  protected hideGroupModal() {
    this.$emit("closeModal", { user_id: this.user_id });
    this.$modal.hide("editGroup");
  }
}

import {
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID
} from "class-validator";
import {
  EApplicationTypes,
  EEducationTypes
} from "@/models/application/application";
import UserUser from "@/models/user/user";
import { IBlockSkill } from "../block/block";

export enum ESkillCategory {
  ALL = 0,
  METHODOLOGICAL = 1,
  FUNCTIONAL = 2,
  COMMON = 3,
  COMPULSORY_EDUCATION = 4
}

export const skillDocumentTypes = [
  {
    label: "Групповой",
    value: 1
  },
  {
    label: "Индивидуальный",
    value: 2
  }
];

export enum ESkillDocumentTypes {
  GROUP = 1,
  INDIVIDUAL = 2
}

export interface IBlockGroup {
  readonly id: string;
  readonly full_name: string;
  readonly skills_count: number;
  include_skill: boolean;
}

export interface ISignSign {
  readonly id: string;
  readonly full_name: string;
}

export default class SkillLocal {
  @IsUUID("4")
  public readonly id!: string;

  @IsEnum(ESkillCategory)
  public category_id!: ESkillCategory;

  @IsOptional()
  @IsUUID("4")
  public readonly origin_id?: string;

  @IsOptional()
  @IsUUID("4")
  public pillar_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public factory_id?: string | null;

  public readonly factory_name?: string;

  @IsOptional()
  @IsUUID("4")
  public responsible_id?: string;

  public responsible?: UserUser;

  @IsOptional()
  @IsUUID("4")
  public workshop_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public workline_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public equipment_id?: string | null;

  @IsOptional()
  @IsUUID("4")
  public infinity_id?: string | null;

  @IsOptional()
  @IsBoolean()
  public is_local?: boolean;

  @IsBoolean()
  public is_archived: boolean = false;

  @IsOptional()
  @IsBoolean()
  public is_compulsory_education: boolean = false;

  @IsOptional()
  @IsBoolean()
  public documentary_evidence: boolean = false;

  @IsOptional()
  @IsEnum(ESkillDocumentTypes)
  public document_type?: ESkillDocumentTypes | null;

  @IsOptional()
  @IsEnum(EApplicationTypes)
  public application_type?: EApplicationTypes | null;

  @IsOptional()
  @IsEnum(EEducationTypes)
  public education_type?: EEducationTypes | null;

  @IsNumber()
  public first_notification: number = 3;

  @IsNumber()
  public next_notification: number = 1;

  @IsNumber()
  public deadline: number = 10;

  @IsString()
  public readonly pillar_name?: string = "";

  @IsString()
  public full_name: string = "";

  @IsString()
  public workplace_full_name: string = "";

  @IsString()
  public readonly pillar_color?: string = "";

  public signs?: ISignSign[];

  public blocks?: IBlockGroup[];

  public priority: number = 1000;

  @IsUUID("4")
  public skill_id?: string;

  public is_show?: boolean;

  public deleted?: boolean = false;

  public get isOpenEducationSkill() {
    return (
      this.is_compulsory_education &&
      this.education_type === EEducationTypes.EXTERNAL
    );
  }
}

export function isLocalFunctional(skill: IBlockSkill) {
  return skill.category_id === ESkillCategory.FUNCTIONAL && !skill.origin_id;
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import Preloader from "@/components/Preloader.vue";
import SubscriptionUnitDocument from "./unit/Document.vue";
import SubscriptionUnitTest from "./unit/Test.vue";
import SubscriptionUnitPractical from "./unit/Practical.vue";
import SubscriptionUnitEvent from "./unit/Event.vue";
import SubscriptionUnitScheduleStatus from "./unit/SheduleStatus.vue";

import { EDeviceTypes } from "@/enums/deviceTypes";

import subscriptionRoutes from "@/api/routes/subscriptions";
import SubscriptionProgram from "@/models/subscriptions/program";
import SubscriptionUnit from "@/models/subscriptions/unit";

import { EUnitableType } from "@/models/unit/unit_version";
import CFileDownload from "@/components/FileDownload.vue";

@Component({
  components: {
    Preloader,
    SubscriptionUnitDocument,
    SubscriptionUnitTest,
    SubscriptionUnitPractical,
    SubscriptionUnitEvent,
    SubscriptionUnitScheduleStatus,
    CFileDownload
  },
  data: () => {
    return {
      EDeviceTypes
    };
  }
})
export default class SubscriptionsUnit extends Vue {
  @Prop({ required: true }) protected id!: string;
  @Prop({ required: true }) protected unit_id!: string;
  @Prop({ required: true }) protected level!: number;

  protected preload: boolean = false;

  protected reassignUnitId?: string;

  protected mobileDescShow: boolean = false;

  protected nextUnitId: string = "";

  protected subscription_program: SubscriptionProgram | null = null;
  protected subscription_unit: SubscriptionUnit | null = null;

  protected isDocument(unitable_type: EUnitableType) {
    return EUnitableType["Document::Version"] === unitable_type;
  }

  protected isTest(unitable_type: EUnitableType) {
    return EUnitableType["Test::Version"] === unitable_type;
  }

  protected isPractical(unitable_type: EUnitableType) {
    return EUnitableType["Practical::Version"] === unitable_type;
  }

  protected isEvent(unitable_type: EUnitableType) {
    return EUnitableType["Event::Version"] === unitable_type;
  }

  protected async created() {
    await this.loadData();
  }

  @Watch("unit_id")
  protected async loadData() {
    this.preload = true;

    await Promise.all([
      this.loadSubscriptionProgram(),
      this.loadSubscriptionUnit(),
      this.loadNotStartedUnit()
    ]);
    this.preload = false;
  }

  protected async reassign() {
    await this.$api
      .get(subscriptionRoutes.reassign(this.unit_id))
      .then(({ data: res }: { data: SubscriptionUnit }) => {
        this.$router.push({
          name: "subscription_unit",
          params: {
            id: this.id,
            unit_id: res.id,
            level: this.level.toString()
          }
        });
      });
  }

  protected async loadSubscriptionProgram() {
    return this.$api
      .get(subscriptionRoutes.subscription_program(this.id), {
        params: {
          units: false
        }
      })
      .then(({ data: res }: { data: SubscriptionProgram }) => {
        this.subscription_program = res;
      });
  }

  protected async loadSubscriptionUnit() {
    return this.$api
      .get(subscriptionRoutes.subscription_unit(this.unit_id), {
        params: {
          units: true,
          results: true
        }
      })
      .then(({ data: res }: { data: SubscriptionUnit }) => {
        this.subscription_unit = plainToClass(SubscriptionUnit, res);
      });
  }

  protected async loadNotStartedUnit() {
    return this.$api
      .get(subscriptionRoutes.not_started_unit(this.id), {
        params: {
          this_unit_id: this.unit_id,
          level: this.level
        }
      })
      .then(({ data: res }: { data: string }) => {
        this.nextUnitId = res;
      });
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"v-user-catalog"}},[_c('div',{staticClass:"breadcrumbs d-xs-none"},[_c('router-link',{attrs:{"to":{ name: 'root' }}},[_vm._v("Главная")]),_c('i'),_c('router-link',{attrs:{"to":{ name: 'me_examination' }}},[_vm._v("Проверка знаний")]),_c('i'),_c('span',[_vm._v("Тесты")])],1),_vm._m(0),_c('div',{staticClass:"tabs flex-none t-xs-0"},_vm._l((_vm.links),function(link){return _c('router-link',{key:link.id,attrs:{"to":link.to,"id":link.id,"tag":"div"}},[_vm._v(_vm._s(link.title))])}),1),_c('Preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.preload),expression:"preload"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preload),expression:"!preload"}],class:{ 'd-flex flex-column flex-auto': _vm.assignments.length === 0 }},[(_vm.assignments.length)?[_vm._m(1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.infiniteHandler),expression:"infiniteHandler"}],attrs:{"infinite-scroll-distance":"10","infinite-scroll-throttle-delay":"200"}},_vm._l((_vm.assignments),function(assignment){return _c('router-link',{key:assignment.id,staticClass:"skill_item",attrs:{"to":{
            name: 'user_pass_testing',
            params: { id: assignment.id }
          },"tag":"div"}},[_c('div',{staticClass:"row align-items-center align-items-xs-start min-height-24 font-12"},[_c('div',{staticClass:"col-auto d-xs-none"},[(assignment.ends_at)?[_c('div',{staticClass:"w-110"},[_vm._v(_vm._s(_vm._f("toDate")(assignment.ends_at)))])]:[_c('div',{staticClass:"w-110"},[_vm._v("Нет")])]],2),_c('div',{staticClass:"col font-xs-12"},[_c('span',{staticClass:"d-block font-xs-wrap"},[_vm._v(_vm._s(assignment.testing_version.name))]),(assignment.ends_at)?_c('span',{staticClass:"d-none d-xs-block t-2 font-gray"},[_vm._v(_vm._s(_vm._f("toDate")(assignment.ends_at)))]):_vm._e()]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-140 w-xs-120 align-items-center d-flex",class:_vm.normalizedTestingAssignmentStatusColor(assignment.status)},[_c('inline-svg',{staticClass:"w-20 h-20 d-sm-none d-xs-block br-xs-6",attrs:{"src":'/img/icon-status-assignment-' +
                      assignment.status +
                      '.svg'}}),_c('div',{staticClass:"bl-4 font-normal bl-sm-0 max-width-sm-90 font-ellipsis"},[_vm._v(" "+_vm._s(_vm._f("normalizedTestingAssignmentStatus")(assignment.status))+" ")])],1)])])])}),1)]:[_c('div',{staticClass:"t-40 flex-auto d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"no-found-icon"},[_c('inline-svg',{staticClass:"w-40 h-40",attrs:{"src":'/img/icon-menu-me_examination.svg'}})],1),_c('h4',{staticClass:"t-24 font-superbold font-18"},[_vm._v("Назначения отсутствуют")]),_vm._m(2)])]],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row align-items-center middle d-xs-none flex-none"},[_c('div',{staticClass:"col col-sm col-xs-12"},[_c('h1',[_vm._v("Тесты")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row align-items-center h-36 font-10 font-gray b-8 d-xs-none"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-110"},[_vm._v("Дата окончания")])]),_c('div',{staticClass:"col"},[_vm._v("Название")]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"w-140"},[_vm._v("Статус")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"t-8 font-14 align-center"},[_vm._v(" Здесь будут отображаться "),_c('br'),_vm._v("назначенные вам тесты ")])
}]

export { render, staticRenderFns }
export enum EFormatFile {
  mp4 = "mp4",
  mpeg = "mp3",
  wav = "wav",
  jpeg = "jpeg",
  jpg = "jpg",
  bmp = "bmp",
  png = "png",
  pdf = "pdf"
}

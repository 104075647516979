import { IsOptional, IsString, IsUUID } from "class-validator";

export default class SkillLearning {
  @IsOptional()
  @IsUUID("4")
  public readonly id?: string | null;

  @IsUUID("4")
  public user_id?: string | null;

  @IsUUID("4")
  public skill_id?: string | null;

  @IsString()
  public full_name?: string | null;
}


import { Component, Prop, Vue } from "vue-property-decorator";

import { phases } from "@/consts";

import { IBlockSkill } from "@/models/block/block";

@Component
export default class CSkillRadio extends Vue {
  @Prop({ required: true }) skill!: IBlockSkill;
  @Prop({ required: true }) idElement!: string;
  @Prop({ required: true }) prefix!: string;
  @Prop({ required: false }) disabled_level?: number;
  @Prop({ required: false, default: () => false }) show_another_level!: boolean;

  protected phases = phases;

  protected watchers: Function[] = [];

  protected created() {
    if (this.skill!.level! < this.disabled_level!) {
      this.skill.level = this.disabled_level;
    }

    this.watchers.push(
      this.$store.watch(
        state => state.editedSkillReload,
        () => {
          if (
            this.$store.state.editedSkillIdElement === this.idElement &&
            this.prefix === this.$store.state.editedSkillPrefix
          ) {
            this.skill.level = this.$store.state.editedSkillLevel;
          }
        }
      )
    );
  }

  protected changeLevel(level: number) {
    this.$store.commit("editedSkillReload", Date.now());
    this.$store.commit("editedSkillLevel", level);
    this.$store.commit("editedSkillPrefix", this.prefix);
    this.$store.commit("editedSkillId", this.skill.id);
    this.$store.commit("editedSkillIdElement", this.idElement);

    this.skill.is_manual = true;
    this.$emit("input", this.skill);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }
}


import { Component, Vue } from "vue-property-decorator";
import { classToClass, plainToClass } from "class-transformer";

import { kpiSettingRoutes } from "@/router/kpi";

import Preloader from "@/components/Preloader.vue";
import CWorkplacePlaceholder from "@/components/WorkplacePlaceholder.vue";

import kpiRoutes from "@/api/routes/kpi";

import KpiTemplate from "@/models/kpi/template";
import KpiSetting from "@/models/kpi/setting";
import { EPermission } from "@/enums/permissions";
import userRoutes from "@/api/routes/users";

@Component({
  components: {
    Preloader,
    CWorkplacePlaceholder
  }
})
export default class VKpiSetting extends Vue {
  protected preload: boolean = false;

  protected templates: KpiTemplate[] = [];

  protected currentTemplate: KpiTemplate | null = null;
  protected editSetting: KpiSetting | null = null;

  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected links = kpiSettingRoutes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  protected workplaceExist: boolean = true;

  protected watchers: Function[] = [];

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    this.WorkplaceExist().then(
      async ({ data: res }: { data: { is_exist: boolean } }) => {
        this.workplaceExist = res.is_exist;

        if (res.is_exist) {
          await this.loadTemplates();
        }
      }
    );
  }

  protected async WorkplaceExist() {
    return this.$api.get(userRoutes.workplace_exists(this.currentUser?.id!));
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async loadTemplates() {
    this.preload = true;

    return this.$api
      .get(kpiRoutes.settings)
      .then(({ data: res }: { data: KpiTemplate[] }) => {
        this.templates = plainToClass(KpiTemplate, res);

        this.currentTemplate = this.templates[0];
        this.editSetting = classToClass(this.currentTemplate.setting!);
      })
      .finally(() => {
        this.preload = false;
      });
  }

  protected changeTemplate() {
    this.editSetting = classToClass(this.currentTemplate!.setting!);
  }

  protected async updateSetting() {
    if (!this.canWrite()) {
      return;
    }

    return this.$api
      .put(kpiRoutes.setting(this.editSetting!.id), this.editSetting)
      .then(({ data: res }: { data: KpiSetting }) => {
        this.currentTemplate!.setting = plainToClass(KpiSetting, res);

        this.$notify({
          group: "notifications",
          type: "success",
          text: "Изменения сохранены",
          speed: 500
        });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected canWrite() {
    return this.$api.canWrite(EPermission.kpi_settings);
  }
}
